import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Badge, Drawer, List, ListItem, ListItemText, IconButton, Box, Typography, Stack, CardMedia, ListItemButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { RootState, AppDispatch } from 'redux/store';
import { fetchProductById } from 'redux/product/productActions';
import { addItem, maxProductAllowed } from 'redux/reducers/cartReducer';
import { updateItemQuantity, removeItem, deleteItem } from 'redux/reducers/cartReducer';
import { getProductImgSm } from 'utils/getNames';
import { Product } from 'redux/product/ProductsTypes';
import ProductPriceTag from 'components/ProductPriceTag';
import QuantityControl from './QuantityControl';
import AvailablePayments from 'components/AvailablePayments';

function CartButton() {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const itemCount = useSelector((state: RootState) => state.cart.items.reduce((total, item) => total + item.quantity, 0));
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [productDetails, setProductDetails] = useState<{ [key: string]: Product & { platform: any } }>({});
  const [removedItems, setRemovedItems] = useState<string[]>([]);
  const prevItemCountRef = React.useRef(itemCount);
  const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
  const regions = useSelector((state: RootState) => state.productInfo.region.regions);
  const cartTotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  useEffect(() => {
    if (itemCount > prevItemCountRef.current && location.pathname !== '/cart') {
      setDrawerOpen(true);
    }
    prevItemCountRef.current = itemCount;
  }, [itemCount, location.pathname]);

  useEffect(() => {
    cartItems.forEach(item => {
      if (!removedItems.includes(item.id)) {
        async function getProduct() {
          const product = await dispatch(fetchProductById(item.productId)).unwrap() as Product;
          const platformDetails = product.platforms.find(p => p._id === item.platformGroupId);
          setProductDetails(prev => ({
            ...prev,
            [item.id]: {
              ...product,
              platform: platformDetails
            }
          }));
        }
        getProduct();
      }
    });
  }, [dispatch, cartItems, removedItems]);

  const handleAddMore = (id: string) => {
    const details = productDetails[id];
    if (details) {
      dispatch(addItem({
        id: id,
        productId: details._id,
        platformGroupId: details.platform._id,
        price: details.platform.price,
        quantity: 1
      }));
    }
  };

  const handleQuantityChange = (id: string, newQuantity: number) => {
    dispatch(updateItemQuantity({
      id,
      quantity: newQuantity
    }));
  };

  const handleRemove = (id: string) => {
    dispatch(removeItem({ id: id }));
  };

  const handleDelete = (id: string) => {
    dispatch(deleteItem({ id: id }));
    setRemovedItems(prev => [...prev, id]);
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        sx={{ minWidth: '32px', height: '32px', p: '4px', color: 'text.primary' }}
        component={RouterLink}
        to="/cart"
      >
        <Badge badgeContent={itemCount} color="secondary" invisible={itemCount === 0}>
          <ShoppingCartIcon />
        </Badge>
      </Button>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            margin: '25px',
            height: 'calc(100vh - 50px)',
            borderRadius: '21px',
          },
        }}
      >
        <Box sx={{
          width: {
            xs: '100%',
            md: 450,
          }, position: 'relative', height: '100%', display: 'flex', flexDirection: 'column'
        }}>
          <IconButton onClick={toggleDrawer(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ p: 2 }}>
            {t('yourCart')}
          </Typography>
          <List>
            {cartItems.length === 0 && (
              <ListItem>
                <ListItemText primary={t('emptyCartSubheading')} />
              </ListItem>
            )}
            {cartItems.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton>
                  <CardMedia
                    component="img"
                    image={getProductImgSm(item.productId)}
                    alt={productDetails[item.id]?.title}
                    sx={{ width: 120, height: 55, margin: 'auto', objectFit: 'cover', marginRight: 2, borderRadius: '8px' }}
                  />

                  <ListItemText
                    primary={productDetails[item.id]?.title}
                  />
                  <Stack direction="column" spacing={1} alignItems="center">
                    <ProductPriceTag productTagPrice={item.price.toFixed(2)} currencyPadding={'0'} variant="body2" />
                    <QuantityControl
                      size="small"
                      maxProductAllowed={maxProductAllowed}
                      quantity={item.quantity}
                      onAdd={() => handleAddMore(item.id)}
                      onRemove={() => handleRemove(item.id)}
                      onDelete={() => handleDelete(item.id)}
                      onQuantityChange={(newQuantity) => handleQuantityChange(item.id, newQuantity)}
                    />
                  </Stack>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Stack sx={{ p: 2, marginTop: 'auto' }} spacing={3}>
            <Stack direction="column" spacing={1} sx={{ mb: 5 }}>
              <Typography variant="h5" textAlign={'right'} display={'flex'} justifyContent={'space-between'}>
                {t('total')}:
                <ProductPriceTag productTagPrice={cartTotal.toFixed(2)} variant="h5" />
              </Typography>
              <Typography variant="h5" textAlign={'right'} display={'flex'} color={'text.secondary'} justifyContent={'space-between'}>
                {t('discount')}:
                <ProductPriceTag productTagPrice={'0'} variant="h5" />
              </Typography>
              <Typography variant="h5" textAlign={'right'} display={'flex'} justifyContent={'space-between'}>
                {t('total')}:
                <ProductPriceTag productTagPrice={cartTotal.toFixed(2)} variant="h5" />
              </Typography>
            </Stack>

            <Button
              variant="outlined"
              color="primary"
              component={RouterLink}
              startIcon={<ShoppingCartIcon />}
              to="/cart"
              fullWidth
              onClick={toggleDrawer(false)}
            >
              {t('goToCart')}
            </Button>
            {user && (
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                startIcon={<CreditCardIcon />}
                to="/cart/payment"
                fullWidth
                onClick={toggleDrawer(false)}
              >
                {t('goToPayment')}
              </Button>
            )}
          </Stack>
          <AvailablePayments />
        </Box>
      </Drawer >
    </>
  );
}

export default CartButton;
