import { useThemeContext } from './ThemeContext';

const useToggleTheme = () => {
  const { toggleColorMode, mode } = useThemeContext();
  
  const setMode = (newMode: 'light' | 'dark' | 'auto') => {
    toggleColorMode(newMode);
  };

  return { setMode, mode };
};

export default useToggleTheme;
