import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Typography, Card, Autocomplete, Link, Stack } from '@mui/material';
import { register, login } from 'redux/auth/authThunks';
import { addFavorite } from 'redux/auth/updateUserThunks';
import { RootState, AppDispatch } from 'redux/store';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { fetchProductById } from 'redux/product/productActions';
import { makeUrlSafe, getPlatformName, getRegionName, getTypeName } from 'utils/getNames';
import countries from 'utils/countries.json';

const RegisterForm: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState<{ name: string; code: string } | null>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, error } = useSelector((state: RootState) => state.auth);
  const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
  const regions = useSelector((state: RootState) => state.productInfo.region.regions);
  const types = useSelector((state: RootState) => state.productInfo.type.types);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      console.error('Passwords do not match');
      return;
    }
    const trimmedEmail = email.trim();

    dispatch(register({ firstName, lastName, birthday, address, city, zip, country: country?.code, email: trimmedEmail, password, confirmPassword })).then(async (action: any) => {
      if (register.fulfilled.match(action)) {
        dispatch(login({ email: trimmedEmail, password })).then(async (loginAction: any) => {
          if (login.fulfilled.match(loginAction)) {
            const params = new URLSearchParams(location.search);
            const redirect = params.get('redirect');
            const productId = params.get('productId');
            const platformGroupId = params.get('platformGroupId');

            if (redirect === 'product' && productId && platformGroupId) {
              try {
                const product = await dispatch(fetchProductById(productId)).unwrap();
                const platform = product.platforms.find((p: any) => p._id === platformGroupId);
                if (!platform) {
                  console.error("Platform not found");
                  navigate('/dashboard', { replace: true });
                  return;
                }

                const category = platform.category;
                const typeName = makeUrlSafe(getTypeName(product.type, types));
                const productName = makeUrlSafe(product.title);
                const productPlatform = getPlatformName(platform.platformId, platforms);
                const productRegion = getRegionName(platform.region, regions);

                await dispatch(addFavorite({ productId, platformGroupId }));
                navigate(`/${typeName}/${productId}/${productName}/${category}/${productPlatform}/${productRegion}`, { replace: true });
              } catch (error) {
                console.error("Failed to fetch product details or add favorite:", error);
                navigate('/dashboard', { replace: true });
              }
            } else if (location.pathname === '/cart/login') {
              navigate('/cart/payment', { replace: true });
            } else {
              navigate('/dashboard');
            }
          } else {
            console.error(loginAction.payload);
          }
        });
      } else {
        console.error(action.payload);
      }
    });
  };

  return (
    <Card elevation={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5">Sign Up</Typography>
      <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 8 }}>
        <TextField
          margin="normal"
          required
          size="small"
          fullWidth
          label="First Name"
          name="firstName"
          autoComplete="given-name"
          autoFocus
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label="Last Name"
          name="lastName"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label="Birthday"
          name="birthday"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label="Address"
          name="address"
          autoComplete="address-line1"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Stack direction="row" spacing={2} sx={{ width: '100%', mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            label="City"
            name="city"
            autoComplete="address-level2"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            label="Zip Code"
            name="zip"
            autoComplete="postal-code"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
          />
        </Stack>
        <Autocomplete
          options={countries}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Country" size="small" margin="normal" required />}
          value={country}
          onChange={(event, newValue) => setCountry(newValue)}
          fullWidth
        />
        <TextField
          margin="normal"
          required
          size="small"
          fullWidth
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          size="small"
          fullWidth
          name="password"
          label="Password"
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          autoComplete="new-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {error && <Typography color="error">{error}</Typography>}
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isLoading}>
          Sign Up
        </Button>
      </form>
      <Link component={RouterLink} to={{ pathname: "/login", search: location.search }}>Already have an account? Sign in</Link>
    </Card>
  );
};

export default RegisterForm;
