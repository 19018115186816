import { Container, Typography } from '@mui/material';
import { Link as MaterialLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export default function TermsPage() {
    return (
        <>
            <Container
                id="terms"
                sx={{
                    mb: 8,
                    pt: { xs: 4, sm: 20 },
                    pb: { xs: 8, sm: 16 },
                    position: 'relative',
                    alignItems: 'center',
                    gap: { xs: 2, sm: 3 },
                }}
            >
                <Typography component="h2" variant="h4"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Terms and Conditions of Bigswiss GmbH
                </Typography>
                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Last Updated: 12 Sep 2024
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    These Terms and Conditions ("Terms") govern your access to and use of the services provided by Bigswiss GmbH ("we," "us," "our"). By accessing or using any part of our services, including but not limited to our online shop, wholesale store, kiosk applications, and API store, you ("the User") agree to be bound by these Terms.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    1. Use of Services
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    The services provided by Bigswiss GmbH are subject to these Terms, as well as applicable local, national, and international laws and regulations. Any breach of these Terms may result in the suspension or termination of access to the services without prior notice.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    2. Account Registration
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    Users may be required to create an account to access certain features of the services. Users are responsible for ensuring the confidentiality of their account credentials and agree to accept full responsibility for any activity conducted under their account.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    3. Prohibition of Reselling
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    The resale of digital codes or products purchased from Bigswiss GmbH is expressly prohibited. Any attempt to resell such products constitutes a material breach of these Terms and may result in immediate account termination and legal action.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    4. Fraud Prevention and Data Sharing
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    To safeguard against fraudulent activities, Bigswiss GmbH reserves the right to share user data, including but not limited to usernames and IP addresses, with game distributors and other relevant partners. By using our services, you expressly consent to this data sharing for fraud prevention purposes.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    5. Refunds and Replacements
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    Upon the revelation of a product key, the User forfeits the right to request a refund or replacement. Refunds shall only be granted in the event that the key is determined to be invalid or defective, subject to verification by Bigswiss GmbH.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    6. Limitation of Liability
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    To the maximum extent permitted by applicable law, Bigswiss GmbH shall not be held liable for any indirect, incidental, punitive, or consequential damages arising from or related to the use of our services, even if we have been advised of the possibility of such damages.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    7. Governing Law
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    These Terms shall be governed and construed in accordance with the laws of Switzerland, without regard to its conflict of law principles. Any disputes arising from these Terms shall be subject to the exclusive jurisdiction of the courts of Zurich, Switzerland.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    8. Dispute Resolution
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    Any dispute or claim arising out of or in connection with these Terms shall first be attempted to be resolved through good-faith negotiations. Should such negotiations fail, the dispute shall be resolved by binding arbitration under the rules of the Swiss Arbitration Association.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    9. Force Majeure
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    Bigswiss GmbH shall not be held responsible for any delay or failure to perform its obligations under these Terms due to circumstances beyond its reasonable control, including but not limited to acts of God, war, strikes, or government regulations.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    10. Severability
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    If any provision of these Terms is found to be unenforceable by a court of competent jurisdiction, such provision shall be modified to reflect the parties' intention, and all remaining provisions shall remain in full force and effect.
                </Typography>

                <Typography component="h2" variant="h5"  sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    11. Contact Us
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    If you have any questions regarding these Terms, please contact us at <MaterialLink color="text.primary" component={RouterLink} to="mailto:info@bigswiss.ch"> info@bigswiss.ch</MaterialLink>.
                </Typography>
            </Container>
        </>
    );
}
