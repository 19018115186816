import { combineReducers } from 'redux';
import publisherReducer from './publisherReducer';
import genreReducer from './genreReducer';
import developerReducer from './developerReducer';
import categoryReducer from './categoryReducer';
import platformReducer from './platformReducer';
import regionReducer from './regionReducer';
import productTypeReducer from './productTypeReducer';
import ratingReducer from './ratingReducer';

const productInfoReducer = combineReducers({
    category: categoryReducer,
    platform: platformReducer,
    region: regionReducer,
    publisher: publisherReducer,
    genre: genreReducer,
    developer: developerReducer,
    rating: ratingReducer,
    type: productTypeReducer,
});

export default productInfoReducer;
