import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import logoDark from 'assets/LogoDark.svg';
import logoLight from 'assets/LogoLight.svg';
import { useThemeContext } from 'theme/ThemeContext';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const Logo: React.FC<LogoProps> = ({ ...rest }) => {
  const { mode } = useThemeContext();
  const isSystemDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  const getLogoSrc = () => {
    if (mode === 'light') return logoDark;
    if (mode === 'dark') return logoLight;
    return isSystemDarkMode ? logoLight : logoDark;
  };

  return (
    <Box {...rest} component={RouterLink} to="/">
      <img src={getLogoSrc()} alt="Logo" style={{ width: '140px', height: '30.5px', cursor: 'pointer' }} />
    </Box>
  );
};

export default Logo;
