import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { resetFilters } from 'redux/product/productSlice';
import { FilterValues } from 'redux/product/ProductsTypes';
import { RootState, AppDispatch } from 'redux/store';
import { fetchProducts, setSearchQuery } from 'redux/product/productActions';
import { setFilter } from 'redux/product/productSlice';

import { Grid2 as Grid, Button, Container, Avatar, Typography, Chip, Box } from '@mui/material';

import TagIcon from '@mui/icons-material/Tag';
import SearchIcon from '@mui/icons-material/Search';
import ProductFilter from 'pages/ProductsPage/Components/ProductFilter';
import ProductGrid from 'pages/ProductsPage/Components/ProductGrid';
import LoadingGrid from 'pages/ProductsPage/Components/LoadingGrid';
import noProductImage from 'assets/noProduct.webp';
import PlatformLogo from 'components/icons/PlatformLogos';
import CategoryLogosMap from 'components/icons/categoryLogosMap';

export default function ProductsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const platformId = queryParams.get('platform') || '';
  const typeId = queryParams.get('type') || '';
  const genreId = queryParams.get('genre') || '';
  const categoryId = queryParams.get('category') || '';
  const maxPrice = queryParams.get('maxPrice') || '';
  const searchQuery = queryParams.get('search') || '';

  const { products, isLoading, error, filter } = useSelector((state: RootState) => state.product);

  const categories = useSelector((state: RootState) => state.productInfo.category.categories);
  const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
  const types = useSelector((state: RootState) => state.productInfo.type.types);
  const genres = useSelector((state: RootState) => state.productInfo.genre.genres);

  useEffect(() => {
    if (platformId) {
      dispatch(setFilter({ key: 'platform', value: platformId }));
    }
    if (typeId) {
      dispatch(setFilter({ key: 'type', value: typeId }));
    }
    if (genreId) {
      dispatch(setFilter({ key: 'genre', value: genreId }));
    }
    if (categoryId) {
      dispatch(setFilter({ key: 'category', value: categoryId }));
    }
    if (maxPrice) {
      dispatch(setFilter({ key: 'maxPrice', value: maxPrice }));
    }
    dispatch(fetchProducts({ searchQuery }));
  }, [dispatch, platformId, typeId, genreId, categoryId, maxPrice, searchQuery, location.search]);

  const handleResetFilters = () => {
    dispatch(resetFilters());
    navigate('/products');
  };

  const handleDeleteChip = (key: keyof FilterValues) => {
    dispatch(setFilter({ key, value: '' }));
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.delete(key);
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
  };

  const handleDeleteSearchQuery = () => {
    dispatch(setSearchQuery(''));
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.delete('search');
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
  };

  const activeFilters = [
    { key: 'category', value: categoryId, label: categories.find(c => c._id === categoryId)?.name || categoryId },
    { key: 'platform', value: platformId, label: platforms.find(p => p._id === platformId)?.name || platformId },
    { key: 'type', value: typeId, label: types.find(t => t._id === typeId)?.name || typeId },
    { key: 'genre', value: genreId, label: genres.find(g => g._id === genreId)?.name || genreId },
    { key: 'maxPrice', value: maxPrice, label: `${t('maxPrice')}: ${maxPrice}` },
  ].filter(filter => filter.value);

  const renderAvatar = (filterKey: string, filterValue: string) => {
    if (filterKey === 'platform') {
      return <PlatformLogo platformId={filterValue} />;
    } else if (filterKey === 'category') {
      const IconComponent = CategoryLogosMap[filterValue];
      if (IconComponent) {
        return (
          <Avatar>
            <IconComponent />
          </Avatar>
        );
      }
    } else if (filterKey === 'genre') {
      return <TagIcon fontSize="small" />;
    }
    return null;
  };

  return (
    <Container sx={{ py: { xs: 8, sm: 14 } }}>
      <ProductFilter filter={{ ...filter, platform: platformId, maxPrice }} categories={categories} platforms={platforms} maxPrice={maxPrice} types={types} genres={genres} />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 4 }}>
        {activeFilters.length > 0 && (
          <Chip
            label={t('resetFilters')}
            onClick={handleResetFilters}
            sx={{ m: 0.5 }}
            color="primary"
          />
        )}
        {activeFilters.map((filter) => {
          const avatar = renderAvatar(filter.key, filter.value);
          return (
            <Chip
              key={filter.key}
              avatar={avatar ? avatar : undefined}
              label={filter.label}
              onDelete={() => handleDeleteChip(filter.key as keyof FilterValues)}
              sx={{ m: 0.5 }}
            />
          );
        })}
        {searchQuery && (
          <Chip
            avatar={<SearchIcon />}
            label={`Search: ${searchQuery}`}
            onDelete={handleDeleteSearchQuery}
            sx={{ m: 0.5 }}
          />
        )}
      </Box>
      {isLoading ? (
        <LoadingGrid />
      ) : error ? (
        <Typography>Error: {error}</Typography>
      ) : (
        <>
          {products.length > 0 ? (
            <ProductGrid products={products} filter={filter} searchQuery={searchQuery} />
          ) : (
            <Grid size={{ xs:12 }} display="flex" flexDirection="column" alignItems="center" gap="23px">
              <img src={noProductImage} alt="No products" />
              <Typography variant="h3">{t('noProductsFound')}</Typography>
              <Typography variant="h6">{t('tryDifferentQuery')}</Typography>
              <Button variant="contained" color="primary" onClick={handleResetFilters}>
                {t('resetFilters')}
              </Button>
            </Grid>
          )}
        </>
      )}
    </Container>
  );
}
