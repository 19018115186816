import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';

import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import ProductsPage from './pages/ProductsPage';
import AboutPage from './pages/AboutPage';
import PosPage from './pages/AboutPage/PosTerminalPage';
import ApiPage from './pages/AboutPage/ApiPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import ContactUsPage from './pages/ContactUsPage';
import CareersPage from './pages/CareersPage';
import GamePage from './pages/ProductPage/Game';
import CreditPage from './pages/ProductPage/Credit';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/LoginPage/RegisterPage';
import CartPage from './pages/CartPage';
import UserPage from './pages/UserSettingsPage';
import ProtectedRoute from './ProtectedRoutes';
import OfflinePage from './pages/OfflinePage/OfflinePage';

import { useTheme } from '@mui/system';
import { Box, alpha } from '@mui/material';

const RoutesGroup = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const theme = useTheme();
  const location = useLocation();
  const isOnline = useSelector((state: RootState) => state.application.isOnline);

  return (
    <Box
      sx={{
        width: '100%',
        backgroundImage: theme.palette.mode === 'light' ? 'linear-gradient(180deg, #c9e3fe, #FFF)' : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 200px',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Routes location={location}>
        {isOnline ? (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/about/pos" element={<PosPage />} />
            <Route path="/about/api" element={<ApiPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/contactus" element={<ContactUsPage />} />
            <Route path="/Careers" element={<CareersPage />} />
            <Route path="/dashboard/*" element={
              <ProtectedRoute user={user}>
                <UserPage />
              </ProtectedRoute>
            } />
            <Route path="/cart/*" element={<CartPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="game/:productId/:productName/:categoryId/:platformName/:regionName" element={<GamePage key={window.location.pathname} />} />
            <Route path="dlc/:productId/:productName/:categoryId/:platformName/:regionName" element={<GamePage key={window.location.pathname} />} />
            <Route path="gift-card/:productId/:productName/:categoryId/:platformName/:regionName" element={<CreditPage key={window.location.pathname} />} />
            <Route path="subscription/:productId/:productName/:categoryId/:platformName/:regionName" element={<CreditPage key={window.location.pathname} />} />
          </>
        ) : (
          <>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/about/pos" element={<PosPage />} />
            <Route path="/about/api" element={<ApiPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="*" element={<OfflinePage />} />
          </>
        )}
      </Routes>
    </Box>
  );
};

export default RoutesGroup;