import React from 'react';
import { Web as BrowserIcon, Key as KeylessIcon } from '@mui/icons-material';
import GOGIcon from 'components/icons/Platforms/GogIcon';
import ActivationGuide from './ActivationGuide';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface GOGInstructionsProps {
    keyCode: string;
}

const GOGInstructions: React.FC<GOGInstructionsProps> = ({ keyCode }) => {
    const { t } = useTranslation();

    const steps = {
        default: [
            {
                label: t('activationMethod'),
                description: t('chooseActivation'),
                options: [
                    { title: t('Browser'), icon: <BrowserIcon sx={{ width: '64px', height: '64px' }} />, method: 'browser' },
                    { title: t('GOG Galaxy'), icon: <GOGIcon sx={{ width: '64px', height: '64px' }} />, method: 'gogApp' },
                    { title: t('keylessActivation'), icon: <KeylessIcon sx={{ width: '64px', height: '64px' }} />, method: 'keyless' },
                ]
            }
        ],
        browser: [
            { label: t('activationMethod'), description: '', imageUrl: '' },

            {
                label: t('activationLink'),
                description: t('gogStep1'),
                imageUrl: '/img/activation/activation-link.webp',
                actionButton: (
                    <Button
                        variant="contained"
                        color="secondary"
                        href={`https://www.gog.com/redeem/${keyCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('goToGOGActivation')}
                    </Button>
                ),
            },
            {
                label: t('activateKey'),
                description: t('gogStep2'),
                imageUrl: '/img/activation/gog-redeem-code.webp',
            },
            {
                label: t('linkToAccount'),
                description: t('gogStep3'),
                imageUrl: '/img/activation/gog-clickon-redeem-code.webp',
            },
            {
                label: t('login'),
                description: t('gogStep4'),
                imageUrl: '/img/activation/gog-login.webp',
            },
            {
                label: t('confirmActivation'),
                description: t('gogStep5'),
                imageUrl: '/img/activation/download.webp',
            }
        ],
        gogApp: [
            { label: t('activationMethod'), description: '', imageUrl: '' },

            {
                label: t('login'),
                description: t('gogStep2app'),
                imageUrl: '/img/activation/gog-app-login.webp'
            },
            {
                label: t('goToActivation'),
                description: t('gogStep3app'),
                imageUrl: '/img/activation/gog-app-clickon-redeem-code.webp'
            },
            {
                label: t('enterTheKey'),
                description: t('gogStep4app'),
                imageUrl: '/img/activation/gog-app-redeem-code.webp'
            },
            {
                label: t('confirmActivation'),
                description: t('gogStep5'),
                imageUrl: '/img/activation/download.webp',
            }
        ]
    };

    return <ActivationGuide steps={steps} />;
};

export default GOGInstructions;
