import { SET_FILTER, RESET_FILTER } from '../types/actionTypes';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Product, SetFilterPayload } from './ProductsTypes';
import { ProductDetail, CreditDetail } from '../types/ProductDetailTypes';
import { createAuthHeaders } from '../apiHelper';
import { RootState } from '../store'
import axios from 'axios';
import { fetchWithLocationHeaders } from '../services/locationActions';

interface FetchProductsParams {
  searchQuery?: string;
}

export const fetchProducts = createAsyncThunk<Product[], FetchProductsParams>(
  'products/fetchProducts',
  async ({ searchQuery }: FetchProductsParams, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
    const filters = state.product.filter;

    // Build the query string with filters
    let queryString = Object.entries(filters)
      .filter(([, value]) => value) // Filter out empty values
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
      
    if (searchQuery) {
      queryString += `&search=${encodeURIComponent(searchQuery)}`;
    }

    let url = '/api/product/products';

    if (queryString.length > 0) {
      url += `?${queryString}`;
    }

    try {
      // Use the helper function to make the request
      return await fetchWithLocationHeaders(url, 'GET', getState, dispatch, rejectWithValue);
    } catch (error) {
      console.error('Fetch error:', error);
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchProductById = createAsyncThunk<Product, string>(
  'products/fetchProductById',
  async (productId, { getState, dispatch, rejectWithValue }) => {
    const url = `/api/product/products/${productId}`;

    try {
      // Use the helper function to make the request
      return await fetchWithLocationHeaders(url, 'GET', getState, dispatch, rejectWithValue);
    } catch (error) {
      console.error('Fetch error:', error);
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);


// Fetch a product by ID and platform ID
export const fetchProductByIdAndPlatformId = createAsyncThunk<Product, { productId: string, platformId: string, lang: string }>(
  'products/fetchProductByIdAndPlatformId',
  async ({ productId, platformId, lang }, { getState, dispatch, rejectWithValue }) => {
    const url = `/api/product/products/${productId}/${platformId}?lang=${lang}`;

    try {
      // Use the helper function to make the request
      return await fetchWithLocationHeaders(url, 'GET', getState, dispatch, rejectWithValue);
    } catch (error) {
      console.error('Fetch error:', error);
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// Fetch product details by product ID
export const fetchProductDetailById = createAsyncThunk<ProductDetail, { productId: string, lang: string }>(
  'products/fetchProductDetailById',
  async ({ productId, lang }, { getState, dispatch, rejectWithValue }) => {
    const url = `/api/product/product-details/${productId}?lang=${lang}`;

    try {
      // Use the helper function to make the request
      return await fetchWithLocationHeaders(url, 'GET', getState, dispatch, rejectWithValue);
    } catch (error) {
      console.error('Fetch error:', error);
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);


// Fetch product screenshots by product ID
export const fetchProductScreenshots = createAsyncThunk<{ images: string[] }, { productId: string }>(
  'products/fetchProductScreenshots',
  async ({ productId }, { rejectWithValue }) => {
    const url = `/api/product/screenshots?productId=${productId}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const screenshots = await response.json();
      return screenshots;
    } catch (error) {
      console.error('Fetch error:', error);
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchCreditDetailById = createAsyncThunk<CreditDetail, string>(
  'products/fetchCreditDetailById',
  async (productId, { getState, dispatch, rejectWithValue }) => {
    const url = `/api/product/credit-details/${productId}`;

    try {
      return await fetchWithLocationHeaders(url, 'GET', getState, dispatch, rejectWithValue);
    } catch (error) {
      console.error('Fetch error:', error);
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

export const setSearchQuery = (query: string) => ({
  type: 'SET_SEARCH_QUERY',
  payload: query,
});

export const setFilter = createAsyncThunk(
  'product/setFilter',
  async ({ key, value }: SetFilterPayload, { dispatch, getState }) => {
    const currentFilter = (getState() as RootState).product.filter;
    const updatedFilter = { ...currentFilter, [key]: value };
    dispatch({
      type: SET_FILTER,
      payload: updatedFilter,
    });
  }
);

export const resetFilter = () => ({
  type: RESET_FILTER,
});
