import { createSlice } from '@reduxjs/toolkit';
import { SettingsState } from '../types/dataTypes';
import { fetchSettings } from '../actions/fetchDataActions';

const initialState: SettingsState = {
  loading: false,
  settings: [],
  error: null,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // Purge the settings state when needed (e.g., after location or currency change)
    purgeSettings(state) {
      state.settings = [];  // Clear the settings
      state.error = null;   // Reset error
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSettings.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.settings = payload;
      })
      .addCase(fetchSettings.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || 'Failed to fetch settings';
      });
  },
});

// Export the purgeSettings action
export const { purgeSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
