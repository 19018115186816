import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import SteamIcon from "components/icons/Platforms/SteamIcon";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          languageChanged: "Language Changed",
          selectedLanguage: "English is now selected",
          dark: "Dark",
          light: "Light",
          systemPreferences: "System Preferences",
          changeApplicationTheme: "Select application theme",
          applicationTheme: "Application theme",
          darkDescription: "Welcome to the dark side, we have cookies!",
          lightDescription: "Embrace the light, noble gamer!",
          autoDescription: "Let your system decide your fate!",
          welcome: "Welcome",
          selectLanguage: "Select Default Language",
          selectLanguageLabel: "Select Language",
          gdprFadpDescription: "We use cookies to ensure the proper functioning of our website and to enhance your experience. By selecting 'Accept Necessary,' you consent to the use of essential cookies required for core functionality, including third-party cookies from Stripe for secure payment processing. By selecting 'Accept All,' you allow us to also use optional cookies, such as those for analytics (Google Analytics) and advertising, to further improve our services.",
          acceptTerms: "I agree to the terms and conditions.",
          acceptNecessary: "Essential Only",
          acceptAll: "Accept All",
          termsWarning: "Please agree to the terms and conditions to proceed.",

          selectCurrency: "Select Currency",
          newsletter: "Newsletter",
          subscribeMessage:
            "Subscribe to our newsletter for weekly updates and promotions.",
          subscribe: "Subscribe",
          products: "Products",
          position: "Position",
          message: "Message",
          invalidEmail: "Invalid Email",
          allFieldsRequired: "Please fill in all required fields",
          messageSent: "Message sent",
          contacted: "Request Sent",
          jobThankYou: "Thank you for your interest. We will contact you soon.",
          duplicateApplication:
            "You have already applied, please be patient until we contact you.",
          duplicateSubscription: "You are already subscribed.",
          succesSubcribed: "You have successfully subscribed.",
          company: "Company",
          getInTouch: "Get In Touch",
          privacyPolicy: "Privacy Policy",
          termsOfService: "Terms of Service",
          pcGames: "PC Games",
          xboxGames: "XBOX Games",
          playstationGames: "PlayStation Games",
          nintendoGames: "Nintendo Games",
          giftCards: "Gift Cards",
          subscriptionCards: "Subscriptions Cards",
          aboutUs: "About us",
          testimonials: "Testimonials",
          testimonialsDescription:
            "See what our customers love about our Features. Discover how we excel in efficiency and satisfaction. Join us for low cost, retail innovation, and reliable support.",

          highlights: "Highlights",
          pricing: "Pricing",
          faqs: "FAQ",
          giftAndSubscriptionCards: "Gift & Subscription Cards",
          checkTopSellers: "Check Top Sellers",
          platforms: "Platforms",
          category: "Category",
          platform: "Platform",
          contactSupportText: "If you want to change your account region, please contact our support",
          region: "Region",
          productType: "Product Type",
          none: "None",
          sort: "Sort",
          priceIncreasing: "Price Increasing",
          priceDecreasing: "Price Decreasing",

          consoles: "Consoles",
          aboutUsDescription:
            "Since 2002, Bigswiss.ch has been your go-to destination for digital games and gift cards, proudly operating from the heart of Switzerland. With over two decades of experience, we are committed to bringing you the latest and greatest in the world of digital entertainment.\n\nOur extensive selection includes a wide variety of digital games across all popular platforms, as well as an array of gift cards for your favorite services. Whether you're a seasoned gamer looking for the next big release or searching for the perfect gift for a loved one, Bigswiss.ch has you covered.\n\nAt Bigswiss.ch, we pride ourselves on providing exceptional customer service and a seamless shopping experience. Our dedicated team is always here to assist you, ensuring that your journey with us is smooth and enjoyable.",
          onlineStoreTitle: "Online Store",
          onlineStoreDescription:
            "Buy over 10,000 different video games and digital products",
          posTerminalsTitle: "POS Terminals",
          posTerminalsDescription:
            "Integrate our POS Terminals in your store and get access to all our games",
          wholesaleApiTitle: "Wholesale API",
          wholesaleApiDescription:
            "Integrate our wholesale API to your online store",
          learnMore: "Learn More",
          goToStore: "Go to store",
          highlightsDescription:
            "Explore why our product stands out: adaptability, durability, user-friendly design, and innovation. Enjoy reliable customer support and precision in every detail.",
          instantDeliveryTitle: "Instant Delivery",
          instantDeliveryDescription:
            "Receive your digital codes instantly after purchase.",
          digitalDownloadTitle: "Digital Download",
          digitalDownloadDescription:
            "Get access to your favorite games and services with easy-to-redeem codes.",
          customerSupportTitle: "24/7 Customer Support",
          customerSupportDescription:
            "We're here for you at any time, any day, ready to resolve any issue.",
          adaptablePerformanceTitle: "Adaptable Performance",
          adaptablePerformanceDescription:
            "Our product effortlessly adjusts to your needs, boosting efficiency and simplifying your tasks.",
          innovativeFunctionalityTitle: "Innovative Functionality",
          innovativeFunctionalityDescription:
            "Stay ahead with features that set new standards, addressing your evolving needs better than the rest.",
          precisionDetailTitle: "Precision in Every Detail",
          precisionDetailDescription:
            "Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.",
          ourTopSellingProducts: "Our Top Selling Products",
          faqTitle: "Frequently asked questions",
          faq1Question:
            "How do I contact customer support if I have a question or issue?",
          faq1Answer:
            "We're here to assist you promptly. You can reach our customer support team by emailing ",
          faq2Question:
            "Can I return the product if it doesn't meet my expectations?",
          faq2Answer:
            "Absolutely! We offer a hassle-free return policy. If you're not completely satisfied, you can return the product within 7 days for a full refund or exchange.",
          faq3Question:
            "What makes your product stand out from others in the market?",
          faq3Answer:
            "Our product distinguishes itself through its adaptability, durability, and innovative features. We prioritize user satisfaction and continually strive to exceed expectations in every aspect.",
          faq4Question:
            "Is there a warranty on the product, and what does it cover?",
          faq4Answer:
            "Yes, our product comes with a 1 year warranty. It covers defects in materials and workmanship. If you encounter any issues covered by the warranty, please contact our customer support for assistance.",
          available: "Available",
          now: "Now",
          games: "Games",
          about: "About",
          game: "Game",
          website: "Website",
          description: "Description",
          rating: "Rating",
          developer: "Developer",
          publisher: "Publisher",
          released: "Release Date",
          genre: "Genre",
          gallery: "Gallery",
          maxPrice: "Max Price",

          availableEditions: "Available Editions",
          availablePlatforms: "Available Platforms",
          addToCart: "Add to Cart",
          emptyCartHeading: "Cart's feeling light...",
          emptyCartSubheading:
            "Your cart is longing for some company. Begin your shopping adventure now!",
          exploreCatalog: "Explore our catalog",
          yourCart: "Your Cart",
          quantity: "Quantity",
          maxProductAllowed: "Maximum of {{max}} products allowed",
          total: "Total",
          goToCart: "Go to Cart",
          payNow: "Pay Now",
          goToPayment: "Go to Payment",
          discount: "Discount",
          originalPrice: "Original Price",
          checkMoreProducts: "Check more products",
          cartSummary: "Cart Summary",
          cartItems: "Cart Items",
          payment: "Payment",
          gameActivation: "Game Activation",
          login: "Login",
          remove: "Remove",

          Notification: "Notification",
          notificationsEnabled: "Browser notifications are enabled",
          notificationsEnableFail: "Failed to enable notifications",
          notificationsDisabled: "Browser notifications are disabled",
          notificationsDisableFail: "Failed to disable notifications",
          notificationsDenied: "Notification permission was denied",
          notificationsNoPermission: "Notification permission not granted",
          notificationsPermissionFaild: "Notification permission request failed",
          notificationsNotSupported: "Push messaging is not supported",
          notificationsFailToSubscribe: "Failed to subscribe the user",
          notificationsFailToUnsubscritbe: "Failed to unsubscribe the user",
          serviceWorkerNotReady: "Please reload the page and try again",
          notificationsSubscriptionErro: "Error getting subscription",
          browserNotifications: "Browser Notifications",

          noProductsFound: "No products found.",
          tryDifferentQuery:
            "Please try a different search query or reset the filters.",
          resetFilters: "Reset Filters",
          achievements: "Achievements",
          noFavorites: "Oops! No Loot Found!",
          noFavoritesText: "Time to hunt for some epic loot!",
          favorites: "Favorites",
          purchases: "Purchases",
          points: "Points",
          dashboard: "Dashboard",
          settings: "Settings",
          updateSettings: "Update Settings",
          security: "Security",
          invoiceNotFound: "Invoice Not Found",
          action: "Action",
          date: "Date",
          rowsPerPage: "Rows per page",
          aboutOurApi: "About Our API",
          aboutOurApiDescription:
            "Welcome to our API product page! Our API provides seamless access to over 10,000 products, ensuring that you can integrate and offer a wide range of items to your customers all the time. Whether you're building an e-commerce platform, a mobile app, or any other service, our API has you covered.",
          whyChooseOurApi: "Why Choose Our API?",
          whyChooseOurApiDescription:
            "Our API is designed to be robust, reliable, and easy to use. Here are some of the key features:",
          featureApi1: "Access to over 10,000 products",
          featureApi2: "Real-time product availability and pricing",
          featureApi3: "High availability with 99.9% uptime",
          featureApi4: "Comprehensive documentation and support",
          featureApi5: "Easy integration with RESTful endpoints",
          featureApi6: "Secure and scalable architecture",
          WithOurApi:
            "With our API, you can ensure your customers have access to a vast inventory of products at all times. Our high availability and real-time updates mean you'll never miss a sale.",

          aboutOurPosTerminals: "About Our POS Terminals,",
          welcomeMessage:
            "Welcome to our product page! We are proud to offer state-of-the-art POS terminals designed specifically for stores that sell games and subscription cards. Our terminals are easy to use, secure, and efficient, helping you manage your sales and inventory with ease,",
          whyChooseOurPosTerminals: "Why Choose Our POS Terminals?",
          posTerminalFeatures:
            "Our POS terminals come with a range of features to streamline your store operations:",
          feature1: "Seamless integration with your existing systems",
          feature2: "Fast and secure payment processing,",
          feature3: "Real-time inventory management,",
          feature4: "User-friendly interface for quick training,",
          feature5: "Customizable settings to fit your store's needs,",
          posTerminalDescription:
            "Whether you're selling physical games or digital subscription cards, our POS terminals are equipped to handle all your transactions efficiently. Enhance your customer experience and boost your sales with our reliable and versatile POS solutions,",
          invoiceNo: "Invoice No",

          howTo: "How to",
          activate: "Activate",
          step: "Step",
          giftToFriend: "Gift to a Friend",
          enterEmailTwice: "Please enter your friend's email address twice for confirmation.",
          friendEmail: "Friend's Email",
          confirmFriendsEmail: "Confirm Friend's Email",
          emailsDoNotMatch: "The email addresses do not match. Please try again.",
          sendGift: "Send Gift",
          giftSent: "Gift Sent",
          giftSentMessage: "We have successfully sent the key as a gift to your friend at the following email:",
          close: "Close",

          buyers: "Buyers",
          reviews: "Reviews",
          user: "User",
          comment: "Comment",

          requirements: "Requirements",
          minimumRequirements: "Minimum Requirements",
          recommendedRequirements: "Recommended Requirements",
          OS: "OS",
          Processor: "Processor",
          Memory: "Memory",
          Graphics: "Graphics",
          DirectX: "DirectX",
          Storage: "Storage",
          AdditionalNotes: "Additional Notes",

          careers: "Careers",
          contactUs: "Contact Us",
          updatePersonalData: "Update your billing data",
          updateApplicationSettings: "Update Application Settings",
          birthday: "Birthday",
          yourName: "Your Name",
          yourLastName: "Your Last Name",
          enterEmail: "Enter your email address",
          yourEmail: "Your email address",
          changePassword: "Change Password",
          currentPassword: "Current Password",
          newPassword: "New Password",
          confirmPassword: "Confirm Password",
          showLess: "Show Less",
          showMore: "Show More",
          PageNotFound: "Page not found",
          DontGiveUp:
            "Don't give up, maybe you will find here what you are looking for.",
          Jobs: "Jobs",
          ElevateYourCareer:
            "Elevate your career with Bigswiss GmbH, where innovation and passion meet. Dive into a world where your work directly shapes the future of gaming and technology.",
          exploreOpportunities:
            "Explore opportunities, embrace challenges, and be part of a dynamic team dedicated to excellence. Your journey to make an impact starts here.",
          enterYourEmailAddress: "Enter your email address",
          yourEmailAddress: "Your email address",
          contactUsAgree: 'By clicking "Contact us" you agree to our',
          termsAndConditions: "Terms & Conditions",
          bigswissSupport: "Contact Bigswiss ",
          support: "Support",
          contactDescription:
            "From inquiries about our innovative online shop and wholesale store to assistance with our kiosk applications for video game purchases and the wholesale API store, we're here to elevate your experience.",
          connectTeam:
            "Connect with our dedicated team for top-tier features and services designed just for you.",

          pricingDescription: "Choose the plan that suits your needs",
          basic: "Basic",
          pro: "Pro",
          enterprise: "enterprise",
          getStarted: "Get Started",
          startNow: "Start Now",
          mostPopular: "Most Popular",
          perMonth: "per month",
          basicDesc1: "API access for up to 100 requests per month",
          basicDesc2: "Basic analytics and reporting",
          basicDesc3: "Standard support",
          proDesc1: "API access for up to 10,000 requests per month",
          proDesc2: "Advanced analytics and reporting",
          proDesc3: "Priority support",
          proDesc4: "Access to exclusive game and subscription card deals",
          enterpriseDesc1: "Unlimited API requests",
          enterpriseDesc2: "Custom analytics and reporting",
          enterpriseDesc3: "24/7 dedicated support",
          enterpriseDesc4: "Customized POS terminal solutions",
          enterpriseDesc5: "Best pricing on bulk purchases",

          chooseActivation: "Choose where you wish to activate your product.",
          thankYouForPurchase: "Thank you for your purchase!",
          findYourKeysBellow: "You can find your keys and invoice below.",
          productKey: "Product Key",
          productKeys: "Product Keys",
          productActivation: "Product Activation",
          productActivationDescription: "Please follow the instructions below to activate your product.",
          sendAsGift: "Gift",
          hide: "Hide",
          viewKeys: "View Keys",
          downloadInvoice: "Download Invoice",
          download: "Download",
          back: "Back",
          next: "Next",
          activationMethod: "Activation Method",
          keylessActivation: "Keyless Activation",
          activationLink: "Activation Link",
          enterTheKey: "Enter the Key",
          confirmActivation: "Confirm Activation",
          battlenetStep1: "Click the button below to navigate to the Battle.net key activation page.",
          goToBattlenetActivation: "Go to Battle.net Activation Page",
          battlenetStep2: "Log in with your Battle.net credentials. If you don’t have an account, create one for free.",
          battlenetStep3: "Enter the key you received in the provided field, then click 'Redeem Code'.",
          battlenetStep4: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Battle.net account.",
          goToActivation: "Go to Activation",
          battlenetStep2app: "Open the Blizzard Battle.net app. And log in with your Battle.net credentials. If you don’t have an account, create one for free.",
          battlenetStep3app: "Click on your profile to open a menu, then click on 'Redeem Code'",
          goToSteamActivation: "Go to Steam Activation Page",
          steamStep1: "Click the button below to navigate to the Steam key activation page.",
          steamStep2: "Log in with your Steam credentials. If you don’t have an account, create one for free.",
          steamStep3: "Read and agree to Steam Subscriber Agreement, then click 'Continue'.",
          steamStep4: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Steam account.",
          steamStep2app: "Open the Steam app and log in with your Steam credentials. If you don’t have an account, create one for free.",
          steamStep3app: "Click on 'Games' in the top menu, then click on 'Activate a Product on Steam'.",
          steamStep4app: "Enter the key you received in the provided field, then click 'Confirm'.",

          goToMicrosoftActivation: "Go to Microsoft Store Activation Page",
          microsoftStep1: "Click the button below to navigate to the Microsoft Store key activation page.",
          microsoftStep2: "Log in with your Microsoft credentials. If you don’t have an account, create one for free.",
          microsoftStep3: "Enter the key you received in the provided field, then click 'Next'.",
          microsoftStep4: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Microsoft account.",

          microsoftStep2app: "On your Windows system, open the Microsoft Store app and log in with your Microsoft credentials. If you don’t have an account, create one for free.",
          microsoftStep3app: "Click on profile logo in top right corner, then click on 'Redeem code or gift cards'.",

          goToGOGActivation: "Go to GOG Activation Page",
          activateKey: "Activate Key",
          linkToAccount: "Link to Account",
          gogStep1: "Click the button below to navigate to the GOG key activation page.",
          gogStep2: "Click on 'Continue' to confirm key activation.",
          gogStep3: "Clic on 'Sign In' to link the key to your GOG account.",
          gogStep4: "Log in with your GOG credentials. If you don’t have an account, create one for free.",
          gogStep5: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your GOG account.",

          gogStep2app: "Open the GOG Galaxy app and log in with your GOG credentials. If you don’t have an account, create one for free.",
          gogStep3app: "Click on 'Add games & friends' in the top menu, then click on 'Redeem GOG code'.",
          gogStep4app: "Enter the key you received in the provided field, then click 'Continue'.",

          goToEpicActivation: "Go to Epic Games Activation Page",
          epicStep1: "Click the button below to navigate to the Epic Games key activation page.",
          epicStep2: "Click on 'Redeem' to confirm key activation.",
          epicStep3: "Click on 'Sign In' to link the key to your Epic Games account.",
          epicStep4: "Log in with your Epic Games credentials. If you don’t have an account, create one for free.",
          epicStep5: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Epic Games account.",

          epicStep2app: "Open the Epic Games app and log in with your Epic Games credentials. If you don’t have an account, create one for free.",
          epicStep3app: "Click on your profile in the top right corner, then click on 'Redeem Code'.",
          epicStep4app: "Enter the key you received in the provided field, then click 'Redeem'.",

          goToRockstarActivation: "Go to Rockstar Activation Page",
          rockstarStep1: "Click the button below to navigate to the Rockstar key activation page.",
          rockstarStep2: "Log in with your Rockstar credentials. If you don’t have an account, create one for free.",
          rockstarStep3: "Please wait for page to load, and Rockstar validation to complete. It can take up for several minutes. Once the page is loaded you will see confirmation message. Click on 'Confirm' to activate the key to your Rockstar account.",
          rockstarStep4: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Rockstar account.",
          rockstarStep2app: "Open the Rockstar Games Launcher app and log in with your Rockstar credentials. If you don’t have an account, create one for free.",
          rockstarStep3app: "Click on the menu in the top righ corner, then click on 'REDEEM CODE'.",
          rockstarStep4app: "Enter the key you received in the provided field, then click 'CHECK'.",
          rockstarStep5app: "Read and agree to the terms and conditions, then click 'REDEEM'.",


          goToEAPlayActivation: "Go to EA Play Activation Page",
          eaStep1: "Click the button below to navigate to the EA Play key activation page.",
          eaStep2: "Log in with your EA Play credentials. If you don’t have an account, create one for free.",
          eaStep3: "Enter the key you received in the provided field, then click 'Next'.",
          eaStep4: "Click on the 'Next' button to link the key to your EA Play account.",
          eaStep5: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your EA Play account.",

          eaStep2app: "Open the EA Play app and log in with your EA Play credentials. If you don’t have an account, create one for free.",
          eaStep3app: "Click on the menu in the top right corner, then click on 'Redeem Code'.",
          eaStep4app: "Enter the key you received in the provided field, then click 'Next'.",

          ubisoftStep5: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Ubisoft account.",
          ubisoftStep2app: "Open the Ubisoft app and log in with your Ubisoft credentials. If you don’t have an account, create one for free.",
          ubisoftStep3app: "Click on the profile menu in the top left corner, then click on 'Key Activation'.",
          ubisoftStep4app: "Enter the key you received in the provided field, then click 'Activate'.",

          goToSwitchActivation: "Go to Nintendo Switch Activation Page",
          openeshop: "Open eShop",
          selectUser: "Select User",
          redeem: "Redeem Key",
          switchStep1: "Click the button below to navigate to the Nintendo Switch key activation page.",
          switchStep2: "Log in with your Nintendo Switch credentials. If you don’t have an account, create one for free.",
          switchStep3: "Enter the key you received in the provided field, then click 'Continue'.",
          switchStep4: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Nintendo Switch account.",
          switchStep1app: "Open the Nintendo eShop app.",
          switchStep2app: "Select account where you wish to activate the product.",
          switchStep3app: "Enter the key you received in the provided field, then click 'Confirm'.",
          switchStep4app: "Select 'Redeem' and click 'Confirm' to activate the product to your Nintendo Switch account.",


          goToPlayStationActivation: "Go to PlayStation Activation Page",
          openLogin: "Open Login",
          psStep1: "Click the button below to navigate to the PlayStation key activation page.",
          psStep2: "In top right corner click on 'Loign'",
          psStep3: "Log in with your PlayStation credentials. If you don’t have an account, create one for free.",
          psStep4: "In top right corner click on your profile, then click on 'Redeem Code'.",
          psStep5: "Enter the key you received in the provided field, then click 'Continue'.",
          psStep6: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your PlayStation account.",
          openStore: "Open Store",
          psStep2console: "Select the Playstation Store on the left and ress 'X'.",
          psStep3console: "Press '△' to acces menu on the top right corner, select '...' and select 'Redeem Code'.",
          psStep4console: "Enter the key you received in the provided field, then click 'Redeem'.",
          psStep2app: "Open the PlayStation app, and open the PlayStation store.",
          openMenu: "Open Menu",
          psStep3app: "Select the menu on the top right corner, to view more option",
          psStep4app: "Select 'Redeem Code'",


          goToXboxActivation: "Go to Xbox Activation Page",
          xboxStep1: "Click the button below to navigate to the Xbox key activation page.",
          xboxStep2: "Click on 'Sign in to redeem' to log in with your Xbox credentials. If you don’t have an account, create one for free.",
          xboxStep3: "Click on 'Redeem now' to to open key activation modal.",
          xboxStep4: "Enter the key you received in the provided field, then click 'Next'. And continue following the instructions",
          xboxStep5: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Xbox account.",
          xboxStep2console: "Press the Xbox button on your controller to open the guide. While signed with your account, select 'Store'.",
          xboxStep3console: "Once opened, press the view button on your controller to open the menu, then select 'Redeem'.",
          xboxStep4console: "Enter the key you received in the provided field, then click 'Check code'.",

          warningModalTitle: "Important Notice",
          warningModalMessage1: "Once you reveal the key, you lose the right for an instant refund.",
          warningModalMessage2: "You will only be eligible for a refund if the key is invalid.",
          doYouWantToProceed: "Do you wish to proceed?",
          revealKey: "Reveal Key"
        },
      },
      de: {
        translation: {
          languageChanged: "Sprache geändert",
          selectedLanguage: "Deutsch ist jetzt ausgewählt",
          dark: "Dunkel",
          light: "Hell",
          systemPreferences: "Systemeinstellungen",
          changeApplicationTheme: "Anwendungsthema auswählen",
          applicationTheme: "Anwendungsthema",
          darkDescription: "Willkommen auf der dunklen Seite, wir haben Kekse!",
          lightDescription: "Umarmen Sie das Licht, edler Spieler!",
          autoDescription: "Lassen Sie Ihr System Ihr Schicksal entscheiden!",
          welcome: "Willkommen",
          selectLanguage: "Standardsprache auswählen",
          selectLanguageLabel: "Sprache auswählen",
          gdprFadpDescription: "Wir verwenden Cookies, um die ordnungsgemäße Funktion unserer Website sicherzustellen und Ihre Erfahrung zu verbessern. Wenn Sie 'Nur notwendige Cookies akzeptieren' auswählen, stimmen Sie der Verwendung wesentlicher Cookies zu, die für die Kernfunktionen erforderlich sind, einschließlich Drittanbieter-Cookies von Stripe für die sichere Zahlungsabwicklung. Wenn Sie 'Alle Cookies akzeptieren' auswählen, erlauben Sie uns, auch optionale Cookies wie Analyse- (Google Analytics) und Werbecookies zu verwenden, um unsere Dienste weiter zu verbessern.",
          acceptTerms: "Ich stimme den Allgemeinen Geschäftsbedingungen zu.",
          acceptNecessary: "Nur Essenziell",
          acceptAll: "Alle Akzeptieren",
          termsWarning: "Bitte stimmen Sie den Allgemeinen Geschäftsbedingungen zu, um fortzufahren.",


          selectCurrency: "Währung auswählen",
          newsletter: "Newsletter",
          subscribeMessage:
            "Abonnieren Sie unseren Newsletter für wöchentliche Updates und Aktionen.",
          subscribe: "Abonnieren",
          products: "Produkte",
          position: "Position",
          message: "Nachricht",
          invalidEmail: "Ungültige E-Mail",
          allFieldsRequired: "Bitte füllen Sie alle erforderlichen Felder aus",
          messageSent: "Nachricht gesendet",
          contacted: "Anfrage gesendet",
          jobThankYou:
            "Vielen Dank für Ihr Interesse. Wir werden Sie bald kontaktieren.",
          duplicateApplication:
            "Sie haben sich bereits beworben, bitte haben Sie Geduld, bis wir uns bei Ihnen melden.",
          duplicateSubscription: "Sie sind bereits abonniert.",
          succesSubcribed: "Sie haben sich erfolgreich angemeldet.",
          company: "Unternehmen",
          getInTouch: "Kontakt",
          privacyPolicy: "Datenschutzrichtlinie",
          termsOfService: "Nutzungsbedingungen",
          pcGames: "PC-Spiele",
          xboxGames: "XBOX-Spiele",
          playstationGames: "PlayStation-Spiele",
          nintendoGames: "Nintendo-Spiele",
          giftCards: "Geschenkkarten",
          subscriptionCards: "Abonnementkarten",
          aboutUs: "Über uns",
          testimonials: "Erfahrungsberichte",
          testimonialsDescription:
            "Sehen Sie, was unsere Kunden an unseren Funktionen lieben. Entdecken Sie, wie wir in Effizienz und Zufriedenheit übertreffen. Schließen Sie sich uns an für niedrige Kosten, Einzelhandelsinnovation und zuverlässigen Support.",

          highlights: "Höhepunkte",
          pricing: "Preisgestaltung",
          faqs: "FAQ",
          giftAndSubscriptionCards: "Geschenk & Abonnementkarten",
          checkTopSellers: "Top-Verkäufer überprüfen",
          platforms: "Plattformen",
          category: "Kategorie",
          platform: "Plattform",
          contactSupportText: "Wenn Sie die Region Ihres Kontos ändern möchten, wenden Sie sich bitte an unseren Support",
          region: "Region",
          productType: "Produkttyp",
          none: "Keine",
          sort: "Sortieren",
          priceIncreasing: "Preiserhöhung",
          priceDecreasing: "Preis sinkend",

          consoles: "Konsolen",
          aboutUsDescription:
            "Seit 2002 ist Bigswiss.ch Ihr Anlaufpunkt für digitale Spiele und Geschenkkarten, stolz aus dem Herzen der Schweiz operierend.Mit über zwei Jahrzehnten Erfahrung sind wir bestrebt, Ihnen die neuesten und besten digitalen Unterhaltungsangebote zu bieten.\n\nUnser umfangreiches Sortiment umfasst eine Vielzahl von digitalen Spielen auf allen beliebten Plattformen sowie eine Auswahl an Geschenkkarten für Ihre bevorzugten Dienste.Ob Sie ein erfahrener Gamer sind, der auf der Suche nach dem nächsten großen Release ist, oder das perfekte Geschenk für einen geliebten Menschen suchen, Bigswiss.ch hat alles, was Sie brauchen.\n\nBei Bigswiss.ch legen wir großen Wert auf außergewöhnlichen Kundenservice und ein nahtloses Einkaufserlebnis.Unser engagiertes Team steht Ihnen jederzeit zur Verfügung, um sicherzustellen, dass Ihre Reise mit uns reibungslos und angenehm verläuft.",
          onlineStoreTitle: "Online-Shop",
          onlineStoreDescription:
            "Kaufen Sie über 10.000 verschiedene Videospiele und digitale Produkte",
          posTerminalsTitle: "POS-Terminals",
          posTerminalsDescription:
            "Integrieren Sie unsere POS-Terminals in Ihrem Geschäft und erhalten Sie Zugriff auf alle unsere Spiele",
          wholesaleApiTitle: "Großhandels-API",
          wholesaleApiDescription:
            "Integrieren Sie unsere Großhandels-API in Ihren Online-Shop",
          learnMore: "Erfahren Sie mehr",
          goToStore: "Zum Shop",
          highlightsDescription:
            "Erfahren Sie, warum unser Produkt herausragt: Anpassungsfähigkeit, Haltbarkeit, benutzerfreundliches Design und Innovation. Genießen Sie zuverlässigen Kundensupport und Präzision in jedem Detail.",
          instantDeliveryTitle: "Sofortige Lieferung",
          instantDeliveryDescription:
            "Erhalten Sie Ihre digitalen Codes sofort nach dem Kauf.",
          digitalDownloadTitle: "Digitaler Download",
          digitalDownloadDescription:
            "Greifen Sie auf Ihre Lieblingsspiele und -dienste mit einfach einzulösenden Codes zu.",
          customerSupportTitle: "24/7 Kundenservice",
          customerSupportDescription:
            "Wir sind rund um die Uhr für Sie da, bereit, jedes Problem zu lösen.",
          adaptablePerformanceTitle: "Anpassungsfähige Leistung",
          adaptablePerformanceDescription:
            "Unser Produkt passt sich mühelos Ihren Bedürfnissen an, steigert die Effizienz und vereinfacht Ihre Aufgaben.",
          innovativeFunctionalityTitle: "Innovative Funktionalität",
          innovativeFunctionalityDescription:
            "Bleiben Sie mit Funktionen, die neue Maßstäbe setzen, Ihrer Entwicklung stets einen Schritt voraus.",
          precisionDetailTitle: "Präzision in jedem Detail",
          precisionDetailDescription:
            "Genießen Sie ein sorgfältig gestaltetes Produkt, bei dem kleine Details einen großen Einfluss auf Ihr Gesamterlebnis haben.",
          ourTopSellingProducts: "Unsere Bestseller-Produkte",

          faqTitle: "Häufig gestellte Fragen",
          faq1Question:
            "Wie kontaktiere ich den Kundenservice, wenn ich eine Frage oder ein Problem habe?",
          faq1Answer:
            "Wir sind hier, um Ihnen umgehend zu helfen. Sie können unser Kundensupport-Team erreichen, indem Sie eine E-Mail an ",
          faq2Question:
            "Kann ich das Produkt zurückgeben, wenn es nicht meinen Erwartungen entspricht?",
          faq2Answer:
            "Absolut! Wir bieten eine unkomplizierte Rückgaberecht. Wenn Sie nicht vollständig zufrieden sind, können Sie das Produkt innerhalb von 7 Tagen für eine vollständige Rückerstattung oder einen Umtausch zurückgeben.",
          faq3Question:
            "Was unterscheidet Ihr Produkt von anderen auf dem Markt?",
          faq3Answer:
            "Unser Produkt zeichnet sich durch seine Anpassungsfähigkeit, Haltbarkeit und innovativen Funktionen aus. Wir legen großen Wert auf Kundenzufriedenheit und streben ständig danach, in allen Aspekten die Erwartungen zu übertreffen.",
          faq4Question:
            "Gibt es eine Garantie auf das Produkt und was deckt sie ab?",
          faq4Answer:
            "Ja, unser Produkt kommt mit einer 1 Jahr Garantie. Sie deckt Material- und Verarbeitungsfehler ab. Wenn Sie Probleme haben, die von der Garantie abgedeckt sind, kontaktieren Sie bitte unseren Kundenservice für Unterstützung.",
          available: "Verfügbar",
          now: "Jetzt",
          games: "Spiele",
          about: "Über",
          game: "Spiel",
          website: "Webseite",
          description: "Beschreibung",
          rating: "Bewertung",
          developer: "Entwickler",
          publisher: "Verleger",
          released: "Veröffentlichungsdatum",
          genre: "Genre",
          gallery: "Galerie",
          maxPrice: "Maximaler Preis",

          availableEditions: "Verfügbare Editionen",
          availablePlatforms: "Verfügbare Plattformen",
          addToCart: "In den Warenkorb",
          emptyCartHeading: "Der Warenkorb ist leer...",
          emptyCartSubheading:
            "Ihr Warenkorb sehnt sich nach Gesellschaft. Beginnen Sie jetzt Ihr Einkaufserlebnis!",
          exploreCatalog: "Durchstöbern Sie unseren Katalog",
          yourCart: "Ihr Warenkorb",
          quantity: "Menge",
          maxProductAllowed: "Maximal {{max}} Produkte erlaubt",
          total: "Gesamt",
          goToCart: "Zum Warenkorb",
          payNow: "Jetzt bezahlen",
          goToPayment: "Zur Zahlung",
          discount: "Rabatt",
          originalPrice: "Originalpreis",
          checkMoreProducts: "Weitere Produkte ansehen",
          cartSummary: "Warenkorb Zusammenfassung",
          cartItems: "Warenkorb",
          payment: "Bezahlung",
          gameActivation: "Spielaktivierung",
          login: "Anmeldung",
          remove: "Entfernen",

          Notification: "Benachrichtigung",
          notificationsEnabled: "Browser-Benachrichtigungen sind aktiviert",
          notificationsEnableFail: "Fehler beim Aktivieren der Benachrichtigungen",
          notificationsDisabled: "Browser-Benachrichtigungen sind deaktiviert",
          notificationsDisableFail: "Fehler beim Deaktivieren der Benachrichtigungen",
          notificationsDenied: "Benachrichtigungsberechtigung wurde verweigert",
          notificationsNoPermission: "Benachrichtigungsberechtigung nicht erteilt",
          notificationsPermissionFaild: "Anforderung der Benachrichtigungsberechtigung fehlgeschlagen",
          notificationsNotSupported: "Push-Nachrichten werden nicht unterstützt",
          notificationsFailToSubscribe: "Fehler beim Abonnieren des Benutzers",
          notificationsFailToUnsubscribe: "Fehler beim Abbestellen des Benutzers",
          serviceWorkerNotReady: "Bitte laden Sie die Seite neu und versuchen Sie es erneut",
          notificationsSubscriptionErro: "Fehler beim Abrufen des Abonnements",
          browserNotifications: "Browser-Benachrichtigungen",

          noProductsFound: "Keine Produkte gefunden.",
          tryDifferentQuery:
            "Bitte versuchen Sie es mit einer anderen Suchanfrage oder setzen Sie die Filter zurück.",
          resetFilters: "Filter zurücksetzen",
          achievements: "Erfolge",
          noFavorites: "Hoppla! Keine Beute gefunden!",
          noFavoritesText: "Zeit, nach epischer Beute zu jagen!",
          favorites: "Favoriten",
          purchases: "Einkäufe",
          points: "Punkte",
          dashboard: "Armaturenbrett",
          settings: "Einstellungen",
          updateSettings: "Einstellungen aktualisieren",
          security: "Sicherheit",
          invoiceNotFound: "Rechnung nicht gefunden",
          action: "Aktion",
          date: "Datum",
          rowsPerPage: "Zeilen pro Seite",
          aboutOurApi: "Über unsere API",
          aboutOurApiDescription:
            "Willkommen auf unserer API-Produktseite! Unsere API bietet nahtlosen Zugang zu über 10.000 Produkten und stellt sicher, dass Sie jederzeit eine breite Palette von Artikeln für Ihre Kunden integrieren und anbieten können. Ob Sie eine E-Commerce-Plattform, eine mobile App oder einen anderen Service erstellen, unsere API hat Sie abgedeckt.",
          whyChooseOurApi: "Warum Sie unsere API wählen sollten?",
          whyChooseOurApiDescription:
            "Unsere API ist robust, zuverlässig und einfach zu bedienen. Hier sind einige der wichtigsten Funktionen:",
          featureApi1: "Zugang zu über 10.000 Produkten",
          featureApi2: "Echtzeit-Produktverfügbarkeit und -preisgestaltung",
          featureApi3: "Hohe Verfügbarkeit mit 99,9% Betriebszeit",
          featureApi4: "Umfassende Dokumentation und Unterstützung",
          featureApi5: "Einfache Integration mit RESTful-Endpunkten",
          featureApi6: "Sichere und skalierbare Architektur",
          WithOurApi:
            "Mit unserer API können Sie sicherstellen, dass Ihre Kunden jederzeit Zugang zu einem umfangreichen Produktinventar haben. Unsere hohe Verfügbarkeit und Echtzeit-Updates bedeuten, dass Sie nie einen Verkauf verpassen.",

          aboutOurPosTerminals: "Über unsere POS-Terminals",
          welcomeMessage:
            "Willkommen auf unserer Produktseite! Wir sind stolz darauf, hochmoderne POS-Terminals anzubieten, die speziell für Geschäfte entwickelt wurden, die Spiele und Abonnementkarten verkaufen. Unsere Terminals sind einfach zu bedienen, sicher und effizient und helfen Ihnen, Ihre Verkäufe und Ihr Inventar mit Leichtigkeit zu verwalten",
          whyChooseOurPosTerminals: "Warum unsere POS-Terminals wählen?",
          posTerminalFeatures:
            "Unsere POS-Terminals bieten eine Reihe von Funktionen zur Optimierung Ihrer Geschäftsabläufe:",
          feature1: "Nahtlose Integration in Ihre bestehenden Systeme",
          feature2: "Schnelle und sichere Zahlungsabwicklung",
          feature3: "Echtzeit-Inventarverwaltung",
          feature4: "Benutzerfreundliche Oberfläche für schnelles Training",
          feature5:
            "Anpassbare Einstellungen, die zu den Bedürfnissen Ihres Geschäfts passen",
          posTerminalDescription:
            "Ob Sie physische Spiele oder digitale Abonnementkarten verkaufen, unsere POS-Terminals sind darauf ausgelegt, alle Ihre Transaktionen effizient abzuwickeln. Verbessern Sie das Kundenerlebnis und steigern Sie Ihren Umsatz mit unseren zuverlässigen und vielseitigen POS-Lösungen,",

          invoiceNo: "Rechnungsnummer",
          howTo: "Wie",
          activate: "Aktivieren",
          step: "Schritt",
          giftToFriend: "Als Geschenk an einen Freund",
          enterEmailTwice: "Bitte geben Sie die E-Mail-Adresse Ihres Freundes zweimal zur Bestätigung ein.",
          friendEmail: "E-Mail des Freundes",
          confirmFriendsEmail: "E-Mail des Freundes bestätigen",
          emailsDoNotMatch: "Die E-Mail-Adressen stimmen nicht überein. Bitte versuchen Sie es erneut.",
          sendGift: "Geschenk senden",
          giftSent: "Gift gesendet",
          giftSentMessage: "Wir haben den Schlüssel erfolgreich als Geschenk an Ihren Freund an die folgende E-Mail gesendet:",
          close: "Schließen",
          
          buyers: "Käufer",
          reviews: "Bewertungen",
          user: "Benutzer",
          comment: "Kommentar",

          requirements: "Anforderungen",
          minimumRequirements: "Mindestanforderungen",
          recommendedRequirements: "Empfohlene Anforderungen",
          OS: "Betriebssystem",
          Processor: "Prozessor",
          Memory: "Speicher",
          Graphics: "Grafik",
          DirectX: "DirectX",
          Storage: "Speicherplatz",
          AdditionalNotes: "Zusätzliche Hinweise",

          careers: "Karriere",
          contactUs: "Kontaktieren Sie uns",
          updatePersonalData: "Rechnungsdaten aktualisieren",  
          updateApplicationSettings: "Anwendungseinstellungen aktualisieren",
          birthday: "Geburtstag",
          yourName: "Ihr Vorname",
          yourLastName: "Ihr Nachname",
          enterEmail: "Geben Sie Ihre E-Mail-Adresse ein",
          yourEmail: "Ihre E-Mail-Adresse",

          changePassword: "Passwort ändern",
          currentPassword: "Aktuelles Passwort",
          newPassword: "Neues Passwort",
          confirmPassword: "Passwort bestätigen",
          showLess: "Weniger anzeigen",
          showMore: "Mehr anzeigen",
          PageNotFound: "Seite nicht gefunden",
          DontGiveUp:
            "Gib nicht auf, vielleicht findest du hier, wonach du suchst.",
          Jobs: "Jobs",
          ElevateYourCareer:
            "Steigern Sie Ihre Karriere bei Bigswiss GmbH, wo Innovation und Leidenschaft aufeinandertreffen. Tauchen Sie ein in eine Welt, in der Ihre Arbeit die Zukunft des Gamings und der Technologie direkt mitgestaltet.",
          exploreOpportunities:
            "Erkunden Sie Möglichkeiten, stellen Sie sich Herausforderungen und werden Sie Teil eines dynamischen Teams, das sich der Exzellenz verschrieben hat. Ihre Reise, um Einfluss zu nehmen, beginnt hier.",
          enterYourEmailAddress: "Geben Sie Ihre E-Mail-Adresse ein",
          yourEmailAddress: "Ihre E-Mail-Adresse",
          contactUsAgree:
            'Mit dem Klick auf "Kontaktieren Sie uns" stimmen Sie unseren',
          termsAndConditions: "Geschäftsbedingungen zu",
          bigswissSupport: "Kontakt Bigswiss ",
          support: "Unterstützung",
          contactDescription:
            "Von Anfragen zu unserem innovativen Online-Shop und Großhandel bis hin zur Unterstützung bei unseren Kiosk-Anwendungen für Videospielkäufe und dem Großhandels-API-Shop sind wir hier, um Ihre Erfahrung zu verbessern.",
          connectTeam:
            "Verbinden Sie sich mit unserem engagierten Team für erstklassige Funktionen und Dienstleistungen, die speziell für Sie entwickelt wurden.",

          pricingDescription:
            "Wählen Sie den Plan, der Ihren Anforderungen entspricht",
          basic: "Basis",
          pro: "Pro",
          enterprise: "Unternehmen",
          getStarted: "Loslegen",
          startNow: "Jetzt starten",
          mostPopular: "Am beliebtesten",
          perMonth: "pro Monat",
          basicDesc1: "API-Zugriff für bis zu 100 Anfragen pro Monat",
          basicDesc2: "Grundlegende Analysen und Berichte",
          basicDesc3: "Standardunterstützung",
          proDesc1: "API-Zugriff für bis zu 10.000 Anfragen pro Monat",
          proDesc2: "Erweiterte Analysen und Berichte",
          proDesc3: "Prioritätsunterstützung",
          proDesc4:
            "Zugang zu exklusiven Angeboten für Spiele und Abonnementkarten",
          enterpriseDesc1: "Unbegrenzte API-Anfragen",
          enterpriseDesc2: "Benutzerdefinierte Analysen und Berichte",
          enterpriseDesc3: "24/7 dedizierter Support",
          enterpriseDesc4: "Angepasste POS-Terminallösungen",
          enterpriseDesc5: "Beste Preise bei Massenkäufen",
          chooseActivation: "Wählen Sie, wo Sie Ihr Produkt aktivieren möchten.",
          thankYouForPurchase: "Vielen Dank für Ihren Kauf!",
          findYourKeysBellow: "Sie finden Ihre Schlüssel und Rechnung unten.",
          productKey: "Produktschlüssel",
          productKeys: "Produktschlüssel",
          productActivation: "Produktaktivierung",
          productActivationDescription: "Bitte befolgen Sie die Anweisungen unten, um Ihr Produkt zu aktivieren.",
          sendAsGift: "Verschenken",
          hide: "Ausblenden",
          viewKeys: "Schlüssel anzeigen",
          downloadInvoice: "Rechnung herunterladen",
          download: "Herunterladen",
          back: "Zurück",
          next: "Weiter",
          activationMethod: "Aktivierungsmethode",
          keylessActivation: "Schlüssellose Aktivierung",
          activationLink: "Aktivierungslink",
          enterTheKey: "Schlüssel eingeben",
          confirmActivation: "Aktivieren",
          battlenetStep1: "Klicken Sie auf die Schaltfläche unten, um zur Battle.net-Schlüsselaktivierungsseite zu navigieren.",
          goToBattlenetActivation: "Zur Battle.net-Aktivierungsseite gehen",
          battlenetStep2: "Melden Sie sich mit Ihren Battle.net-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
          battlenetStep3: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie dann auf 'Code einlösen'.",
          battlenetStep4: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert und es ist bereit, auf Ihrem Battle.net-Konto heruntergeladen und gespielt zu werden.",
          goToActivation: "Aktivierung",
          battlenetStep2app: "Öffnen Sie die Blizzard Battle.net-App. Und melden Sie sich mit Ihren Battle.net-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
          battlenetStep3app: "Klicken Sie auf Ihr Profil, um ein Menü zu öffnen, und klicken Sie dann auf 'Code einlösen'",
          goToSteamActivation: "Zur Steam-Aktivierungsseite gehen",
          steamStep1: "Klicken Sie auf die Schaltfläche unten, um zur Steam-Schlüsselaktivierungsseite zu navigieren.",
          steamStep2: "Melden Sie sich mit Ihren Steam-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
          steamStep3: "Lesen und akzeptieren Sie die Steam-Abonnementvereinbarung und klicken Sie dann auf 'Weiter'.",
          steamStep4: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert und es ist bereit, auf Ihrem Steam-Konto heruntergeladen und gespielt zu werden.",
          steamStep2app: "Öffnen Sie die Steam-App und melden Sie sich mit Ihren Steam-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
          steamStep3app: "Klicken Sie im oberen Menü auf 'Spiele' und dann auf 'Ein Produkt bei Steam aktivieren'.",
          steamStep4app: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie dann auf 'Bestätigen'.",

          goToMicrosoftActivation: "Zur Microsoft Store Aktivierungsseite gehen",
          microsoftStep1: "Klicken Sie auf die Schaltfläche unten, um zur Microsoft Store Schlüsselaktivierungsseite zu gelangen.",
          microsoftStep2: "Melden Sie sich mit Ihren Microsoft-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
          microsoftStep3: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Weiter'.",
          microsoftStep4: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert, und es ist bereit, auf Ihrem Microsoft-Konto heruntergeladen und gespielt zu werden.",
          microsoftStep2app: "Öffnen Sie auf Ihrem Windows-System die Microsoft Store App und melden Sie sich mit Ihren Microsoft-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
          microsoftStep3app: "Klicken Sie auf das Profil-Logo oben rechts und dann auf 'Code oder Geschenkkarten einlösen'.",

          goToGOGActivation: "Zur GOG-Aktivierungsseite gehen",
          activateKey: "Schlüssel aktivieren",
          linkToAccount: "Mit Konto verknüpfen",
          gogStep1: "Klicken Sie auf die Schaltfläche unten, um zur GOG-Schlüsselaktivierungsseite zu gelangen.",
          gogStep2: "Klicken Sie auf 'Weiter', um die Schlüsselaktivierung zu bestätigen.",
          gogStep3: "Klicken Sie auf 'Anmelden', um den Schlüssel mit Ihrem GOG-Konto zu verknüpfen.",
          gogStep4: "Melden Sie sich mit Ihren GOG-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie kostenlos eines.",
          gogStep5: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert und es ist bereit, auf Ihrem GOG-Konto heruntergeladen und gespielt zu werden.",

          gogStep2app: "Öffnen Sie die GOG Galaxy-App und melden Sie sich mit Ihren GOG-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie kostenlos eines.",
          gogStep3app: "Klicken Sie im oberen Menü auf 'Spiele & Freunde hinzufügen' und dann auf 'GOG-Code einlösen'.",
          gogStep4app: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Weiter'.",


          goToEpicActivation: "Zur Epic Games-Aktivierungsseite gehen",
          epicStep1: "Klicken Sie auf die Schaltfläche unten, um zur Epic Games-Schlüsselaktivierungsseite zu navigieren.",
          epicStep2: "Klicken Sie auf 'Einlösen', um die Schlüsselaktivierung zu bestätigen.",
          epicStep3: "Klicken Sie auf 'Anmelden', um den Schlüssel mit Ihrem Epic Games-Konto zu verknüpfen.",
          epicStep4: "Melden Sie sich mit Ihren Epic Games-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie kostenlos eines.",
          epicStep5: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert und es ist bereit, auf Ihrem Epic Games-Konto heruntergeladen und gespielt zu werden.",
          epicStep2app: "Öffnen Sie die Epic Games-App und melden Sie sich mit Ihren Epic Games-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie kostenlos eines.",
          epicStep3app: "Klicken Sie oben rechts auf Ihr Profil und dann auf 'Code einlösen'.",
          epicStep4app: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Einlösen'.",

          goToRockstarActivation: "Zur Rockstar-Aktivierungsseite gehen",
          rockstarStep1: "Klicken Sie auf den untenstehenden Button, um zur Rockstar-Schlüsselaktivierungsseite zu gelangen.",
          rockstarStep2: "Melden Sie sich mit Ihren Rockstar-Anmeldedaten an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
          rockstarStep3: "Bitte warten Sie, bis die Seite geladen und die Rockstar-Validierung abgeschlossen ist. Dies kann einige Minuten dauern. Sobald die Seite geladen ist, sehen Sie eine Bestätigungsnachricht. Klicken Sie auf 'Bestätigen', um den Schlüssel auf Ihr Rockstar-Konto zu aktivieren.",
          rockstarStep4: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert, und es ist bereit, auf Ihrem Rockstar-Konto heruntergeladen und gespielt zu werden.",
          rockstarStep2app: "Öffnen Sie die Rockstar Games Launcher-App und melden Sie sich mit Ihren Rockstar-Anmeldedaten an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
          rockstarStep3app: "Klicken Sie auf das Menü in der oberen rechten Ecke und dann auf 'CODE EINLÖSEN'.",
          rockstarStep4app: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'PRÜFEN'.",
          rockstarStep5app: "Lesen und akzeptieren Sie die Allgemeinen Geschäftsbedingungen, und klicken Sie dann auf 'EINLÖSEN'.",

          goToEAPlayActivation: "Zur EA Play Aktivierungsseite gehen",
          eaStep1: "Klicken Sie unten auf die Schaltfläche, um zur EA Play-Schlüsselaktivierungsseite zu gelangen.",
          eaStep2: "Melden Sie sich mit Ihren EA Play-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie eines kostenlos.",
          eaStep3: "Geben Sie den erhaltenen Schlüssel in das entsprechende Feld ein und klicken Sie auf 'Weiter'.",
          eaStep4: "Klicken Sie auf die Schaltfläche 'Weiter', um den Schlüssel mit Ihrem EA Play-Konto zu verknüpfen.",
          eaStep5: "Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert, und es kann nun auf Ihrem EA Play-Konto heruntergeladen und gespielt werden.",

          eaStep2app: "Öffnen Sie die EA Play-App und melden Sie sich mit Ihren EA Play-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie eines kostenlos.",
          eaStep3app: "Klicken Sie oben rechts im Menü auf 'Code einlösen'.",
          eaStep4app: "Geben Sie den erhaltenen Schlüssel in das entsprechende Feld ein und klicken Sie auf 'Weiter'.",

          ubisoftStep2app: "Öffnen Sie die Ubisoft-App und melden Sie sich mit Ihren Ubisoft-Zugangsdaten an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
          ubisoftStep3app: "Klicken Sie im Profilmenü in der oberen linken Ecke und dann auf 'Schlüsselaktivierung'.",
          ubisoftStep4app: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Aktivieren'.",

          goToSwitchActivation: "Zur Nintendo Switch-Aktivierungsseite gehen",
          openeshop: "eShop öffnen",
          selectUser: "Benutzer auswählen",
          redeem: "Schlüssel einlösen",
          switchStep1: "Klicken Sie unten auf die Schaltfläche, um zur Nintendo Switch-Schlüsselaktivierungsseite zu gelangen.",
          switchStep2: "Melden Sie sich mit Ihren Nintendo Switch-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie eines kostenlos.",
          switchStep3: "Geben Sie den erhaltenen Schlüssel in das entsprechende Feld ein und klicken Sie auf 'Weiter'.",
          switchStep4: "Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert, und es kann nun auf Ihrem Nintendo Switch-Konto heruntergeladen und gespielt werden.",
          switchStep1app: "Öffnen Sie die Nintendo eShop-App.",
          switchStep2app: "Wählen Sie das Konto, auf dem Sie das Produkt aktivieren möchten.",
          switchStep3app: "Geben Sie den erhaltenen Schlüssel in das entsprechende Feld ein und klicken Sie auf 'Bestätigen'.",
          switchStep4app: "Wählen Sie 'Einlösen' und klicken Sie auf 'Bestätigen', um das Produkt mit Ihrem Nintendo Switch-Konto zu verknüpfen.",

          goToPlayStationActivation: "Zur PlayStation-Aktivierungsseite gehen",
          openLogin: "Login öffnen",
          psStep1: "Klicken Sie unten, um zur PlayStation-Aktivierungsseite zu gelangen.",
          psStep2: "Klicken Sie oben rechts auf 'Login'",
          psStep3: "Melden Sie sich mit Ihren PlayStation-Zugangsdaten an. Wenn Sie noch kein Konto haben, erstellen Sie eines kostenlos.",
          psStep4: "Klicken Sie oben rechts auf Ihr Profil und dann auf 'Code einlösen'.",
          psStep5: "Geben Sie den erhaltenen Schlüssel in das entsprechende Feld ein und klicken Sie auf 'Weiter'.",
          psStep6: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert. Es ist jetzt bereit, auf Ihrem PlayStation-Konto heruntergeladen und gespielt zu werden.",
          openStore: "Store öffnen",
          psStep2console: "Wählen Sie den PlayStation Store auf der linken Seite aus und drücken Sie 'X'.",
          psStep3console: "Drücken Sie '△', um auf das Menü in der oberen rechten Ecke zuzugreifen, wählen Sie '...' und wählen Sie 'Code einlösen'.",
          psStep4console: "Geben Sie den erhaltenen Code in das vorgesehene Feld ein und klicken Sie auf 'Einlösen'.",
          psStep2app: "Öffnen Sie die PlayStation-App und den PlayStation Store.",
          openMenu: "Menü öffnen",
          psStep3app: "Wählen Sie das Menü in der oberen rechten Ecke aus, um weitere Optionen anzuzeigen.",
          psStep4app: "Wählen Sie 'Code einlösen'.",

          goToXboxActivation: "Zur Xbox-Aktivierungsseite gehen",
          xboxStep1: "Klicken Sie auf die Schaltfläche unten, um zur Xbox-Schlüsselaktivierungsseite zu navigieren.",
          xboxStep2: "Klicken Sie auf 'Anmelden, um einzulösen', um sich mit Ihren Xbox-Anmeldeinformationen anzumelden. Wenn Sie kein Konto haben, erstellen Sie kostenlos eines.",
          xboxStep3: "Klicken Sie auf 'Jetzt einlösen', um das Schlüsselaktivierungsfenster zu öffnen.",
          xboxStep4: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Weiter'. Folgen Sie den weiteren Anweisungen.",
          xboxStep5: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert, und es ist bereit, auf Ihrem Xbox-Konto heruntergeladen und gespielt zu werden.",
          xboxStep2console: "Drücken Sie die Xbox-Taste auf Ihrem Controller, um den Guide zu öffnen. Während Sie mit Ihrem Konto angemeldet sind, wählen Sie 'Store'.",
          xboxStep3console: "Sobald es geöffnet ist, drücken Sie die Ansichtstaste auf Ihrem Controller, um das Menü zu öffnen, und wählen Sie 'Einlösen'.",
          xboxStep4console: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Code prüfen'.",

          warningModalTitle: "Wichtiger Hinweis",
          warningModalMessage1: "Sobald Sie den Schlüssel anzeigen, verlieren Sie das Recht auf eine sofortige Rückerstattung.",
          warningModalMessage2: "Sie sind nur dann für eine Rückerstattung berechtigt, wenn der Schlüssel ungültig ist.",
          doYouWantToProceed: "Möchten Sie fortfahren?",
          revealKey: "Schlüssel anzeigen"
        },
      },
      fr: {
        translation: {
          languageChanged: "Langue changée",
          selectedLanguage: "Le français est maintenant sélectionné",
          dark: "Sombre",
          light: "Clair",
          systemPreferences: "Préférences système",
          changeApplicationTheme: "Sélectionnez le thème de l'application",
          applicationTheme: "Thème de l'application",
          darkDescription: "Bienvenue du côté obscur, nous avons des cookies!",
          lightDescription: "Embrassez la lumière, noble joueur!",
          autoDescription: "Laissez votre système décider de votre destin!",
          welcome: "Bienvenue",
          selectLanguage: "Sélectionnez la langue par défaut",
          selectLanguageLabel: "Choisir la langue",
          gdprFadpDescription: "Nous utilisons des cookies pour garantir le bon fonctionnement de notre site Web et améliorer votre expérience. En sélectionnant 'Accepter uniquement les cookies nécessaires', vous consentez à l'utilisation de cookies essentiels pour les fonctionnalités de base, y compris les cookies tiers de Stripe pour le traitement sécurisé des paiements. En sélectionnant 'Accepter tous les cookies', vous nous permettez également d'utiliser des cookies facultatifs, tels que ceux destinés à l'analyse (Google Analytics) et à la publicité, afin d'améliorer davantage nos services.",
          acceptTerms: "J'accepte les termes et conditions.",
          acceptNecessary: "Essentiel Seulement",
          acceptAll: "Tout Accepter",
          termsWarning: "Veuillez accepter les conditions générales pour continuer.",


          selectCurrency: "Sélectionner la devise",
          newsletter: "Bulletin",
          subscribeMessage:
            "Abonnez-vous à notre newsletter pour des mises à jour et des promotions hebdomadaires.",
          subscribe: "S'abonner",
          products: "Produits",
          position: "Poste",
          message: "Message",
          invalidEmail: "E-mail invalide",
          allFieldsRequired: "Veuillez remplir tous les champs obligatoires",
          messageSent: "Message envoyé",
          contacted: "Demande envoyée",
          jobThankYou:
            "Merci de votre intérêt. Nous vous contacterons bientôt.",
          duplicateApplication:
            "Vous avez déjà postulé, veuillez patienter jusqu'à ce que nous vous contactions.",
          duplicateSubscription: "Vous êtes déjà abonné.",
          succesSubcribed: "Vous vous êtes abonné avec succès.",
          company: "Entreprise",
          getInTouch: "Prendre contact",
          privacyPolicy: "Politique de confidentialité",
          termsOfService: "Conditions d'utilisation",
          pcGames: "Jeux PC",
          xboxGames: "Jeux XBOX",
          playstationGames: "Jeux PlayStation",
          nintendoGames: "Jeux Nintendo",
          giftCards: "Cartes-cadeaux",
          subscriptionCards: "Cartes d'abonnement",
          aboutUs: "À propos de nous",
          testimonials: "Témoignages",
          testimonialsDescription:
            "Découvrez ce que nos clients aiment dans nos fonctionnalités. Découvrez comment nous excellons en efficacité et satisfaction. Rejoignez-nous pour des coûts réduits, une innovation dans la vente au détail et un support fiable.",

          highlights: "Points forts",
          pricing: "Tarification",

          faqs: "FAQ",
          giftAndSubscriptionCards: "Cartes cadeaux & d'abonnement",
          checkTopSellers: "Vérifiez les meilleures ventes",
          platforms: "Plateformes",
          category: "Catégorie",
          platform: "Plateforme",
          contactSupportText: "Si vous souhaitez changer la région de votre compte, veuillez contacter notre support",
          region: "Région",
          productType: "Type de produit",
          none: "Aucun",
          sort: "Trier",
          priceIncreasing: "Augmentation des prix",
          priceDecreasing: "Diminution des prix",

          consoles: "Consoles",
          aboutUsDescription:
            "Depuis 2002, Bigswiss.ch est votre destination privilégiée pour les jeux numériques et les cartes cadeaux, opérant fièrement depuis le cœur de la Suisse. Avec plus de deux décennies d'expérience, nous nous engageons à vous offrir le meilleur du divertissement numérique.\n\nNotre vaste sélection comprend une grande variété de jeux numériques sur toutes les plateformes populaires, ainsi qu'une gamme de cartes cadeaux pour vos services préférés. Que vous soyez un joueur chevronné à la recherche de la prochaine grande sortie ou à la recherche du cadeau parfait pour un être cher, Bigswiss.ch a tout ce qu'il vous faut.\n\nChez Bigswiss.ch, nous sommes fiers de fournir un service client exceptionnel et une expérience d'achat fluide. Notre équipe dédiée est toujours là pour vous assister, garantissant que votre parcours avec nous soit agréable et sans encombre.",
          onlineStoreTitle: "Boutique en ligne",
          onlineStoreDescription:
            "Achetez plus de 10 000 jeux vidéo et produits numériques différents",
          posTerminalsTitle: "Terminaux POS",
          posTerminalsDescription:
            "Intégrez nos terminaux POS dans votre magasin et accédez à tous nos jeux",
          wholesaleApiTitle: "API de gros",
          wholesaleApiDescription:
            "Intégrez notre API de gros à votre boutique en ligne",
          learnMore: "En savoir plus",
          goToStore: "Aller au magasin",
          highlightsDescription:
            "Découvrez pourquoi notre produit se distingue : adaptabilité, durabilité, design convivial et innovation. Profitez d'un support client fiable et d'une précision dans chaque détail.",
          instantDeliveryTitle: "Livraison Instantanée",
          instantDeliveryDescription:
            "Recevez vos codes numériques instantanément après l'achat.",
          digitalDownloadTitle: "Téléchargement Numérique",
          digitalDownloadDescription:
            "Accédez à vos jeux et services préférés avec des codes faciles à utiliser.",
          customerSupportTitle: "Support Client 24/7",
          customerSupportDescription:
            "Nous sommes là pour vous à tout moment, prêts à résoudre tout problème.",
          adaptablePerformanceTitle: "Performance Adaptable",
          adaptablePerformanceDescription:
            "Notre produit s'ajuste facilement à vos besoins, augmentant l'efficacité et simplifiant vos tâches.",
          innovativeFunctionalityTitle: "Fonctionnalité Innovante",
          innovativeFunctionalityDescription:
            "Restez en avance avec des fonctionnalités qui établissent de nouvelles normes, répondant mieux à vos besoins en évolution.",
          precisionDetailTitle: "Précision dans chaque détail",
          precisionDetailDescription:
            "Profitez d'un produit méticuleusement conçu où les petits détails ont un impact significatif sur votre expérience globale.",
          ourTopSellingProducts: "Nos produits les plus vendus",

          faqTitle: "Questions fréquemment posées",
          faq1Question:
            "Comment contacter le support client si j'ai une question ou un problème?",
          faq1Answer:
            "Nous sommes là pour vous aider rapidement. Vous pouvez contacter notre équipe d'assistance clientèle en envoyant un e-mail à ",
          faq2Question:
            "Puis-je retourner le produit s'il ne répond pas à mes attentes?",
          faq2Answer:
            "Absolument! Nous offrons une politique de retour sans tracas. Si vous n'êtes pas entièrement satisfait, vous pouvez retourner le produit dans les 7 jours pour un remboursement complet ou un échange.",
          faq3Question:
            "Qu'est-ce qui distingue votre produit des autres sur le marché?",
          faq3Answer:
            "Notre produit se distingue par son adaptabilité, sa durabilité et ses fonctionnalités innovantes. Nous priorisons la satisfaction de l'utilisateur et nous efforçons continuellement de dépasser les attentes dans tous les aspects.",
          faq4Question:
            "Y a-t-il une garantie sur le produit, et que couvre-t-elle?",
          faq4Answer:
            "Oui, notre produit est livré avec une garantie de 1 an. Elle couvre les défauts de matériaux et de fabrication. Si vous rencontrez des problèmes couverts par la garantie, veuillez contacter notre support client pour obtenir de l'aide.",

          available: "Disponible",
          now: "Maintenant",
          games: "Jeux",
          about: "À propos de",
          game: "Jeu",
          website: "Site web",
          description: "Description",
          rating: "Évaluation",
          developer: "Développeur",
          publisher: "Éditeur",
          released: "Date de sortie",
          genre: "Genre",
          gallery: "Galerie",
          maxPrice: "Prix maximum",

          availableEditions: "Éditions Disponibles",
          availablePlatforms: "Plateformes Disponibles",
          addToCart: "Ajouter au panier",
          emptyCartHeading: "Le panier est vide...",
          emptyCartSubheading:
            "Votre panier se sent seul. Commencez votre aventure shopping maintenant!",
          exploreCatalog: "Explorez notre catalogue",
          yourCart: "Votre Panier",
          quantity: "Quantité",
          maxProductAllowed: "Maximum de {{max}} produits autorisés",
          total: "Total",
          goToCart: "Aller au Panier",
          payNow: "Payer maintenant",
          goToPayment: "Aller au Paiement",
          discount: "Remise",
          originalPrice: "Prix d'origine",
          checkMoreProducts: "Voir plus de produits",
          cartSummary: "Récapitulatif du panier",
          cartItems: "Articles dans le panier",
          payment: "Paiement",
          gameActivation: "Activation du jeu",
          login: "Connexion",
          remove: "Supprimer",

          Notification: "Notification",
          notificationsEnabled: "Les notifications du navigateur sont activées",
          notificationsEnableFail: "Échec de l'activation des notifications",
          notificationsDisabled: "Les notifications du navigateur sont désactivées",
          notificationsDisableFail: "Échec de la désactivation des notifications",
          notificationsDenied: "Autorisation de notification refusée",
          notificationsNoPermission: "Autorisation de notification non accordée",
          notificationsPermissionFaild: "Échec de la demande d'autorisation de notification",
          notificationsNotSupported: "La messagerie push n'est pas prise en charge",
          notificationsFailToSubscribe: "Échec de l'abonnement de l'utilisateur",
          notificationsFailToUnsubscribe: "Échec de la désinscription de l'utilisateur",
          serviceWorkerNotReady: "Veuillez recharger la page et réessayer",
          notificationsSubscriptionErro: "Erreur lors de l'obtention de l'abonnement",
          browserNotifications: "Notifications du navigateur",

          noProductsFound: "Aucun produit trouvé.",
          tryDifferentQuery:
            "Veuillez essayer une autre recherche ou réinitialiser les filtres.",
          resetFilters: "Réinitialiser les filtres",
          achievements: "Réalisations",
          noFavorites: "Oups ! Pas de butin trouvé !",
          noFavoritesText: "Il est temps de chasser du butin épique !",
          favorites: "Favoris",
          purchases: "Achats",
          points: "Points",
          dashboard: "Tableau de bord",
          settings: "Paramètres",
          updateSettings: "Mettre à jour les paramètres",
          security: "Sécurité",
          invoiceNotFound: "Facture non trouvée",
          action: "Action",
          date: "Date",
          rowsPerPage: "Lignes par page",
          aboutOurApi: "À propos de notre API",
          aboutOurApiDescription:
            "Bienvenue sur notre page produit API! Notre API offre un accès transparent à plus de 10 000 produits, garantissant que vous pouvez intégrer et proposer une large gamme d'articles à vos clients à tout moment. Que vous construisiez une plateforme de commerce électronique, une application mobile ou tout autre service, notre API vous couvre.",
          whyChooseOurApi: "Pourquoi choisir notre API?",
          whyChooseOurApiDescription:
            "Notre API est conçue pour être robuste, fiable et facile à utiliser. Voici quelques-unes des principales caractéristiques:",
          featureApi1: "Accès à plus de 10 000 produits",
          featureApi2:
            "Disponibilité et tarification des produits en temps réel",
          featureApi3:
            "Haute disponibilité avec un temps de fonctionnement de 99,9%",
          featureApi4: "Documentation complète et support",
          featureApi5:
            "Intégration facile avec des points de terminaison RESTful",
          featureApi6: "Architecture sécurisée et évolutive",
          WithOurApi:
            "Avec notre API, vous pouvez garantir à vos clients un accès à un vaste inventaire de produits à tout moment. Notre haute disponibilité et nos mises à jour en temps réel signifient que vous ne manquerez jamais une vente.",

          aboutOurPosTerminals: "À propos de nos terminaux de point de vente",
          welcomeMessage:
            "Bienvenue sur notre page produit! Nous sommes fiers de proposer des terminaux de point de vente de pointe conçus spécifiquement pour les magasins qui vendent des jeux et des cartes d'abonnement. Nos terminaux sont faciles à utiliser, sécurisés et efficaces, vous aidant à gérer vos ventes et votre inventaire avec facilité",
          whyChooseOurPosTerminals:
            "Pourquoi choisir nos terminaux de point de vente?",
          posTerminalFeatures:
            "Nos terminaux de point de vente sont dotés d'une gamme de fonctionnalités pour rationaliser les opérations de votre magasin:",
          feature1: "Intégration transparente avec vos systèmes existants",
          feature2: "Traitement des paiements rapide et sécurisé",
          feature3: "Gestion des stocks en temps réel",
          feature4: "Interface conviviale pour une formation rapide",
          feature5:
            "Paramètres personnalisables pour répondre aux besoins de votre magasin",
          posTerminalDescription:
            "Que vous vendiez des jeux physiques ou des cartes d'abonnement numériques, nos terminaux de point de vente sont équipés pour gérer toutes vos transactions efficacement. Améliorez l'expérience de vos clients et augmentez vos ventes avec nos solutions POS fiables et polyvalentes",

          invoiceNo: "No de facture",
          howTo: "Comment",
          activate: "Activer",
          step: "Étape",
          giftToFriend: "Offrir à un ami",
          enterEmailTwice: "Veuillez entrer deux fois l'adresse e-mail de votre ami pour confirmation.",
          friendEmail: "E-mail de l'ami",
          confirmFriendsEmail: "Confirmer l'e-mail de l'ami",
          emailsDoNotMatch: "Les adresses e-mail ne correspondent pas. Veuillez réessayer.",
          sendGift: "Envoyer le cadeau",
          giftSent: "Cadeau envoyé",
          giftSentMessage: "Nous avons envoyé la clé en cadeau à votre ami à l'adresse e-mail suivante :",
          close: "Fermer",
          

          buyers: "Acheteurs",
          reviews: "Avis",
          user: "Utilisateur",
          comment: "Commentaire",

          requirements: "Exigences",
          minimumRequirements: "Exigences minimales",
          recommendedRequirements: "Exigences recommandées",
          OS: "OS",
          Processor: "Processeur",
          Memory: "Mémoire",
          Graphics: "Graphiques",
          DirectX: "DirectX",
          Storage: "Stockage",
          AdditionalNotes: "Notes supplémentaires",

          careers: "Carrières",
          contactUs: "Nous contacter",
          updatePersonalData: "Mettre à jour les données de facturation",  
          updateApplicationSettings:
            "Mettre à jour les paramètres de l'application",
          birthday: "Anniversaire",
          yourName: "Votre prénom",
          yourLastName: "Votre nom",
          enterEmail: "Entrez votre adresse e-mail",
          yourEmail: "Votre adresse e-mail",
          changePassword: "Changer le mot de passe",
          currentPassword: "Mot de passe actuel",
          newPassword: "Nouveau mot de passe",
          confirmPassword: "Confirmer le mot de passe",
          showLess: "Montrer moins",
          showMore: "Montrer plus",
          PageNotFound: "Page non trouvée",
          DontGiveUp:
            "Ne vous découragez pas, peut-être trouverez-vous ici ce que vous cherchez.",
          Jobs: "Emplois",
          ElevateYourCareer:
            "Élevez votre carrière chez Bigswiss GmbH, où l'innovation et la passion se rencontrent. Plongez dans un monde où votre travail façonne directement l'avenir du jeu et de la technologie.",
          exploreOpportunities:
            "Explorez les opportunités, relevez les défis et faites partie d'une équipe dynamique dédiée à l'excellence. Votre voyage pour avoir un impact commence ici.",
          enterYourEmailAddress: "Entrez votre adresse email",
          yourEmailAddress: "Votre adresse email",
          contactUsAgree: 'En cliquant sur "Contactez-nous", vous acceptez nos',
          termsAndConditions: "Conditions générales",
          support: "Support",
          contactDescription:
            "Des demandes de renseignements sur notre boutique en ligne innovante et notre magasin de gros à l'assistance pour nos applications de kiosque pour les achats de jeux vidéo et le magasin API de gros, nous sommes là pour améliorer votre expérience.",
          connectTeam:
            "Connectez-vous avec notre équipe dédiée pour des fonctionnalités et des services de premier ordre conçus juste pour vous.",

          pricingDescription: "Choisissez le plan qui convient à vos besoins",
          basic: "Basique",
          pro: "Pro",
          enterprise: "Entreprise",
          getStarted: "Commencer",
          startNow: "Commencez maintenant",
          mostPopular: "Le plus populaire",
          perMonth: "par mois",
          basicDesc1: "Accès API pour jusqu'à 100 demandes par mois",
          basicDesc2: "Analyses et rapports de base",
          basicDesc3: "Support standard",
          proDesc1: "Accès API pour jusqu'à 10 000 demandes par mois",
          proDesc2: "Analyses et rapports avancés",
          proDesc3: "Support prioritaire",
          proDesc4:
            "Accès à des offres exclusives de jeux et de cartes d'abonnement",
          enterpriseDesc1: "Requêtes API illimitées",
          enterpriseDesc2: "Analyses et rapports personnalisés",
          enterpriseDesc3: "Support dédié 24/7",
          enterpriseDesc4: "Solutions de terminal POS personnalisées",
          enterpriseDesc5: "Meilleurs prix pour les achats en gros",


          chooseActivation: "Choisissez où vous souhaitez activer votre produit.",
          thankYouForPurchase: "Merci pour votre achat!",
          findYourKeysBellow: "Vous trouverez vos clés et votre facture ci-dessous.",
          productKey: "Clé de produit",
          productKeys: "Clés de produit",
          productActivation: "Activation du produit",
          productActivationDescription: "Veuillez suivre les instructions ci-dessous pour activer votre produit.",
          sendAsGift: "Offrir",
          hide: "Cacher",
          viewKeys: "Voir les clés",
          downloadInvoice: "Télécharger la facture",
          download: "Télécharger",
          back: "Retour",
          next: "Suivant",
          activationMethod: "Méthode d'activation",
          keylessActivation: "Activation sans clé",
          activationLink: "Lien d'activation",
          enterTheKey: "Entrez la clé",
          confirmActivation: "Confirmer l'activation",
          battlenetStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé Battle.net.",
          goToBattlenetActivation: "Aller à la page d'activation Battle.net",
          battlenetStep2: "Connectez-vous avec vos identifiants Battle.net. Si vous n'avez pas de compte, créez-en un gratuitement.",
          battlenetStep3: "Entrez la clé reçue dans le champ prévu à cet effet, puis cliquez sur 'Racheter'.",
          battlenetStep4: "Félicitations ! Vous avez activé votre produit avec succès et il est prêt à être téléchargé et joué sur votre compte Battle.net.",
          goToActivation: "Aller à l'activation",
          battlenetStep2app: "Ouvrez l'application Blizzard Battle.net. Et connectez-vous avec vos identifiants Battle.net. Si vous n'avez pas de compte, créez-en un gratuitement.",
          battlenetStep3app: "Cliquez sur votre profil pour ouvrir un menu, puis cliquez sur 'Racheter un code'.",
          goToSteamActivation: "Aller à la page d'activation Steam",
          steamStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé Steam.",
          steamStep2: "Connectez-vous avec vos identifiants Steam. Si vous n'avez pas de compte, créez-en gratuitement.",
          steamStep3: "Lisez et acceptez l'accord d'abonnement Steam, puis cliquez sur 'Continuer'.",
          steamStep4: "Félicitations ! Vous avez activé votre produit avec succès et il est prêt à être téléchargé et joué sur votre compte Steam.",
          steamStep2app: "Ouvrez l'application Steam et connectez-vous avec vos identifiants Steam. Si vous n'avez pas de compte, créez-en un gratuitement.",
          steamStep3app: "Cliquez sur 'Jeux' dans le menu supérieur, puis cliquez sur 'Activer un produit sur Steam'.",
          steamStep4app: "Entrez la clé reçue dans le champ prévu à cet effet, puis cliquez sur 'Valider'.",

          goToMicrosoftActivation: "Aller à la page d'activation du Microsoft Store",
          microsoftStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé du Microsoft Store.",
          microsoftStep2: "Connectez-vous avec vos identifiants Microsoft. Si vous n'avez pas de compte, créez-en un gratuitement.",
          microsoftStep3: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Suivant'.",
          microsoftStep4: "Félicitations ! Vous avez activé votre produit avec succès et il est prêt à être téléchargé et joué sur votre compte Microsoft.",
          microsoftStep2app: "Sur votre système Windows, ouvrez l'application Microsoft Store et connectez-vous avec vos identifiants Microsoft. Si vous n'avez pas de compte, créez-en un gratuitement.",
          microsoftStep3app: "Cliquez sur l'icône de profil en haut à droite, puis cliquez sur 'Utiliser un code ou une carte cadeau'.",

          goToGOGActivation: "Aller à la page d'activation GOG",
          activateKey: "Activer la clé",
          linkToAccount: "Lier au compte",
          gogStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé GOG.",
          gogStep2: "Cliquez sur 'Continuer' pour confirmer l'activation de la clé.",
          gogStep3: "Cliquez sur 'Se connecter' pour lier la clé à votre compte GOG.",
          gogStep4: "Connectez-vous avec vos identifiants GOG. Si vous n'avez pas de compte, créez-en un gratuitement.",
          gogStep5: "Félicitations ! Vous avez activé votre produit avec succès, et il est prêt à être téléchargé et joué sur votre compte GOG.",

          gogStep2app: "Ouvrez l'application GOG Galaxy et connectez-vous avec vos identifiants GOG. Si vous n'avez pas de compte, créez-en un gratuitement.",
          gogStep3app: "Cliquez sur 'Ajouter des jeux et des amis' dans le menu supérieur, puis sur 'Racheter un code GOG'.",
          gogStep4app: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Continuer'.",

          goToEpicActivation: "Aller à la page d'activation Epic Games",
          epicStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé Epic Games.",
          epicStep2: "Cliquez sur 'Échanger' pour confirmer l'activation de la clé.",
          epicStep3: "Cliquez sur 'Se connecter' pour lier la clé à votre compte Epic Games.",
          epicStep4: "Connectez-vous avec vos identifiants Epic Games. Si vous n'avez pas de compte, créez-en un gratuitement.",
          epicStep5: "Félicitations ! Vous avez activé votre produit avec succès et il est prêt à être téléchargé et joué sur votre compte Epic Games.",
          epicStep2app: "Ouvrez l'application Epic Games et connectez-vous avec vos identifiants Epic Games. Si vous n'avez pas de compte, créez-en un gratuitement.",
          epicStep3app: "Cliquez sur votre profil en haut à droite, puis sur 'Échanger un code'.",
          epicStep4app: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Échanger'.",

          goToRockstarActivation: "Aller à la page d'activation Rockstar",
          rockstarStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé Rockstar.",
          rockstarStep2: "Connectez-vous avec vos identifiants Rockstar. Si vous n'avez pas de compte, créez-en un gratuitement.",
          rockstarStep3: "Veuillez patienter pendant le chargement de la page et la validation Rockstar. Cela peut prendre plusieurs minutes. Une fois la page chargée, vous verrez un message de confirmation. Cliquez sur 'Confirmer' pour activer la clé sur votre compte Rockstar.",
          rockstarStep4: "Félicitations ! Vous avez activé votre produit avec succès et il est prêt à être téléchargé et joué sur votre compte Rockstar.",
          rockstarStep2app: "Ouvrez l'application Rockstar Games Launcher et connectez-vous avec vos identifiants Rockstar. Si vous n'avez pas de compte, créez-en un gratuitement.",
          rockstarStep3app: "Cliquez sur le menu en haut à droite, puis sur 'UTILISER UN CODE'.",
          rockstarStep4app: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'VÉRIFIER'.",
          rockstarStep5app: "Lisez et acceptez les conditions générales, puis cliquez sur 'UTILISER'.",

          goToEAPlayActivation: "Aller à la page d'activation EA Play",
          eaStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé EA Play.",
          eaStep2: "Connectez-vous avec vos identifiants EA Play. Si vous n'avez pas de compte, créez-en un gratuitement.",
          eaStep3: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Suivant'.",
          eaStep4: "Cliquez sur le bouton 'Suivant' pour lier la clé à votre compte EA Play.",
          eaStep5: "Félicitations! Vous avez activé votre produit avec succès, et il est prêt à être téléchargé et joué sur votre compte EA Play.",

          eaStep2app: "Ouvrez l'application EA Play et connectez-vous avec vos identifiants EA Play. Si vous n'avez pas de compte, créez-en un gratuitement.",
          eaStep3app: "Cliquez sur le menu en haut à droite, puis cliquez sur 'Utiliser un code'.",
          eaStep4app: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Suivant'.",

          ubisoftStep2app: "Ouvrez l'application Ubisoft et connectez-vous avec vos identifiants Ubisoft. Si vous n'avez pas de compte, créez-en un gratuitement.",
          ubisoftStep3app: "Cliquez sur le menu de profil en haut à gauche, puis cliquez sur 'Activation de clé'.",
          ubisoftStep4app: "Entrez la clé que vous avez reçue dans le champ prévu, puis cliquez sur 'Activer'.",

          goToSwitchActivation: "Aller à la page d'activation Nintendo Switch",
          openeshop: "Ouvrir eShop",
          selectUser: "Sélectionner l'utilisateur",
          redeem: "Utiliser la clé",
          switchStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé Nintendo Switch.",
          switchStep2: "Connectez-vous avec vos identifiants Nintendo Switch. Si vous n'avez pas de compte, créez-en un gratuitement.",
          switchStep3: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Continuer'.",
          switchStep4: "Félicitations! Vous avez activé votre produit avec succès, et il est prêt à être téléchargé et joué sur votre compte Nintendo Switch.",
          switchStep1app: "Ouvrez l'application Nintendo eShop.",
          switchStep2app: "Sélectionnez le compte sur lequel vous souhaitez activer le produit.",
          switchStep3app: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Confirmer'.",
          switchStep4app: "Sélectionnez 'Utiliser' et cliquez sur 'Confirmer' pour activer le produit sur votre compte Nintendo Switch.",

          goToPlayStationActivation: "Aller à la page d'activation PlayStation",
          openLogin: "Ouvrir la connexion",
          psStep1: "Cliquez ci-dessous pour accéder à la page d'activation PlayStation.",
          psStep2: "Cliquez en haut à droite sur 'Connexion'",
          psStep3: "Connectez-vous avec vos identifiants PlayStation. Si vous n'avez pas de compte, créez-en un gratuitement.",
          psStep4: "Cliquez en haut à droite sur votre profil, puis sur 'Utiliser un code'.",
          psStep5: "Entrez la clé que vous avez reçue dans le champ prévu, puis cliquez sur 'Continuer'.",
          psStep6: "Félicitations! Vous avez activé votre produit avec succès. Il est prêt à être téléchargé et joué sur votre compte PlayStation.",
          openStore: "Ouvrir le Store",
          psStep2console: "Sélectionnez le PlayStation Store à gauche et appuyez sur 'X'.",
          psStep3console: "Appuyez sur '△' pour accéder au menu en haut à droite, sélectionnez '...' et sélectionnez 'Utiliser un code'.",
          psStep4console: "Entrez le code reçu dans le champ prévu, puis cliquez sur 'Utiliser'.",
          psStep2app: "Ouvrez l'application PlayStation et accédez au PlayStation Store.",
          openMenu: "Ouvrir le menu",
          psStep3app: "Sélectionnez le menu en haut à droite pour afficher plus d'options.",
          psStep4app: "Sélectionnez 'Utiliser un code'.",

          goToXboxActivation: "Aller à la page d'activation Xbox",
          xboxStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de clé Xbox.",
          xboxStep2: "Cliquez sur 'Se connecter pour échanger' pour vous connecter avec vos identifiants Xbox. Si vous n'avez pas de compte, créez-en un gratuitement.",
          xboxStep3: "Cliquez sur 'Échanger maintenant' pour ouvrir la fenêtre d'activation de la clé.",
          xboxStep4: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Suivant'. Continuez à suivre les instructions.",
          xboxStep5: "Félicitations ! Vous avez activé votre produit avec succès, et il est prêt à être téléchargé et joué sur votre compte Xbox.",
          xboxStep2console: "Appuyez sur le bouton Xbox de votre manette pour ouvrir le guide. Une fois connecté à votre compte, sélectionnez 'Store'.",
          xboxStep3console: "Une fois ouvert, appuyez sur le bouton Vue de votre manette pour ouvrir le menu, puis sélectionnez 'Échanger'.",
          xboxStep4console: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Vérifier le code'.",

          warningModalTitle: "Avis Important",
          warningModalMessage1: "Une fois que vous révélez la clé, vous perdez le droit à un remboursement immédiat.",
          warningModalMessage2: "Vous ne serez éligible à un remboursement que si la clé est invalide.",
          doYouWantToProceed: "Souhaitez-vous continuer?",
          revealKey: "Révéler la clé"

        },
      },
      it: {
        translation: {
          languageChanged: "Lingua cambiata",
          selectedLanguage: "L'italiano è ora selezionato",
          dark: "Scuro",
          light: "Chiaro",
          systemPreferences: "Preferenze di sistema",
          changeApplicationTheme: "Seleziona il tema dell'applicazione",
          applicationTheme: "Tema dell'applicazione",
          darkDescription: "Benvenuto nel lato oscuro, abbiamo i biscotti!",
          lightDescription: "Abbraccia la luce, nobile giocatore!",
          autoDescription: "Lascia che il tuo sistema decida il tuo destino!",
          welcome: "Benvenuto",
          selectLanguage: "Seleziona la lingua predefinita",
          selectLanguageLabel: "Seleziona lingua",
          gdprFadpDescription: "Utilizziamo i cookie per garantire il corretto funzionamento del nostro sito Web e migliorare la tua esperienza. Selezionando 'Accetta solo i cookie necessari', acconsenti all'uso di cookie essenziali necessari per le funzionalità di base, inclusi i cookie di terze parti di Stripe per l'elaborazione sicura dei pagamenti. Selezionando 'Accetta tutti i cookie', ci consenti anche di utilizzare cookie opzionali, come quelli per l'analisi (Google Analytics) e la pubblicità, per migliorare ulteriormente i nostri servizi.",
          acceptTerms: "Accetto i termini e le condizioni.",
          acceptNecessary: "Solo Essenziali",
          acceptAll: "Accetta Tutto",
          termsWarning: "Si prega di accettare i termini e le condizioni per procedere.",

          selectCurrency: "Seleziona la valuta",
          newsletter: "Notiziario",
          subscribeMessage:
            "Iscriviti alla nostra newsletter per aggiornamenti settimanali e promozioni.",
          subscribe: "Iscriviti",
          products: "Prodotti",
          position: "Posizione",
          message: "Messaggio",
          invalidEmail: "E-mail non valida",
          allFieldsRequired: "Si prega di compilare tutti i campi obbligatori",
          messageSent: "Messaggio inviato",
          contacted: "Richiesta inviata",
          jobThankYou:
            "Grazie per il vostro interesse. Vi contatteremo presto.",
          duplicateApplication:
            "Hai già fatto domanda, ti preghiamo di pazientare fino a quando non ti contatteremo.",
          duplicateSubscription: "Sei già iscritto.",
          succesSubcribed: "Ti sei iscritto con successo.",
          company: "Azienda",
          getInTouch: "Mettersi in contatto",
          privacyPolicy: "Politica sulla riservatezza",
          termsOfService: "Termini di servizio",
          pcGames: "Giochi per PC",
          xboxGames: "Giochi XBOX",
          playstationGames: "Giochi PlayStation",
          nintendoGames: "Giochi Nintendo",
          giftCards: "Carte regalo",
          subscriptionCards: "Carte di abbonamento",
          aboutUs: "Chi siamo",
          testimonials: "Testimonianze",
          testimonialsDescription:
            "Scopri cosa amano i nostri clienti delle nostre funzionalità. Scopri come eccelliamo in efficienza e soddisfazione. Unisciti a noi per costi ridotti, innovazione nel retail e supporto affidabile.",

          highlights: "Punti salienti",
          pricing: "Prezzi",

          faqs: "FAQ",
          giftAndSubscriptionCards: "Carte regalo & di abbonamento",
          checkTopSellers: "Controlla i più venduti",
          platforms: "Piattaforme",
          category: "Categoria",
          platform: "Piattaforma",
          contactSupportText: "Se desideri cambiare la regione del tuo account, contatta il nostro supporto",
          region: "Regione",
          productType: "Tipo di prodotto",
          none: "Nessuno",
          sort: "Ordina",
          priceIncreasing: "Aumento del prezzo",
          priceDecreasing: "Diminuzione del prezzo",

          consoles: "Console",
          aboutUsDescription:
            "Dal 2002, Bigswiss.ch è la tua destinazione di riferimento per giochi digitali e carte regalo, operando orgogliosamente dal cuore della Svizzera. Con oltre due decenni di esperienza, ci impegniamo a offrirti il meglio del mondo dell'intrattenimento digitale.\n\nLa nostra vasta selezione include una grande varietà di giochi digitali su tutte le piattaforme popolari, oltre a una gamma di carte regalo per i tuoi servizi preferiti. Che tu sia un giocatore esperto in cerca della prossima grande uscita o alla ricerca del regalo perfetto per una persona cara, Bigswiss.ch ha tutto ciò di cui hai bisogno.\n\nSu Bigswiss.ch, siamo orgogliosi di offrire un servizio clienti eccezionale e un'esperienza di acquisto senza soluzione di continuità. Il nostro team dedicato è sempre a tua disposizione per assisterti, assicurandoti che il tuo viaggio con noi sia piacevole e senza intoppi.",
          onlineStoreTitle: "Negozio online",
          onlineStoreDescription:
            "Acquista oltre 10.000 videogiochi e prodotti digitali diversi",
          posTerminalsTitle: "Terminali POS",
          posTerminalsDescription:
            "Integra i nostri terminali POS nel tuo negozio e accedi a tutti i nostri giochi",
          wholesaleApiTitle: "API all'ingrosso",
          wholesaleApiDescription:
            "Integra la nostra API all'ingrosso nel tuo negozio online",
          learnMore: "Scopri di più",
          goToStore: "Vai al negozio",
          highlightsDescription:
            "Scopri perché il nostro prodotto si distingue: adattabilità, durata, design intuitivo e innovazione. Goditi un supporto clienti affidabile e una precisione in ogni dettaglio.",
          instantDeliveryTitle: "Consegna Istantanea",
          instantDeliveryDescription:
            "Ricevi i tuoi codici digitali immediatamente dopo l'acquisto.",
          digitalDownloadTitle: "Download Digitale",
          digitalDownloadDescription:
            "Accedi ai tuoi giochi e servizi preferiti con codici facili da riscattare.",
          customerSupportTitle: "Supporto Clienti 24/7",
          customerSupportDescription:
            "Siamo qui per te in qualsiasi momento, pronti a risolvere qualsiasi problema.",
          adaptablePerformanceTitle: "Prestazioni Adattabili",
          adaptablePerformanceDescription:
            "Il nostro prodotto si adatta facilmente alle tue esigenze, aumentando l'efficienza e semplificando i tuoi compiti.",
          innovativeFunctionalityTitle: "Funzionalità Innovativa",
          innovativeFunctionalityDescription:
            "Rimani all'avanguardia con funzionalità che stabiliscono nuovi standard, rispondendo meglio alle tue esigenze in evoluzione.",
          precisionDetailTitle: "Precisione in ogni dettaglio",
          precisionDetailDescription:
            "Goditi un prodotto meticolosamente realizzato in cui i piccoli dettagli hanno un impatto significativo sulla tua esperienza complessiva.",
          ourTopSellingProducts: "I nostri prodotti più venduti",

          faqTitle: "Domande frequenti",
          faq1Question:
            "Come posso contattare l'assistenza clienti se ho una domanda o un problema?",
          faq1Answer:
            "Siamo qui per assisterti prontamente. Puoi contattare il nostro team di assistenza clienti inviando un'email a ",
          faq2Question:
            "Posso restituire il prodotto se non soddisfa le mie aspettative?",
          faq2Answer:
            "Assolutamente! Offriamo una politica di restituzione senza problemi. Se non sei completamente soddisfatto, puoi restituire il prodotto entro 7 giorni per un rimborso completo o uno scambio.",
          faq3Question:
            "Cosa rende il vostro prodotto diverso dagli altri sul mercato?",
          faq3Answer:
            "Il nostro prodotto si distingue per la sua adattabilità, durata e caratteristiche innovative. Prioritizziamo la soddisfazione dell'utente e ci sforziamo continuamente di superare le aspettative in ogni aspetto.",
          faq4Question: "C'è una garanzia sul prodotto e cosa copre?",
          faq4Answer:
            "Sì, il nostro prodotto viene fornito con una garanzia di 1 anno. Copre i difetti di materiali e di lavorazione. Se riscontri problemi coperti dalla garanzia, contatta il nostro servizio clienti per assistenza.",

          available: "Disponibile",
          now: "Ora",
          games: "Giochi",
          about: "Informazioni su",
          game: "Gioco",
          website: "Sito web",
          description: "Descrizione",
          rating: "Valutazione",
          developer: "Sviluppatore",
          publisher: "Editore",
          released: "Data di rilascio",
          genre: "Genere",
          gallery: "Galleria",
          maxPrice: "Prezzo massimo",

          availableEditions: "Edizioni Disponibili",
          availablePlatforms: "Piattaforme Disponibili",
          addToCart: "Aggiungi al carrello",
          emptyCartHeading: "Il carrello è vuoto...",
          emptyCartSubheading:
            "Il tuo carrello desidera compagnia. Inizia ora la tua avventura di shopping!",
          exploreCatalog: "Esplora il nostro catalogo",
          yourCart: "Il tuo Carrello",
          quantity: "Quantità",
          maxProductAllowed: "Massimo di {{max}} prodotti consentiti",
          total: "Totale",
          goToCart: "Vai al Carrello",
          payNow: "Paga ora",
          goToPayment: "Vai al Pagamento",
          discount: "Sconto",
          originalPrice: "Prezzo originale",
          checkMoreProducts: "Vedi più prodotti",
          cartSummary: "Riepilogo del carrello",
          cartItems: "Articoli nel carrello",
          payment: "Pagamento",
          gameActivation: "Attivazione del gioco",
          login: "Accesso",
          remove: "Rimuovere",

          Notification: "Notifica",
          notificationsEnabled: "Le notifiche del browser sono abilitate",
          notificationsEnableFail: "Impossibile abilitare le notifiche",
          notificationsDisabled: "Le notifiche del browser sono disabilitate",
          notificationsDisableFail: "Impossibile disabilitare le notifiche",
          notificationsDenied: "Permesso di notifica negato",
          notificationsNoPermission: "Permesso di notifica non concesso",
          notificationsPermissionFaild: "Richiesta di permesso di notifica non riuscita",
          notificationsNotSupported: "La messaggistica push non è supportata",
          notificationsFailToSubscribe: "Impossibile iscrivere l'utente",
          notificationsFailToUnsubscribe: "Impossibile annullare l'iscrizione dell'utente",
          serviceWorkerNotReady: "Si prega di ricaricare la pagina e riprovare",
          notificationsSubscriptionErro: "Errore durante l'ottenimento dell'abbonamento",
          browserNotifications: "Notifiche del browser",

          noProductsFound: "Nessun prodotto trovato.",
          tryDifferentQuery:
            "Si prega di provare una ricerca diversa o reimpostare i filtri.",
          resetFilters: "Reimposta filtri",
          achievements: "Successi",
          noFavorites: "Ops! Nessun bottino trovato!",
          noFavoritesText: "È ora di cacciare qualche bottino epico!",
          favorites: "Preferiti",
          purchases: "Acquisti",
          points: "Punti",
          dashboard: "Pannello di controllo",
          settings: "Impostazioni",
          updateSettings: "Aggiorna impostazioni",
          security: "Sicurezza",
          invoiceNotFound: "Fattura non trovata",
          action: "Azione",
          date: "Data",
          rowsPerPage: "Righe per pagina",
          aboutOurApi: "Informazioni sulla nostra API",
          aboutOurApiDescription:
            "Benvenuto nella nostra pagina del prodotto API! La nostra API offre un accesso senza soluzione di continuità a oltre 10.000 prodotti, garantendo che tu possa integrare e offrire una vasta gamma di articoli ai tuoi clienti in ogni momento. Che tu stia costruendo una piattaforma di e-commerce, un'app mobile o qualsiasi altro servizio, la nostra API ti copre.",
          whyChooseOurApi: "Perché scegliere la nostra API?",
          whyChooseOurApiDescription:
            "La nostra API è progettata per essere robusta, affidabile e facile da usare. Ecco alcune delle caratteristiche chiave:",
          featureApi1: "Accesso a oltre 10.000 prodotti",
          featureApi2: "Disponibilità e prezzi dei prodotti in tempo reale",
          featureApi3: "Alta disponibilità con il 99,9% di uptime",
          featureApi4: "Documentazione completa e supporto",
          featureApi5: "Facile integrazione con endpoint RESTful",
          featureApi6: "Architettura sicura e scalabile",
          WithOurApi:
            "Con la nostra API, puoi garantire ai tuoi clienti l'accesso a un vasto inventario di prodotti in ogni momento. La nostra alta disponibilità e gli aggiornamenti in tempo reale significano che non perderai mai una vendita.",

          aboutOurPosTerminals: "Informazioni sui nostri terminali POS",
          welcomeMessage:
            "Benvenuti nella nostra pagina prodotti! Siamo orgogliosi di offrire terminali POS all'avanguardia progettati specificamente per negozi che vendono giochi e carte di abbonamento. I nostri terminali sono facili da usare, sicuri ed efficienti, aiutandoti a gestire le tue vendite e il tuo inventario con facilità",
          whyChooseOurPosTerminals: "Perché scegliere i nostri terminali POS?",
          posTerminalFeatures:
            "I nostri terminali POS sono dotati di una serie di funzionalità per semplificare le operazioni del tuo negozio:",
          feature1:
            "Integrazione senza soluzione di continuità con i tuoi sistemi esistenti",
          feature2: "Elaborazione dei pagamenti rapida e sicura",
          feature3: "Gestione dell'inventario in tempo reale",
          feature4: "Interfaccia user-friendly per una formazione rapida",
          feature5:
            "Impostazioni personalizzabili per adattarsi alle esigenze del tuo negozio",
          posTerminalDescription:
            "Che tu stia vendendo giochi fisici o carte di abbonamento digitali, i nostri terminali POS sono equipaggiati per gestire tutte le tue transazioni in modo efficiente. Migliora l'esperienza dei tuoi clienti e aumenta le tue vendite con le nostre soluzioni POS affidabili e versatili",
          invoiceNo: "Numero fattura",
          howTo: "Come",
          activate: "Attivare",
          step: "Passo",
          giftToFriend: "Regala a un amico",
          enterEmailTwice: "Inserisci due volte l'indirizzo e-mail del tuo amico per conferma.",
          friendEmail: "E-mail dell'amico",
          confirmFriendsEmail: "Conferma l'e-mail dell'amico",
          emailsDoNotMatch: "Gli indirizzi e-mail non corrispondono. Riprova.",
          sendGift: "Invia regalo",
          giftSent: "Regalo inviato",
          giftSentMessage: "Abbiamo inviato con successo la chiave come regalo al tuo amico all'indirizzo email seguente:",
          close: "Chiudi",
          
          buyers: "Acquirenti",
          reviews: "Recensioni",
          user: "Utente",
          comment: "Commento",

          requirements: "Requisiti",
          minimumRequirements: "Requisiti minimi",
          recommendedRequirements: "Requisiti consigliati",
          OS: "Sistema operativo",
          Processor: "Processore",
          Memory: "Memoria",
          Graphics: "Grafica",
          DirectX: "DirectX",
          Storage: "Memorizzazione",
          AdditionalNotes: "Note aggiuntive",

          careers: "Carriere",
          contactUs: "Contattaci",
          updatePersonalData: "Aggiorna i dati di fatturazione",
          updateApplicationSettings: "Aggiorna impostazioni dell'applicazione",
          birthday: "Data di nascita",
          yourName: "Il tuo nome",
          yourLastName: "Il tuo cognome",
          enterEmail: "Inserisci il tuo indirizzo email",
          yourEmail: "Il tuo indirizzo email",
          changePassword: "Cambia password",
          currentPassword: "Password attuale",
          newPassword: "Nuova password",
          confirmPassword: "Conferma password",
          showLess: "Mostra meno",
          showMore: "Mostra di più",
          PageNotFound: "Pagina non trovata",
          DontGiveUp:
            "Non arrenderti, forse troverai qui quello che stai cercando.",
          Jobs: "Lavori",
          ElevateYourCareer:
            "Elevate la tua carriera con Bigswiss GmbH, dove innovazione e passione si incontrano. Immergiti in un mondo in cui il tuo lavoro modella direttamente il futuro del gioco e della tecnologia.",
          exploreOpportunities:
            "Esplora opportunità, affronta sfide e fai parte di un team dinamico dedicato all'eccellenza. Il tuo viaggio per fare la differenza inizia qui.",
          enterYourEmailAddress: "Inserisci il tuo indirizzo email",
          yourEmailAddress: "Il tuo indirizzo email",
          contactUsAgree: 'Cliccando su "Contattaci" accetti i nostri',
          termsAndConditions: "Termini e condizioni",
          bigswissSupport: "Contatta Bigswiss ",
          support: "Supporto",
          contactDescription:
            "Dalle richieste sul nostro negozio online innovativo e il negozio all'ingrosso all'assistenza per le nostre applicazioni per chioschi per gli acquisti di videogiochi e il negozio API all'ingrosso, siamo qui per migliorare la tua esperienza.",
          connectTeam:
            "Connettiti con il nostro team dedicato per funzionalità e servizi di alto livello progettati appositamente per te.",

          pricingDescription: "Scegli il piano che soddisfa le tue esigenze",
          basic: "Base",
          pro: "Pro",
          enterprise: "Impresa",
          getStarted: "Inizia",
          startNow: "Inizia ora",
          mostPopular: "Più popolare",
          perMonth: "al mese",
          basicDesc1: "Accesso API per fino a 100 richieste al mese",
          basicDesc2: "Analisi e report di base",
          basicDesc3: "Supporto standard",
          proDesc1: "Accesso API per fino a 10.000 richieste al mese",
          proDesc2: "Analisi e report avanzati",
          proDesc3: "Supporto prioritario",
          proDesc4:
            "Accesso a offerte esclusive di giochi e carte di abbonamento",
          enterpriseDesc1: "Richieste API illimitate",
          enterpriseDesc2: "Analisi e report personalizzati",
          enterpriseDesc3: "Supporto dedicato 24/7",
          enterpriseDesc4: "Soluzioni terminali POS personalizzate",
          enterpriseDesc5: "Migliori prezzi per acquisti all'ingrosso",

          chooseActivation: "Scegli dove desideri attivare il tuo prodotto.",
          thankYouForPurchase: "Grazie per il tuo acquisto!",
          findYourKeysBellow: "Di seguito troverai le tue chiavi e la tua fattura.",
          productKey: "Chiave del prodotto",
          productKeys: "Chiavi del prodotto",
          productActivation: "Attivazione del prodotto",
          productActivationDescription: "Segui le istruzioni di seguito per attivare il tuo prodotto.",
          sendAsGift: "Regalo",
          hide: "Nascondi",
          viewKeys: "Visualizza le chiavi",
          downloadInvoice: "Scarica la fattura",
          download: "Scarica",
          back: "Indietro",
          next: "Avanti",
          activationMethod: "Metodo di attivazione",
          keylessActivation: "Attivazione senza chiave",
          activationLink: "Link di attivazione",
          enterTheKey: "Inserisci la chiave",
          confirmActivation: "Conferma attivazione",
          battlenetStep1: "Fai clic sul pulsante qui sotto per accedere alla pagina di attivazione della chiave Battle.net.",
          goToBattlenetActivation: "Vai alla pagina di attivazione di Battle.net",
          battlenetStep2: "Accedi con le tue credenziali Battle.net. Se non hai un account, creane uno gratuitamente.",
          battlenetStep3: "Inserisci la chiave ricevuta nel campo apposito, quindi fai clic su 'Riscatta'.",
          battlenetStep4: "Congratulazioni! Hai attivato il tuo prodotto con successo ed è pronto per essere scaricato e giocato sul tuo account Battle.net.",
          goToActivation: "Vai all'attivazione",
          battlenetStep2app: "Apri l'app Blizzard Battle.net. E accedi con le tue credenziali Battle.net. Se non hai un account, creane uno gratuitamente.",
          battlenetStep3app: "Fai clic sul tuo profilo per aprire un menu, quindi fai clic su 'Riscatta codice'.",

          goToSteamActivation: "Vai alla pagina di attivazione di Steam",
          steamStep1: "Fai clic sul pulsante qui sotto per accedere alla pagina di attivazione della chiave Steam.",
          steamStep2: "Accedi con le tue credenziali Steam. Se non hai un account, creane uno gratuitamente.",
          steamStep3: "Leggi e accetta l'accordo di abbonamento Steam, quindi fai clic su 'Continua'.",
          steamStep4: "Congratulazioni! Hai attivato il tuo prodotto con successo ed è pronto per essere scaricato e giocato sul tuo account Steam.",
          steamStep2app: "Apri l'app Steam e accedi con le tue credenziali Steam. Se non hai un account, creane uno gratuitamente.",
          steamStep3app: "Fai clic su 'Giochi' nel menu in alto, quindi fai clic su 'Attiva un prodotto su Steam'.",
          steamStep4app: "Inserisci la chiave ricevuta nel campo apposito, quindi fai clic su 'Convalida'.",

          goToMicrosoftActivation: "Vai alla pagina di attivazione del Microsoft Store",
          microsoftStep1: "Fai clic sul pulsante in basso per accedere alla pagina di attivazione del codice Microsoft Store.",
          microsoftStep2: "Accedi con le tue credenziali Microsoft. Se non hai un account, creane uno gratuitamente.",
          microsoftStep3: "Inserisci il codice ricevuto nel campo fornito e fai clic su 'Avanti'.",
          microsoftStep4: "Congratulazioni! Hai attivato con successo il tuo prodotto ed è pronto per essere scaricato e giocato sul tuo account Microsoft.",
          microsoftStep2app: "Sul tuo sistema Windows, apri l'app Microsoft Store e accedi con le tue credenziali Microsoft. Se non hai un account, creane uno gratuitamente.",
          microsoftStep3app: "Fai clic sull'icona del profilo in alto a destra, quindi fai clic su 'Riscatta codice o carte regalo'.",

          goToGOGActivation: "Vai alla pagina di attivazione GOG",
          activateKey: "Attiva la chiave",
          linkToAccount: "Collega all'account",
          gogStep1: "Clicca sul pulsante qui sotto per andare alla pagina di attivazione della chiave GOG.",
          gogStep2: "Clicca su 'Continua' per confermare l'attivazione della chiave.",
          gogStep3: "Clicca su 'Accedi' per collegare la chiave al tuo account GOG.",
          gogStep4: "Accedi con le tue credenziali GOG. Se non hai un account, creane uno gratuitamente.",
          gogStep5: "Congratulazioni! Hai attivato con successo il tuo prodotto ed è pronto per essere scaricato e giocato sul tuo account GOG.",

          gogStep2app: "Apri l'app GOG Galaxy e accedi con le tue credenziali GOG. Se non hai un account, creane uno gratuitamente.",
          gogStep3app: "Fai clic su 'Aggiungi giochi e amici' nel menu in alto, quindi fai clic su 'Riscatta codice GOG'.",
          gogStep4app: "Inserisci la chiave ricevuta nel campo fornito, quindi fai clic su 'Continua'.",

          goToEpicActivation: "Vai alla pagina di attivazione di Epic Games",
          epicStep1: "Fai clic sul pulsante qui sotto per andare alla pagina di attivazione della chiave Epic Games.",
          epicStep2: "Fai clic su 'Riscatta' per confermare l'attivazione della chiave.",
          epicStep3: "Fai clic su 'Accedi' per collegare la chiave al tuo account Epic Games.",
          epicStep4: "Accedi con le tue credenziali Epic Games. Se non hai un account, creane uno gratuitamente.",
          epicStep5: "Congratulazioni! Hai attivato con successo il tuo prodotto ed è pronto per essere scaricato e giocato sul tuo account Epic Games.",
          epicStep2app: "Apri l'app Epic Games e accedi con le tue credenziali Epic Games. Se non hai un account, creane uno gratuitamente.",
          epicStep3app: "Fai clic sul tuo profilo nell'angolo in alto a destra, quindi fai clic su 'Riscatta codice'.",
          epicStep4app: "Inserisci la chiave ricevuta nel campo fornito, quindi fai clic su 'Riscatta'.",

          goToRockstarActivation: "Vai alla pagina di attivazione Rockstar",
          rockstarStep1: "Clicca sul pulsante qui sotto per andare alla pagina di attivazione della chiave Rockstar.",
          rockstarStep2: "Accedi con le tue credenziali Rockstar. Se non hai un account, creane uno gratuitamente.",
          rockstarStep3: "Attendere il caricamento della pagina e il completamento della validazione Rockstar. Questo potrebbe richiedere alcuni minuti. Una volta che la pagina è stata caricata, vedrai un messaggio di conferma. Clicca su 'Conferma' per attivare la chiave sul tuo account Rockstar.",
          rockstarStep4: "Congratulazioni! Hai attivato con successo il tuo prodotto, ed è pronto per essere scaricato e giocato sul tuo account Rockstar.",
          rockstarStep2app: "Apri l'app Rockstar Games Launcher e accedi con le tue credenziali Rockstar. Se non hai un account, creane uno gratuitamente.",
          rockstarStep3app: "Clicca sul menu nell'angolo in alto a destra, poi su 'RISCUOTI CODICE'.",
          rockstarStep4app: "Inserisci la chiave ricevuta nel campo fornito, poi clicca su 'VERIFICA'.",
          rockstarStep5app: "Leggi e accetta i termini e le condizioni, poi clicca su 'RISCUOTI'.",

          goToEAPlayActivation: "Vai alla pagina di attivazione di EA Play",
          eaStep1: "Clicca sul pulsante qui sotto per accedere alla pagina di attivazione della chiave EA Play.",
          eaStep2: "Accedi con le tue credenziali EA Play. Se non hai un account, creane uno gratuitamente.",
          eaStep3: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Avanti'.",
          eaStep4: "Clicca su 'Avanti' per collegare la chiave al tuo account EA Play.",
          eaStep5: "Congratulazioni! Hai attivato con successo il tuo prodotto, ed è pronto per essere scaricato e giocato sul tuo account EA Play.",

          eaStep2app: "Apri l'app EA Play e accedi con le tue credenziali EA Play. Se non hai un account, creane uno gratuitamente.",
          eaStep3app: "Clicca sul menu in alto a destra, quindi clicca su 'Riscatta Codice'.",
          eaStep4app: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Avanti'.",

          ubisoftStep2app: "Apri l'app Ubisoft e accedi con le tue credenziali Ubisoft. Se non hai un account, creane uno gratuitamente.",
          ubisoftStep3app: "Clicca sul menu del profilo in alto a sinistra, quindi clicca su 'Attivazione chiave'.",
          ubisoftStep4app: "Inserisci il codice ricevuto nel campo previsto, quindi clicca su 'Attiva'.",

          goToSwitchActivation: "Vai alla pagina di attivazione Nintendo Switch",
          openeshop: "Apri eShop",
          selectUser: "Seleziona Utente",
          redeem: "Riscatta Chiave",
          switchStep1: "Clicca sul pulsante qui sotto per accedere alla pagina di attivazione della chiave Nintendo Switch.",
          switchStep2: "Accedi con le tue credenziali Nintendo Switch. Se non hai un account, creane uno gratuitamente.",
          switchStep3: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Continua'.",
          switchStep4: "Congratulazioni! Hai attivato con successo il tuo prodotto, ed è pronto per essere scaricato e giocato sul tuo account Nintendo Switch.",
          switchStep1app: "Apri l'app Nintendo eShop.",
          switchStep2app: "Seleziona l'account su cui desideri attivare il prodotto.",
          switchStep3app: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Conferma'.",
          switchstep4app: "Seleziona 'Riscatta' e clicca su 'Conferma' per attivare il prodotto sul tuo account Nintendo Switch.",

          goToPlayStationActivation: "Vai alla pagina di attivazione di PlayStation",
          openLogin: "Apri il login",
          psStep1: "Clicca qui sotto per andare alla pagina di attivazione PlayStation.",
          psStep2: "In alto a destra, clicca su 'Accedi'",
          psStep3: "Accedi con le tue credenziali PlayStation. Se non hai un account, creane uno gratuitamente.",
          psStep4: "In alto a destra, clicca sul tuo profilo, quindi su 'Riscatta codice'.",
          psStep5: "Inserisci la chiave che hai ricevuto nel campo previsto, quindi clicca su 'Continua'.",
          psStep6: "Congratulazioni! Hai attivato con successo il tuo prodotto. È pronto per essere scaricato e giocato sul tuo account PlayStation.",
          openStore: "Apri il Negozio",
          psStep2console: "Seleziona il PlayStation Store a sinistra e premi 'X'.",
          psStep3console: "Premi '△' per accedere al menu in alto a destra, seleziona '...' e seleziona 'Riscatta codice'.",
          psStep4console: "Inserisci il codice ricevuto nel campo fornito, quindi fai clic su 'Riscatta'.",
          psStep2app: "Apri l'app PlayStation e apri il PlayStation Store.",
          openMenu: "Apri il menu",
          psStep3app: "Seleziona il menu in alto a destra per visualizzare ulteriori opzioni.",
          psStep4app: "Seleziona 'Riscatta codice'.",

          goToXboxActivation: "Vai alla pagina di attivazione Xbox",
          xboxStep1: "Clicca sul pulsante qui sotto per andare alla pagina di attivazione della chiave Xbox.",
          xboxStep2: "Clicca su 'Accedi per riscattare' per accedere con le tue credenziali Xbox. Se non hai un account, creane uno gratuitamente.",
          xboxStep3: "Clicca su 'Riscatta ora' per aprire la finestra di attivazione della chiave.",
          xboxStep4: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Avanti'. Continua a seguire le istruzioni.",
          xboxStep5: "Congratulazioni! Hai attivato con successo il tuo prodotto ed è pronto per essere scaricato e giocato sul tuo account Xbox.",
          xboxStep2console: "Premi il pulsante Xbox sul tuo controller per aprire la guida. Mentre sei connesso al tuo account, seleziona 'Store'.",
          xboxStep3console: "Una volta aperto, premi il pulsante Visualizza sul tuo controller per aprire il menu, quindi seleziona 'Riscatta'.",
          xboxStep4console: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Controlla codice'.",

          warningModalTitle: "Avviso Importante",
          warningModalMessage1: "Una volta rivelata la chiave, perdi il diritto a un rimborso immediato.",
          warningModalMessage2: "Sarai idoneo per un rimborso solo se la chiave è invalida.",
          doYouWantToProceed: "Vuoi procedere?",
          revealKey: "Rivela la chiave"
        },
      },
    },
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
