import React from 'react';
import Box from '@mui/material/Box';
import { Carousel } from 'react-carousel3';

const style = {
  width: 297,
  height: 296,
};

const images = [
  { alt: 'CS GO', src: '/img/products/cs_go.webp' },
  { alt: 'Resident Evil', src: '/img/products/resident_evil.webp' },
  { alt: 'Rainbow Siege', src: '/img/products/rainbows_siege.webp' },
  { alt: 'GTA', src: '/img/products/gta.webp' },
  { alt: 'Doom', src: '/img/products/doom.webp' },
  { alt: 'Titanfall 2', src: '/img/products/titanfall_2.webp' },
  { alt: 'X Kom 2', src: '/img/products/x_kom_2.webp' },
  { alt: 'Battlefield 1', src: '/img/products/battlefield_1.webp' },
  { alt: 'Watch Dogs 2', src: '/img/products/watch_dogs_2.webp' },
];

// Name the component
const Hero = () => (
  <Box
    id="hero"
    sx={{
      width: '100%',
      display: { xs: 'none', md: 'flex' },
      justifyContent: 'center',
      padding: '100px 0',
    }}
  >
    <Carousel height={460} width={1280} yOrigin={42} yRadius={68} autoPlay={true}>
      {images.map((image, index) => (
        <div key={index} style={style}>
          <img alt={image.alt} height="350" width="auto" src={image.src} />
        </div>
      ))}
    </Carousel>
  </Box>
);

// Export the named component
export default Hero;
