import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { RootState } from 'redux/store'; 
import { Typography, Box, Stack, Button } from '@mui/material';
import InvoicePreview from './InvoicePreview';
import { useTranslation } from 'react-i18next';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import KeyIcon from '@mui/icons-material/Key';
import ProductKeys from 'pages/CartPage/Components/ProductKeys';
import ManualVerificationModal from 'pages/CartPage/Components/ManualVerificationModal';
import InvoicePdfGenerator from 'components/Invoice/InvoicePdfGenerator'

const PurchaseDetailPage: React.FC = () => {
    const { invoiceId } = useParams<{ invoiceId: string }>(); 
    const invoices = useSelector((state: RootState) => state.auth.user?.invoices || []);
    const { t } = useTranslation();
    const productKeysRef = useRef<HTMLDivElement>(null);
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    const [modalTriggered, setModalTriggered] = useState(false); 

    const invoice = invoices.find(inv => inv.invoiceNumber === invoiceId);
    const handleViewKeysClick = () => {
        if (productKeysRef.current) {
            const offsetTop = productKeysRef.current.getBoundingClientRect().top + window.pageYOffset - 100;
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        if (invoice?.items && !modalTriggered) {
            const hasUndefinedKey = invoice.items.some(item => item.key === undefined);
            if (hasUndefinedKey && !verificationModalOpen) {
                setVerificationModalOpen(true);
                setModalTriggered(true); 
            }
        }
    }, [invoice, verificationModalOpen, modalTriggered]);

    const handleVerificationModalClose = () => {
        setVerificationModalOpen(false);
    };

    if (!invoice) {
        return (
            <Box>
                <Typography variant="h6" color="error">Invoice not found</Typography>
            </Box>
        );
    }

    return (
        <Stack alignItems={"center"}>
            <Typography variant="h2"> Invoice {invoice.invoiceNumber}</Typography>

            <Stack direction="row" alignItems="center" spacing={4} sx={{ my: 5 }}>
                <Button sx={{ mt: 5 }} startIcon={<ArrowBackRoundedIcon />} variant="contained" color="secondary" component={RouterLink} to="/dashboard/purchases">
                    {t('back')}
                </Button>
                {invoice && (
                    <InvoicePdfGenerator invoice={invoice} title={t('downloadInvoice')} />
                )}
                <Button sx={{ mt: 5 }} startIcon={<KeyIcon />} variant="contained" color="primary" onClick={handleViewKeysClick}>
                    {t('viewKeys')}
                </Button>
            </Stack>
            <InvoicePreview invoice={invoice} />

            <Typography ref={productKeysRef} variant='h2' sx={{ my: 5 }}>
                {t('productKey')}
            </Typography>

            {invoice?.items.map((item: any, index: number) => (
                item.keys && item.keys.length > 0 ? (
                    item.keys.map((keyObj: { key: string; isRevealed: boolean; isGifted: string | null }, keyIndex: number) => (
                        <ProductKeys
                            invoiceId={invoice._id}
                            key={`${index}-${keyIndex}`}
                            productId={item.productId}
                            platformGroupId={item.platformGroupId}
                            keyCode={keyObj.key}
                            keyIsRevealed={keyObj.isRevealed} 
                            isGifted={keyObj.isGifted}
                            productKeysRef={productKeysRef}
                        />
                    ))
                ) : (
                    Array.from({ length: item.quantity }).map((_, i) => (
                        <ProductKeys
                            invoiceId={invoice._id}
                            key={`${index}-${i}`}
                            productId={item.productId}
                            platformGroupId={item.platformGroupId}
                            keyCode={undefined}
                            productKeysRef={productKeysRef}
                        />
                    ))
                )
            ))}

            <ManualVerificationModal
                open={verificationModalOpen}
                onClose={handleVerificationModalClose}
            />
        </Stack>
    );
};

export default PurchaseDetailPage;
