import {  Card, CardHeader, Skeleton } from "@mui/material";

export default function LoadingCard() {

  return (
      <Card>
        <Skeleton variant="rectangular" width="100%" height={180} animation="wave" />
        <CardHeader
          avatar={<Skeleton variant="circular" width={40} height={40} animation="wave" />}
          title={<Skeleton variant="text" width="75%" height="30px" animation="wave" />}
          subheader={<Skeleton variant="text" width="40%"  height="25px" animation="wave" />}
          action={<Skeleton variant="text" width="60px" height="40px" animation="wave" />}
          sx={{ height: 103 }}
        />
      </Card>
  );
}
