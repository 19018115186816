import React from 'react';
import { Web as BrowserIcon, Key as KeylessIcon } from '@mui/icons-material';
import SwitchIcon from 'components/icons/Platforms/SwitchIcon'; 
import ActivationGuide from './ActivationGuide';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next'; 

interface SwitchInstructionsProps {
    keyCode: string; 
}

const SwitchInstructions: React.FC<SwitchInstructionsProps> = ({ keyCode }) => {
    const { t } = useTranslation();

    const steps = {
        default: [
            {
                label: t('activationMethod'),
                description: t('chooseActivation'),
                options: [
                    { title: t('Browser'), icon: <BrowserIcon sx={{ width: '64px', height: '64px' }} />, method: 'browser' },
                    { title: t('Nintendo eShop'), icon: <SwitchIcon sx={{ width: '64px', height: '64px' }} />, method: 'eshop' },
                    { title: t('keylessActivation'), icon: <KeylessIcon sx={{ width: '64px', height: '64px' }} />, method: 'keyless' }
                ]
            }
        ],
        browser: [
            { label: t('activationMethod'), description: '', imageUrl: '' },

            {
                label: t('activationLink'),
                description: t('switchStep1'),
                imageUrl: '/img/activation/activation-link.webp',
                actionButton: (
                    <Button
                        variant="contained"
                        color="secondary"
                        href={`https://ec.nintendo.com/redeem`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('goToSwitchActivation')}
                    </Button>
                ),
            },
            {
                label: t('login'),
                description: t('switchStep1'),
                imageUrl: '/img/activation/switch-login.webp',
            },
            {
                label: t('activateKey'),
                description: t('switchStep2'),
                imageUrl: '/img/activation/switch-redeem-code.webp',
            },
            {
                label: t('confirmActivation'),
                description: t('switchStep4'),
                imageUrl: '/img/activation/download.webp',
            }
        ],
        eshop: [
            { label: t('activationMethod'), description: '', imageUrl: '' },

            {
                label: t('openeshop'),
                description: t('switchStep1app'),
                imageUrl: '/img/activation/switch-app-shop.webp'
            },
            {
                label: t('selectUser'),
                description: t('switchStep2app'),
                imageUrl: '/img/activation/switch-app-select-user.webp'
            },
            {
                label: t('enterTheKey'),
                description: t('switchStep3app'),
                imageUrl: '/img/activation/switch-app-redeem-code.webp'
            },
            {
                label: t('redeem'),
                description: t('switchStep4app'),
                imageUrl: '/img/activation/switch-app-clickon-redeem-code.webp',
            },
            {
                label: t('confirmActivation'),
                description: t('switchStep4'),
                imageUrl: '/img/activation/switch-app-download.webp',
            }
        ]
    };

    return <ActivationGuide steps={steps} />;
};

export default SwitchInstructions;
