import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Ps4Icon = (props: SvgIconProps) => {

  return (
      <SvgIcon
      {...props}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
    >
      <g clipPath="url(#clip0_275_3)">
        <path
          d="M25.3128 45.0284V39.9124C25.3128 38.8687 25.7802 38.1213 26.7771 38.1213H32.9261C32.9636 38.1213 33 38.0824 33 38.0438V37.0259C32.9996 37.017 32.9977 37.0082 32.9943 37H25.4757C23.8461 37.2159 23.05 38.3995 23.05 39.9124V45.0282C23.05 46.0719 22.5695 46.8193 21.586 46.8193H15.0922C15.0554 46.8193 15.0184 46.8452 15.0184 46.8966V47.9147C15.0184 47.957 15.0433 47.9894 15.0724 48H22.4453C24.3667 47.9474 25.3128 46.6689 25.3128 45.0284ZM0.0501148 48H2.2091C2.22539 47.9933 2.23933 47.9816 2.249 47.9664C2.25867 47.9512 2.26361 47.9332 2.26314 47.915V44.6546C2.26314 43.6109 2.8288 42.8891 3.65289 42.8891H12.5458C14.5506 42.8891 15.5225 41.6005 15.5225 39.9124C15.5225 38.3995 14.7272 37.2159 13.0967 37H0.0101543C0.0063876 37.0085 1.14812e-06 37.0152 1.14812e-06 37.0259V38.0438C1.14812e-06 38.0954 0.0245662 38.1213 0.0736972 38.1213H11.7954C12.7918 38.1213 13.2592 38.8687 13.2592 39.9124C13.2592 40.9562 12.7918 41.7036 11.7954 41.7036H2.5214C1.08236 41.7036 1.14812e-06 42.9796 1.14812e-06 44.6546V47.9152C-0.000162622 47.957 0.017197 47.9894 0.0501148 48Z"
          fill="#ffff" />
        <path
          d="M33.3587 46.7351H45.4238C45.4608 46.7351 45.4859 46.7602 45.4859 46.7857V47.8091C45.4859 47.8596 45.5242 47.8849 45.5612 47.8849H47.7359C47.7744 47.8849 47.811 47.8596 47.811 47.8091V46.8109C47.811 46.7729 47.8495 46.7351 47.8865 46.7351H50.0478C50.0982 46.7351 50.1233 46.6971 50.1233 46.6595V45.6486C50.1233 45.6104 50.0982 45.5728 50.0478 45.5728H47.8865C47.8495 45.5728 47.811 45.5346 47.811 45.4972V38.207C47.811 37.6121 47.5901 37.2333 47.2183 37.1076H46.5891C46.393 37.158 46.1798 37.2481 45.9513 37.3855L32.9566 45.4085C32.4537 45.7242 32.2775 46.0778 32.4034 46.3432C32.5039 46.5708 32.7922 46.7351 33.3587 46.7351ZM35.2436 45.3958L45.3099 39.1672C45.3734 39.1166 45.4993 39.1293 45.4993 39.2682V45.4972C45.4993 45.5351 45.4608 45.5728 45.4238 45.5728H35.3058C35.2186 45.5728 35.1798 45.5479 35.1683 45.5223C35.1545 45.4716 35.1796 45.4337 35.2436 45.3958Z"
          fill="#ffff" />
        <path
          d="M19.3113 0C21.2118 0.354287 23.0799 0.860412 24.9393 1.38795C26.0025 1.69682 27.0634 2.01639 28.1172 2.35672C29.7725 2.88394 31.4206 3.48091 32.9198 4.37117C33.5914 4.77899 34.2364 5.23969 34.7876 5.80162C35.3848 6.38794 35.874 7.0749 36.2327 7.83098C36.8864 9.19622 37.1103 10.7214 37.1765 12.221C37.2076 13.1995 37.2057 14.1864 37.0227 15.1523C36.8709 15.9916 36.6009 16.8192 36.1519 17.5483C35.7554 18.1919 35.2042 18.749 34.5352 19.1078C33.8935 19.4537 33.159 19.6185 32.4312 19.6039C31.3275 19.6153 30.2565 19.2636 29.266 18.8022C29.2478 15.5082 29.2631 12.2135 29.2582 8.92013C29.2514 8.32413 29.292 7.72197 29.1716 7.13409C29.0869 6.67858 28.9013 6.21949 28.5435 5.9103C28.3067 5.6965 27.9991 5.57581 27.6902 5.5106C27.3778 5.45122 27.0446 5.58392 26.8512 5.83471C26.5297 6.23637 26.4453 6.76974 26.4307 7.2697C26.4281 16.0883 26.434 24.9072 26.4281 33.7251C24.0556 32.9732 21.6833 32.2204 19.3113 31.4667C19.309 20.9779 19.31 10.4891 19.3113 0ZM8.64825 22.3162C11.7411 21.2212 14.8308 20.1152 17.9249 19.0241C17.9298 20.2382 17.9249 21.4529 17.9269 22.6682C17.9249 22.833 17.9353 22.9985 17.9149 23.1623C15.4692 24.0292 13.0282 24.9114 10.5851 25.7838C10.2464 25.912 9.89896 26.0567 9.63778 26.3152C9.51612 26.4366 9.41684 26.616 9.48335 26.7896C9.56706 26.9943 9.77599 27.1059 9.96871 27.1899C10.5047 27.408 11.0906 27.4391 11.6616 27.4651C12.2982 27.455 12.9377 27.4073 13.5609 27.2697C13.9823 27.1867 14.3879 27.0449 14.7896 26.894C15.8336 26.5161 16.8776 26.1358 17.9233 25.7618C17.9308 26.7889 17.9246 27.8167 17.9266 28.8452C17.9233 29.0506 17.936 29.2573 17.9155 29.4617C16.6314 29.6923 15.332 29.8487 14.0268 29.8695C11.3985 29.9288 8.76407 29.522 6.26751 28.7005C5.44895 28.4634 4.63526 28.1474 3.95134 27.6276C3.57499 27.3363 3.23823 26.97 3.06692 26.5193C2.92352 26.1375 2.9339 25.6969 3.11267 25.3286C3.28494 24.961 3.58602 24.671 3.9176 24.4452C4.68457 23.9154 5.53104 23.5111 6.3882 23.151C7.1276 22.8363 7.89392 22.591 8.64825 22.3162Z"
          fill="#ffff" />
        <path
          d="M33.5337 20.9328C34.2351 20.8517 34.9411 20.8326 35.6464 20.8073C37.9662 20.816 40.2982 21.1044 42.5174 21.8007C42.9528 21.9331 43.3746 22.1047 43.8083 22.2426C44.5818 22.5252 45.3559 22.8678 45.9749 23.4238C46.3468 23.7629 46.6676 24.22 46.6718 24.7417C46.6939 25.1667 46.5018 25.5742 46.2261 25.8873C45.7018 26.4752 45.0088 26.8794 44.3132 27.2318C43.5725 27.6094 42.7763 27.8531 41.9996 28.1405C37.2589 29.8429 32.5175 31.5446 27.7778 33.2488C27.7768 31.9206 27.7791 30.593 27.7768 29.2663C27.784 29.2131 27.7551 29.1207 27.8297 29.1067C31.4368 27.8236 35.043 26.5365 38.6498 25.2495C39.1374 25.0671 39.6627 24.9351 40.0763 24.6045C40.2457 24.4715 40.4115 24.2599 40.3284 24.0315C40.2418 23.832 40.0348 23.7217 39.8443 23.639C39.3045 23.4251 38.7166 23.3839 38.1423 23.3677C37.2433 23.3739 36.3365 23.4845 35.4813 23.7729C32.9121 24.6733 30.3477 25.5846 27.7778 26.482C27.7781 25.0561 27.7768 23.6312 27.7785 22.2056C29.6372 21.5531 31.5786 21.1576 33.5337 20.9328Z"
          fill="#ffff" />
      </g>
      <defs>
        <clipPath id="clip0_275_3">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default Ps4Icon;