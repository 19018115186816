import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState, AppDispatch } from 'redux/store';
import { updatePassword } from 'redux/auth/updateUserThunks';

import { Typography, Grid2 as Grid, Card, TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useSnackbarAlert from 'utils/useSnackbarAlert';

const SecurityPage: React.FC = () => {
    const { t } = useTranslation();

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { isLoading, error } = useSelector((state: RootState) => state.auth);
    const showAlert = useSnackbarAlert();

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmNewPassword(event.target.value);
    };

    const handleMouseDownPassword = () => {
        setShowPassword(true);
    };

    const handleMouseUpPassword = () => {
        setShowPassword(false);
    };

    const handleMouseDownNewPassword = () => {
        setShowNewPassword(true);
    };

    const handleMouseUpNewPassword = () => {
        setShowNewPassword(false);
    };

    const handleMouseDownConfirmNewPassword = () => {
        setShowConfirmNewPassword(true);
    };

    const handleMouseUpConfirmNewPassword = () => {
        setShowConfirmNewPassword(false);
    };

    const handleSubmitPasswordChange = async (event: React.FormEvent) => {
        event.preventDefault();
        if (newPassword !== confirmNewPassword) {
            showAlert('Passwords do not match', 'error');
            return;
        }

        try {
            await dispatch(updatePassword({ currentPassword: password, newPassword })).unwrap();
            showAlert('Password updated successfully', 'success');
            setPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        } catch (error) {
            showAlert('Failed to update password', 'error');
        }
    };

    return (
        <Grid container spacing={4}>
            <Grid size={{ xs: 12 }} >
                <Card sx={{ p: 3 }}>
                    <Typography variant="h6">{t('changePassword')}</Typography>
                    <form onSubmit={handleSubmitPasswordChange}>
                        <input
                            type="text"
                            name="username"
                            autoComplete="username"
                            style={{ display: 'none' }}
                        />
                        <TextField
                            fullWidth
                            label={t('currentPassword')}
                            type={showPassword ? 'text' : 'password'}
                            size="small"
                            margin="normal"
                            value={password}
                            onChange={handlePasswordChange}
                            autoComplete="current-password"
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onMouseDown={handleMouseDownPassword}
                                                onMouseUp={handleMouseUpPassword}
                                                onMouseLeave={handleMouseUpPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            label={t('newPassword')}
                            type={showNewPassword ? 'text' : 'password'}
                            size="small"
                            margin="normal"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            autoComplete="new-password"
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onMouseDown={handleMouseDownNewPassword}
                                                onMouseUp={handleMouseUpNewPassword}
                                                onMouseLeave={handleMouseUpNewPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            label={t('confirmPassword')}
                            type={showConfirmNewPassword ? 'text' : 'password'}
                            size="small"
                            margin="normal"
                            value={confirmNewPassword}
                            onChange={handleConfirmNewPasswordChange}
                            autoComplete="new-password"
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onMouseDown={handleMouseDownConfirmNewPassword}
                                                onMouseUp={handleMouseUpConfirmNewPassword}
                                                onMouseLeave={handleMouseUpConfirmNewPassword}
                                                edge="end"
                                            >
                                                {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                        <Button fullWidth type="submit" variant="contained" color="primary" sx={{ mt: 2 }} disabled={isLoading}>
                            {t('changePassword')}
                        </Button>
                    </form>
                </Card>
            </Grid>
            {/* <Grid size={{ xs: 12 }} >
                <Card sx={{ p: 3 }}>
                    <Typography variant="h6">Two-Factor Authentication</Typography>
                    <FormControlLabel
                        control={<Switch checked={is2FAEnabled} onChange={handle2FAToggle} />}
                        label={is2FAEnabled ? "Enabled" : "Disabled"}
                        labelPlacement="end"
                    />
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handle2FAChange}>
                        {is2FAEnabled ? "Disable 2FA" : "Enable 2FA"}
                    </Button>
                </Card>
            </Grid> */}
        </Grid>
    );
};

export default SecurityPage;
