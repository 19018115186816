import React from 'react';
import { Helmet } from 'react-helmet';
import { generateStructuredData } from '../utils/SEOgenerateStructuredData';

interface SEOProps {
  title: string;
  description: string;
  keywords?: string;
  image?: string;
  url?: string;
  twitterHandle?: string;
}

const SEO: React.FC<SEOProps> = ({ title, description, keywords, image, url, twitterHandle }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    {keywords && <meta name="keywords" content={keywords} />}
    <meta name="author" content="Bigswiss GmbH" />

    {/* Open Graph Meta Tags */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    {image && <meta property="og:image" content={image} />}
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    {url && <meta property="og:url" content={url} />}
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Bigswiss GmbH" />
    <meta property="og:locale" content="en_US" />

    {/* Twitter Card Meta Tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    {image && <meta name="twitter:image" content={image} />}
    <meta name="twitter:image:width" content="1200" />
    <meta name="twitter:image:height" content="628" />
    {twitterHandle && <meta name="twitter:site" content={`@${twitterHandle}`} />}
    {twitterHandle && <meta name="twitter:creator" content={`@${twitterHandle}`} />}

    {/* Canonical Link */}
    {url && <link rel="canonical" href={url} />}

    {/* Structured Data */}
    <script type="application/ld+json">
      {generateStructuredData()}
    </script>
  </Helmet>
);

export default SEO;
