import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState, AppDispatch } from 'redux/store';
import { Invoice } from 'redux/auth/authTypes';
import { fetchUserInvoices } from 'redux/auth/fetchUserThunks';
import { Typography, Grid2 as Grid, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TableSortLabel, TablePagination } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useTranslation } from 'react-i18next';
import InvoicePdfGenerator from 'components/Invoice/InvoicePdfGenerator'

const PurchasesPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const invoices = useSelector((state: RootState) => state.auth.user?.invoices) || [];
    const [sortField, setSortField] = useState<keyof Invoice>('invoiceNumber');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        dispatch(fetchUserInvoices());
    }, [dispatch]);

    const handleSort = (field: keyof Invoice) => {
        const isAsc = sortField === field && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortField(field);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const sortedInvoices = [...invoices].sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    const paginatedInvoices = sortedInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }} >
                <TableContainer component={Card}>
                    <Table aria-label="purchases table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'invoiceNumber'}
                                        direction={sortField === 'invoiceNumber' ? sortOrder : 'asc'}
                                        onClick={() => handleSort('invoiceNumber')}
                                    >
                                        {t('invoiceNo')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel
                                        active={sortField === 'createdAt'}
                                        direction={sortField === 'createdAt' ? sortOrder : 'asc'}
                                        onClick={() => handleSort('createdAt')}
                                    >
                                        {t('date')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel
                                        active={sortField === 'total'}
                                        direction={sortField === 'total' ? sortOrder : 'asc'}
                                        onClick={() => handleSort('total')}
                                    >
                                        {t('total')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">{t('action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedInvoices.length > 0 ? (
                                paginatedInvoices.map((invoice: Invoice) => (
                                    <TableRow key={invoice.invoiceNumber} hover style={{ cursor: 'pointer' }}>
                                        <TableCell component="th" scope="row">
                                            <Link to={`/dashboard/purchases/${invoice.invoiceNumber}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                {invoice.invoiceNumber}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="right">
                                            {new Date(invoice.createdAt).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell align="right">
                                            {`${invoice.total.toFixed(2)}`}
                                        </TableCell>
                                        <TableCell align="right">
                                            <InvoicePdfGenerator title={t('download')} invoice={invoice} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        <Typography variant="body1">{t('invoiceNotFound')}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{
                        mt: 2,
                        '& .MuiTablePagination-toolbar': {
                            backgroundColor: 'rgba(19, 27, 32, 0.6)',
                            color: '#fff',
                        },
                    }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={invoices.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('rowsPerPage')}
                />
            </Grid>
        </Grid >
    );
};

export default PurchasesPage;
