import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FAQ from "./Components/FAQ";
import Pricing from "./Components/Pricing";
import { useTranslation } from "react-i18next";

import { alpha, Container } from "@mui/material";
export default function AboutApiPage() {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        alignItems: "center",
        pt: { xs: 14, sm: 20 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      <Box
        id="image"
        sx={(theme) => ({
          mb: { xs: 8, sm: 10 },
          alignSelf: "center",
          height: { xs: 200, sm: 300 },
          width: "100%",
          backgroundImage: 'url("/img/apicover.webp")',
          backgroundSize: "cover",
          backgroundPositionY: "top",
          borderRadius: "10px",
          outline: "1px solid",
          ...theme.applyStyles('light', {
            outlineColor: alpha("#BFCCD9", 0.5),
            boxShadow: `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`,
          }),
          ...theme.applyStyles('dark', {
            outlineColor: alpha("#9CCCFC", 0.1),
            boxShadow: `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          }),
        })}
      />

      <Box sx={{ padding: 3 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          {t("aboutOurApi")}
        </Typography>
        <Typography variant="body1" paragraph>
          {t("aboutOurApiDescription")}
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          {t("whyChooseOurApi")}
        </Typography>
        <Typography variant="body1" paragraph>
          {t("whyChooseOurApiDescription")}
        </Typography>
        <ul>
          <li>{t("featureApi1")}</li>
          <li>{t("featureApi2")}</li>
          <li>{t("featureApi3")}</li>
          <li>{t("featureApi4")}</li>
          <li>{t("featureApi5")}</li>
          <li>{t("featureApi6")}</li>
        </ul>
        <Typography variant="body1" paragraph>
          {t("WithOurApi")}
        </Typography>
        <Divider sx={{ marginY: 4 }} />
        <Pricing />
        <Divider sx={{ marginY: 4 }} />
        <FAQ />
      </Box>
    </Container>
  );
}
