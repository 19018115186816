import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const NintendoOnlineIcon = (props: SvgIconProps) => {

  return (
      <SvgIcon
      {...props}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
    >
      <g clipPath="url(#clip0_296_78)">
        <circle cx="25" cy="25" r="25" fill="#E60012" />
        <path d="M27.2945 29.6918H30.6551C33.7413 29.6918 36.2446 27.0872 36.2446 23.8761V12.8157C36.2446 9.60456 33.7413 7 30.6551 7H27.2773C27.1916 7 27.1402 7.07136 27.1402 7.14272V29.5312C27.1402 29.6204 27.2088 29.6918 27.2945 29.6918ZM31.4438 17.1863C32.6611 17.1863 33.6556 18.221 33.6556 19.4876C33.6556 20.7542 32.6611 21.7889 31.4438 21.7889C30.2264 21.7889 29.232 20.7542 29.232 19.4876C29.2491 18.221 30.2264 17.1863 31.4438 17.1863ZM17.83 13.8147C17.83 14.9921 18.7559 15.9554 19.8875 15.9554C21.0191 15.9554 21.945 14.9921 21.945 13.8147C21.945 12.6373 21.0191 11.6739 19.8875 11.6739C18.7388 11.6739 17.83 12.6194 17.83 13.8147ZM24.7912 7H20.0247C16.9385 7 14.4352 9.60456 14.4352 12.8157V23.8761C14.4352 27.0872 16.9385 29.6918 20.0247 29.6918H24.7912C24.8769 29.6918 24.9455 29.6204 24.9455 29.5312V7.16055C24.9455 7.07136 24.8769 7 24.7912 7ZM23.1967 27.8721H20.0247C18.9959 27.8721 18.0358 27.4618 17.3157 26.6947C16.5955 25.9455 16.184 24.9465 16.184 23.8761V12.8157C16.184 11.7453 16.5784 10.7463 17.3157 9.99703C18.0358 9.24777 18.9959 8.81962 20.0247 8.81962H23.1967V27.8721Z" fill="white" />
        <path d="M19.7251 38.6422L17.0739 34.8807V38.6777H16V33.0355H17.0739L19.6915 36.726V33H20.7654V38.6422H19.7251Z" fill="white" />
        <path d="M22.7885 33.0355H24V37.5H26.5V38.6422L22.7885 38.6777V33.0355Z" fill="white" />
        <path d="M28.7708 33.0355H29.9454V38.6777H28.7708V33.0355Z" fill="white" />
        <path d="M35.7176 38.6422L33.0664 34.8807V38.6777H31.9925V33.0355H33.0664L35.684 36.726V33H36.7579V38.6422H35.7176Z" fill="white" />
        <path d="M39.9461 37.6131H43V38.6777H38.8386V33.0355H43V34.0646H39.9461V35.2711H42.7651V36.3001H39.9461V37.6131Z" fill="white" />
        <path d="M10.825 33C9.26294 33 8 34.2629 8 35.825C8 37.3871 9.26294 38.65 10.825 38.65C12.3871 38.65 13.65 37.3871 13.65 35.825C13.6168 34.2629 12.3538 33 10.825 33ZM10.825 37.4535C9.92764 37.4535 9.19646 36.7224 9.19646 35.825C9.19646 34.9276 9.92764 34.1965 10.825 34.1965C11.7223 34.1965 12.4535 34.9276 12.4535 35.825C12.4535 36.7224 11.7223 37.4535 10.825 37.4535Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_296_78">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default NintendoOnlineIcon;