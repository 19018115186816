import { useTranslation } from 'react-i18next';

import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SecurityIcon from '@mui/icons-material/Security';

const useMenuItems = () => {
  const { t } = useTranslation();

  return [
    { path: "/dashboard/purchases", label: t('purchases'), icon: <ShoppingCartIcon fontSize="small" /> },
    { path: "/dashboard/favorites", label: t('favorites'), icon: <FavoriteIcon fontSize="small" /> },
    // { path: "/dashboard/achievements", label: t('achievements'), icon: <EmojiEventsIcon fontSize="small" /> },
    { path: "/dashboard/user-settings", label: t('settings'), icon: <SettingsIcon fontSize="small" /> },
    { path: "/dashboard/security", label: t('security'), icon: <SecurityIcon fontSize="small" /> },
  ];
};

export default useMenuItems;
