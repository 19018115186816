import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTranslation } from 'react-i18next';
import { addItem, maxProductAllowed } from 'redux/reducers/cartReducer';
import { RootState } from 'redux/store';
import { useNavigate } from 'react-router-dom';

interface CartButtonProps {
  productId: string;
  platformGroupId: string;
  price: number;
  quantity: number;
}

function CartButton({ productId, platformGroupId, price, quantity = 1 }: CartButtonProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartItem = useSelector((state: RootState) =>
    state.cart.items.find(item => item.productId === productId && item.platformGroupId === platformGroupId)
  );

  const currentQuantity = cartItem ? cartItem.quantity : 0;

  const handleAddToCart = () => {
    dispatch(addItem({ id: `${productId}-${platformGroupId}`, productId, platformGroupId, price, quantity }));
  };

  const handleGoToCart = () => { navigate('/cart'); };

  return (
    <>
      {currentQuantity >= maxProductAllowed ? (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{ minWidth: '230px' }}
          startIcon={<ShoppingCartIcon />}
          onClick={handleGoToCart}
        >
          {t('goToCart')}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          sx={{ minWidth: '230px' }}
          startIcon={<AddShoppingCartIcon />}
          onClick={handleAddToCart}
        >
          {t('addToCart')}
        </Button>
      )}
    </>
  );
}

export default CartButton;
