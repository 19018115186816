// redux/reducers/platformReducer.ts
import { createSlice } from '@reduxjs/toolkit';
import { TypeState } from '../types/dataTypes';
import { fetchTypes } from '../actions/fetchDataActions';

const initialState: TypeState = {
  loading: false,
  types: [],
  error: null,
};

const typeSlice = createSlice({
  name: 'type',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTypes.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.types = payload;
      })
      .addCase(fetchTypes.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || 'Failed to fetch types';
      });
  },
});

export default typeSlice.reducer;