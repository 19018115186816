import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface ManualVerificationModalProps {
    open: boolean;
    onClose: () => void;
}

const ManualVerificationModal: React.FC<ManualVerificationModalProps> = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} sx={{ alignItems: "center" }}

            PaperProps={{
                sx: {
                    display: 'flex',
                    alignItems: 'center',
                    p: 4,
                    borderRadius: 5,
                }
            }}
        >
            <DialogTitle>Thank You for Your Purchase!</DialogTitle>
            <img src="/img/support_verification.webp" width="350" height="350" alt="Manual Verification" />
            <DialogContent>
                <Typography variant="body1">
                    We're currently reviewing your purchase to ensure everything is in order. This process typically takes a short time, and our team is working to get your product to you as quickly as possible.
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    If you have any questions or need further assistance, feel free to reach out to our support team.
                </Typography>
            </DialogContent>
            <DialogActions >
                <Button onClick={onClose} variant="contained" color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default ManualVerificationModal;
