// src/components/ScrollToTopWrapper.tsx
import React from 'react';
import useScrollToTop from '../utils/ScrollToTop';

interface ScrollToTopWrapperProps {
    children: React.ReactNode;
}

const ScrollToTopWrapper: React.FC<ScrollToTopWrapperProps> = ({ children }) => {
    useScrollToTop();
    return <>{children}</>;
};

export default ScrollToTopWrapper;
