import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Card, CardMedia, CardContent, CardActions, Stack, useMediaQuery, useTheme } from '@mui/material';
import PlatformLogo from 'components/icons/PlatformLogos';
import ProductPriceTag from 'components/ProductPriceTag';
import { getProductImgSm } from 'utils/getNames';
import QuantityControl from 'components/Cart/QuantityControl';

interface ProductProps {
    title: string;
    price: number;
    imageUrl: string;
    quantity: number;
    maxProductAllowed: number;
    category: string;
    platfrom: string;
    platformId: string;
    region: string;
    onAdd: () => void;
    onRemove: () => void;
    onDelete: () => void;
    onQuantityChange: (newQuantity: number) => void;
}

const ProductCartItem = forwardRef<HTMLDivElement, ProductProps>(({
    title,
    price,
    imageUrl,
    quantity,
    category,
    platfrom,
    platformId,
    region,
    onAdd,
    onRemove,
    onDelete,
    onQuantityChange,
    maxProductAllowed,
}, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMediumScreen = useMediaQuery('(min-width:900px) and (max-width:1051px)');
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(705));

    const shouldShowImage = !isMediumScreen && !isSmallScreen;

    return (
        <Card ref={ref} sx={{ display: 'flex', marginBottom: 2, maxHeight: 160 }}>
            {shouldShowImage && (
                <CardMedia
                    component="img"
                    sx={{
                        width: 250,
                        height: 160,
                        objectFit: 'cover',
                    }}
                    image={getProductImgSm(imageUrl)}
                    alt={title}
                />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, p: 1 }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="h5" sx={{ maxWidth: 250, whiteSpace: 'normal', wordWrap: 'break-word' }}>{title}</Typography>
                    <Typography variant="body1" color="textSecondary">{region} {t('region')}</Typography>
                    <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                        <PlatformLogo platformId={platformId} sx={{ width: 24, height: 24, mr: 1 }} />
                        {category} - {platfrom}
                    </Typography>
                </CardContent>
                <CardActions sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Stack direction="column" spacing={1} alignItems={'center'}>
                        <ProductPriceTag variant='h5' productTagPrice={price.toString()} currencyPadding='2px' />
                        <Typography>{t('quantity')}: {quantity}</Typography>
                        <QuantityControl
                            quantity={quantity}
                            onAdd={onAdd}
                            onRemove={onRemove}
                            onDelete={onDelete}
                            onQuantityChange={onQuantityChange}
                            size="small"
                            maxProductAllowed={maxProductAllowed}
                        />
                    </Stack>
                </CardActions>
            </Box>
        </Card>
    );
});

export default ProductCartItem;
