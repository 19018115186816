import { createSlice } from '@reduxjs/toolkit';
import { PlatformState } from '../types/dataTypes';
import { fetchPlatforms } from '../actions/fetchDataActions';

const initialState: PlatformState = {
  loading: false,
  platforms: [],
  error: null,
};

const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlatforms.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPlatforms.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.platforms = payload;
      })
      .addCase(fetchPlatforms.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || 'Failed to fetch platforms';
      });
  },
});

export default platformSlice.reducer;
