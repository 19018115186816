import React from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import platformIcons from './platformLogosMap';

interface PlatformLogoProps extends AvatarProps {
  platformId: string;
}

const PlatformLogo: React.FC<PlatformLogoProps> = ({ platformId, ...rest }) => {
  const IconComponent = platformIcons[platformId];

  if (!IconComponent) {
    console.error(`No icon found for platform ID: ${platformId}`);
    return null;
  }

  return (
    <Avatar
      component={'span'}
      variant="square"
      {...rest}
    >
      <IconComponent />
    </Avatar>
  );
};

export default PlatformLogo;
