import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchProducts, fetchProductById, fetchProductByIdAndPlatformId } from './productActions';
import { ProductState, Product, FilterValues } from './ProductsTypes';

const initialState: ProductState = {
  products: [],
  isLoading: false,
  error: null,
  filter: {
    platform: '',
    maxPrice: '',
    category: '',
    type: '',
    genre: '',
    sort: 'A-Z',
  },
};

function isFilterKey(key: any): key is keyof FilterValues {
  return ['category', 'platform', 'type', 'sort', 'genre', 'maxPrice'].includes(key);
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<{ key: keyof FilterValues; value: string }>) => {
      const { key, value } = action.payload;
      if (isFilterKey(key)) {
        state.filter[key] = value;
      }
    },
    resetFilters: (state) => {
      state.filter = {
        platform: '',
        maxPrice: '',
        type: '',
        category: '',
        genre: '',
        sort: 'A-Z',
      };
    },
    purgeProducts: (state) => {
      state.products = []; // Clear the products array
      state.error = null;  // Reset any error
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action: PayloadAction<Product[]>) => {
        state.isLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(fetchProductById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProductById.fulfilled, (state, action: PayloadAction<Product>) => {
        state.isLoading = false;
        const index = state.products.findIndex(p => p._id === action.payload._id);
        if (index !== -1) {
          state.products[index] = action.payload;
        } else {
          state.products.push(action.payload);
        }
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(fetchProductByIdAndPlatformId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProductByIdAndPlatformId.fulfilled, (state, action: PayloadAction<Product>) => {
        state.isLoading = false;
        const index = state.products.findIndex(p => p._id === action.payload._id);
        if (index !== -1) {
          state.products[index] = action.payload;
        } else {
          state.products.push(action.payload);
        }
      })
      .addCase(fetchProductByIdAndPlatformId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? null;
      });
  },
});

// Export the purgeProducts action
export const { setFilter, resetFilters, purgeProducts } = productSlice.actions;

export default productSlice.reducer;
