import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const sizes = {
  small: '20px',
  medium: '40px',
  large: '60px',
  inherit: 'inherit',
};

const StyledSvgIcon = styled(SvgIcon)(({ fontSize }: SvgIconProps) => ({
  width: fontSize && sizes[fontSize] ? sizes[fontSize] : '100%',
  height: 'auto',
}));

const XboxXIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
      <StyledSvgIcon
      {...props}
      viewBox="0 0 50 50"
    >
      <g clipPath="url(#clip0_275_6)">
        <path d="M22.4946 49.8972C18.6453 49.5285 14.748 48.1461 11.4002 45.9618C8.59479 44.1315 7.96127 43.3791 7.96127 41.8775C7.96127 38.8612 11.2777 33.5784 16.9518 27.556C20.1743 24.1357 24.6631 20.1266 25.1485 20.2351C26.0921 20.4461 33.6368 27.8055 36.4613 31.2699C40.9276 36.7482 42.9809 41.2338 41.9378 43.2336C41.1448 44.7538 36.2245 47.7249 32.6097 48.8664C29.6304 49.8071 25.7177 50.2059 22.4946 49.8972ZM4.17225 38.7415C1.84102 35.165 0.66321 31.6441 0.0945549 26.5516C-0.0932164 24.8701 -0.0259289 23.9083 0.520935 20.4568C1.20252 16.1551 3.65227 11.1786 6.59587 8.11594C7.84957 6.81153 7.96154 6.77975 9.48972 7.29456C11.3455 7.91973 13.3274 9.28846 16.4009 12.0677L18.1942 13.6892L17.2149 14.8922C12.6692 20.4767 7.87049 28.3926 6.06201 33.2899C5.07884 35.9523 4.6823 38.6248 5.10532 39.7374C5.39093 40.4886 5.12859 40.2086 4.17225 38.7415ZM45.0925 39.3497C45.3228 38.2255 45.0315 36.1608 44.3488 34.0783C42.8704 29.5683 37.9287 21.1782 33.3909 15.4738L31.9624 13.678L33.5078 12.259C35.5257 10.4061 36.9268 9.29663 38.4384 8.35444C39.6313 7.61094 41.336 6.95276 42.0688 6.95276C42.5205 6.95276 44.1109 8.60327 45.3949 10.4045C47.3834 13.1943 48.8462 16.5761 49.5874 20.0965C50.0663 22.3712 50.1062 27.2403 49.6645 29.5094C49.3021 31.3715 48.5368 33.787 47.7903 35.4253C47.2309 36.6527 45.8396 39.0367 45.2299 39.8124C44.9165 40.2112 44.9162 40.2104 45.0925 39.3497ZM22.9067 6.10113C20.813 5.03789 17.583 3.89657 15.7987 3.58951C15.1732 3.48186 14.1062 3.42183 13.4275 3.4561C11.9554 3.53044 12.0212 3.45345 14.3828 2.3377C16.3462 1.41009 17.9839 0.864609 20.2072 0.397784C22.7082 -0.127359 27.4092 -0.133522 29.8704 0.385116C32.5287 0.945289 35.6589 2.11016 37.4228 3.19563L37.947 3.51824L36.7443 3.4575C34.3541 3.3368 30.8708 4.30242 27.131 6.12241C26.003 6.67137 25.0216 7.1098 24.9502 7.09671C24.8788 7.08362 23.9592 6.63561 22.9067 6.10113Z" fill={theme.palette.text.primary} />
      </g>
      <defs>
        <clipPath id="clip0_275_6">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </StyledSvgIcon>
  );
}

export default XboxXIcon;