import React from 'react';
import { Web as BrowserIcon, Key as KeylessIcon } from '@mui/icons-material';
import EpicIcon from 'components/icons/Platforms/EpicIcon';
import ActivationGuide from './ActivationGuide';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface EpicInstructionsProps {
    keyCode: string;
}

const EpicInstructions: React.FC<EpicInstructionsProps> = ({ keyCode }) => {
    const { t } = useTranslation();

    const steps = {
        default: [
            {
                label: t('activationMethod'),
                description: t('chooseActivation'),
                options: [
                    { title: t('Browser'), icon: <BrowserIcon sx={{ width: '64px', height: '64px' }} />, method: 'browser' },
                    { title: t('Epic App'), icon: <EpicIcon sx={{ width: '64px', height: '64px' }} />, method: 'epicApp' },
                    { title: t('keylessActivation'), icon: <KeylessIcon sx={{ width: '64px', height: '64px' }} />, method: 'keyless' }
                ]
            }
        ],
        browser: [
            { label: t('activationMethod'), description: '', imageUrl: '' },

            {
                label: t('activationLink'),
                description: t('epicStep1'),
                imageUrl: '/img/activation/activation-link.webp',
                actionButton: (
                    <Button
                        variant="contained"
                        color="secondary"
                        href={`https://epicgames.com/store/en-US/redeem?code=${keyCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('goToEpicActivation')}
                    </Button>
                ),
            },
            {
                label: t('activateKey'),
                description: t('epicStep2'),
                imageUrl: '/img/activation/epic-redeem-code.webp',
            },
            {
                label: t('linkToAccount'),
                description: t('epicStep3'),
                imageUrl: '/img/activation/epic-clickon-redeem-code.webp',
            },
            {
                label: t('login'),
                description: t('epicStep4'),
                imageUrl: '/img/activation/epic-login.webp',
            },
            {
                label: t('confirmActivation'),
                description: t('epicStep5'),
                imageUrl: '/img/activation/download.webp',
            }
        ],
        epicApp: [
            { label: t('activationMethod'), description: '', imageUrl: '' },

            {
                label: t('login'),
                description: t('epicStep2app'),
                imageUrl: '/img/activation/epic-app-login.webp'
            },
            {
                label: t('goToActivation'),
                description: t('epicStep3app'),
                imageUrl: '/img/activation/epic-app-clickon-redeem-code.webp'
            },
            {
                label: t('enterTheKey'),
                description: t('epicStep4app'),
                imageUrl: '/img/activation/epic-app-redeem-code.webp'
            },
            {
                label: t('confirmActivation'),
                description: t('epicStep5'),
                imageUrl: '/img/activation/download.webp',
            }
        ]
    };

    return <ActivationGuide steps={steps} />;
};

export default EpicInstructions;
