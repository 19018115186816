import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Box, Button, Container, Stack, TextField, Typography, Link } from '@mui/material';
import axios from 'axios';
import SiteTitle from 'components/SiteTitle';
import useSnackbarAlert from 'utils/useSnackbarAlert';

export default function ContactUsPage() {
    const { t } = useTranslation();
    const showAlert = useSnackbarAlert();
    const [email, setEmail] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [fullName, setFullName] = useState<string>('');
    const [position, setPosition] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(event.target.value);
    };

    const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFullName(event.target.value);
    };

    const handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPosition(event.target.value);
    };

    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleContactUs = async () => {
        if (!email || !companyName || !fullName || !position || !message) {
            showAlert(t('allFieldsRequired'), 'error');
            return;
        }
        if (!validateEmail(email)) {
            showAlert(t('invalidEmail'), 'error');
            return;
        }

        setLoading(true);
        try {
            await axios.post('/api/mail/contactus', { email, companyName, fullName, position, message });
            setSuccess(true);
            setEmail('');
            setCompanyName('');
            setFullName('');
            setPosition('');
            setMessage('');
            showAlert(t('messageSent'), 'success');
        } catch (error) {
            showAlert(t('contactFailed'), 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container
            sx={{
                alignItems: 'center',
                pt: { xs: 14, sm: 20 },
                pb: { xs: 8, sm: 12 },
            }}
        >
            <Box
                id="image"
                sx={(theme) => ({
                    mb: { xs: 8, sm: 10 },
                    alignSelf: 'center',
                    height: { xs: 200, sm: 300 },
                    width: '100%',
                    backgroundImage: 'url("/img/contactus.jpeg")',
                    backgroundSize: 'cover',
                    backgroundPositionY: 'center',
                    borderRadius: '10px',
                    outline: '1px solid',
                    ...theme.applyStyles('light', {
                        outlineColor: alpha('#BFCCD9', 0.5),
                        boxShadow: `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`,
                    }),
                    ...theme.applyStyles('dark', {
                        outlineColor: alpha('#9CCCFC', 0.1),
                        boxShadow: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    }),
                })}
            />

            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                <SiteTitle firstTitle={t("bigswissSupport")} secondTitle={t("support")} variant="h1" color="primary" display="flex" position='center' />

                <Typography
                    textAlign="center"
                    color="text.secondary"
                    sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
                >
                    {t("contactDescription")}
                    <br />
                    <br />
                    {t("connectTeam")}
                </Typography>

                <Stack spacing={3} useFlexGap sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}>
                    <Stack spacing={2} direction={'row'} justifyContent={'space-between'}>
                        <TextField
                            id="email-input"
                            label={t("yourEmailAddress")}
                            variant="outlined"
                            fullWidth
                            value={email}
                            size='small'
                            onChange={handleEmailChange}
                            disabled={loading || success}
                            aria-label={t("enterYourEmailAddress")}
                            slotProps={{
                                input: {
                                    autoComplete: 'off',
                                },
                            }}
                        />
                        <TextField
                            id="full-name-input"
                            size='small'
                            label={t("yourName")}
                            variant="outlined"
                            fullWidth
                            value={fullName}
                            onChange={handleFullNameChange}
                            disabled={loading || success}
                            aria-label={t("enterFullName")}
                            slotProps={{
                                input: {
                                    autoComplete: 'off',
                                },
                            }}
                        />
                    </Stack>
                    <Stack spacing={2} direction={'row'} justifyContent={'space-between'}>
                        <TextField
                            id="company-name-input"
                            label={t("company")}
                            variant="outlined"
                            size='small'
                            fullWidth
                            value={companyName}
                            onChange={handleCompanyNameChange}
                            disabled={loading || success}
                            aria-label={t("enterCompanyName")}
                            slotProps={{
                                input: {
                                    autoComplete: 'off',
                                },
                            }}
                        />

                        <TextField
                            id="position-input"
                            label={t("position")}
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={position}
                            onChange={handlePositionChange}
                            disabled={loading || success}
                            aria-label={t("enterPosition")}
                            slotProps={{
                                input: {
                                    autoComplete: 'off',
                                },
                            }}
                        />
                    </Stack>

                    <TextField
                        id="message-input"
                        label={t("message")}
                        variant="outlined"
                        fullWidth
                        multiline
                        size='small'
                        rows={4}
                        value={message}
                        onChange={handleMessageChange}
                        disabled={loading || success}
                        aria-label={t("enterMessage")}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: 'auto',
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: '8.5px 14px',
                            },
                        }}
                        slotProps={{
                            input: {
                                autoComplete: 'off',
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleContactUs}
                        disabled={loading || success}
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        {success ? t("messageSent") : t("contactUs")}
                    </Button>
                </Stack>

                <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
                    {t("contactUsAgree")}&nbsp;
                    <Link href="#" color="primary">
                        {t("termsOfService")}
                    </Link>
                    .
                </Typography>
            </Stack>
        </Container>
    );
}
