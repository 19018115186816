// redux/reducers/platformReducer.ts
import { createSlice } from '@reduxjs/toolkit';
import { RegionState } from '../types/dataTypes';
import { fetchRegions } from '../actions/fetchDataActions';

const initialState: RegionState = {
  loading: false,
  regions: [],
  error: null,
};

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRegions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.regions = payload;
      })
      .addCase(fetchRegions.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || 'Failed to fetch regions';
      });
  },
});

export default regionSlice.reducer;