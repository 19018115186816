import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';

import { Grid2 as Grid, Card, Typography, Avatar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

// import PersonIcon from '@mui/icons-material/Person';
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { getUserAvatarUrl } from 'utils/getNames';

const avatars = [
  '/img/avatar-1.webp',
  '/img/avatar-2.webp',
];

const getRandomAvatar = () => {
  const randomIndex = Math.floor(Math.random() * avatars.length);
  return avatars[randomIndex];
};

const UserDashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const achievementsInfo = { totalPoints: 0 };
  const favoritesCount = user?.favorites?.length || 0;
  const purchasesCount = user?.invoices?.length || 0;
  const userId = user?._id || '';

  const [avatarUrl, setAvatarUrl] = useState(getUserAvatarUrl(userId) || getRandomAvatar());

  const handleImageError = () => {
    setAvatarUrl(getRandomAvatar());
  };

  return (
    <Grid container spacing={4}>
      <Grid size={{ xs: 12, sm: 12, md: 12 }}>
        <Card
          sx={{ textDecoration: 'none', p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <Avatar sx={{ mb: 2, color: 'text.primary' }} src={avatarUrl} onError={handleImageError} />
          <Typography variant="h6" sx={{ mb: 1 }}>{user?.firstName} {user?.lastName}</Typography>
          <Typography variant="body2">{user?.email}</Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>{user?.country}</Typography>
        </Card>
      </Grid>

      {[
        // {
        //   link: '/dashboard/achievements',
        //   avatar: <EmojiEventsIcon sx={{ color: 'gold', fontSize: 40 }} />,
        //   title: t('achievements'),
        //   subheader: `${achievementsInfo.totalPoints} ${t('points')}`,
        //   isProfile: false
        // },
        {
          link: '/dashboard/favorites',
          avatar: <FavoriteIcon sx={{ color: 'red', fontSize: 40 }} />,
          title: t('favorites'),
          subheader: `${favoritesCount} ${t('games')}`,
          isProfile: false
        },
        {
          link: '/dashboard/purchases',
          avatar: <ShoppingCartCheckoutIcon sx={{ color: 'text.primary', fontSize: 40 }} />,
          title: t('purchases'),
          subheader: `${purchasesCount} ${t('purchases')}`,
          isProfile: false
        }
      ].map((card, index) => (
        <Grid size={{ xs: 12, sm: 6, md: 6 }} key={index}>
          <Card
            component={RouterLink} to={card.link}
            sx={{
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.07)',
                zIndex: 100
              },
              textDecoration: 'none', p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'
            }}>
            <Avatar sx={{ mb: 2, bgcolor: 'transparent' }} children={card.avatar} />
            <Typography variant="h6" sx={{ mb: 1 }}>{card.title}</Typography>
            <Typography variant="body2">{card.subheader}</Typography>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default UserDashboardPage;
