import React from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import categoryIcons from './categoryLogosMap';

interface PlatformLogoProps extends AvatarProps {
  categoryId: string;
}

const PlatformLogo: React.FC<PlatformLogoProps> = ({ categoryId, ...rest }) => {
  const IconComponent = categoryIcons[categoryId];

  if (!IconComponent) {
    console.error(`No icon found for platform ID: ${categoryId}`);
    return null;
  }

  return (
    <Avatar
      component={'span'}
      variant="square"
      {...rest}
    >
      <IconComponent />
    </Avatar>
  );
};

export default PlatformLogo;
