import React from 'react';
import { Web as BrowserIcon, Key as KeylessIcon } from '@mui/icons-material';
import SteamIcon from 'components/icons/Platforms/SteamIcon';
import ActivationGuide from './ActivationGuide';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SteamInstructionsProps {
    keyCode: string;
}

const SteamInstructions: React.FC<SteamInstructionsProps> = ({ keyCode }) => {
    const { t } = useTranslation();

    const steps = {
        default: [
            {
                label: t('activationMethod'),
                description: t('chooseActivation'),
                options: [
                    { title: t('Browser'), icon: <BrowserIcon sx={{ width: '64px', height: '64px' }} />, method: 'browser' },
                    { title: 'Steam App', icon: <SteamIcon sx={{ width: '64px', height: '64px' }} />, method: 'app' },
                    { title: t('keylessActivation'), icon: <KeylessIcon sx={{ width: '64px', height: '64px' }} />, method: 'keyless' },
                ]
            }
        ],
        browser: [
            { label: t('activationMethod'), description: '', imageUrl: '' },
            {
                label: t('activationLink'),
                description: t('steamStep1'),
                imageUrl: '/img/activation/activation-link.webp',
                actionButton: (
                    <Button
                        variant="contained"
                        color="secondary"
                        href={`https://store.steampowered.com/account/registerkey?key=${keyCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('goToSteamActivation')}
                    </Button>
                ),
            },
            {
                label: t('login'),
                description: t('steamStep2'),
                imageUrl: '/img/activation/steam-login.webp',
            },
            {
                label: t('enterTheKey'),
                description: t('steamStep3'),
                imageUrl: '/img/activation/steam-clickon-redeem-code.webp',
            },
            {
                label: t('confirmActivation'),
                description: t('steamStep4'),
                imageUrl: '/img/activation/download.webp',
            }
        ],
        app: [
            { label: t('activationMethod'), description: '', imageUrl: '' },
            { label: t('login'), description: t('steamStep2app'), imageUrl: '/img/activation/steam-app-login.webp' },
            { label: t('goToActivation'), description: t('steamStep3app'), imageUrl: '/img/activation/steam-app-clickon-redeem-code.webp' },
            { label: t('enterTheKey'), description: t('steamStep4app'), imageUrl: '/img/activation/steam-app-redeem-code.webp' },
            {
                label: t('confirmActivation'),
                description: t('steamStep4'),
                imageUrl: '/img/activation/download.webp',
            }
        ]
    };

    return <ActivationGuide steps={steps} />;
};

export default SteamInstructions;
