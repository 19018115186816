import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const GermanSwissFlag: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 60 30" preserveAspectRatio="xMidYMid slice">
    <rect width="60" height="10" fill="#000"/>
    <rect width="60" height="10" y="10" fill="#D00"/>
    <rect width="60" height="10" y="20" fill="#FFCE00"/>
  </SvgIcon>
);

export default GermanSwissFlag;
