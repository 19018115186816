import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Typography, Card, Divider, Link } from '@mui/material';
import { login, authMe } from 'redux/auth/authThunks';
import { addFavorite } from 'redux/auth/updateUserThunks';
import { RootState, AppDispatch } from 'redux/store';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { fetchProductById } from 'redux/product/productActions';
import { makeUrlSafe, getPlatformName, getRegionName, getTypeName } from 'utils/getNames';
import GoogleLoginButton from './SocialLogin/GoogleButton';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
  const regions = useSelector((state: RootState) => state.productInfo.region.regions);
  const types = useSelector((state: RootState) => state.productInfo.type.types);

  const { isLoading, error, isLoggedIn, isGoogleLoading } = useSelector((state: RootState) => state.auth); 

  useEffect(() => {
    if (isGoogleLoading) {
      dispatch(authMe()).unwrap()
        .then(() => {
          if (isLoggedIn) {
            handlePostLoginRedirection();
          }
        })
        .catch((error) => {
          console.error('Error during Google login authentication:', error);
        });
    }
  }, [dispatch, isLoading, isLoggedIn, isGoogleLoading,location.search]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedEmail = email.trim();
    dispatch(login({ email: trimmedEmail, password })).then((action: any) => {
      if (login.fulfilled.match(action)) {
        handlePostLoginRedirection();
      } else {
        console.error('Login failed:', action.payload);
      }
    });
  };

  const handlePostLoginRedirection = async () => {
    const searchParams = new URLSearchParams(location.search);
    if (location.pathname === '/cart/login') {
      navigate('/cart/payment');
      return;
    }
    const productId = searchParams.get('productId');
    const platformGroupId = searchParams.get('platformGroupId');
    if (productId && platformGroupId) {
      try {
        const product = await dispatch(fetchProductById(productId)).unwrap();

        const platform = product.platforms.find((p: any) => p._id === platformGroupId);

        if (!platform) {
          console.error('Platform not found');
          navigate('/dashboard', { replace: true });
          return;
        }

        const category = platform.category;
        const typeName = makeUrlSafe(getTypeName(product.type, types));
        const productName = makeUrlSafe(product.title);
        const productPlatform = getPlatformName(platform.platformId, platforms);
        const productRegion = getRegionName(platform.region, regions);

        await dispatch(addFavorite({ productId, platformGroupId }));
        navigate(`/${typeName}/${productId}/${productName}/${category}/${productPlatform}/${productRegion}`, { replace: true });
      } catch (error) {
        console.error('Failed to fetch product details:', error);
        navigate('/dashboard', { replace: true });
      }
      return;
    }

    navigate('/dashboard');
  };

  return (
    <Card elevation={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5">Login</Typography>

      <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 8 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Email Address"
          name="email"
          size="small"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <Typography color="error">{error}</Typography>}
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isLoading}>
          Sign In
        </Button>
      </form>

      <Link component={RouterLink} to={{ pathname: "/register", search: location.search }}>
        Don't have an account? Sign up
      </Link>

      <Divider sx={{ my: 2, color: 'text.primary' }} />

      <GoogleLoginButton />
    </Card>
  );
};

export default LoginForm;
