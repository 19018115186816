import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid2 as Grid, Typography, List, ListItem, ListItemText, Divider, Rating, Box } from '@mui/material';

interface Review {
    user: string;
    rating: number;
    comment: string;
    createdAt: string;
}

interface ProductReviewsProps {
    reviews: Review[];
}

export const ProductReviews = ({ reviews }: ProductReviewsProps) => {
    const { t } = useTranslation();
    const sortedReviews = [...reviews].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    if (sortedReviews.length === 0) {
        return null;
    }

    return (
        <Grid size={{ xs: 12 }}>
            <Typography
                variant="h3"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mb: 2
                }}
            >
                {t('buyers')}&nbsp;
                <Typography
                    component="span"
                    variant="h3"
                    sx={(theme) => ({
                        color: theme.applyStyles('light', {
                            color: theme.palette.primary.main,
                        }).color || theme.palette.primary.light,
                    })}
                >
                    {t('reviews')}&nbsp;
                </Typography>
            </Typography>

            <List>
                {sortedReviews.map((review, index) => (
                    <React.Fragment key={index}>
                        <ListItem alignItems="flex-start" sx={{ pl: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ flexGrow: 1, marginRight: 2 }}>
                                <ListItemText
                                    primary={review.user}
                                    secondary={review.comment}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Rating name="read-only" value={review.rating} readOnly />
                                <Typography variant="body2" color="textSecondary">
                                    {new Date(review.createdAt).toLocaleDateString()}
                                </Typography>
                            </Box>
                        </ListItem>
                        {index < reviews.length - 1 && <Divider variant="inset" component="li" sx={{ marginLeft: 0 }} />}
                    </React.Fragment>
                ))}
            </List>
        </Grid>
    );
};
