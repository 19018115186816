import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';

import { Grid2 as Grid, Card, Typography, Button, IconButton, Divider, Stack, Box, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import ProductPriceTag from 'components/ProductPriceTag';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CloseIcon from '@mui/icons-material/Close';

interface PaymentFormProps {
    clientSecret: string | undefined;
    onBackToCart: () => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ clientSecret, onBackToCart }) => {
    const { t } = useTranslation(); 
    const stripe = useStripe();
    const elements = useElements();

    const cartItems = useSelector((state: RootState) => state.cart.items);
    const user = useSelector((state: RootState) => state.auth.user);
    const [isReadyToPay, setIsReadyToPay] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const totalAmount = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);

    const handleCardDetailsChange = (event: StripePaymentElementChangeEvent) => {
        setIsReadyToPay(event.complete);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsReadyToPay(false);

        if (!stripe || !elements || !clientSecret) {
            setIsReadyToPay(true);
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${import.meta.env.VITE_APP_URL}/cart/payment-status`,
                receipt_email: user!.email, 
            },
        });

    };

    const handleCloseDialog = () => {
        setErrorDialogOpen(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Dialog
                open={errorDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Payment Error"}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errorMessage}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Grid container spacing={4}>
                <Grid size={{ xs: 12, sm: 8 }}>
                    <Card sx={{ padding: 3 }}>
                        <Typography variant="h6" sx={{ mb: 3 }}>Payment Options</Typography>
                        <PaymentElement onChange={handleCardDetailsChange} />
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }} sx={{
                    position: 'sticky',
                    top: 90,
                    height: 'fit-content'
                }}>
                    <Card sx={{ padding: 3 }}>
                        <Typography variant="h6" sx={{ mb: 3 }}>Cart Summary</Typography>
                        <Stack direction="column" spacing={1} sx={{ mb: 5 }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography>Original Price: </Typography>
                                <ProductPriceTag variant='h6' productTagPrice={totalAmount.toFixed(2)} color="text.secondary" currencyPadding='2px' />
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography>Discount: </Typography>
                                <ProductPriceTag variant='h5' productTagPrice="0" color="text.secondary" currencyPadding='2px' />
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography>Total: </Typography>
                                <ProductPriceTag variant='h5' productTagPrice={totalAmount.toFixed(2)} currencyPadding='0' />
                            </Stack>
                        </Stack>
                        <Box marginTop={2} >
                            <Button variant="contained" color="secondary" fullWidth type="submit" disabled={!isReadyToPay}>
                               {t('payNow')}
                            </Button>
                            <Divider sx={{ my: 2 }} />
                            <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                onClick={onBackToCart}
                                startIcon={<ArrowBackRoundedIcon />}
                            >
                                    {t('goToCart')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

export default PaymentForm;
