import React from 'react';
import { Web as BrowserIcon } from '@mui/icons-material';
import Ps5Icon from 'components/icons/Platforms/Ps5Icon';
import PsStoreIcon from 'components/icons/Platforms/PsStoreIcon';
import ActivationGuide from './ActivationGuide';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PlayStationInstructionsProps {
    keyCode: string;
}

const PlayStationInstructions: React.FC<PlayStationInstructionsProps> = ({ keyCode }) => {
    const { t } = useTranslation();

    const steps = {
        default: [
            {
                label: t('activationMethod'),
                description: t('chooseActivation'),
                options: [
                    { title: t('Browser'), icon: <BrowserIcon sx={{ width: '64px', height: '64px' }} />, method: 'browser' },
                    { title: t('PS5 Console'), icon: <Ps5Icon sx={{ width: '64px', height: '64px' }} />, method: 'playstationConsole' },
                    { title: t('PlayStation APP'), icon: <PsStoreIcon sx={{ width: '64px', height: '64px' }} />, method: 'playstationApp' }
                ]
            }
        ],
        browser: [
            { label: t('activationMethod'), description: '', imageUrl: '' },

            {
                label: t('activationLink'),
                description: t('psStep1'),
                imageUrl: '/img/activation/activation-link.webp',
                actionButton: (
                    <Button
                        variant="contained"
                        color="secondary"
                        href={`https://store.playstation.com/pages/latest`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('goToPlayStationActivation')}
                    </Button>
                ),
            },
            {
                label: t('openLogin'),
                description: t('psStep2'),
                imageUrl: '/img/activation/ps-home.webp',
            },
            {
                label: t('login'),
                description: t('psStep3'),
                imageUrl: '/img/activation/ps-login.webp',
            },
            {
                label: t('goToActivation'),
                description: t('psStep4'),
                imageUrl: '/img/activation/ps-clickon-redeem-code.webp',
            },
            {
                label: t('enterTheKey'),
                description: t('psStep5'),
                imageUrl: '/img/activation/ps-redeem-code.webp',
            },
            {
                label: t('confirmActivation'),
                description: t('psStep6'),
                imageUrl: '/img/activation/download.webp',
            }
        ],
        playstationConsole: [
            { label: t('activationMethod'), description: '', imageUrl: '' },
            {
                label: t('openStore'),
                description: t('psStep2console'),
                imageUrl: '/img/activation/ps-console-store.webp'
            },
            {
                label: t('goToActivation'),
                description: t('psStep3console'),
                imageUrl: '/img/activation/ps-console-clickon-redeem.webp'
            },
            {
                label: t('enterTheKey'),
                description: t('psStep4console'),
                imageUrl: '/img/activation/ps-console-redeem-code.webp'
            },
            {
                label: t('confirmActivation'),
                description: t('psStep6'),
                imageUrl: '/img/activation/ps-console-download.webp',
            }
        ],
        playstationApp: [
            { label: t('activationMethod'), description: '', imageUrl: '' },
            {
                label: t('openStore'),
                description: t('psStep2app'),
                imageUrl: '/img/activation/ps-app-store.webp',
            },
            {
                label: t('openMenu'),
                description: t('psStep3app'),
                imageUrl: '/img/activation/ps-app-menu.webp'
            },
            {
                label: t('goToActivation'),
                description: t('psStep4app'),
                imageUrl: '/img/activation/ps-app-clickon-redeem-code.webp'
            },
       
            {
                label: t('enterTheKey'),
                description: t('psStep5app'),
                imageUrl: '/img/activation/ps-app-redeem-code.webp'
            },
            {
                label: t('confirmActivation'),
                description: t('psStep6'),
                imageUrl: '/img/activation/download.webp',
            }
        ],

    };

    return <ActivationGuide steps={steps} />;
};

export default PlayStationInstructions;
