import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { Grid2 as Grid, List, ListItem, ListItemText, Container, ListItemIcon, BottomNavigation, BottomNavigationAction, useMediaQuery, useTheme, ListItemButton } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useTranslation } from 'react-i18next';

import FavoritesPage from './UserFavoritesPage/FavoritesPage';
import PurchasesPage from './UsersPurchaesPage/PurchasesPage';
import PurchaseDetailPage from './UsersPurchaesPage/PurchaseDetailPage';
import UserSettingsPage from './UserSettingsPage/UserSettingsPage';
import UserDashboardPage from './UserDashboardPage/UserDashboardPage';
import SecurityPage from './UserSecurityPage/SecurityPage';
import ProtectedRoute from 'ProtectedRoutes';

const UserPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const [value, setValue] = useState(0);
    const user = useSelector((state: RootState) => state.auth.user);

    const navigationItems = [
        { label: t('dashboard'), icon: <DashboardIcon />, path: '/dashboard' },
        { label: t('favorites'), icon: <FavoriteIcon />, path: '/dashboard/favorites' },
        { label: t('purchases'), icon: <ShoppingCartCheckoutIcon />, path: '/dashboard/purchases' },
        { label: t('settings'), icon: <SettingsIcon />, path: '/dashboard/user-settings' },
        { label: t('security'), icon: <SecurityIcon />, path: '/dashboard/security' },
    ];

    useEffect(() => {
        const currentIndex = navigationItems.findIndex(item => item.path === location.pathname);
        setValue(currentIndex !== -1 ? currentIndex : 0);
    }, [location, navigationItems]);

    return (
        <Container sx={{ py: isMobile ? 12 : 20, minHeight: '100vh' }}>
            <Grid container spacing={2} height={'100%'}>
                <Grid size={{ xs: 12, md: 3 }}>
                    {isMobile ? (
                        <BottomNavigation
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            showLabels={isMdUp}
                            sx={{ px: 5, width: '100%', position: 'fixed', bottom: 0, left: 0, zIndex: 1000 }}
                        >
                            {navigationItems.map((item, index) => (
                                <BottomNavigationAction
                                    key={index}
                                    sx={{
                                        p: { xs: 0, md: 2 },
                                        minWidth: { xs: 'auto', md: '80px' }
                                    }}
                                    label={isMdUp ? item.label : undefined}
                                    icon={item.icon}
                                    component={Link}
                                    to={item.path}
                                />
                            ))}
                        </BottomNavigation>
                    ) : (
                        <List
                            sx={{
                                paddingTop: 0,
                                position: 'sticky',
                                top: 160,
                            }}
                        >
                            {navigationItems.map((item, index) => (
                                <ListItem disablePadding key={index}>
                                    <ListItemButton
                                        component={Link}
                                        to={item.path}
                                        selected={location.pathname === item.path}
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor: theme.palette.action.selected,
                                            },
                                        }}
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.label} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Grid>
                <Grid size={{ xs: 12, md: 9 }} sx={{ height: isMobile ? '100vh' : 'fit-content' }}>
                    <Routes>
                        <Route path="/" element={
                            <ProtectedRoute user={user}>
                                <UserDashboardPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/favorites" element={
                            <ProtectedRoute user={user}>
                                <FavoritesPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/purchases" element={
                            <ProtectedRoute user={user}>
                                <PurchasesPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/purchases/:invoiceId" element={
                            <ProtectedRoute user={user}>
                                <PurchaseDetailPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/user-settings" element={
                            <ProtectedRoute user={user}>
                                <UserSettingsPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/security" element={
                            <ProtectedRoute user={user}>
                                <SecurityPage />
                            </ProtectedRoute>
                        } />
                    </Routes>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UserPage;
