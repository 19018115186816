import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar, SnackbarContent } from 'notistack';
import { Box, Button, Grid2 as Grid, TextField, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import DevicesIcon from '@mui/icons-material/Devices';
import useToggleTheme from './useToggleTheme';

interface ToggleColorModeProps {
  useButtons?: boolean;
  fullWidth?: boolean;
}

const ToggleColorMode: React.FC<ToggleColorModeProps> = ({ useButtons, fullWidth }) => {
  const { t } = useTranslation();
  const { setMode, mode: currentMode } = useToggleTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [mode, setInternalMode] = useState<'light' | 'dark' | 'auto'>(currentMode);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setInternalMode(currentMode);
  }, [currentMode]);

  const getIcon = (mode: 'light' | 'dark' | 'auto') => {
    if (mode === 'dark') {
      return <ModeNightRoundedIcon fontSize="small" color="info" />;
    } else if (mode === 'light') {
      return <WbSunnyRoundedIcon fontSize="small" color="warning" />;
    } else {
      return <DevicesIcon fontSize="small" />;
    }
  };

  const darkDescription = () => t('darkDescription');
  const lightDescription = () => t('lightDescription');
  const autoDescription = () => t('autoDescription');

  const getSecondaryText = (mode: 'light' | 'dark' | 'auto') => {
    if (mode === 'dark') {
      return darkDescription();
    } else if (mode === 'light') {
      return lightDescription();
    } else {
      return autoDescription();
    }
  };

  const CustomSnackbarContent: React.FC<{ message: string; icon: React.ReactNode }> = ({ message, icon }) => {
    const { closeSnackbar } = useSnackbar(); 
  
    return (
      <SnackbarContent onClick={() => closeSnackbar()}> 
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2">{t('applicationTheme')}</Typography>
            <Typography variant="body2">{message}</Typography>
          </Box>
        </Box>
      </SnackbarContent>
    );
  };
  

  const handleButtonClick = (newMode: 'light' | 'dark' | 'auto') => {
    setInternalMode(newMode);
    setMode(newMode);
    const icon = getIcon(newMode);
    const message = getSecondaryText(newMode);
    enqueueSnackbar(<CustomSnackbarContent message={message} icon={icon} />);
  };

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newMode = event.target.value as 'light' | 'dark' | 'auto';
    handleButtonClick(newMode); 
  };

  if (useButtons) {
    return (
      <Grid container spacing={2}>
        <Grid>
          <Button
            variant="outlined"
            onClick={() => handleButtonClick('light')}
            startIcon={<WbSunnyRoundedIcon />}
            disabled={mode === 'light'}
          >
            {t('light')}
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="outlined"
            onClick={() => handleButtonClick('dark')}
            startIcon={<ModeNightRoundedIcon />}
            disabled={mode === 'dark'}
          >
            {t('dark')}
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="outlined"
            onClick={() => handleButtonClick('auto')}
            startIcon={<DevicesIcon />}
            disabled={mode === 'auto'}
          >
            {t('systemPreferences')}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <TextField
      select
      sx={{ maxWidth: fullWidth || isSmallScreen ? '100%' : 200 }}
      fullWidth={fullWidth || isSmallScreen}
      value={mode}
      onChange={handleModeChange}
      variant="outlined"
      size="small"
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              width: fullWidth || isSmallScreen ? 'auto' : 160,
            },
          },
        },
      }}
    >
      <MenuItem value="light">
        <WbSunnyRoundedIcon fontSize="small" sx={{ mr: 2 }} />
        {t('light')}
      </MenuItem>
      <MenuItem value="dark">
        <ModeNightRoundedIcon fontSize="small" sx={{ mr: 2 }} />
        {t('dark')}
      </MenuItem>
      <MenuItem value="auto">
        <DevicesIcon fontSize="small" sx={{ mr: 2 }} />
        {t('systemPreferences')}
      </MenuItem>
    </TextField>
  );
};

export default ToggleColorMode;
