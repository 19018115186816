import axios from 'axios';

export const fetchCsrfToken = async () => {
  try {
    const response = await axios.get('/api/csrf-token');
    const csrfToken = response.data.csrfToken;
    axios.defaults.headers.post['X-CSRF-Token'] = csrfToken;
  } catch (error) {
    console.error('Failed to fetch CSRF token:', error);
  }
};
