import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const EnglishFlag: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 60 30" preserveAspectRatio="xMidYMid meet">
      <rect width="60" height="30" fill="#012169"/>
      <path fill="#FFF" d="M0 0v2L56 30h4v-2L4 0H0zm60 0v2L4 30H0v-2L56 0h4z"/>
      <path fill="#C8102E" d="M0 0v1L57.5 30h2.5v-1L2.5 0H0zm60 0v1L2.5 30H0v-1L57.5 0h2.5z"/>
      <path fill="#FFF" d="M25 0v30h10V0H25zM0 10v10h60V10H0z"/>
      <path fill="#C8102E" d="M26 0v30h8V0h-8zM0 11v8h60v-8H0z"/>
    </SvgIcon>
  );
  
  export default EnglishFlag;
