import { createSlice } from '@reduxjs/toolkit';
import { RatingState } from '../types/dataTypes';
import { fetchRatings } from '../actions/fetchDataActions';

const initialState: RatingState = {
  loading: false,
  ratings: [],
  error: null,
};

const ratingSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRatings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRatings.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.ratings = payload;
      })
      .addCase(fetchRatings.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || 'Failed to fetch ratings';
      });
  },
});

export default ratingSlice.reducer;
