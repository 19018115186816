import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Box, Typography, TextField, Button, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface GiftToFriendModalProps {
    open: boolean;
    onClose: () => void;
    onSendGift: (email: string) => void;
}

const GiftToFriendModal: React.FC<GiftToFriendModalProps> = ({ open, onClose, onSendGift }) => {
    const { t } = useTranslation();
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [error, setError] = useState('');
    const [giftSent, setGiftSent] = useState(false);

    const handleSendGift = () => {
        if (email1 !== email2) {
            setError(t('emailsDoNotMatch'));
        } else {
            setError('');
            onSendGift(email1);
            setGiftSent(true); 
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">
                    {giftSent ? t('giftSent') : t('giftToFriend')}
                </Typography>
                <IconButton onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {!giftSent ? (
                <>
                    <DialogContent dividers>
                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                            <img src="/img/gift_to_friend.webp" alt="Gift" style={{ width: '40%', height: 'auto' }} />
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                {t('enterEmailTwice')}
                            </Typography>
                        </Box>
                        <Stack spacing={2}>
                            <TextField
                                label={t('friendEmail')}
                                variant="outlined"
                                value={email1}
                                size="small"
                                onChange={(e) => setEmail1(e.target.value)}
                                onPaste={handlePaste}
                                fullWidth
                            />
                            <TextField
                                label={t('confirmFriendsEmail')}
                                variant="outlined"
                                value={email2}
                                size="small"
                                onChange={(e) => setEmail2(e.target.value)}
                                onPaste={handlePaste}
                                fullWidth
                            />
                            {error && (
                                <Typography color="error" variant="body2">
                                    {error}
                                </Typography>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button variant="contained" color="secondary" onClick={handleSendGift} fullWidth>
                            {t('sendGift')}
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent dividers>
                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                            <img src="/img/gift_sent.webp" alt="Gift Sent" style={{ width: '40%', height: 'auto' }} />
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                {t('giftSentMessage')}
                            </Typography>
                            <Typography variant="h6" sx={{ mt: 1 }}>
                                {email1} 
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button variant="contained" color="secondary" onClick={onClose} fullWidth>
                            {t('close')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default GiftToFriendModal;
