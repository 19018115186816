import * as React from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar, SnackbarContent } from 'notistack';
import { Box, Typography } from '@mui/material';

const NotificationSettings = () => {
  const { t } = useTranslation();
  const [notificationsEnabled, setNotificationsEnabled] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  const CustomSnackbarContent: React.FC<{ message: string }> = ({ message }) => (
    <SnackbarContent>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src="/img/180.png" width="32" height="32" alt="APP Notification" />
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle2">{t('Notification')}</Typography>
          <Typography variant="body2">{message}</Typography>
        </Box>
      </Box>
    </SnackbarContent>
  );

  const sendSubscriptionToBackend = (subscription: PushSubscription) => {
    fetch('/api/noty/subscribe', {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json();
      })
      .then(() => {
        enqueueSnackbar(<CustomSnackbarContent message={t('notificationsEnabled')} />);
      })
      .catch(error => {
        console.error('Error sending subscription to backend:', error);
        enqueueSnackbar(<CustomSnackbarContent message={t('notificationsEnableFail')} />);
      });
  };

  const removeSubscriptionFromBackend = (subscription: PushSubscription) => {
    fetch('/api/noty/unsubscribe', {
      method: 'POST',
      body: JSON.stringify({ endpoint: subscription.endpoint }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json();
      })
      .then(() => {
        enqueueSnackbar(<CustomSnackbarContent message={t('notificationsDisabled')} />);
      })
      .catch(error => {
        console.error('Error removing subscription from backend:', error);
        enqueueSnackbar(<CustomSnackbarContent message={t('notificationsDisableFail')} />);
      });
  };

  const handleNotificationSubscription = () => {
    if ('Notification' in window && navigator.serviceWorker) {
      if (Notification.permission === 'granted') {
        subscribeUser();
      } else if (Notification.permission === 'denied') {
        console.warn('Notification permission was denied.');
        enqueueSnackbar(<CustomSnackbarContent message={t('notificationsDenied')} />);
      } else {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            subscribeUser();
          } else {
            console.warn('Notification permission not granted.');
            enqueueSnackbar(<CustomSnackbarContent message={t('notificationsNoPermission')} />);
          }
        }).catch(error => {
          console.error('Notification permission request failed:', error);
          enqueueSnackbar(<CustomSnackbarContent message={t('notificationsPermissionFaild')} />);
        });
      }
    } else {
      console.warn('Push messaging is not supported.');
      enqueueSnackbar(<CustomSnackbarContent message={t('notificationsNotSupported')} />);
    }
  };

  const subscribeUser = () => {
    navigator.serviceWorker.ready.then(registration => {
      const applicationServerKey = urlBase64ToUint8Array('BNWwOoIWbWphxdc-_nBZY4sDLODSabrcX8o7glGkehvthm1Hvr6Jq30wHSovFuv5XIjIPH8-nmuEgMuj5bQOZhY');
      registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey
      }).then(subscription => {
        sendSubscriptionToBackend(subscription);
      }).catch(error => {
        console.error('Failed to subscribe the user:', error);
        enqueueSnackbar(<CustomSnackbarContent message={t('notificationsFailToSubscribe')} />);
      });
    }).catch(error => {
      console.error('Service worker not ready:', error);
      enqueueSnackbar(<CustomSnackbarContent message={t('serviceWorkerNotReady')} />);
    });
  };

  const unsubscribeUser = () => {
    navigator.serviceWorker.ready.then(registration => {
      registration.pushManager.getSubscription().then(subscription => {
        if (subscription) {
          subscription.unsubscribe().then(() => {
            removeSubscriptionFromBackend(subscription);
          }).catch(error => {
            console.error('Failed to unsubscribe the user:', error);
            enqueueSnackbar(<CustomSnackbarContent message={t('notificationsFailToUnsubscritbe')} />);
          });
        }
      }).catch(error => {
        console.error('Error getting subscription:', error);
        enqueueSnackbar(<CustomSnackbarContent message={t('notificationsSubscriptionErro')} />);
      });
    }).catch(error => {
      console.error('Service worker not ready:', error);
      enqueueSnackbar(<CustomSnackbarContent message={t('serviceWorkerNotReady')} />);
    });
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = event.target.checked;
    setNotificationsEnabled(enabled);
    if (enabled) {
      handleNotificationSubscription();
    } else {
      unsubscribeUser();
    }
  };

  return (
    <FormControlLabel
      sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}
      control={
        <Switch
          checked={notificationsEnabled}
          onChange={handleToggleChange}
          name="notificationsToggle"
          color="primary"
        />
      }
      label={t('browserNotifications')}
      labelPlacement="start"
    />
  );
}

export default NotificationSettings;
