import { FC, ReactElement } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { DropdownSection } from "./Components";
import { Button, List, ListItemIcon, MenuItem, Stack, Typography } from "@mui/material";
import { LocalFireDepartment } from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import PlatformLogo from "../../../icons/PlatformLogos";

interface ProductsDropdownProps {
  displayPlatforms: number[];
  groupTitle: string;
  groupIcon?: ReactElement;
  giftCards?: { title: string; platformId: number; type: number; imgId: string }[];
  title: string;
}

const ProductsDropdownEl = styled.div`
  width: 518px;
`;

const SubProductsList = styled.ul`
  li {
    display: flex;
    margin-bottom: 1rem;
  }
  h3 {
    margin-right: 1rem;
    width: 3.2rem;
    display: block;
  }
`;

const ProductsSection = styled.ul`
  li {
    display: flex;
  }
`;

const CheckTopSellers = styled.div`
  border-top: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  h3 {
    margin-bottom: 0;
  }
`;

const ProductsDropdown: FC<ProductsDropdownProps> = ({ displayPlatforms, groupTitle, groupIcon, giftCards, title }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);

  const handlePlatformClick = (platformId: number) => {
    const type = title === t('giftCards') ? 3 : 1;
    navigate(`/products?type=${type}&platform=${platformId}`);
  };

  const handleGiftCardClick = (platformId: number, typeId: number) => {
    navigate(`/products?platform=${platformId}&type=${typeId}`);
  };

  return (
    <ProductsDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <ProductsSection>
          <Stack direction="row" spacing={1} pl={2} alignItems={'center'} sx={{ color: 'text.secondary' }}>
            {groupIcon}
            <Typography variant="h6" component="p" sx={{ display: 'flex', alignItems: 'center' }} color="text.secondary">
              {groupTitle}
            </Typography>
          </Stack>

          <List sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {platforms
              .filter((platform) => displayPlatforms?.includes(parseInt(platform._id)) || displayPlatforms?.length === 0)
              .map((platform, index) => (
                <MenuItem key={index} sx={{ my: 1, mr: 1, minWidth: '223px' }} onClick={() => handlePlatformClick(parseInt(platform._id))}>
                  <ListItemIcon>
                    <PlatformLogo platformId={platform._id || '/path/to/default/image.svg'} sx={{ width: 24, height: 24, mr: 1 }} />
                  </ListItemIcon>
                  <Typography color="text.primary">{platform.name}</Typography>
                </MenuItem>
              ))}
          </List>
        </ProductsSection>
      </DropdownSection>
      <DropdownSection>
        {giftCards && giftCards.length > 0 && (
          <>
            <SubProductsList>
              <Typography variant="h6" component="p" sx={{ display: 'flex', alignItems: 'center', ml: '13px' }} color="text.secondary">
                {t('giftAndSubscriptionCards')}
              </Typography>
              <List sx={{ display: 'flex', flexWrap: 'wrap' }} component={'ul'}>
                {giftCards.map((card, index) => (
                  <MenuItem key={`${card.platformId}-${index}`} onClick={() => handleGiftCardClick(card.platformId, card.type)} sx={{ minWidth: '223px' }}>
                    <ListItemIcon>
                      <PlatformLogo platformId={card.imgId} sx={{ width: 24, height: 24, mr: 1 }} />
                    </ListItemIcon>
                    {card.title}
                  </MenuItem>
                ))}
              </List>
            </SubProductsList>
          </>
        )}
        <CheckTopSellers>
          <Button component={RouterLink} to="/products" variant="outlined"  startIcon={<LocalFireDepartment color="error" />}>
            {t('checkTopSellers')}
          </Button>
        </CheckTopSellers>
      </DropdownSection>
    </ProductsDropdownEl>
  );
};

export default ProductsDropdown;
