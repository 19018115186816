import { Grid2 as Grid, Card, CardHeader, Skeleton } from "@mui/material";

export const BestSellerGridLoading = () => {
  const placeholders = Array.from(new Array(6));

  return (
    <Grid container spacing={4}>
      <Grid size={12}>
        <Skeleton variant="text" width={290} height={80} animation="wave" sx={{ mb: 1 }} />
      </Grid>
      {placeholders.map((_, index) => (
        <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
          <Card>
            <Skeleton variant="rectangular" width="100%" height={180} animation="wave" />
            <CardHeader
              sx={{ minHeight: '102.23px' }}
              avatar={<Skeleton variant="circular" width={40} height={40} animation="wave" />}
              title={<Skeleton variant="text" width="60%" animation="wave" />}
              subheader={<Skeleton variant="text" width="40%" animation="wave" />}
              action={<Skeleton variant="text" width="30px" animation="wave" />}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
