import React from 'react';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useDispatch, useSelector } from 'react-redux';
import { googleLogin } from 'redux/auth/authThunks';
import { useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';

const GoogleLoginButton: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const { isGoogleLoading } = useSelector((state: RootState) => state.auth);
    const handleClick = () => {
        const redirectUrl = location.pathname === '/cart/login' ? '/cart/login' : '/login';

        dispatch(googleLogin({ redirectUrl }));
    };

    return (
        <Button
            onClick={handleClick}
            variant="outlined"
            color="primary"
            startIcon={<GoogleIcon />}
            disabled={isGoogleLoading}
        >
            Login with Google
        </Button>
    );
};

export default GoogleLoginButton;
