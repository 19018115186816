import React, { Component, ReactNode, ReactElement } from "react"
import styled from "styled-components"
import { Typography, MenuItem, ListItemIcon } from '@mui/material';
import { useMediaQuery } from '@mui/material';

const NavbarItemEl = styled.span`
  position: relative;
`

const DropdownSlot = styled.div`
  position: absolute;
  left: 50%;
  margin-top: 10px;
  transform: translateX(-50%);
  perspective: 1500px;
`

interface INavbarItemProps {
  onMouseEnter: (index: number) => void;
  title: string;
  titleHide?: boolean;
  icon: ReactElement | null;
  index: number;
  children?: ReactNode;
}

const NavbarItem = ({ onMouseEnter, title, titleHide, icon, index, children }: INavbarItemProps) => {
  const handleMouseEnter = () => {
    onMouseEnter(index)
  }

  // Use media query to check the viewport width
  const isHidden = useMediaQuery('(min-width: 900px) and (max-width: 1075px)');

  return (
    <NavbarItemEl onMouseEnter={handleMouseEnter} onFocus={handleMouseEnter}>
      <>
        {icon ? (
          <MenuItem sx={{ py: '6px', 
          px: '12px',
          mr: titleHide && isHidden ? 2 : 0,
          
          }} component="li">
            <ListItemIcon
              sx={{
                minWidth: titleHide && isHidden ? '24px !important' : '24px',
              }}
            >
              {icon}
            </ListItemIcon>
            {!titleHide || !isHidden ? (
              <Typography variant="body2" color="text.primary">
                {title}
              </Typography>
            ) : null}
          </MenuItem>
        ) : (
          <MenuItem sx={{ mt: '2px', py: '6px', px: '12px' }} component="li">
            {!titleHide || !isHidden ? (
              <Typography variant="body2" color="text.primary">
                {title}
              </Typography>
            ) : null}
          </MenuItem>
        )}
      </>
      <DropdownSlot>{children}</DropdownSlot>
    </NavbarItemEl>
  );
};

export default NavbarItem;
