import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface WarningModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({ open, onClose, onConfirm }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="warning-dialog-title">
            <DialogTitle id="warning-dialog-title">
                {t('warningModalTitle')}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" color="textPrimary">
                    {t('warningModalMessage1')}
                    <br />
                    {t('warningModalMessage2')}
                    <br /><br />
                    {t('doYouWantToProceed')}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', p: 3 }}>
                <Button onClick={onClose} color="primary" variant="outlined">
                    {t('back')}
                </Button>
                <Button onClick={onConfirm} color="secondary" variant="contained">
                    {t('revealKey')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WarningModal;
