import React from 'react';
import { IconButton, TextField, Stack, useTheme, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

interface QuantityControlProps {
  quantity: number;
  onAdd: () => void;
  onRemove: () => void;
  onDelete?: () => void;
  onQuantityChange: (newQuantity: number) => void;
  maxProductAllowed: number;
  size?: 'small' | 'medium' | 'large';
}

const QuantityControl: React.FC<QuantityControlProps> = ({ quantity, onAdd, onRemove, onDelete, onQuantityChange, maxProductAllowed = 3, size = 'small' }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newQuantity = parseInt(event.target.value, 10);
    if (isNaN(newQuantity) || newQuantity < 0) {
      newQuantity = 1;
    } else if (newQuantity > maxProductAllowed) {
      newQuantity = maxProductAllowed;
    }

    if (newQuantity === 0 && onDelete) {
      onDelete();
    } else {
      onQuantityChange(newQuantity);
    }
  };

  return (
    <Stack direction="row" spacing={0} alignItems={'center'} sx={{ backgroundColor: theme.palette.background.paper, borderRadius: '16px' }}>
      <IconButton size={size} onClick={quantity === 1 && onDelete ? onDelete : onRemove} sx={{ backgroundColor: theme.palette.background.default }}>
        {quantity === 1 && onDelete ? <DeleteIcon fontSize={size} /> : <RemoveIcon fontSize={size} />}
      </IconButton>
      <TextField
        value={quantity}
        onChange={handleChange}
        size="small"
        variant="outlined"
        sx={{
          width: '40px',
          minHeight: '34px !important',
          marginLeft: 0,
          '& .MuiInputBase-root': {
            backgroundColor: theme.palette.background.paper,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent !important',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
            backgroundColor: theme.palette.background.paper,
            border: 0,
          },
          '& .MuiInputBase-input': {
            textAlign: 'center',
            padding: '0',
          },
        }}
        slotProps={{
          input: {
            inputProps: {
              min: 0,
              max: maxProductAllowed,
            },
          },
        }}
      />
      
      <Tooltip title={quantity >= maxProductAllowed ? t('maxProductAllowed', { max: maxProductAllowed }) : ''} arrow>
      <span>
          <IconButton
            size={size}
            onClick={onAdd}
            disabled={quantity >= maxProductAllowed}
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <AddIcon fontSize={size} />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  );
};

export default QuantityControl;
