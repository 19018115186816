import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from 'redux/store';
import { fetchProductByIdAndPlatformId } from 'redux/product/productActions';
import { Product } from 'redux/product/ProductsTypes';

import { Button, Typography, Grid2 as Grid, Pagination } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ProductCardComponent from 'components/ProductCard';
import LoadingCard from './LoadingCard';

const FavoritePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const favorites = useSelector((state: RootState) => state.auth.user?.favorites);
  const language = useSelector((state: RootState) => state.productDetail.language);

  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 6;

  useEffect(() => {
    const fetchFavoriteProducts = async () => {
      setLoading(true);
      try {
        const fetchedProducts = await Promise.all(favorites?.map(async (favorite) => {
          const action = await dispatch(fetchProductByIdAndPlatformId({
            productId: favorite.productId,
            platformId: favorite.platformGroupId,
            lang: language,
          }));
          return unwrapResult(action);
        }) || []);
        setProducts(fetchedProducts);
        setError(null);
      } catch (err) {
        setError('Failed to fetch products');
      } finally {
        setLoading(false);
      }
    };

    if (favorites) {
      fetchFavoriteProducts();
    }
  }, [dispatch, favorites, language]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  if (!favorites || favorites.length === 0) {
    return (
      <Grid size={{ xs: 12 }} alignItems={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'82vh'}>
        <Typography variant="h4" align="center" >
          {t('noFavorites')}
        </Typography>
        <Typography variant="h6" align="center">
          {t('noFavoritesText')}
        </Typography>
        <Button
          sx={{ mt: 5 }}
          startIcon={<ArrowBackRoundedIcon />}
          variant="contained"
          color="secondary"
          component={RouterLink}
          to="/products">
          {t('exploreCatalog')}
        </Button>
      </Grid>
    );
  }

  return (
    <Grid container spacing={4} height={'100%'}>
      {loading && favorites.map((favorite) => (
        <Grid size={{ xs: 12, md: 6 }} key={favorite.productId}>
          <LoadingCard />
        </Grid>
      ))}
      {error && <Typography color="error">{error}</Typography>}
      {currentProducts.length > 0 && currentProducts.map((product) => (
        <Grid size={{ xs: 12, md: 6 }}  key={product._id}>
          <ProductCardComponent
            withFav
            title={product.title}
            price={product.price}
            category={product.category}
            platform={product.platformId ?? ''}
            productId={product.productId ?? ''}
            productPlatformId={product._id ?? ''}
            platformImageUrl={product.platformId ?? ''}
            region={product.region}
            type={product.type}
            regionId={product.region}
          />
        </Grid>
      ))}
      {products.length > productsPerPage && (
        <Grid size={{ xs: 12 }}  display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(products.length / productsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="secondary"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FavoritePage;
