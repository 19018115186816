import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { Box, Container, IconButton, Link, Typography, Stack } from '@mui/material';
import { Grid2 as Grid, Link as MaterialLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import Logo from 'components/Logo';

import LanguageSwitcher from 'components/LanguageSwitcher';
import AvailablePayments from 'components/AvailablePayments';
import EmailSubscribe from './EmailSubscribe';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://bigswiss.ch/">Bigswiss&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const userId = user ? user._id : undefined;

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Container
      sx={{
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Grid container spacing={3} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <LanguageSwitcher />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <AvailablePayments />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Logo sx={{ mb: '1rem' }} />
            <Typography variant="body2" fontWeight={600} sx={{ my: 2 }}>
              {t('newsletter')}
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              {t('subscribeMessage')}
            </Typography>
            <EmailSubscribe userId={userId} />
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            {t('products')}
          </Typography>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/products?category=pc&type=1"
          >
            {t('pcGames')}
          </MaterialLink>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/products?category=xb&type=1"
          >
            {t('xboxGames')}
          </MaterialLink>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/products?category=ps&type=1"
          >
            {t('playstationGames')}
          </MaterialLink>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/products?category=nt&type=1"
          >
            {t('nintendoGames')}
          </MaterialLink>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/products?type=3"
          >
            {t('giftCards')}
          </MaterialLink>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/products?type=4"
          >
            {t('subscriptionCards')}
          </MaterialLink>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
            mx: 5,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            {t('company')}
          </Typography>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/about"
          >
            {t('aboutUs')}
          </MaterialLink>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/about#products"
            onClick={() => scrollToSection('products')}
          >
            {t('products')}
          </MaterialLink>
          {/* <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/about#testimonials"
            onClick={() => scrollToSection('testimonials')}
          >
            {t('testimonials')}
          </MaterialLink> */}
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/about#highlights"
            onClick={() => scrollToSection('highlights')}
          >
            {t('highlights')}
          </MaterialLink>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/about#pricing"
            onClick={() => scrollToSection('pricing')}
          >
            {t('pricing')}
          </MaterialLink>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/about#faqs"
            onClick={() => scrollToSection('faq')}
          >
            {t('faqs')}
          </MaterialLink>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            {t('getInTouch')}
          </Typography>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/careers"
          >
            {t('careers')}
          </MaterialLink>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/contactus"
          >
            {t('contactUs')}
          </MaterialLink>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/privacy"
          >
            {t('privacyPolicy')}
          </MaterialLink>
          <Typography
            display="inline"
            sx={{ mx: 0.5, opacity: 0.5, display: { xs: 'none', sm: 'block' } }}
          >
            &nbsp;•&nbsp;
          </Typography>
          <MaterialLink
            color="text.secondary"
            component={RouterLink}
            to="/terms"
          >
            {t('termsOfService')}
          </MaterialLink>
        </Box>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href="https://github.com/Bigswiss-GmbH"
            target="_blank" rel="noopener noreferrer"
            aria-label="GitHub"
            sx={{ alignSelf: 'center' }}
          >
            <GitHubIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://x.com/bigswiss_gmbh"
            target="_blank" rel="noopener noreferrer"
            aria-label="x"
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/bigswiss-gmbh/"
            target="_blank" rel="noopener noreferrer"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
      <Copyright />
    </Container>
  );
}
