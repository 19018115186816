import React, { useState, useEffect } from 'react';
import { Typography, Button, Box, Stepper, Step, StepLabel, Paper, Card, CardActionArea, CardContent, Grid2 as Grid } from '@mui/material';
import ChromeIcon from 'components/icons/Browsers/ChromeIcon';
import SafariIcon from 'components/icons/Browsers/SafariIcon';
import BraveIcon from 'components/icons/Browsers/BraveIcon';
import OperaIcon from 'components/icons/Browsers/OperaIcon';
import IEIcon from 'components/icons/Browsers/IEIcon';
import BrowserIcon from '@mui/icons-material/OpenInBrowser';
import FirefoxIcon from 'components/icons/Browsers/FirefoxIcon';
import { useTranslation } from 'react-i18next';

interface StepOption {
    title: string;
    icon: React.ReactNode;
    method: string;
}

interface StepData {
    label: string;
    description: string;
    imageUrl?: string;
    actionButton?: React.ReactNode;
    options?: StepOption[];
}

interface ActivationGuideProps {
    steps: { [key: string]: StepData[] };
}

const ActivationGuide: React.FC<ActivationGuideProps> = ({ steps }) => {
    const { t } = useTranslation(); 
    const [activeStep, setActiveStep] = useState(0);
    const [selectedMethod, setSelectedMethod] = useState<string>('');
    const [browser, setBrowser] = useState('Browser');

    useEffect(() => {
        setBrowser(detectBrowser());
    }, []);

    const detectBrowser = () => {
        const userAgent = navigator.userAgent;

        if (navigator.brave) {
            return 'Brave';
        } else if (userAgent.includes('Firefox')) {
            return 'Firefox';
        } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
            return 'Chrome';
        } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
            return 'Safari';
        } else if (userAgent.includes('OPR') || userAgent.includes('Opera')) {
            return 'Opera';
        } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
            return 'Internet Explorer';
        } else {
            return 'Browser';
        }
    };

    const getBrowserIcon = (): React.ReactNode => {
        switch (browser) {
            case 'Chrome':
                return <ChromeIcon sx={{ width: '64px', height: '64px' }} />;
            case 'Safari':
                return <SafariIcon sx={{ width: '64px', height: '64px' }} />;
            case 'Brave':
                return <BraveIcon sx={{ width: '64px', height: '64px' }} />;
            case 'Opera':
                return <OperaIcon sx={{ width: '64px', height: '64px' }} />;
            case 'Internet Explorer':
                return <IEIcon sx={{ width: '64px', height: '64px' }} />;
            case 'Firefox':
                return <FirefoxIcon sx={{ width: '64px', height: '64px' }} />;
            default:
                return <BrowserIcon sx={{ width: '64px', height: '64px' }} />;
        }
    };

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 1) {
            setSelectedMethod('');
        }
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleOptionClick = (method: string) => {
        setSelectedMethod(method);
        setActiveStep(1);
    };

    const currentSteps = selectedMethod ? steps[selectedMethod] : steps['default'];

    const keylessAvailable = Boolean(steps['keyless']);
    const browserAvailable = Boolean(steps['browser']);
    const appAvailable = Boolean(steps['app']);

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {currentSteps.map((step: StepData, index: number) => (
                    <Step key={index}>
                        <StepLabel>{t(step.label)}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={3} sx={{ p: 3, mt: 2, textAlign: 'center', height: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box sx={{ height: '100px' }}>
                    <Typography variant="h6" gutterBottom>
                        {t(currentSteps[activeStep].label)}
                    </Typography>
                    <Typography variant="body2" sx={{ height: '' }}>
                        {t(currentSteps[activeStep].description)}
                    </Typography>
                </Box>

                {activeStep === 0 && (
                    <Grid container spacing={8} justifyContent="center" sx={{ marginTop: '-100px' }}>
                        {currentSteps[0].options?.map((option: StepOption) => {
                            const isDisabled = (option.method === 'keyless' && !keylessAvailable) ||
                                (option.method === 'browser' && !browserAvailable) ||
                                (option.method === 'app' && !appAvailable);

                            return (
                                <Grid size={{ xs: 12, sm: 4 }} key={option.title}>
                                    <Card>
                                        <CardActionArea
                                            sx={{ py: 3 }}
                                            onClick={() => handleOptionClick(option.method)}
                                            disabled={isDisabled}
                                        >
                                            <CardContent sx={{ flexDirection: 'column', alignItems: 'center', display: 'flex' }}>
                                                <Box sx={{ width: '48px', height: '48px', mb: 3, opacity: isDisabled ? 0.5 : 1 }}>
                                                    {option.method === 'browser' ? getBrowserIcon() : option.icon}
                                                </Box>
                                                <Typography variant="h6" sx={{ opacity: isDisabled ? 0.5 : 1 }}>
                                                    {t(option.method === 'browser' ? browser : option.title)}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}

                {activeStep > 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '430px',
                            pb: 3,
                        }}
                    >
                        <img
                            src={currentSteps[activeStep].imageUrl}
                            alt={t(`Step ${activeStep + 1}`)}
                            style={{
                                maxHeight: '100%',
                                maxWidth: '100%',
                                objectFit: 'contain',
                                borderRadius: 4,
                            }}
                        />
                    </Box>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button variant="contained" color="primary" disabled={activeStep === 0} onClick={handleBack}>
                        {t('back')}
                    </Button>

                    {currentSteps[activeStep].actionButton && <Box>{currentSteps[activeStep].actionButton}</Box>}

                    <Button variant="contained" color="primary" onClick={handleNext} disabled={activeStep === currentSteps.length - 1}>
                        {activeStep === currentSteps.length - 1 ? t('finish') : t('next')}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default ActivationGuide;
