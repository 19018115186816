import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosGetToken from '../auth/axiosGetToken';

export const activateKey = createAsyncThunk(
    'keys/activateKey',
    async ({ keyId, invoiceId }: { keyId: string; invoiceId: string }, { rejectWithValue }) => {
        try {
            const response = await axiosGetToken.post('/users/key-activation', { keyId, invoiceId });

            return response.data.key;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.error || 'Failed to activate key');
        }
    }
);