import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';
import { Stepper, Step, StepLabel, Box, Container } from '@mui/material';

import CartItems from 'components/Cart/CartItems';
import Payment from './Payment';
import PaymentStatus from './PaymentStatus';
import GameActivation from './GameActivation';
import LoginForm from '../LoginPage/LoginForm';
import EmptyCart from 'components/Cart/EmptyCart';
import ProtectedRoute from 'ProtectedRoutes';
const CartPage: React.FC = () => {
    const { t } = useTranslation();
    const cartItems = useSelector((state: RootState) => state.cart.items);
    const user = useSelector((state: RootState) => state.auth.user);
    const navigate = useNavigate();
    const location = useLocation();
    const urlSteps = user ? ['cart-items', 'payment', 'game-activation'] : ['cart-items', 'login', 'payment', 'game-activation'];
    const translatedSteps = user ? [t('cartItems'), t('payment'), t('gameActivation')] : [t('cartItems'), t('login'), t('payment'), t('gameActivation')];
    const isGameActivationPage = location.pathname.includes('/game-activation');

    // Hook to handle redirection after user login
    useEffect(() => {
        if (user && location.pathname === '/cart/login') {
            navigate('/cart/payment', { replace: true });
        }
    }, [user, location.pathname, navigate]);

    // Hook to determine active step
    const determineActiveStep = () => {
        const path = location.pathname;
        if (path.includes('/game-activation')) {
            return urlSteps.indexOf('game-activation');
        } else if (path.includes('/payment')) {
            return urlSteps.indexOf('payment');
        } else if (path.includes('/login')) {
            return urlSteps.indexOf('login');
        }
        return urlSteps.indexOf('cart-items');
    };

    const activeStep = determineActiveStep();

    // Navigation handlers
    const handleNext = () => {
        const nextStep = urlSteps[activeStep + 1];
        navigate(`/cart/${nextStep}`);
    };

    const handleBack = () => {
        if (location.pathname.includes('/payment')) {
            navigate('/cart', { replace: true });
        } else if (activeStep > 0) {
            const prevStep = urlSteps[activeStep - 1];
            navigate(`/cart/${prevStep}`, { replace: true });
        }
    };

    // Ensure hooks are called before any early return
    if (cartItems.length === 0 && !isGameActivationPage) {
        return <EmptyCart />;
    }

    return (
        <Container sx={{ alignItems: 'center', pt: { xs: 14, sm: 15 }, pb: { xs: 8, sm: 10 } }}>
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
                    {translatedSteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Routes>

                    <Route path="/" element={<CartItems onProcessToPayment={handleNext} />} />
                    {!user && <Route path="/login" element={<LoginForm />} />}
                    <Route path="/payment" element={
                        <ProtectedRoute user={user}>
                            <Payment onBackToCart={handleBack} />
                        </ProtectedRoute>
                    } />
                    <Route path="/payment-status" element={
                        <ProtectedRoute user={user}>
                            <PaymentStatus />
                        </ProtectedRoute>
                    } />
                    <Route path="/game-activation" element={
                        <ProtectedRoute user={user}>
                            <GameActivation />
                        </ProtectedRoute>
                    } />
                </Routes>
            </Box>
        </Container>
    );
};

export default CartPage;
