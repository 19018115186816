import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
const NoDataFound = ({ text, ...rest }: { text: string, [key: string]: any }) => {
  return (
    <Grid container spacing={3} py={25}>
      <Grid size={12} display="flex" flexDirection="column" alignItems="center" gap="23px">
        <Typography variant="h3" {...rest}>{text}</Typography>
        <Typography variant="h6">Please try to search for a product.</Typography>
        <Button variant="contained" component={RouterLink} to="/products" color="primary">
          Search Products
        </Button>
      </Grid>
    </Grid>
  );
};

export default NoDataFound;
