import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SiteTitle from 'components/SiteTitle';
import JobsContactForm from './JobsContactForm';

export default function CareersPage() {
    const { t } = useTranslation();

    return (
        <Container
            sx={{
                alignItems: 'center',
                pt: { xs: 14, sm: 20 },
                pb: { xs: 8, sm: 12 },
            }}
        >
            <Box
                id="image"
                sx={(theme) => ({
                    mb: { xs: 8, sm: 10 },
                    alignSelf: 'center',
                    height: { xs: 200, sm: 300 },
                    width: '100%',
                    backgroundImage: 'url("/img/careers.webp")',
                    backgroundSize: 'cover',
                    backgroundPositionY: 'center',
                    borderRadius: '10px',
                    outline: '1px solid',
                    ...theme.applyStyles('light', {
                        outlineColor: alpha('#BFCCD9', 0.5),
                        boxShadow: `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`,
                    }),
                    ...theme.applyStyles('dark', {
                        outlineColor: alpha('#9CCCFC', 0.1),
                        boxShadow: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    }),
                })}
            />

            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>

                <SiteTitle firstTitle="Bigswiss" secondTitle={t("Jobs")} variant="h1" color="primary" position='center' display='flex' />

                <Typography
                    textAlign="center"
                    color="text.secondary"
                    sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
                >
                    {t("ElevateYourCareer")}
                    <br />
                    <br />
                    {t("exploreOpportunities")}
                </Typography>

                <JobsContactForm />

                <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
                    {t("contactUsAgree")}&nbsp;
                    <Link href="#" color="primary">
                        {t("termsOfService")}
                    </Link>
                    .
                </Typography>
            </Stack>

        </Container>
    );
}
