import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import ClearIcon from '@mui/icons-material/Clear';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const iconSize = 55;

const shapes = [
    <ChangeHistoryIcon key="triangle" sx={{ color: '#00AC9F', fontSize: iconSize }} />,
    <CropSquareIcon key="square" sx={{ color: '#F3C300', fontSize: iconSize }} />,
    <PanoramaFishEyeIcon key="circle" sx={{ color: '#DF0024', fontSize: iconSize }} />,
    <ClearIcon key="cross" sx={{ color: '#2E6DB4', fontSize: iconSize }} />
];

const texts = [
    '▲ ■ ● ✖',
    '404',
    'X Y A B',
    '404',
    'X Y B A',
    '404',
    'W A S D',
    '404'
];

const colorMap: { [key: string]: string } = {
    X: '#066ed8',
    Y: '#ff8b06',
    A: '#92a136',
    B: '#e30f0d' 
};

const NotFoundPage: React.FC = () => {
    const { t } = useTranslation();
    const [text, setText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(200);

    useEffect(() => {
        const handleTyping = () => {
            const currentIndex = loopNum % texts.length;
            const fullText = texts[currentIndex];
            const updatedText = isDeleting
                ? fullText.substring(0, text.length - 1)
                : fullText.substring(0, text.length + 1);

            setText(updatedText);

            if (!isDeleting && updatedText === fullText) {
                setTimeout(() => setIsDeleting(true), 1800);
                setTypingSpeed(50);
            } else if (isDeleting && updatedText === '') {
                setIsDeleting(false);
                setTypingSpeed(100);
                setLoopNum(loopNum + 1);
            }
        };

        const typingTimer = setTimeout(handleTyping, typingSpeed);
        return () => clearTimeout(typingTimer);
    }, [text, isDeleting, typingSpeed, loopNum]);

    const getShapeIcons = () => {
        const currentLength = text.length;
        return shapes.slice(0, currentLength).map((shape, index) => (
            <Box key={index} component="span" sx={{ mx: 0.5 }}>
                {shape}
            </Box>
        ));
    };

    const getColoredText = () => {
        if (texts[loopNum % texts.length] === 'X Y A B' || texts[loopNum % texts.length] === 'X Y B A') {
            return text.split('').map((char, index) => (
                <Box
                    key={index}
                    component="span"
                    sx={{ color: colorMap[char] || 'inherit', transition: 'opacity 0.6s', fontSize: 'inherit' }}
                >
                    {char}
                </Box>
            ));
        }
        return text;
    };

    return (

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Box className="circles">
                <Box className="circle"></Box>
                <Box className="circle"></Box>
                <Box className="circle"></Box>
                <Box className="circle"></Box>
            </Box>
            <Box sx={{ position: 'absolute', zIndex: 10, textAlign: 'center' }}>
                <Typography
                    variant="h2"
                    className="typing-effect"
                    sx={{ transition: 'opacity 0.6s', fontWeight: 900 }}
                >
                    {texts[loopNum % texts.length] === '▲ ■ ● ✖' ? getShapeIcons() : getColoredText()}
                </Typography>
            </Box>
            <Box sx={{ position: 'absolute', mt: '380px', zIndex: 10, textAlign: 'center' }}>
                <Typography variant='h2'>
                    {t('PageNotFound')}
                </Typography>
                <Typography variant='h5'>
                    {t('DontGiveUp')}
                </Typography>
                <Button
                    sx={{ mt: 5 }}
                    startIcon={<ArrowBackRoundedIcon />}
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to="/products">
                    {t('exploreCatalog')}
                </Button>
            </Box>
        </Box>

    );
};

export default NotFoundPage;
