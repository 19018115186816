import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    const scrollToSection = (sectionId: string) => {
      if (sectionId === 'top') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        return;
      }

      const sectionElement = document.getElementById(sectionId);
      const offset = 128;
      if (sectionElement) {
        const targetScroll = sectionElement.offsetTop - offset;
        sectionElement.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
          top: targetScroll,
          behavior: 'smooth',
        });
      }
    };

    if (hash) {
      scrollToSection(hash.substring(1));
    } else {
      scrollToSection('top');
    }
  }, [pathname, hash]);
};

export default useScrollToTop;
