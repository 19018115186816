import React, { Component, ReactNode } from "react"
import styled from "styled-components"

const NavbarEl = styled.nav`
    margin: auto;
`

const NavbarList = styled.ul`
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
`

interface INavbarProps {
    children?: ReactNode;
    onMouseLeave?: () => void;
}

class Navbar extends Component<INavbarProps> {
    render() {
        const { children, onMouseLeave } = this.props
        return (
            <NavbarEl onMouseLeave={onMouseLeave}>
                <NavbarList role="menu">{children}</NavbarList>
            </NavbarEl>
        )
    }
}

export default Navbar