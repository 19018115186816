import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, TextField, Button } from '@mui/material';
import axios from 'axios';
import useSnackbarAlert from 'utils/useSnackbarAlert';

const JobContactForm: React.FC = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const showAlert = useSnackbarAlert();

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleJobInquiry = async () => {
        if (!validateEmail(email)) {
            showAlert(t("invalidEmail"), 'error');
            return;
        }

        setLoading(true);

        try {
            await axios.post('/api/mail/jobs', { email });
            setSuccess(true);
            setEmail('');
            showAlert(t("jobThankYou"), 'success');
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.data === 'duplicate_application') {
                showAlert(t("duplicateApplication"), 'error');
            } else {
                console.error('Job contact submission failed', error);
                showAlert(t("contactFailed"), 'error');
            }
        } finally {
            setLoading(false);
        }
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
        >
            <TextField
                id="job-email-input"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                value={email}
                disabled={loading || success}
                onChange={handleEmailChange}
                placeholder={t("yourEmailAddress")}
                aria-label={t("enterYourEmailAddress")}
                slotProps={{
                    input: {
                        autoComplete: 'off',
                    },
                }}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleJobInquiry}
                disabled={loading || success}
                sx={{ whiteSpace: 'nowrap', minWidth: 'auto', px: 2 }}
            >
                {success ? t("contacted") : t("contactUs")}
            </Button>
        </Stack>
    );
};

export default JobContactForm;
