import React from 'react';
import { Container, Grid2 as Grid, useMediaQuery, useTheme } from '@mui/material';
import RegisterForm from './RegisterForm';
import CoverComponent from './CoverComponent';

const RegisterPage: React.FC = () => {
  const theme = useTheme();
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
       <Grid container spacing={3} sx={{ width: '100%'}}>
        <Grid size={{ xs: 12, md: 6 }}>
          <RegisterForm />
        </Grid>
        {isMediumOrLarger && (
          <Grid size={{ xs: 12, md: 6 }}>
            <CoverComponent />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default RegisterPage;
