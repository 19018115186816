import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';

import BoltIcon from '@mui/icons-material/Bolt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { styled } from '@mui/system';

const FeatureIcon = styled(Box)(({ theme }: { theme: any }) => ({
    borderRadius: '50%',
    width: theme.spacing(8),
    height: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.shadows[3],
    marginBottom: theme.spacing(2),
    '&:hover': {
        boxShadow: theme.shadows[8],
        transform: 'translateY(-5px)',
        transition: '0.3s',
    },
}));

const FeatureText: React.FC<{ children: string }> = ({ children }) => {
    const theme = useTheme();
    return (
        <Typography
            variant="h6"
            component="p"
            sx={{ color: theme.palette.text.primary }}
        >
            {children}
        </Typography>
    );
};

const WhyUs: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Box py={5} textAlign="center">
            <Grid container spacing={4} justifyContent="center">
                {[{
                    Icon: BoltIcon,
                    title: t('instantDeliveryTitle'),
                    description: t('instantDeliveryDescription')
                }, {
                    Icon: CloudDownloadIcon,
                    title: t('digitalDownloadTitle'),
                    description: t('digitalDownloadDescription')
                }, {
                    Icon: SupportAgentIcon,
                    title: t('customerSupportTitle'),
                    description: t('customerSupportDescription')
                }].map((feature, index) => (
                    <Grid size={{ xs:12, sm:6, md:4 }}  key={index} alignItems={'center'}>
                        <Box display="flex" flexDirection="column" alignItems="center" height="100%">
                            <FeatureIcon bgcolor={theme.palette.primary.main}>
                                <feature.Icon fontSize="large" htmlColor={theme.palette.common.white} />
                            </FeatureIcon>
                            <FeatureText>
                                {feature.title}
                            </FeatureText>
                            <Typography color="text.secondary">
                                {feature.description}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default WhyUs;
