import axios from 'axios';
import Cookies from 'js-cookie';
import { store } from '../store';
import { clearAuthState } from '../auth/authSlice';

const axiosGetToken = axios.create({
  baseURL: '/api',
  withCredentials: true,
});

let isLoggingOut = false;

axiosGetToken.interceptors.request.use(
  (config) => {
    const csrfToken = Cookies.get('XSRF-TOKEN');
    if (csrfToken) {
      config.headers['X-CSRF-Token'] = csrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosGetToken.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      if ((status === 401 || (status === 403 && data?.code === 'EBADCSRFTOKEN')) && !isLoggingOut) {
        isLoggingOut = true;
        store.dispatch(clearAuthState());
        isLoggingOut = false; 
      }
    }
    return Promise.reject(error);
  }
);

export default axiosGetToken;
