import React from 'react';
import { useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const XboxJoystickIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon {...props} viewBox="0 0 209 209">
      <path d="M117.873 176H139.621C159.59 176 176 159.59 176 139.621V70.3786C176 50.4099 159.59 34 139.621 34H117.434C116.995 34 116.533 34.4393 116.533 34.8786V175.121C116.533 175.561 116.972 176 117.873 176ZM144.938 97.9044C152.934 97.9044 159.129 104.561 159.129 112.096C159.129 120.092 152.472 126.287 144.938 126.287C136.941 126.287 130.746 120.092 130.746 112.096C130.285 104.121 136.941 97.9044 144.938 97.9044ZM101.441 34H70.3786C50.4099 34 34 50.4099 34 70.3786V139.621C34 159.59 50.4099 176 70.3786 176H101.441C101.88 176 102.342 175.561 102.342 175.121V34.8786C102.342 34.4393 101.903 34 101.441 34ZM91.2482 164.467H70.3786C56.6268 164.467 45.5331 153.373 45.5331 139.621V70.3786C45.5331 56.6268 56.6268 45.5331 70.3786 45.5331H90.8089L91.2482 164.467ZM56.1875 76.5956C56.1875 84.1304 61.9651 89.9081 69.5 89.9081C77.0349 89.9081 82.8125 84.1304 82.8125 76.5956C82.8125 69.0607 77.0349 63.2831 69.5 63.2831C61.9651 63.2831 56.1875 69.0607 56.1875 76.5956Z" fill={theme.palette.text.primary} />
    </SvgIcon>
  );
}

export default XboxJoystickIcon;