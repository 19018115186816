import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MicosoftIcon = (props: SvgIconProps) => {

  return (
      <SvgIcon
      {...props}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
    >
      <g clipPath="url(#clip0_275_91)">
        <path d="M23.844 0H-0.033844V23.8778H23.844V0Z" fill="#D03F42" />
        <path d="M23.844 6.71729H6.68344V23.8778H23.844V6.71729Z" fill="#DA763D" />
        <path d="M23.8615 12.35H12.3162V23.8953H23.8615V12.35Z" fill="#E39735" />
        <path d="M49.9969 23.8853L49.9969 0.00743103L26.119 0.00743103L26.119 23.8853L49.9969 23.8853Z" fill="#7AB951" />
        <path d="M43.2796 23.8939V6.73338L26.119 6.73338L26.119 23.8939L43.2796 23.8939Z" fill="#91C24F" />
        <path d="M37.6469 23.902V12.3567L26.1015 12.3567V23.902H37.6469Z" fill="#C5D743" />
        <path d="M26.1116 50H49.9894V26.1222L26.1116 26.1222L26.1116 50Z" fill="#ECB842" />
        <path d="M26.1029 43.2741H43.2635V26.1135L26.1029 26.1135L26.1029 43.2741Z" fill="#F3D332" />
        <path d="M26.0948 37.6507H37.6402V26.1054H26.0948V37.6507Z" fill="#F8E67A" />
        <path d="M-0.0410156 26.115L-0.0410156 49.9928H23.8368V26.115H-0.0410156Z" fill="#3B68AC" />
        <path d="M6.68492 26.115L6.68492 43.2755H23.8455V26.115H6.68492Z" fill="#467EBD" />
        <path d="M12.3083 26.0975V37.6428H23.8536V26.0975H12.3083Z" fill="#84ACDD" />
      </g>
      <defs>
        <clipPath id="clip0_275_91">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default MicosoftIcon;