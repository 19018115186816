// vatRates.tsx
const vatRates: { [countryCode: string]: number } = {
    AT: 20,  // Austria
    BE: 21,  // Belgium
    BG: 20,  // Bulgaria
    CY: 19,  // Cyprus
    CZ: 21,  // Czech Republic
    DE: 19,  // Germany
    DK: 25,  // Denmark
    EE: 20,  // Estonia
    ES: 21,  // Spain
    FI: 24,  // Finland
    FR: 20,  // France
    GR: 24,  // Greece
    HR: 25,  // Croatia
    HU: 27,  // Hungary
    IE: 23,  // Ireland
    IT: 22,  // Italy
    LT: 21,  // Lithuania
    LU: 17,  // Luxembourg
    LV: 21,  // Latvia
    MT: 18,  // Malta
    NL: 21,  // Netherlands
    PL: 23,  // Poland
    PT: 23,  // Portugal
    RO: 19,  // Romania
    SE: 25,  // Sweden
    SI: 22,  // Slovenia
    SK: 20,  // Slovakia
    CH: 7.7, // Switzerland
};

export default vatRates;
