import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import settingsReducer from './reducers/settingsReducer';
import productReducer from './product/productSlice';
import detailReducer from './reducers/detailReducer';
import creditDetailReducer from './reducers/creditDetailReducer';
import productInfoReducer from './reducers/productInfoReducer';
import searchReducer from './reducers/searchReducer';
import authReducer from './auth/authSlice';
import cartReducer from './reducers/cartReducer';
import paymentReducer from './payment/paymentReducer';
import applicationReducer from './services/applicationReducer';
import keyReducer from './key/keysReducer';

// Define the app version (can be manually updated during new releases)
const CURRENT_VERSION = 3;

const rootReducer = combineReducers({
  settings: settingsReducer,
  product: productReducer,
  productDetail: detailReducer,
  creditDetail: creditDetailReducer,
  productInfo: productInfoReducer,
  search: searchReducer,
  keys: keyReducer,
  auth: authReducer,
  cart: cartReducer,
  payment: paymentReducer,
  application: applicationReducer,
});

const persistConfig = {
  key: 'root',
  version: CURRENT_VERSION,
  storage,
  whitelist: ['auth', 'cart', 'payment', 'application'],
  migrate: async (state: any) => {
    const storedVersion = state?._persist?.version || 0;
    if (storedVersion < CURRENT_VERSION) {
      console.log('Version mismatch, purging store...');
      return Promise.resolve(undefined); // Purge the store
    }
    return Promise.resolve(state); // Keep the state if the version is up-to-date
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const isDevelopment = import.meta.env.MODE === 'development';

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'auth/logout/fulfilled', 'auth/logout/rejected', 'auth/logout/pending'],
      },
    }),
  devTools: isDevelopment,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
