import React, { useState } from 'react';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon, Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom'; 
import { useDispatch } from 'react-redux';
import { setSearchQuery } from 'redux/product/productActions';

interface SearchComponentProps {
    searchActive: boolean;
    setSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchComponent: React.FC<SearchComponentProps> = ({ searchActive, setSearchActive }) => {
    const [searchQuery, setSearchQueryLocal] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = event.target.value;
        setSearchQueryLocal(newQuery);
        dispatch(setSearchQuery(newQuery));
        navigate(`/products?search=${encodeURIComponent(newQuery)}`);
    };

    return (
        <>
            {searchActive ? (
                <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', width: '100%' }}>
                    <TextField
                        variant="standard"
                        size="small"
                        placeholder="Search Product…"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{
                            flexGrow: 1,
                            '& .MuiInputBase-root': {
                                backgroundColor: 'transparent'
                            },
                            '& .MuiInput-input': {
                                backgroundColor: 'transparent'
                            }
                        }}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />

                    <Button
                        variant="text"
                        aria-label="close"
                        sx={{ minWidth: '30px', p: '4px', color: 'text.primary' }}
                        onClick={() => setSearchActive(false)}
                    >
                        <CloseIcon />
                    </Button>
                </Box>
            ) : (
                <Button
                    variant="text"
                    aria-label="search"
                    sx={{ minWidth: '30px', p: '4px', color: 'text.primary' }}
                    onClick={() => setSearchActive(true)}
                >
                    <SearchIcon />
                </Button>
            )}
        </>
    );
};

export default SearchComponent;