import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import AppHeader from './Header';
import { withTranslation } from 'react-i18next';

const AppHeaderWrapper: React.FC<{ $duration: number }> = ({ $duration }) => {
  const isOnline = useSelector((state: RootState) => state.application.isOnline);
  const TranslatedAppHeader = withTranslation()(AppHeader);
  return <TranslatedAppHeader isOnline={isOnline} $duration={$duration} />;
};

export default AppHeaderWrapper;
