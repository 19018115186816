import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { RootState } from 'redux/store';

interface ProductPriceTagProps {
  productTagPrice: string;
  productType?: string;
  productRegionId?: string;
  currencyPadding?: string;
  variant: Variant | 'inherit';
  color?: string | 'text.primary';
}

const ProductPriceTag: React.FC<ProductPriceTagProps> = ({
  productTagPrice,
  productType,
  productRegionId,
  currencyPadding = '2px',
  variant,
  color
}) => {
  const selectedCurrency = useSelector((state: RootState) => state.application.selectedCurrency);
  const regions = useSelector((state: RootState) => state.productInfo.region.regions);

  const getRegionSymbol = (regionId: string) => {
    const region = regions.find(r => r.shortname === regionId);
    return region ? region.symbol : selectedCurrency.toUpperCase();
  };

  const currencySymbol = productType === '3' && productRegionId ? getRegionSymbol(productRegionId) : selectedCurrency.toUpperCase();

  return (
    <Typography component="span" variant={variant} color={color} sx={{ display: "flex" }}>
      {productTagPrice}
      <Typography component='span' sx={{ fontSize: 'small', pt: currencyPadding }}>
        {currencySymbol}
      </Typography>
    </Typography>
  );
};

export default ProductPriceTag;
