import styled from "styled-components"

interface IHeadingProps {
  noMarginBottom?: boolean;
  color?: string;
}

export const Heading = styled.h3<IHeadingProps>`
  margin-top: 0;
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? 0 : "1rem")};
  color: ${({ color }) => (color ? `var(--${color})` : "var(--blue)")};
`

export const HeadingLink = Heading as typeof Heading & { withComponent: (tag: "li") => typeof Heading }

interface ILinkListProps {
  marginLeft?: string;
}

export const LinkList = styled.ul<ILinkListProps>`
  li {
    margin-bottom: 1rem;
  }

  li:last-of-type {
    margin-bottom: 0;
  }
  
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 0)};
`

export const Icon = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 13px;
  background-color: var(--blue);
  opacity: 0.8;
  display: inline-block;
`

export const DropdownSection = styled.div`
  padding: var(--spacer);
  position: relative;
  z-index: 1;
`