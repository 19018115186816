import React from 'react';
import { Stack } from '@mui/material';

const AvailablePayments = ({ ...rest }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mb={2} {...rest}>
      <img src="/img/visa.svg" alt="Visa" height={38} width={38}/>
      <img src="/img/MasterCard.svg" alt="MasterCard" height={38} width={38} />
      <img src="/img/Apple_Pay.svg" alt="Apple Pay" height={38} width={38} />
      <img src="/img/googlepay.svg" alt="Google Pay" height={38} width={38} />
      <img src="/img/klarna.svg" alt="Klarna" height={38} width={38} />
      <img src="/img/paypal.svg" alt="PayPal" height={38} width={38} />
    </Stack>
  );
};

export default AvailablePayments;
