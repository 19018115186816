import React, { useEffect, useState } from 'react';
import { Box, Typography, ListItemAvatar, ListItemText, Stack, List, ListItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductById } from 'redux/product/productActions';
import { RootState, AppDispatch } from 'redux/store';
import { Invoice } from 'redux/auth/authTypes';
import { getProductImgSm, getPlatformName, getRegionName } from 'utils/getNames';

interface InvoicePreviewProps {
    invoice: Invoice | null;
}

const InvoicePreview: React.FC<InvoicePreviewProps> = ({ invoice }) => {
    const dispatch = useDispatch<AppDispatch>();
    const products = useSelector((state: RootState) => state.product.products);
    const [productNames, setProductNames] = useState<{ [key: string]: string }>({});
    const [platformDetailsMap, setPlatformDetailsMap] = useState<{ [key: string]: { platformName: string, regionName: string } }>({});
    const regions = useSelector((state: RootState) => state.productInfo.region.regions);
    const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
    
    useEffect(() => {
        if (invoice) {
            invoice.items.forEach(async item => {
                const product = products.find(p => p._id === item.productId);

                if (product) {
                    const platformDetail = product.platforms.find(p => p._id === item.platformGroupId);

                    if (platformDetail) {
                        const platformName = getPlatformName(platformDetail.platformId, platforms);
                        const regionName = getRegionName(platformDetail.region, regions);

                        setPlatformDetailsMap(prev => ({
                            ...prev,
                            [item.platformGroupId]: { platformName, regionName }
                        }));
                    }
                } else {
                    dispatch(fetchProductById(item.productId));
                }
            });
        }
    }, [dispatch, invoice, products, platforms, regions]);

    useEffect(() => {
        if (products) {
            const names = invoice?.items.reduce((acc, item) => {
                const product = products.find(p => p._id === item.productId);
                if (product) {
                    acc[item.productId] = product.title;
                } else {
                    acc[item.productId] = 'Unknown Product';
                }
                return acc;
            }, {} as { [key: string]: string }) || {};
            setProductNames(names);
        }
    }, [products, invoice]);

    if (!invoice) {
        return null;
    }

    return (
        <Box sx={{ width: '100%', p: 4, backgroundColor: '#ffffff', borderRadius: 2 }}>
            <Typography variant="h5" color="black" textAlign={'center'} width={'100%'} gutterBottom>Invoice from Bigswiss</Typography>
            <Typography variant="body2" color="black" textAlign={'center'} width={'100%'} sx={{ mb: 3 }}>
                Invoice #{invoice.invoiceNumber}
            </Typography>
            <Stack spacing={2} direction={'row'} justifyContent={'space-between'} sx={{ mb: 3 }}>

                <Stack direction={'column'}>
                    <Typography variant="button" color="black">
                        Date paid
                    </Typography>
                    <Typography variant="body1" style={{ color: '#32325d' }}>
                        {new Date(invoice.createdAt).toLocaleString()}
                    </Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography variant="button" textAlign={'left'} sx={{ color: 'black' }}>
                        Payment method
                    </Typography>
                    <Stack direction={'row'} spacing={0} alignItems={'center'}>
                        <Typography variant="body1" textAlign={'left'} style={{ color: '#32325d', paddingLeft: 0 }} sx={{ paddingLeft: 4 }}>
                            {invoice.paymentMethod}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction={'column'}>
                    <Typography variant="button" style={{ color: '#32325d', fontWeight: 1000 }}>
                        Amount paid
                    </Typography>
                    <Typography variant="body1" style={{ color: '#32325d', fontWeight: 1000 }}>
                        {invoice.total.toFixed(2)} {invoice.currency}
                    </Typography>
                </Stack>
            </Stack>

            <Typography variant='button' sx={{ color: 'black' }} >
                Summary
            </Typography>


            <List sx={{ marginTop: 3, backgroundColor: '#f6f9fc', borderRadius: '8px', padding: '16px' }}>
                {invoice.items.map((item, index) => (
                    <ListItem
                        key={index}
                        divider={index < invoice.items.length - 1}
                        secondaryAction={
                            <Typography color="black">
                                {(item.price * item.quantity).toFixed(2)} {invoice.currency}
                            </Typography>
                        }
                    >
                        <ListItemAvatar>
                            <Typography color="black">
                                {item.quantity}
                            </Typography>
                        </ListItemAvatar>
                        <ListItemText
                            primary={productNames[item.productId] || `Product ID: ${item.productId}`}
                            secondary={
                                platformDetailsMap[item.platformGroupId]
                                    ? `${platformDetailsMap[item.platformGroupId].platformName} - ${platformDetailsMap[item.platformGroupId].regionName}`
                                    : `Platform Group ID: ${item.platformGroupId}`
                            }
                            primaryTypographyProps={{ style: { color: 'black' } }}
                        />
                    </ListItem>
                ))}
            </List>


            <Typography variant="caption" color="textSecondary" sx={{ mt: 4, display: 'block' }}>
                If you have any questions, contact us at{' '}
                <a href="mailto:support@bigswiss.ch" style={{ color: '#635bff' }}>
                    support@bigswiss.ch
                </a>
                .
            </Typography>
        </Box>
    );
};

export default InvoicePreview;
