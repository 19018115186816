import { Grid2 as Grid, Card, CardHeader, Skeleton } from "@mui/material";

export default function LoadingGrid() {
  const placeholders = Array.from(new Array(9));

  return (
    <Grid container spacing={4}>
      {placeholders.map((_, index) => (
        <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
          <Card>
            <Skeleton variant="rectangular" width="100%" height={180} animation="wave" />
            <CardHeader
              avatar={<Skeleton variant="circular" width={40} height={40} animation="wave" />}
              title={<Skeleton variant="text" width="75%" height="30px" animation="wave" />}
              subheader={<Skeleton variant="text" width="40%" height="25px" animation="wave" />}
              action={<Skeleton variant="text" width="60px" height="40px" animation="wave" />}
              sx={{ height: 103 }}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
