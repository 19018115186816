import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const IEIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      style={{ width: '100%', height: 'auto' }}
      viewBox="0 -0.5 48 48"
      {...props}
    >
      <g fill="#00BBF5" fillRule="evenodd">
        <path d="M26.512 35.456c-5.283 0-9.045-4.579-9.176-9.234h30.622c.468-10.025-3-17.135-11.883-22.095 1.298-1.42 11.93-5.156 9.209 5.998l1.045 1.51c4.612-13.972-9.24-13.212-18.406-8.778C14.711 1.813 7.231 10.08 5.175 18.564c3.571-5.1 8.188-8.122 11.198-8.962-17.231 13.782-25.238 44.18-1.838 34.546l-1.624-.974c-14.082 4.417-8.641-8.067-5.931-9.868 3.244 6.6 10.156 11.874 18.612 11.874 10.762 0 18.756-5.527 21.928-14.695H35.098c-1.794 3.178-4.287 4.971-8.585 4.971zm0-23.669c5.283 0 8.805 4.123 8.936 8.213H17.468c.175-3.481 3.402-8.213 9.044-8.213z" />
      </g>
    </SvgIcon>
  );
};

export default IEIcon;
