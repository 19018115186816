import React from 'react';
import { Web as BrowserIcon, Key as KeylessIcon } from '@mui/icons-material';
import BlizzardIcon from 'components/icons/Platforms/BlizzardIcon';
import ActivationGuide from './ActivationGuide';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface BlizzardInstructionsProps {
    keyCode: string;
}

const BlizzardInstructions: React.FC<BlizzardInstructionsProps> = ({ keyCode }) => {
    const { t } = useTranslation();

    const steps = {
        default: [
            {
                label: t('activationMethod'),
                description: t('chooseActivation'),
                options: [
                    { title: t('Browser'), icon: <BrowserIcon sx={{ width: '64px', height: '64px' }} />, method: 'browser' },
                    { title: 'Blizzard App', icon: <BlizzardIcon sx={{ width: '64px', height: '64px' }} />, method: 'app' },
                    { title: t('keylessActivation'), icon: <KeylessIcon sx={{ width: '64px', height: '64px' }} />, method: 'keyless' },
                ]
            }
        ],
        browser: [
            { label: t('activationMethod'), description: '', imageUrl: '' },
            {
                label: t('activationLink'),
                description: t('battlenetStep1'),
                imageUrl: '/img/activation/activation-link.webp',
                actionButton: (
                    <Button
                        variant="contained"
                        color="secondary"
                        href={`https://eu.battle.net/shop/en/checkout/key-claim?keyCode=${keyCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('goToBattlenetActivation')}
                    </Button>
                ),
            },
            {
                label: t('login'),
                description: t('battlenetStep2'),
                imageUrl: '/img/activation/blizzard-login.webp',
            },
            {
                label: t('confirmActivation'),
                description: t('battlenetStep4'),
                imageUrl: '/img/activation/download.webp',
            }
        ],
        app: [
            { label: t('activationMethod'), description: '', imageUrl: '' },
            { label: t('login'), description: t('battlenetStep2app'), imageUrl: '/img/activation/blizzard-app-login.webp' },
            { label: t('goToActivation'), description: t('battlenetStep3app'), imageUrl: '/img/activation/blizzard-app-clickon-redeem-code.webp' },
            { label: t('enterTheKey'), description: t('battlenetStep3'), imageUrl: '/img/activation/blizzard-app-redeem-code.webp' },
            {
                label: t('confirmActivation'),
                description: t('battlenetStep4'),
                imageUrl: '/img/activation/download.webp',
            }
        ]
    };

    return <ActivationGuide steps={steps} />;
};

export default BlizzardInstructions;
