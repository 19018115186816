import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { RootState, AppDispatch } from 'redux/store';
import { fetchProductById, fetchProductDetailById, fetchProductByIdAndPlatformId } from 'redux/product/productActions';
import { HomeConfig } from 'redux/types/dataTypes';

import { HomeCover } from './Components/HomeCover';
import BestSellerGrid from './Components/BestSellerGrid';
import WhyUs from './Components/WhyUs';

import { getPlatformName, getRegionName, getCategoryName, getTypeName } from 'utils/getNames';
import LoadingAnimation from 'components/Animations/LoadingAnimation';
import SEO from 'utils/SEO';

export default function HomePage() {
  const dispatch = useDispatch<AppDispatch>();
  const settings = useSelector((state: RootState) => state.settings.settings);
  const homeConfig = settings.find(setting => setting.name === 'homeConfig') as HomeConfig;
  const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
  const regions = useSelector((state: RootState) => state.productInfo.region.regions);
  const categories = useSelector((state: RootState) => state.productInfo.category.categories);
  const types = useSelector((state: RootState) => state.productInfo.type.types);
  const products = useSelector((state: RootState) => state.product.products);
  const language = useSelector((state: RootState) => state.productDetail.language);
  const isLoading = useSelector((state: RootState) => state.product.isLoading);

  useEffect(() => {
    if (homeConfig) {
      dispatch(fetchProductById(homeConfig.coverProduct));
      dispatch(fetchProductDetailById({ productId: homeConfig.coverProduct, lang: language }));

      Object.entries(homeConfig).forEach(([key, entries]) => {
        if (Array.isArray(entries)) {
          entries.forEach((entry: any) => {
            if (entry.id && entry.platform_id) {
              dispatch(fetchProductByIdAndPlatformId({
                productId: entry.id,
                platformId: entry.platform_id,
                lang: language,
              }));
            }
          });
        }
      });
    }
  }, [homeConfig, dispatch, language]);

  const coverProduct = homeConfig ? products.find(p => p._id === homeConfig.coverProduct) : null;

  if (!homeConfig || !coverProduct) {
    return <LoadingAnimation />;
  }

  const platformData = coverProduct.platforms.map(platform => ({
    imageUrl: platform.platformId,
    price: platform.price,
    category: platform.category,
    platformName: getPlatformName(platform.platformId, platforms),
    regionName: getRegionName(platform.region, regions),
  }));

  const coverImageUrl = coverProduct._id;
  const heroTitle = coverProduct.title;
  const heroType = getTypeName(coverProduct.type, types);
  const heroProductId = coverProduct._id;

  return (
    <>
      <SEO
        title={'Bigswiss GmbH'}
        description={'Bigswiss is a digital marketplace for video games, gift cards, and game points. We offer a wide range of products for all platforms.'}
        keywords={`Bigswiss, video games, digital codes, gift cards, game points, subscription cards, gaming, online marketplace`}
        image={'https://bigswiss.ch/img/OG.jpg'}
      />

      <HomeCover
        productCoverImg={coverImageUrl}
        heroTitle={heroTitle}
        heroType={heroType}
        productId={heroProductId}
        platforms={platformData}
      />
      <Container>
        <WhyUs />
        {Object.entries(homeConfig).map(([category, productEntries], index) => (
          Array.isArray(productEntries) && (
            <BestSellerGrid
              key={index}
              category={category}
              categoryTitle={getCategoryName(category, categories)}
              productIds={productEntries}
              regions={regions}
              loading={isLoading} 
            />
          )
        ))}
      </Container>
    </>
  );
}
