// src/utils/generateStructuredData.ts

export const generateStructuredData = () => `
{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Bigswiss GmbH",
  "url": "https://bigswiss.ch",
  "logo": "https://bigswiss.ch/LogoDark.svg",
  "sameAs": [
    "https://www.linkedin.com/company/bigswiss-gmbh/",
    "https://github.com/Bigswiss-GmbH/",
    "https://www.w4g.ch"
  ],
  "contactPoint": {
    "@type": "ContactPoint",
    "contactType": "Customer Service",
    "areaServed": "CH",
    "email": "info@bigswiss.ch",
    "availableLanguage": ["English", "German", "French", "Italian", "Serbian"]
  }
}
`;
