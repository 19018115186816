import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography, Card, Divider, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link as RouterLink } from 'react-router-dom';
import { Flipper, Flipped } from 'react-flip-toolkit';

import ProductComponent from 'pages/CartPage/Components/CartProductItem';
import { RootState, AppDispatch } from 'redux/store';
import { addItem, removeItem, deleteItem, updateItemQuantity, maxProductAllowed } from 'redux/reducers/cartReducer';
import { fetchProductById } from 'redux/product/productActions';
import { Product } from 'redux/product/ProductsTypes';
import { getPlatformName, getRegionName, getCategoryName } from 'utils/getNames';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ProductPriceTag from 'components/ProductPriceTag';

function exitAnimation(element: HTMLElement, index: number, removeElement: () => void) {
    element.style.transition = 'opacity 0.5s, transform 0.5s';
    element.style.opacity = '0';
    element.style.transform = 'translateX(-100%)';

    requestAnimationFrame(() => {
        setTimeout(() => {
            removeElement();
        }, 500);
    });
}

interface CartItemsProps {
    onProcessToPayment: () => void;
}

const CartItems: React.FC<CartItemsProps> = (props) => {
    const { t } = useTranslation();

    const dispatch: AppDispatch = useDispatch();
    const cartItems = useSelector((state: RootState) => state.cart.items);
    const [productDetails, setProductDetails] = useState<{ [key: string]: Product & { platform: any } }>({});
    const [removedItems, setRemovedItems] = useState<string[]>([]);

    const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
    const regions = useSelector((state: RootState) => state.productInfo.region.regions);
    const categories = useSelector((state: RootState) => state.productInfo.category.categories);

    useEffect(() => {
        cartItems.forEach(item => {
            if (!removedItems.includes(item.id)) {
                async function getProduct() {
                    const product = await dispatch(fetchProductById(item.productId)).unwrap() as Product;
                    const platformDetails = product.platforms.find(p => p._id === item.platformGroupId);
                    setProductDetails(prev => ({
                        ...prev,
                        [item.id]: {
                            ...product,
                            platform: platformDetails
                        }
                    }));
                }
                getProduct();
            }
        });
    }, [dispatch, cartItems, removedItems]);

    const handleAddMore = (id: string) => {
        const details = productDetails[id];
        if (details) {
            dispatch(addItem({
                id: id,
                productId: details._id,
                platformGroupId: details.platform._id,
                price: details.platform.price,
                quantity: 1
            }));
        }
    };

    const handleQuantityChange = (id: string, newQuantity: number) => {
        dispatch(updateItemQuantity({
            id,
            quantity: newQuantity
        }));
    };

    const handleRemove = (id: string) => {
        dispatch(removeItem({ id: id }));
    };

    const handleDelete = (id: string) => {
        dispatch(deleteItem({ id: id }));
        setRemovedItems(prev => [...prev, id]);
        setTimeout(() => {
            dispatch(deleteItem({ id: id }));
        }, 500);
    };

    const totalAmount = cartItems.reduce((total, item) => {
        return total + (item.price * item.quantity);
    }, 0);

    return (
        <Flipper flipKey={JSON.stringify(cartItems)}>
            <Grid container spacing={4} sx={{ minHeight: '70vh' }}>
                <Grid size={{ xs: 12, sm: 12, md: 8 }}>
                    <Grid container spacing={2}>
                        {Object.entries(productDetails).map(([id, details]) => (
                            <Flipped
                                flipId={id}
                                key={id}
                                onExit={exitAnimation}
                            >
                                {!removedItems.includes(id) && (
                                    <Grid size={12}>
                                        <ProductComponent
                                            title={details.title}
                                            price={details.platform.price}
                                            imageUrl={details._id}
                                            quantity={cartItems.find(item => item.id === id)?.quantity || 0}
                                            category={getCategoryName(details.platform.category, categories)}
                                            platfrom={getPlatformName(details.platform.platformId, platforms)}
                                            platformId={details.platform.platformId}
                                            region={getRegionName(details.platform.region, regions)}
                                            onAdd={() => handleAddMore(id)}
                                            onRemove={() => handleRemove(id)}
                                            onDelete={() => handleDelete(id)}
                                            maxProductAllowed={maxProductAllowed}
                                            onQuantityChange={(newQuantity) => handleQuantityChange(id, newQuantity)}
                                        />
                                    </Grid>
                                )}
                            </Flipped>
                        ))}
                    </Grid>
                </Grid>
                <Grid container size={{ xs: 12, sm: 12, md: 4 }} sx={{
                    position: 'sticky',
                    top: 90,
                    height: 'fit-content',
                    width: '100%'
                }}>
                    <Grid size={12} sx={{ width: '100%' }}>
                        <Card sx={{ padding: 3 }}>
                            <Typography variant="h6" sx={{ mb: 3 }}>{t('cartSummary')}</Typography>

                            <Stack direction="column" spacing={1} sx={{ mb: 5 }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{t('originalPrice')}: </Typography>
                                    <ProductPriceTag variant='h6' productTagPrice={totalAmount.toFixed(2)} color="text.secondary" currencyPadding='2px' />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography> {t('discount')}: </Typography>
                                    <ProductPriceTag variant='h5' productTagPrice="0" color="text.secondary" currencyPadding='2px' />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{t('total')}: </Typography>
                                    <ProductPriceTag variant='h5' productTagPrice={totalAmount.toFixed(2)} currencyPadding='0' />
                                </Stack>
                            </Stack>

                            <Box marginTop={2} >
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    onClick={props.onProcessToPayment}
                                >
                                    {t('goToPayment')}
                                </Button>

                                <Divider sx={{ my: 2 }} />
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<ArrowBackRoundedIcon />}
                                    component={RouterLink}
                                    to="/products"
                                >
                                    {t('checkMoreProducts')}
                                </Button>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Flipper>
    );
};

export default CartItems;
