import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { Card, CardActionArea, CardMedia, Typography, CardHeader, Skeleton, Box } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import PlatformLogo from 'components/icons/PlatformLogos';
import { Link as RouterLink } from 'react-router-dom';
import ProductPriceTag from 'components/ProductPriceTag';
import { getProductImgSm, getRegionName, makeUrlSafe, getTypeName, getPlatformName } from 'utils/getNames';
import FavoritesButton from 'components/FavoritesButton';

interface ProductProductProps {
  productId: string;
  title: string;
  type: string;
  productPlatformId: string;
  region: string;
  category: string;
  platform: string;
  regionId?: string;
  platformImageUrl: string;
  price: number | string;
  editionName?: string;
  regionCurrency?: string;
  loading?: boolean;
  withFav?: boolean;
}

const ProductCard: React.FC<ProductProductProps> = ({
  title,
  type,
  productId,
  productPlatformId,
  category,
  platform,
  price,
  region,
  platformImageUrl,
  editionName,
  regionCurrency,
  regionId,
  withFav
}) => {
  const { t } = useTranslation();
  const regions = useSelector((state: RootState) => state.productInfo.region.regions);
  const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
  const types = useSelector((state: RootState) => state.productInfo.type.types);

  const [imgError, setImgError] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Card sx={{
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.07)',
        zIndex: 100
      },
      position: 'relative'
    }}>
      {withFav && (
        <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 10 }}>
          <FavoritesButton icon productId={productId} platformGroupId={productPlatformId} />
        </Box>
      )}
      <CardActionArea component={RouterLink} 
      to={`/${makeUrlSafe(getTypeName(type, types))}/${productId}/${makeUrlSafe(title)}/${makeUrlSafe(category)}/${makeUrlSafe(getPlatformName(platform, platforms))}/${makeUrlSafe(getRegionName(region, regions))}`}
      onClick={scrollToTop}>

        {imgError ? (
          <Box sx={{ position: 'relative', width: '100%', height: 180 }}>
            <Skeleton variant="rectangular" width="100%" height={180} />
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'rgba(0, 0, 0, 0.6)',
            }}>
              <ImageIcon fontSize="large" />
            </Box>
          </Box>
        ) : (
          <CardMedia
            component="img"
            height="180"
            image={getProductImgSm(productId)}
            alt={title}
            onError={() => {
              console.error('Image failed to load:', getProductImgSm(productId));
              setImgError(true);
            }}
          />
        )}
        <CardHeader
          sx={{ minHeight: '102.23px' }}
          avatar={<PlatformLogo platformId={platformImageUrl} />}
          action={<ProductPriceTag variant='h6' productRegionId={regionId} productType={type} productTagPrice={(price || '').toString()} currencyPadding='2px' />}
          title={
            <Typography component="p" variant="subtitle2">
              {title}
              {(type === '3' || type === '4') && (
                <>
                  {editionName} {regionCurrency}
                </>
              )}
            </Typography>
          }
          subheader={`${getRegionName(region, regions)} ${t('region')}`}
        />
      </CardActionArea>
    </Card>
  );
};

export default ProductCard;