import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import ChevronRightIcon from '@mui/icons-material/ArrowForwardIos';

interface SiteTitleProps {
  firstTitle: string;
  secondTitle: string;
  display?: string | 'flex' | 'inline' | 'block' | 'inline-block' | 'none';
  color: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
  variant: Variant | 'inherit';
  position?: string | 'flex-start';
  onClick?: () => void;
  arrow?: boolean;
}

const SiteTitle: React.FC<SiteTitleProps> = ({ firstTitle, secondTitle, variant, display, color, position, onClick, arrow, ...rest }) => {
  const theme = useTheme();

  const resolveColor = (): string => {
    return theme.palette.mode === 'light' ? theme.palette[color].main : theme.palette[color].light;
  };

  return (
    <>
      <Typography
        variant={variant}
        onClick={onClick}
        component="span"
        sx={{
          display: display,
          
          flexWrap: 'wrap',
          justifyContent: position,
          my: 1,
          alignItems: 'center',
          color: 'text.primary',
          cursor: onClick ? 'pointer' : 'default',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: onClick ? 'scale(1.07)' : 'none',
            zIndex: onClick ? 100 : 'auto'
          },
          ...rest
        }}
      >
        {firstTitle}&nbsp;
        <Typography
          component="span"
          variant={variant}
          sx={{
            color: resolveColor(),
          }}
        >
          {secondTitle}&nbsp;
          {arrow && <ChevronRightIcon fontSize='large' sx={{ position: 'absolute', mt: '10px' }} />}
        </Typography>

      </Typography>
    </>
  );
};

export default SiteTitle;
