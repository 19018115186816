import React, { forwardRef, ReactNode } from "react"
import styled, { keyframes } from "styled-components"
import { promoteLayer } from "./utils"

interface IFadeContainerProps {
  $animatingOut?: boolean;
  direction?: "right" | "left";
  $duration: number;
}

const getFadeContainerKeyFrame = ({ $animatingOut, direction }: IFadeContainerProps) => {
  if (!direction) return
  return keyframes`
  to {
    transform: translateX(0px);
    opacity: ${$animatingOut ? 0 : 1};
  }
`
}

const FadeContainer = styled.div<IFadeContainerProps>`
  ${promoteLayer}
  animation-name: ${getFadeContainerKeyFrame};
  animation-$duration: ${props => props.$duration}ms;
  animation-fill-mode: forwards;
  opacity: ${props => (props.direction && !props.$animatingOut ? 0 : 1)};
  top: 0;
  left: 0;
`

interface IFadeContentsProps extends IFadeContainerProps {
  children: ReactNode;
}

const FadeContents = forwardRef<HTMLDivElement, IFadeContentsProps>(
  ({ children, $duration, $animatingOut, direction }, ref) => (
    <FadeContainer
      aria-hidden={$animatingOut}
      $animatingOut={$animatingOut}
      direction={direction}
      $duration={$duration}
      ref={ref}
    >
      {children}
    </FadeContainer>
  )
)

export default FadeContents