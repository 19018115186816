import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Box, Grid2 as Grid, Card, alpha, Typography, Button } from '@mui/material';
import ProductPriceTag from 'components/ProductPriceTag';
import FavoritesButton from 'components/FavoritesButton';
import AddToCartButton from 'components/Cart/AddToCartButton';
import PlatformLogo from 'components/icons/PlatformLogos';
import { getCategoryName, getProductImgLg, getCurrencySymbol } from 'utils/getNames';

interface Platform {
    _id: string;
    editionName: string;
    category: string;
    platformId: string;
    region: string;
    price: number;
}

interface CreditCartProps {
    productId: string;
    categoryId: string;
    ProductTitle: string;
    ProductImage: string;
    platformName: string;
    platformGroupId: string;
    ProductRegion: string;
    ProductRegionId: string;
    platforms: any[];
    platformsDetails: Platform[];
    regions: any[];
    [key: string]: any;
}

export const CreditCart = ({
    productId,
    categoryId,
    ProductTypeId,
    platformsDetails,
    ProductImage,
    platformName,
    platformGroupId,
    ProductRegion,
    ProductRegionId,
    ProductTitle,
    platforms,
    regions,
    ...rest
}: CreditCartProps) => {
    const { t } = useTranslation();

    const categories = useSelector((state: RootState) => state.productInfo.category.categories);
    const allPlatforms = useSelector((state: RootState) => state.productInfo.platform.platforms);

    const [selectedPlatformDetails, setSelectedPlatformDetails] = useState<Platform | null>(null);

    useEffect(() => {
        const initialPlatform = platformsDetails.find(p => p._id === platformGroupId);
        if (initialPlatform) {
            setSelectedPlatformDetails(initialPlatform);
        }
    }, [platformGroupId, platformsDetails]);

    const handlePlatformChange = (platformId: string) => {
        const platform = platformsDetails.find(p => p._id === platformId);
        if (platform) {
            setSelectedPlatformDetails(platform);
        }
    };

    return (
        <Grid container spacing={6} sx={{ mb: { xs: 8, sm: 10 } }} justifyContent="center" {...rest}>
            <Grid size={{ xs: 12, md: 6 }}>
                <Box
                    id="image"
                    sx={(theme) => ({
                        alignSelf: 'center',
                        height: { xs: 200, sm: 340 },
                        width: '100%',
                        backgroundImage: `url(${getProductImgLg(ProductImage)})`,
                        backgroundSize: 'cover',
                        backgroundPositionY: 'center',
                        borderRadius: '21px',
                        boxShadow: '#000',
                        ...theme.applyStyles('light', {
                            boxShadow: `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`,
                        }),
                        ...theme.applyStyles('dark', {
                            boxShadow: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                        }),
                    })}
                />

            </Grid>

            <Grid size={{ xs: 12, md: 6 }} >
                <Card sx={{ display: 'flex', height: { xs: 'fit-content', sm: 340 }, overflow: 'visible', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', p: 4 }}>
                    <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                        {ProductTitle}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                        {getCategoryName(categoryId, categories)} - {platformName} - {ProductRegion}
                    </Typography>

                    <Typography variant='subtitle2' my={1}>
                        {t('availableEditions')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                        {platformsDetails
                            .filter(platform => platform.region === ProductRegionId)
                            .map((platform, index) => {
                                const isSelected = selectedPlatformDetails?._id === platform._id;
                                const platformDetails = allPlatforms.find(p => p._id === platform.platformId);

                                return (
                                    <Button
                                        key={`${platform.platformId}-${index}`}
                                        variant={isSelected ? 'contained' : 'outlined'}
                                        disabled={isSelected}
                                        onClick={() => handlePlatformChange(platform._id)}
                                        sx={{ display: 'flex', alignItems: 'center', textTransform: 'none' }}
                                        startIcon={<PlatformLogo platformId={platformDetails?.imageUrl || '/img/avatar.svg'} sx={{ width: '21px', height: '21px' }} />}
                                    >
                                        {platform.editionName} {ProductTypeId === '3' ? getCurrencySymbol(platform.region, regions) : null}
                                    </Button>
                                );
                            })}
                    </Box>

                    {selectedPlatformDetails && (
                        <>
                            <ProductPriceTag
                                productTagPrice={selectedPlatformDetails.price.toString()}
                                variant='h4'
                                productRegionId={selectedPlatformDetails.region}
                                productType={ProductTypeId}
                                currencyPadding="10px"
                            />
                            <Box sx={{ position: 'absolute', bottom: '-20px', zIndex: '100', gap: 2, display: 'flex' }}>
                                <FavoritesButton
                                    productId={productId}
                                    platformGroupId={selectedPlatformDetails._id}
                                />
                                <AddToCartButton
                                    productId={productId}
                                    platformGroupId={selectedPlatformDetails._id}
                                    quantity={1}
                                    price={selectedPlatformDetails.price}
                                />
                            </Box>
                        </>
                    )}
                </Card>
            </Grid>
        </Grid>
    );
};
