import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Hero from './Components/Hero';
import LogoCollection from './Components/LogoCollection';
import Highlights from './Components/Highlights';
import Pricing from './Components/Pricing';
import Products from './Components/Products';
import FAQ from './Components/FAQ';


export default function AboutPage() {

  return (
    <>
      <Box>
        <Products />
        <Highlights />
        <Divider />
        {/* <Testimonials /> */}
        <LogoCollection />
        <Hero />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
      </Box>
    </>
  );
}