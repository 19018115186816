// useSnackbarAlert.ts
import { useSnackbar, VariantType } from 'notistack';

const useSnackbarAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showAlert = (message: string, variant: VariantType = 'default') => {
    enqueueSnackbar(message, { variant });
  };

  return showAlert;
};

export default useSnackbarAlert;
