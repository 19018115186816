import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Invoice } from 'redux/auth/authTypes';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductById } from 'redux/product/productActions';
import { RootState, AppDispatch } from 'redux/store';
import { getPlatformName, getRegionName } from 'utils/getNames';
import vatRates from './vatRates';
interface InvoicePdfGeneratorProps {
    invoice: Invoice;
    title: string;
}

const InvoicePdfGenerator: React.FC<InvoicePdfGeneratorProps> = ({ invoice, title }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const products = useSelector((state: RootState) => state.product.products);
    const regions = useSelector((state: RootState) => state.productInfo.region.regions);
    const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);

    const [productDetailsMap, setProductDetailsMap] = useState<{ [key: string]: { title: string, platform: string, region: string } }>({});

    useEffect(() => {
        const fetchDetails = async () => {
            const productDetails: { [key: string]: { title: string, platform: string, region: string } } = {};

            for (const item of invoice.items) {
                const product = products.find(p => p._id === item.productId);
                if (!product) {
                    const resultAction = await dispatch(fetchProductById(item.productId)).unwrap();
                    const fetchedProduct = resultAction as any;
                    const platformDetail = fetchedProduct.platforms.find((p: any) => p._id === item.platformGroupId);

                    if (platformDetail) {
                        productDetails[item.productId] = {
                            title: fetchedProduct.title,
                            platform: getPlatformName(platformDetail.platformId, platforms),
                            region: getRegionName(platformDetail.region, regions),
                        };
                    }
                } else {
                    const platformDetail = product.platforms.find((p: any) => p._id === item.platformGroupId);
                    if (platformDetail) {
                        productDetails[item.productId] = {
                            title: product.title,
                            platform: getPlatformName(platformDetail.platformId, platforms),
                            region: getRegionName(platformDetail.region, regions),
                        };
                    }
                }
            }
            setProductDetailsMap(productDetails);
        };

        fetchDetails();
    }, [invoice, dispatch, products, platforms, regions]);

    const generatePdf = () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
        });

        const vatPercentage = vatRates[invoice.userCountry] || 0;

        const logoUrl = '/img/LogoDark.png';
        const logoWidth = 70;
        const logoHeight = (64.92 / 297.54) * logoWidth;

        doc.addImage(logoUrl, 'PNG', 14, 12, logoWidth, logoHeight);

        doc.setFontSize(11);
        doc.setTextColor('#282828');
        doc.text('Bigswiss GmbH', 195, 15, { align: 'right' });
        doc.text('Lercherstrasse 30', 195, 20, { align: 'right' });
        doc.text('9195 Gossau, St.Gallen', 195, 25, { align: 'right' });
        doc.text('Switzerland', 195, 30, { align: 'right' });
        doc.text('Reg No:', 140, 40);
        doc.text('CH-320.4.078.777-3', 195, 40, { align: 'right' });
        doc.text('VAT No:', 140, 45);
        doc.text('CHE-361.327.609', 195, 45, { align: 'right' });

        doc.setFontSize(16);
        doc.setTextColor('#282828');
        doc.text('Invoice Number', 14, 60);
        doc.text(`${invoice.invoiceNumber}`, 195, 60, { align: 'right' });
        doc.setDrawColor(0, 0, 0);
        doc.line(14, 63, 196, 63);

        doc.setFontSize(11);
        doc.setTextColor('#282828');
        doc.text('Invoice To:', 14, 70);
        doc.text(`${invoice.userName}`, 14, 80);
        doc.text(`${invoice.userAddress}`, 14, 85);
        doc.text(`${invoice.userCity}, ${invoice.userZip}, ${invoice.userCountry}`, 14, 90);

        doc.text(`Email: ${invoice.userEmail}`, 14, 100);

        doc.setFontSize(11);
        doc.setTextColor('#282828');
        doc.text('Payment details:', 195, 70, { align: 'right' });
        doc.text(`Date Paid`, 195, 80, { align: 'right' });
        doc.text(`${new Date(invoice.createdAt).toLocaleString()}`, 195, 85, { align: 'right' });
        doc.text(`Payment Method`, 195, 95, { align: 'right' });
        doc.text(`${invoice.paymentMethod}`, 195, 100, { align: 'right' });

        const vatAmount = (invoice.total * vatPercentage) / (100 + vatPercentage);
        const netAmount = invoice.total - vatAmount;

        const tableBody = invoice.items.map((item) => {
            const productDetail = productDetailsMap[item.productId] || { title: '', platform: '', region: '' };
            const itemPriceExclVAT = (item.price / (1 + vatPercentage / 100)).toFixed(2);
            const itemVAT = ((item.price * vatPercentage) / (100 + vatPercentage)).toFixed(2);
            const itemTotal = (item.price * item.quantity).toFixed(2);

            return [
                { content: item.quantity, styles: { halign: 'center' as const, valign: 'middle' as const } },
                { content: `${productDetail.title}\n${productDetail.platform} - ${productDetail.region}`, styles: { halign: 'left' as const, valign: 'middle' as const } },
                { content: `${itemPriceExclVAT} ${invoice.currency}`, styles: { halign: 'right' as const, valign: 'middle' as const } },
                { content: `${itemVAT} ${invoice.currency}`, styles: { halign: 'right' as const, valign: 'middle' as const } },
                { content: `${itemTotal} ${invoice.currency}`, styles: { halign: 'right' as const, valign: 'middle' as const } }
            ];
        });

        const tableOptions = {
            head: [
                [
                    { content: 'Qty', styles: { halign: 'center' as const, valign: 'middle' as const } },
                    { content: 'Product', styles: { halign: 'left' as const } },
                    { content: 'Price (Excl. VAT)', styles: { halign: 'right' as const, valign: 'middle' as const } },
                    { content: 'VAT', styles: { halign: 'right' as const, valign: 'middle' as const } },
                    { content: 'Total Price', styles: { halign: 'right' as const, valign: 'middle' as const } }
                ]
            ],
            body: tableBody,
            startY: 110,
        };

        autoTable(doc, tableOptions);

        const finalY = (doc as any).lastAutoTable.finalY;
        doc.setFontSize(11);
        doc.setTextColor('#282828');
        doc.text(`Net Amount (Excl. VAT):`, 115, finalY + 10);
        doc.text(`${netAmount.toFixed(2)} ${invoice.currency}`, 195, finalY + 10, { align: 'right' });
        doc.text(`VAT (${vatPercentage}%):`, 115, finalY + 20);
        doc.text(`${vatAmount.toFixed(2)} ${invoice.currency}`, 195, finalY + 20, { align: 'right' });
        doc.text(`Total Amount (Incl. VAT):`, 115, finalY + 30);
        doc.text(`${invoice.total.toFixed(2)} ${invoice.currency}`, 195, finalY + 30, { align: 'right' });

        doc.setFontSize(10);
        doc.setTextColor('#505050');
        doc.text('For any questions, please contact support@bigswiss.ch', 14, 280);
        doc.text('Bigswiss GmbH | Lercherstrasse 30 | 9195 Gossau | Switzerland', 14, 285);

        doc.save(`invoice_${invoice.invoiceNumber}.pdf`);
    };

    return (
        <Button
            startIcon={<DownloadIcon />}
            variant="contained"
            color="primary"
            onClick={generatePdf}
        >
            {title}
        </Button>
    );
};

export default InvoicePdfGenerator;
