import React from 'react';

import PsIcon from './Platforms/PsIcon';
import XboxIcon from './Platforms/XboxIcon';
import PcIcon from "./Platforms/PcIcon";
import NintendoJoystickIcon from "./Platforms/NintendoJoystickIcon";


const categoryIcons: { [key: string]: React.ElementType } = {
    'pc': PcIcon,
    'ps': PsIcon,
    'xb': XboxIcon,
    'nt': NintendoJoystickIcon,
};

export default categoryIcons;
