import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EaIcon = (props: SvgIconProps) => {

  return (
      <SvgIcon
      {...props}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
    >
      <g clipPath="url(#clip0_275_226)">
        <path fillRule="evenodd" clipRule="evenodd" d="M25 50.0005C11.2149 50.0005 0 38.7855 0 25.0005C0 11.2153 11.2149 0.000427246 25 0.000427246C38.7849 0.000427246 50 11.2154 50 25.0005C50 38.7854 38.785 50.0005 25 50.0005ZM29.2314 15.3307H15.1027L12.959 18.7757H27.0715L29.2314 15.3307ZM32.1188 15.3635L23.4816 29.1167H13.9009L16.1113 25.6687H21.8535L24.047 22.2224H10.7719L8.57889 25.6687H11.7237L7.36041 32.5527H25.5699L32.2956 21.9459L34.748 25.6687H32.5367L30.4426 29.1167H36.9907L39.2645 32.5527H43.4415L32.1188 15.3635Z" fill="#FF4747" />
      </g>
      <defs>
        <clipPath id="clip0_275_226">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default EaIcon;