import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { Box, Card, Container, Grid2 as Grid, Stack, Typography } from '@mui/material';

import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import BoltIcon from '@mui/icons-material/Bolt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const items = (t: TFunction) => [
  {
    icon: <BoltIcon />,
    title: t('instantDeliveryTitle'),
    description: t('instantDeliveryDescription')
  },
  {
    icon: <CloudDownloadIcon />,
    title: t('digitalDownloadTitle'),
    description: t('digitalDownloadDescription')
  },
  {
    icon: <SupportAgentIcon />,
    title: t('customerSupportTitle'),
    description: t('customerSupportDescription')
  },
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: t('adaptablePerformanceTitle'),
    description: t('adaptablePerformanceDescription')
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: t('innovativeFunctionalityTitle'),
    description: t('innovativeFunctionalityDescription')
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: t('precisionDetailTitle'),
    description: t('precisionDetailDescription')
  },
];

export default function Highlights() {
  const { t } = useTranslation();
  const translatedItems = items(t);

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            {t('highlights')}
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            {t('highlightsDescription')}
          </Typography>
        </Box>
        <Grid container spacing={2.5}> {/* Updated to Grid2 */}
          {translatedItems.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}> {/* Updated size prop */}
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
