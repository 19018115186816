import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { PaletteMode } from '@mui/material';

interface ThemeContextProps {
  mode: PaletteMode | 'auto';
  actualMode: PaletteMode;
  toggleColorMode: (newMode?: PaletteMode | 'auto') => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [mode, setMode] = useState<PaletteMode | 'auto'>(
    (localStorage.getItem('themeMode') as PaletteMode | 'auto') || 'auto'
  );
  const [actualMode, setActualMode] = useState<PaletteMode>('light');

  useEffect(() => {
    const updateTheme = () => {
      const preferredMode = mode === 'auto'
        ? window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
        : mode;
      setActualMode(preferredMode);
      document.body.setAttribute('data-theme', preferredMode);
    };

    updateTheme();

    if (mode === 'auto') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = (e: MediaQueryListEvent) => {
        const newMode = e.matches ? 'dark' : 'light';
        setActualMode(newMode);
        document.body.setAttribute('data-theme', newMode);
      };
      mediaQuery.addEventListener('change', handleChange);
      return () => mediaQuery.removeEventListener('change', handleChange);
    }
  }, [mode]);

  const toggleColorMode = useCallback((newMode?: PaletteMode | 'auto') => {
    const modeToSet = newMode || (mode === 'light' ? 'dark' : mode === 'dark' ? 'auto' : 'light');
    setMode(modeToSet);
    if (modeToSet !== 'auto') {
      localStorage.setItem('themeMode', modeToSet);
    } else {
      localStorage.removeItem('themeMode');
    }
  }, [mode]);

  return (
    <ThemeContext.Provider value={{ mode, actualMode, toggleColorMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useThemeContext = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};

export { ThemeProvider, useThemeContext };
