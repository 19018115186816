// redux/actions/fetchDataActions.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Category } from '../types/dataTypes';
import { Platform } from '../types/dataTypes';
import { Region } from '../types/dataTypes';
import { Type } from '../types/dataTypes';
import { Developer } from '../types/dataTypes';
import { Publisher } from '../types/dataTypes';
import { Genre } from '../types/dataTypes';
import { Rating } from '../types/dataTypes';
import { Settings } from '../types/dataTypes';

export const fetchSettings = createAsyncThunk<Settings[], void, { rejectValue: string }>(
  'settings/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/product/settings');
      if (!response.ok) throw new Error('Failed to fetch settings');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }
);

export const fetchPlatforms = createAsyncThunk<Platform[], void, { rejectValue: string }>(
  'platforms/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/product/platform');
      if (!response.ok) throw new Error('Failed to fetch platforms');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }
);

export const fetchDevelopers = createAsyncThunk<Developer[], void, { rejectValue: string }>(
  'developers/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/product/developer');
      if (!response.ok) throw new Error('Failed to fetch developers');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }
);

export const fetchPublishers = createAsyncThunk<Publisher[], void, { rejectValue: string }>(
  'publishers/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/product/publisher');
      if (!response.ok) throw new Error('Failed to fetch publishers');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }
);

export const fetchGenres = createAsyncThunk<Genre[], void, { rejectValue: string }>(
  'genres/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/product/genre');
      if (!response.ok) throw new Error('Failed to fetch genres');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }
);

export const fetchRatings = createAsyncThunk<Rating[], void, { rejectValue: string }>(
  'ratings/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/product/rating');
      if (!response.ok) throw new Error('Failed to fetch ratings');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }
);


export const fetchRegions = createAsyncThunk<Region[], void, { rejectValue: string }>(
  'regions/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/product/region');
      if (!response.ok) throw new Error('Failed to fetch regions');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }
);

export const fetchTypes = createAsyncThunk<Type[], void, { rejectValue: string }>(
  'type/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/product/product-type');
      if (!response.ok) throw new Error('Failed to fetch Product Type');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }
);

export const fetchCategories = createAsyncThunk<Category[], void, { rejectValue: string }>(
  'categories/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/product/category');
      if (!response.ok) throw new Error('Failed to fetch categories');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }
);


