import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrencyAndRegion } from './applicationReducer';
import { getUserLocation } from './locationService';
import { RootState, AppDispatch } from '../store';
import axios from 'axios';
import { createAuthHeaders } from '../apiHelper';
import { purgeSettings } from '../reducers/settingsReducer';
import { fetchSettings } from '../actions/fetchDataActions';

import { purgeProducts } from 'redux/product/productSlice';
import { clearCart } from 'redux/reducers/cartReducer';

export const fetchLocationData = createAsyncThunk(
  'application/fetchLocationData',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const selectedCurrency = state.application.selectedCurrency;
    const selectedRegion = state.application.selectedRegion;
    const userIp = state.application.userIp;  // Get IP from Redux state

    try {
      const locationData = await getUserLocation(dispatch as AppDispatch, userIp);

      if (locationData) {
        const { currency, region, ip } = locationData;

        if (currency !== selectedCurrency || region !== selectedRegion) {
          dispatch(setCurrencyAndRegion({ region, currency }));
        }

        return locationData;
      } else {
        return rejectWithValue('Failed to fetch location data');
      }
    } catch (error) {
      return rejectWithValue('Error fetching location data');
    }
  }
);

interface UserLocation {
  country: string;
  currency: string;
}

export const handleUserLocation = (
  dispatch: AppDispatch,
  { country, currency }: UserLocation,
  currentCountry: string,
  currentCurrency: string
) => {
  let needsRefresh = false;

  // Check if the country or currency has changed
  if (country !== currentCountry || currency !== currentCurrency) {
    // Update state with the new currency and region
    dispatch(setCurrencyAndRegion({ region: country, currency }));
    needsRefresh = true;
  }

  // If needed, re-fetch products or other necessary data
  if (needsRefresh) {
    dispatch(purgeSettings()); 
    dispatch(fetchSettings());
    dispatch(purgeProducts()); 
    dispatch(clearCart());     // Purge products from state
  }
};

export const fetchWithLocationHeaders = async (
  endpoint: string,
  method: 'GET' | 'POST' = 'GET',
  getState: any,
  dispatch: any,
  rejectWithValue: any
) => {
  const state = getState() as RootState;

  // Ensure that location data has been fetched
  if (state.application.locationStatus === 'idle') {
    // Dispatch fetchLocationData if it's still idle
    await dispatch(fetchLocationData()).unwrap();
  }

  // Re-check the state after location fetch
  const updatedState = getState() as RootState;
  if (updatedState.application.locationStatus !== 'succeeded') {
    return rejectWithValue('Location data not ready');
  }

  // Re-fetch the updated values from state
  const selectedCurrency = updatedState.application.selectedCurrency;
  const selectedRegion = updatedState.application.selectedRegion;

  try {
    // Use axios for consistent API requests
    const response = await axios(endpoint, {
      method,
      headers: {
        ...createAuthHeaders(),
        'X-Currency': selectedCurrency,
        'X-Region': selectedRegion,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return rejectWithValue(errorMessage);
  }
};