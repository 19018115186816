import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SafariIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      style={{ width: '100%', height: 'auto' }}
      viewBox="194.5 190.1 135.1 135.1"
      {...props}
    >
      <defs>
        <linearGradient id="a" x1="132.55" x2="134.37" y1="111.67" y2="-105.3">
          <stop offset="0" stopColor="#d2d2d2" />
          <stop offset="53%" stopColor="#f2f2f2" />
          <stop offset="100%" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient id="c" x1="65.44" x2="67.4" y1="115.72" y2="17.14">
          <stop offset="0" stopColor="#005ad5" />
          <stop offset="16%" stopColor="#0875f0" />
          <stop offset="31%" stopColor="#218cee" />
          <stop offset="63%" stopColor="#27a5f3" />
          <stop offset="81%" stopColor="#25aaf2" />
          <stop offset="100%" stopColor="#21aaef" />
        </linearGradient>
        <radialGradient id="i" cx="-69.88" cy="69.29" r="54.01" gradientTransform="matrix(.9023 -.01249 .0377 2.7234 -9.44 -120.29)">
          <stop offset="0" stopColor="#24a5f3" stopOpacity="0.01" />
          <stop offset="100%" stopColor="#1e8ceb" stopOpacity="0.98" />
        </radialGradient>
        <radialGradient id="j" cx="109.35" cy="13.76" r="93.08" gradientTransform="matrix(-.01822 1.0922 -1.042 -.01765 136.95 -115.33)">
          <stop offset="96%" stopOpacity="0" />
          <stop offset="100%" stopColor="#5d96eb" />
        </radialGradient>
      </defs>

      <rect width="220" height="220" x="22" y="-106.53" fill="url(#a)" ry="49" transform="matrix(.56938 0 0 .56921 186.86 255.75)" />
      <g transform="translate(194.2 190.07)">
        <ellipse cx="67.77" cy="67.73" fill="url(#c)" rx="54.01" ry="53.98" />
        <ellipse cx="-69.88" cy="69.29" fill="url(#i)" rx="54.01" ry="53.98" transform="translate(137.65 -1.55)" />
      </g>
      <ellipse cx="120" cy="14.15" fill="url(#j)" rx="93.08" ry="93.67" transform="matrix(.58082 0 0 .57636 192.3 249.63)" />
      <g transform="matrix(.58289 0 0 .56508 196.8 181.63)">
        <path fill="#cac7c8" d="m46 191.66.73.35 72.18-48.2-7.34-8.95L46 191.66Z" />
        <path fill="#fbfffc" d="m45.8 190.87.2.8 65.57-56.8-6.95-8.92-58.83 64.92Z" />
        <path fill="#fb645c" d="m104.62 125.95 6.94 8.92 66.08-57.2-.65-.35-72.37 48.63Z" />
      </g>
    </SvgIcon>
  );
};

export default SafariIcon;
