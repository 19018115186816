import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { clearCart } from 'redux/reducers/cartReducer';
import { fetchOrderStatus, createPaymentIntent } from 'redux/payment/paymentThunks';
import { clearPaymentData } from 'redux/payment/paymentReducer';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress, Container } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import VerificationStatus from './VerificationStatus';

const PaymentStatus: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const paymentId = useSelector((state: RootState) => state.payment.paymentId);
    const paymentStatus = useSelector((state: RootState) => state.payment.orderStatus);
    const paymentError = useSelector((state: RootState) => state.payment.error);
    const cartItems = useSelector((state: RootState) => state.cart.items);
    const cartTotal = useSelector((state: RootState) => state.cart.total);

    const [errorMessage, setErrorMessage] = useState('');
    const [verificationUrl, setVerificationUrl] = useState<string | null>(null);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (paymentId) {
            intervalId = setInterval(() => {
                dispatch(fetchOrderStatus(paymentId)).then((response: any) => {
                    const { status, verificationUrl } = response.payload || {};
                    if (status === 'pending_verification' && verificationUrl) {
                        setVerificationUrl(verificationUrl);
                    }
                });
            }, 10000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [dispatch, paymentId]);

    useEffect(() => {
        if (paymentStatus === 'paid') {
            const paymentIntent = new URLSearchParams(window.location.search).get('payment_intent');
            const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
            navigate(
                `/cart/game-activation?payment_intent=${paymentIntent}&payment_intent_client_secret=${clientSecret}&redirect_status=succeeded`
            );
            dispatch(clearCart());
            dispatch(clearPaymentData());
        } else if (paymentStatus === 'failed') {
            setErrorMessage(paymentError || 'Payment failed, please try again.');
        }
    }, [dispatch, navigate, paymentStatus, paymentError]);

    const handleRetry = async () => {
        const newPaymentIntent = await dispatch(createPaymentIntent({ amount: cartTotal, items: cartItems }));
        if (createPaymentIntent.fulfilled.match(newPaymentIntent)) {
            navigate('/cart/payment');
        } else if (createPaymentIntent.rejected.match(newPaymentIntent)) {
            const error = newPaymentIntent.payload as string;
            setErrorMessage(error);
        }
    };

    return (
        <Box padding={2} textAlign="center">
            {paymentStatus && !['paid', 'failed'].includes(paymentStatus) ? (
                <>
                    {verificationUrl ? (
                        <VerificationStatus verificationUrl={verificationUrl} />
                    ) : (
                        <>
                            <Typography variant="h6" sx={{ mb: 4 }}>
                                Please wait while we confirm your payment...
                            </Typography>
                            <CircularProgress />
                        </>
                    )}
                </>
            ) : (
                paymentStatus === 'failed' && (
                    <>
                        {errorMessage === 'Too many failed requests' ? (
                            <Container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src="/img/payment_block.webp" height={250} alt="Too many failed requests" />
                                <Typography variant="h4" sx={{ mb: 3 }}>
                                    Too many failed requests
                                </Typography>
                                <Typography variant="h6" sx={{ mb: 3 }}>
                                    Please try again later or contact our support.
                                </Typography>
                                <Button
                                    startIcon={<ArrowBackRoundedIcon />}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/')}
                                >
                                    Return Home
                                </Button>
                            </Container>
                        ) : (
                            <Container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src="/img/payment_error.webp" height={250} alt="Payment Error" />
                                <Typography variant="h4" sx={{ mb: 4 }}>
                                    Your payment failed.
                                </Typography>
                                <Typography variant="h6" sx={{ mb: 4 }}>
                                    Please try again, or contact our support.
                                </Typography>
                                <Button
                                    startIcon={<ArrowBackRoundedIcon />}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleRetry}
                                >
                                    Try Again
                                </Button>
                            </Container>
                        )}
                    </>
                )
            )}
        </Box>
    );
};

export default PaymentStatus;
