import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BlizzardIcon = (props: SvgIconProps) => {

  return (
      <SvgIcon
      {...props}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
    >
      <path d="M41.6677 6.61245C41.6677 6.48903 41.6677 6.22848 41.799 6.22848C41.9856 6.22848 42.1445 6.61931 42.2067 6.78387C43.2223 9.98597 42.9321 13.9149 41.4881 18.3375C47.2297 21.3819 50.5255 25.3314 49.9313 29.0203C49.1436 33.868 41.9648 36.7273 32.3056 36.3913C33.2453 35.3902 34.5373 33.916 35.5323 32.6201C39.2495 31.9001 41.9234 30.4396 42.6143 28.1015C43.1256 26.401 41.9441 24.5154 39.7055 22.7395C36.0228 30.4396 29.8666 37.3307 24.1803 41.3144C26.9371 44.8045 29.5073 47.2249 32.4369 48.5826C32.5474 48.6374 32.7824 48.7677 32.6994 48.8911C32.6096 49.0557 32.1743 48.994 32.0085 48.9597C28.7059 48.2329 25.4308 46.025 22.2733 42.5623C16.7458 45.977 11.6537 46.8341 8.73103 44.4754C4.88946 41.3761 5.98804 33.772 11.1009 25.6536C11.5017 26.9564 12.1511 28.8077 12.7799 30.3093C11.5431 33.868 11.4878 36.8918 13.1737 38.6609C14.4036 39.9499 16.6353 39.8814 19.3092 38.8391C14.4658 31.8178 11.5224 23.0824 10.8936 16.1982C6.47169 16.8221 3.07231 17.8164 0.426042 19.6608C0.329311 19.7294 0.0943945 19.8665 0.0183921 19.7431C-0.0714289 19.5786 0.191125 19.2494 0.301674 19.1123C2.58866 16.637 6.16077 14.916 10.7416 13.956C10.5275 7.49698 12.317 2.69041 15.8476 1.3602C20.47 -0.388271 26.5571 4.35659 31.0896 12.8178C29.7423 12.5093 27.8146 12.139 26.184 11.9333C23.6966 9.0946 21.0849 7.53127 18.6943 8.10038C16.96 8.51178 15.9029 10.4659 15.4745 13.2704C24.0352 12.5847 33.1278 14.4291 39.4498 17.3295C41.1219 13.2361 41.951 9.81455 41.6677 6.61245ZM21.6722 37.7421C28.4433 33.9023 33.9431 28.0329 37.5843 21.2722C30.8339 17.3844 22.978 15.5879 15.2603 15.8416C15.2465 23.5829 17.6026 31.2487 21.6791 37.7421H21.6722Z" fill="#148EFF" />
    </SvgIcon>
  );
}

export default BlizzardIcon;