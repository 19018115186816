import React from 'react';
import { Typography, Button, Container } from '@mui/material';

interface VerificationStatusProps {
    verificationUrl: string;
}

const VerificationStatus: React.FC<VerificationStatusProps> = ({ verificationUrl }) => {
    // Log the full verification URL for debugging
    console.log('Full verification URL:', verificationUrl);

    let errorCode: string | null = null;
    let cleanVerificationUrl: string = verificationUrl;

    // Ensure correct URL parsing
    if (verificationUrl.includes('&error=')) {
        const urlParts = verificationUrl.split('&error=');
        cleanVerificationUrl = urlParts[0];
        errorCode = urlParts[1]; // Get the error code directly from the URL
    } else {
        try {
            const url = new URL(verificationUrl);
            errorCode = url.searchParams.get('error'); // Fallback in case the error param is part of query string
        } catch (error) {
            console.error("Invalid verification URL:", verificationUrl, error);
        }
    }

    // Log the extracted error code and cleaned URL for debugging
    console.log('Extracted error code:', errorCode);
    console.log('Clean verification URL:', cleanVerificationUrl);

    // Define error messages based on the error code
    const errorMessages: Record<string, string> = {
        'document_expired': 'The document you uploaded has expired. Please upload a valid document.',
        'document_type_not_supported': 'The document type you provided is not supported. Please upload a valid ID type.',
        'document_unverified_other': 'We were unable to verify the document. Please try again with a clearer document.',
        'selfie_unverified_other': 'Your selfie could not be verified. Please ensure the image is clear and try again.',
        'consent_declined': 'You declined consent for identity verification. Please grant consent to proceed.',
        'document_manipulation': 'The document seems altered. Please upload an unaltered, valid document.',
        'id_number_mismatch': 'The ID number does not match. Please try again with the correct information.',
        'name_mismatch': 'The name on the document does not match the provided information. Please correct this.',
    };

    // Fallback message if no error code is present or unhandled error
    const fallbackMessage = 'Please verify your identity to proceed with the payment.';

    // Check if there is an error code and get corresponding message
    const errorMessage = errorCode && errorMessages[errorCode] ? errorMessages[errorCode] : fallbackMessage;

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="h4" sx={{ mb: 3 }}>
                Identity Verification Required
            </Typography>
            <Typography variant="h6" sx={{ mb: 3 }}>
                {errorMessage}
            </Typography>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => (window.location.href = cleanVerificationUrl)}
            >
                Verify Identity
            </Button>
        </Container>
    );
};

export default VerificationStatus;
