import React from 'react';
import { Grid2 as Grid, Skeleton } from '@mui/material';

const PaymentSkeleton = () => (
    <>
        <Grid container spacing={4}>
            <Grid size={{ xs: 12, sm: 8 }}>
                <Skeleton variant="rectangular" width={'100%'} height={372} sx={{ borderRadius: '11px' }} />
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }} sx={{
                position: 'sticky',
                top: 90,
                height: 'fit-content'
            }}>
                <Skeleton variant="rectangular" width={'100%'} height={354} sx={{ borderRadius: '11px' }} />
            </Grid>
        </Grid>
    </>
);

export default PaymentSkeleton;
