import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid2 as Grid, Typography, Button, Pagination } from '@mui/material';
import ProductCardComponent from '../../../components/ProductCard';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetFilters } from 'redux/product/productSlice';
import { ProductGridProps } from 'redux/product/ProductsTypes';
import noProductImage from 'assets/noProduct.webp';

const ProductGrid: React.FC<ProductGridProps> = ({ products, filter, searchQuery }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const itemsPerPage = 24;

  const filteredProducts = products.filter(product =>
    (!filter.type || product.type === filter.type) &&
    (!filter.platform || product.platformId === filter.platform) &&
    (!filter.category || product.category === filter.category) &&
    product.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const count = Math.ceil(filteredProducts.length / itemsPerPage);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const paginatedProducts = filteredProducts.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const handleResetFilters = () => {
    dispatch(resetFilters());
    navigate('/products');
  };

  return (
    <div>
      <Grid container spacing={4}>
        {paginatedProducts.length > 0 ? paginatedProducts.map((product, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={`${product.productId}-${product._id}-${index}`}>
            <ProductCardComponent
              title={product.title}
              type={product.type}
              price={product.price}
              category={product.category}
              platform={product.platformId}
              productId={product.productId}
              platformImageUrl={product.platformId}
              region={product.region}
              regionId={product.region}
              {...(product.editionName && { editionName: product.editionName })}
              {...(product.regionCurrency && { regionCurrency: product.regionCurrency })}
            />
          </Grid>
        )) : (
          <Grid size={{ xs: 12 }} display="flex" flexDirection="column" alignItems="center" gap="23px">
            <img src={noProductImage} alt="No products" />
            <Typography variant="h3">{t('noProductsFound')}</Typography>
            <Typography variant="h6">{t('tryDifferentQuery')}</Typography>
            <Button variant="contained" color="primary" onClick={handleResetFilters}>
              {t('resetFilters')}
            </Button>
          </Grid>
        )}
      </Grid>

      <Pagination
        count={count}
        page={page}
        boundaryCount={2}
        showFirstButton
        showLastButton
        onChange={handleChangePage}
        sx={{ mt: 8, display: 'flex', justifyContent: 'center' }}
      />
      <Typography variant="body1" mt={3} textAlign={'center'}>
        {t('total')} {filteredProducts.length} {t('products')}
      </Typography>
    </div>
  );
};

export default ProductGrid;
