import { createSlice } from '@reduxjs/toolkit';
import { PublisherState } from '../types/dataTypes';
import { fetchPublishers } from '../actions/fetchDataActions';

const initialState: PublisherState = {
  loading: false,
  publishers: [],
  error: null,
};

const publisherSlice = createSlice({
  name: 'publisher',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublishers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPublishers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.publishers = payload;
      })
      .addCase(fetchPublishers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || 'Failed to fetch publishers';
      });
  },
});

export default publisherSlice.reducer;
