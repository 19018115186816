import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { fetchProductById, fetchProductDetailById } from 'redux/product/productActions';
import { setLanguage } from 'redux/reducers/detailReducer';

import { Container } from '@mui/material';
import { ProductCover } from './Components/ProductCover';
import { ProductCart } from './Components/ProductCart';
import { ProductAbout } from './Components/ProductAbout';
import { ProdcutDescription } from './Components/ProdcutDescription';
import { ProductGallery } from './Components/ProductGallery';
import { ProductRequirements } from './Components/ProductRequirements';
import { ProductReviews } from './Components/ProductReviews';
import NoDataFound from 'components/NoDataFound';
import { ProductLoading } from './Components/ProductLoading';

import { getProductImgLg } from 'utils/getNames';
import SEO from 'utils/SEO';

export default function ProductPage() {
  const { productId, platformName, regionName, categoryId } = useParams<{ productId?: string; platformName?: string; regionName?: string, categoryId?: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);

  const product = useSelector((state: RootState) => state.product.products.find(p => p._id === productId));
  const productDetail = useSelector((state: RootState) => productId ? state.productDetail.productDetails[productId] : undefined);
  const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
  const regions = useSelector((state: RootState) => state.productInfo.region.regions);
  const developers = useSelector((state: RootState) => state.productInfo.developer.developers);
  const publishers = useSelector((state: RootState) => state.productInfo.publisher.publishers);
  const genres = useSelector((state: RootState) => state.productInfo.genre.genres);
  const ratings = useSelector((state: RootState) => state.productInfo.rating.ratings);
  const categories = useSelector((state: RootState) => state.productInfo.category.categories);
  const types = useSelector((state: RootState) => state.productInfo.type.types);
  const language = useSelector((state: RootState) => state.productDetail.language);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('userLanguage');
    if (storedLanguage && storedLanguage !== language) {
      dispatch(setLanguage(storedLanguage));
    }
  }, [dispatch, language]);

  useEffect(() => {
    if (platforms.length === 0 || regions.length === 0) {
      setIsLoading(true);
      Promise.all([

      ]).then(() => setIsLoading(false));
    }
  }, [dispatch, platforms.length, regions.length]);

  useEffect(() => {
    if (productId) {
      setIsLoading(true);
      Promise.all([
        dispatch(fetchProductById(productId)).unwrap(),
        dispatch(fetchProductDetailById({ productId: productId, lang: language })).unwrap()
      ]).finally(() => {
        setIsLoading(false);
      });
    }
  }, [dispatch, productId, language]);

  if (!productDetail) {
    return <ProductLoading />;
  }

  if (!product) {
    return <NoDataFound text="Product not found or details are missing." />;
  }

  const decodedPlatformName = platformName?.replace(/-/g, ' ').toLowerCase();
  const decodedRegionName = regionName?.replace(/-/g, ' ').toLowerCase();
  const matchedPlatform = platforms.find(p => p.name.toLowerCase() === decodedPlatformName);
  const matchedRegion = regions.find(r => r.name.toLowerCase() === decodedRegionName);
  const currentPlatformDetail = productDetail?.platforms.find(p => p.platformId === matchedPlatform?._id);
  const developerName = developers.find(dev => dev._id === productDetail.developer)?.name || 'Unknown Developer';
  const publisherName = publishers.find(pub => pub._id === productDetail.publisher)?.name || 'Unknown Publisher';
  const selectedPlatform = product?.platforms.find(p => p.platformId === matchedPlatform?._id)?.platformId;
  
  const ratingNameId = (productDetail.ratings ?? []).map(ratingId => {
    const rating = ratings.find(rat => rat._id === ratingId);
    return { id: ratingId, name: rating?.name || 'Unknown Rating' };
  });

  const genresWithId = (product.genre ?? []).map(genreId => {
    const genre = genres.find(gen => gen._id === genreId);
    return { id: genreId, name: genre?.name || 'Unknown Genre' };
  });


  const getTypeName = (typeId: string): string => {
    const type = types.find(t => t._id === typeId);
    return type ? type.name : 'Unknown Type';
  };

  if (!matchedPlatform || !matchedRegion) {
    return <NoDataFound text="Platform or region information not found." />;
  }

  const platformDetail = product.platforms.find(platform =>
    platform.platformId === matchedPlatform._id && platform.region === matchedRegion.shortname
  );

  if (!platformDetail) {
    return <NoDataFound text="Platform details not found." />;
  }

  const currentCategory = categories.find(cat => cat._id === categoryId);
  const isPC = currentCategory?.name.toLowerCase() === 'pc';

  const fetchEditionDetailsById = async (editionId: string): Promise<{ title: string, firstPlatform: any }> => {
    if (!editionId) {
      return { title: 'Unknown', firstPlatform: { category: 'Unknown', region: 'Unknown', platformId: 'Unknown' } };
    }
    try {
      const response = await dispatch(fetchProductById(editionId)).unwrap();
      if (!response || !response.platforms || response.platforms.length === 0) {
        return { title: 'Unknown', firstPlatform: { category: 'Unknown', region: 'Unknown', platformId: 'Unknown' } };
      }
      const firstPlatform = response.platforms[0];
      return {
        title: response.title || 'Unknown',
        firstPlatform: {
          category: firstPlatform.category || 'Unknown',
          region: firstPlatform.region || 'Unknown',
          platformId: firstPlatform.platformId || 'Unknown'
        }
      };
    } catch (error) {
      console.error("Failed to fetch edition details:", error);
      return { title: 'Unknown', firstPlatform: { category: 'Unknown', region: 'Unknown', platformId: 'Unknown' } };
    }
  };

  return (
    <>
      <SEO
        title={product.title}
        description={productDetail?.description}
        keywords={`${genresWithId.join(', ')}, Bigswiss, video games, digital codes, gift cards, game points, subscription cards, gaming, online marketplace`}
        image={getProductImgLg(product._id)}
      />

      <ProductCover productCoverImg={product._id} />
      <Container sx={{ mt: { xs: '-130px', sm: '-340px' }, gap: 6, pt: { xs: 14, sm: 20 }, pb: { xs: 8, sm: 12 } }}>
        <ProductCart
          productId={product._id}
          ProductImage={product._id}
          ProductTitle={product.title}
          ProductType={getTypeName(product.type)}
          categoryId={categoryId || ''}
          ProductRegion={decodedRegionName || ''}
          platformName={matchedPlatform.name || ''}
          platformsDetails={product?.platforms}
          ProductPrice={platformDetail.price}
          selectedPlatform={selectedPlatform || ''}
          editions={productDetail.editions}
          platformGroupId={platformDetail._id}
          fetchEditionDetailsById={fetchEditionDetailsById}
        />
        <ProductAbout
          ProductDescription={productDetail?.description || "Description not available"}
          Ratings={ratingNameId}
          Developer={developerName}
          Publisher={publisherName}
          Genres={genresWithId}
          // Languages={'productDetail.languages'}
          ReleaseDate={productDetail.releaseDate}
          Website={productDetail.website}
          metacritic={productDetail.metacritic}
        />
        {/* <ProductRelated /> */}

        <ProductGallery title={product.title} productId={product._id} video={productDetail.video} />
        <ProdcutDescription ProductDescription={productDetail.descriptionLong || 'Long Description is missing'} />

        {isPC && productDetail.requirements && (
          <ProductRequirements
            minimumRequirements={productDetail.requirements.minimumRequirements}
            recommendedRequirements={productDetail.requirements.recommendedRequirements}
            minimumRequirementsMac={productDetail.requirements_mac?.minimumRequirements}
            recommendedRequirementsMac={productDetail.requirements_mac?.recommendedRequirements}
            minimumRequirementsLinux={productDetail.requirements_linux?.minimumRequirements}
            recommendedRequirementsLinux={productDetail.requirements_linux?.recommendedRequirements}
          />
        )}


        {/* <ProductSuggestions /> */}

        {productDetail.reviews && productDetail.reviews.length > 0 && (
          <ProductReviews reviews={productDetail.reviews} />
        )}

      </Container>
    </>
  );
}
