import { createSlice } from '@reduxjs/toolkit';
import { DeveloperState } from '../types/dataTypes';
import { fetchDevelopers } from '../actions/fetchDataActions';

const initialState: DeveloperState = {
  loading: false,
  developers: [],
  error: null,
};

const developerSlice = createSlice({
  name: 'developer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDevelopers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDevelopers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.developers = payload;
      })
      .addCase(fetchDevelopers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || 'Failed to fetch developers';
      });
  },
});

export default developerSlice.reducer;
