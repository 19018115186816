import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import ClearIcon from '@mui/icons-material/Clear';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

const iconSize = 55;

const shapes = [
  <ChangeHistoryIcon key="triangle" sx={{ color: '#00AC9F', fontSize: iconSize }} />,
  <CropSquareIcon key="square" sx={{ color: '#F3C300', fontSize: iconSize }} />,
  <PanoramaFishEyeIcon key="circle" sx={{ color: '#DF0024', fontSize: iconSize }} />,
  <ClearIcon key="cross" sx={{ color: '#2E6DB4', fontSize: iconSize }} />
];

const texts = [
  '▲ ■ ● ✖',
  'Welcome',
  'X Y A B',
  'Willkommen',
  'X Y B A',
  'Benvenuto',
  'W A S D',
  'Bienvenue'
];

const colorMap: { [key: string]: string } = {
  X: '#066ed8',
  Y: '#ff8b06',
  A: '#92a136',
  B: '#e30f0d' 
};

const CoverComponent: React.FC = () => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(200);

  useEffect(() => {
    const handleTyping = () => {
      const currentIndex = loopNum % texts.length;
      const fullText = texts[currentIndex];
      const updatedText = isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1);

      setText(updatedText);

      if (!isDeleting && updatedText === fullText) {
        setTimeout(() => setIsDeleting(true), 1800);
        setTypingSpeed(50);
      } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setTypingSpeed(100);
        setLoopNum(loopNum + 1);
      }
    };

    const typingTimer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(typingTimer);
  }, [text, isDeleting, typingSpeed, loopNum]);

  const getShapeIcons = () => {
    const currentLength = text.length;
    return shapes.slice(0, currentLength).map((shape, index) => (
      <Box key={index} component="span" sx={{ mx: 0.5 }}>
        {shape}
      </Box>
    ));
  };

  const getColoredText = () => {
    if (texts[loopNum % texts.length] === 'X Y A B' || texts[loopNum % texts.length] === 'X Y B A') {
      return text.split('').map((char, index) => (
        <Box
          key={index}
          component="span"
          sx={{ color: colorMap[char] || 'inherit', transition: 'opacity 0.6s', fontSize: 'inherit' }}
        >
          {char}
        </Box>
      ));
    }
    return text;
  };

  return (

    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height:'100%' }}>
      <div className="circles">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
      <Box sx={{ position: 'absolute', zIndex: 10, textAlign: 'center' }}>
        <Typography
          variant="h2"
          className="typing-effect"
          sx={{ transition: 'opacity 0.6s', fontWeight: 900 }}
        >
          {texts[loopNum % texts.length] === '▲ ■ ● ✖' ? getShapeIcons() : getColoredText()}
        </Typography>
      </Box>
    </Box>

  );
};

export default CoverComponent;
