import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SteamIcon = (props: SvgIconProps) => {

  return (
      <SvgIcon
      {...props}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
    >
      <g clipPath="url(#clip0_275_5)">
        <path d="M1.11328 32.191C4.20191 42.4936 13.7562 50 25.0639 50C38.8708 50 50.0639 38.8069 50.0639 25C50.0639 11.1929 38.8708 0 25.0639 0C11.8148 0 0.973797 10.3067 0.117574 23.3391C1.73539 26.0558 2.36672 27.7361 1.1135 32.191H1.11328Z" fill="url(#paint0_linear_275_5)" />
        <path d="M23.7762 18.7386C23.7762 18.7807 23.7762 18.8228 23.7783 18.8622L17.6607 27.7489C16.6697 27.7039 15.6753 27.8777 14.7324 28.2661C14.3167 28.4356 13.9272 28.6438 13.5618 28.8841L0.128521 23.3541C0.128521 23.3541 -0.182209 28.4678 1.11307 32.279L10.6096 36.1974C11.0865 38.3283 12.5483 40.1974 14.7049 41.0966C18.2335 42.5708 22.3019 40.8906 23.7697 37.3605C24.1517 36.4378 24.3298 35.47 24.3041 34.5043L33.0573 28.2489C33.1281 28.2511 33.2011 28.2532 33.2719 28.2532C38.5101 28.2532 42.7676 23.9828 42.7676 18.7386C42.7676 13.494 38.5101 9.22747 33.2719 9.22747C28.0358 9.22747 23.7762 13.494 23.7762 18.7386ZM22.3083 36.7468C21.1723 39.4743 18.038 40.7682 15.3135 39.6331C14.0566 39.1094 13.1077 38.1502 12.5603 37.0064L15.6515 38.2876C17.6607 39.1245 19.9659 38.1717 20.8011 36.1631C21.6388 34.1524 20.6884 31.8434 18.6802 31.0064L15.4847 29.6824C16.7178 29.2146 18.1195 29.1974 19.4292 29.7425C20.7495 30.2918 21.774 31.3262 22.3169 32.6481C22.8599 33.97 22.8577 35.4292 22.3083 36.7468ZM33.2719 25.0773C29.7847 25.0773 26.9457 22.2339 26.9457 18.7386C26.9457 15.2461 29.7847 12.4019 33.2719 12.4019C36.7611 12.4019 39.6002 15.2461 39.6002 18.7386C39.6002 22.2339 36.7611 25.0773 33.2719 25.0773ZM28.5315 18.729C28.5315 16.0996 30.6603 13.967 33.2826 13.967C35.9071 13.967 38.0358 16.0996 38.0358 18.729C38.0358 21.3586 35.9071 23.4893 33.2826 23.4893C30.6603 23.4893 28.5315 21.3586 28.5315 18.729Z" fill="white" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_275_5" x1="25.0907" y1="0" x2="25.0907" y2="50" gradientUnits="userSpaceOnUse">
          <stop stopColor="#111D2E" />
          <stop offset="0.21" stopColor="#051839" />
          <stop offset="0.41" stopColor="#0A1B48" />
          <stop offset="0.58" stopColor="#132E62" />
          <stop offset="0.74" stopColor="#144B7E" />
          <stop offset="0.87" stopColor="#136497" />
          <stop offset="1" stopColor="#1387B8" />
        </linearGradient>
        <clipPath id="clip0_275_5">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default SteamIcon;