import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { useTheme, Theme } from '@mui/material/styles';

const whiteLogos = [
  '/img/platforms/steam.png',
  '/img/platforms/origin.png',
  '/img/platforms/ps.png',
  '/img/platforms/xbox.png',
  '/img/platforms/u-play.png',
  '/img/platforms/nintendo.png',
];

const logoStyle = (theme: Theme) => ({
  width: 'auto',
  height: '40px',
  margin: '32px',
  opacity: 0.7,
  filter: theme.palette.mode === 'light' ? 'invert(1)' : 'none',
});

export default function LogoCollection() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="h1"
        variant="h4"
        align="center"
        color="text.secondary"
      >
        {t('ourTopSellingProducts')}
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 0.6 }}>
        {whiteLogos.map((logo, index) => (
          <Grid size="auto" key={index}>
            <Box
              component="img"
              src={logo}
              alt={`Logo number ${index + 1}`}
              sx={logoStyle(theme)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
