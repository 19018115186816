import React, { Component, Children, createRef, ReactNode } from "react"
import { Flipped } from "react-flip-toolkit"
import {
  DropdownRoot,
  Caret,
  DropdownBackground,
  AltBackground,
  InvertedDiv
} from "./Components"
import FadeContents from "./FadeContents"

interface IDropdownContainerProps {
  children: ReactNode;
  $animatingOut?: boolean;
  direction?: "left" | "right";
  $duration: number;
}

const getFirstDropdownSectionHeight = (el: HTMLElement | null) => {
  if (
    !el ||
    !el.querySelector ||
    !el.querySelector("*[data-first-dropdown-section]")
  )
    return 0
  return (el.querySelector("*[data-first-dropdown-section]") as HTMLElement).offsetHeight
}

const updateAltBackground = ({
  altBackground,
  prevDropdown,
  currentDropdown
}: {
  altBackground: HTMLDivElement;
  prevDropdown: HTMLDivElement | null;
  currentDropdown: HTMLDivElement | null;
}) => {
  const prevHeight = getFirstDropdownSectionHeight(prevDropdown)
  const currentHeight = getFirstDropdownSectionHeight(currentDropdown)

  const immediateSetTranslateY = (el: HTMLDivElement, translateY: number) => {
    el.style.transform = `translateY(${translateY}px)`
    el.style.transition = "transform 0s"
    requestAnimationFrame(() => (el.style.transitionDuration = ""))
  }

  if (prevHeight) {
    immediateSetTranslateY(altBackground, prevHeight)
    requestAnimationFrame(() => {
      altBackground.style.transform = `translateY(${currentHeight}px)`
    })
  } else {
    immediateSetTranslateY(altBackground, currentHeight)
  }
}

class DropdownContainer extends Component<IDropdownContainerProps> {
  currentDropdownEl = createRef<HTMLDivElement>()
  prevDropdownEl = createRef<HTMLDivElement>()
  altBackgroundEl!: HTMLDivElement

  componentDidMount() {
    updateAltBackground({
      altBackground: this.altBackgroundEl,
      prevDropdown: this.prevDropdownEl.current,
      currentDropdown: this.currentDropdownEl.current,
    })
  }

  render() {
    const { children, direction, $animatingOut, $duration } = this.props
    const [currentDropdown, prevDropdown] = Children.toArray(children)
    return (
      <DropdownRoot
        direction={direction}
        $animatingOut={$animatingOut}
        $duration={$duration}
      >
        <Flipped flipId="dropdown-caret">
          <Caret />
        </Flipped>
        <Flipped flipId="dropdown">
          <DropdownBackground>
            <Flipped inverseFlipId="dropdown">
              <InvertedDiv>
                <AltBackground
                  ref={el => (this.altBackgroundEl = el as HTMLDivElement)}
                  $duration={$duration}
                />
                <FadeContents
                  direction={direction}
                  $duration={$duration}
                  ref={this.currentDropdownEl}
                >
                  {currentDropdown}
                </FadeContents>
              </InvertedDiv>
            </Flipped>

            <Flipped inverseFlipId="dropdown" scale>
              <InvertedDiv absolute>
                {prevDropdown && (
                  <FadeContents
                    $animatingOut
                    direction={direction}
                    $duration={$duration}
                    ref={this.prevDropdownEl}
                  >
                    {prevDropdown}
                  </FadeContents>
                )}
              </InvertedDiv>
            </Flipped>
          </DropdownBackground>
        </Flipped>
      </DropdownRoot>
    )
  }
}

export default DropdownContainer