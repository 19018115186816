import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Link as RouterLink } from 'react-router-dom';
import UserMenu from './UserMenu';

function LoginButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const user = useSelector((state: RootState) => state.auth.user);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {user ? (
        <>
          <Button
            variant="text"
            size="small"
            aria-label="User Menu"
            sx={{ minWidth: '32px', height: '32px', p: '4px', color: 'text.primary' }}
            onClick={handleMenu}
          >
            <PersonIcon />
          </Button>
          <UserMenu anchorEl={anchorEl} handleClose={handleClose} />
        </>
      ) : (
        <Button
          variant="text"
          aria-label="Login Button"
          size="small"
          component={RouterLink}
          to="/login"
          sx={{ minWidth: '32px', height: '32px', p: '4px', color: 'text.primary' }}
        >
          <PersonIcon fontSize="small" />
        </Button>
      )}
    </Box>
  );
}

export default LoginButton;
