import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PsPlusIcon = (props: SvgIconProps) => {

  return (
      <SvgIcon
      {...props}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
    >
      <g clipPath="url(#clip0_296_59)">
        <path d="M49.2953 25.6184L47.7208 14.1229C47.6642 13.6938 47.4369 13.3056 47.0902 13.0465C46.7421 12.7858 46.3056 12.6719 45.8745 12.7295L32.6616 14.5409L30.8641 1.40808C30.8048 0.979073 30.5766 0.591541 30.2301 0.331678C29.8834 0.070838 29.4479 -0.0430372 29.0178 0.0146795L17.4282 1.60316C17.2108 1.63318 17.0014 1.70536 16.8117 1.81565C16.6304 1.92342 16.4719 2.0654 16.3449 2.23367C16.2153 2.40002 16.1205 2.59076 16.0662 2.79451C16.0084 3.00422 15.9941 3.22355 16.0244 3.43896L17.8184 16.5613L4.60547 18.3692C4.38793 18.3987 4.17837 18.4709 3.9889 18.5817C3.80659 18.6894 3.64688 18.8313 3.51862 18.9998C3.25486 19.3425 3.13942 19.7766 3.19814 20.2051L4.77617 31.7006C4.83346 32.1294 5.06064 32.5173 5.40668 32.777C5.7545 33.0382 6.19166 33.1509 6.62242 33.0905L19.8353 31.2826L21.6328 44.4049C21.6915 44.8352 21.9195 45.2242 22.266 45.4858C22.6126 45.7473 23.0492 45.8598 23.4791 45.7983L35.0687 44.2133C35.4992 44.1552 35.8889 43.9284 36.1521 43.5828C36.4164 43.239 36.5318 42.8036 36.4725 42.3741L34.6785 29.2552L47.8915 27.4473C48.1091 27.4161 48.3185 27.3427 48.508 27.2313C48.6886 27.1227 48.8479 26.9822 48.9783 26.8167C49.1042 26.6486 49.1975 26.4584 49.2535 26.2559C49.3097 26.0483 49.324 25.8316 49.2953 25.6184Z" fill="url(#paint0_linear_296_59)" />
        <path d="M40.172 15.8124C38.1084 16.0937 36.4176 17.5905 35.8881 19.6048C35.3586 21.619 36.0947 23.7539 37.7532 25.0137C39.4116 26.2735 41.6657 26.41 43.4641 25.3597C45.2625 24.3093 46.251 22.2789 45.9686 20.2155C45.7843 18.8627 45.0699 17.6386 43.9827 16.8127C42.8955 15.9869 41.5247 15.627 40.172 15.8124ZM41.332 24.2982C39.9607 24.4886 38.6099 23.8336 37.91 22.6391C37.2102 21.4445 37.2994 19.9459 38.1359 18.8428C38.9725 17.7397 40.3915 17.2496 41.7305 17.6013C43.0696 17.953 44.0646 19.0771 44.2512 20.4489C44.5033 22.3167 43.1986 24.0371 41.332 24.2982ZM23.3885 2.99307L19.17 13.5655L30.5332 12.0119L23.3885 2.99307ZM21.8801 11.4545L23.8901 6.40342L27.3074 10.709L21.8801 11.4545ZM6.12079 20.9401L7.39227 30.2236L16.6723 28.9521L15.4043 19.6686L6.12079 20.9401ZM8.86927 28.2763L8.06458 22.4171L13.9447 21.6159L14.7459 27.4751L8.86927 28.2763ZM29.4742 37.4762L33.5011 40.5348L32.4561 41.9107L28.4291 38.8522L25.3741 42.8792L23.9807 41.8341L27.0357 37.8107L23.0123 34.7521L24.0573 33.3588L28.0843 36.4173L31.1428 32.3903L32.5362 33.4354L29.4742 37.4762Z" fill="#C58821" />
        <path d="M48.9748 26.8272L45.8013 30.983C45.5374 31.3277 45.1481 31.5543 44.718 31.6135L35.1941 32.9198L34.6959 29.2656L47.9089 27.4577C48.1265 27.4265 48.3359 27.3531 48.5254 27.2417C48.6995 27.1325 48.8519 26.9919 48.9748 26.8272ZM35.6818 44.0113C35.4936 44.1221 35.2851 44.1944 35.0687 44.2238L23.4791 45.8088C23.0492 45.8703 22.6126 45.7578 22.266 45.4962C21.9194 45.2346 21.6915 44.8456 21.6328 44.4154L19.8353 31.293L6.62938 33.1044C6.19861 33.1649 5.76146 33.0521 5.41364 32.7909C5.0676 32.5312 4.84041 32.1434 4.78312 31.7145L3.2051 20.219C3.14638 19.7905 3.26181 19.3564 3.52558 19.0137L0.359076 23.1556C0.0862736 23.4958 -0.0391529 23.9311 0.0107255 24.3643L1.58527 35.8599C1.64455 36.2889 1.87281 36.6764 2.21927 36.9363C2.56709 37.1975 3.00424 37.3102 3.43501 37.2498L16.6479 35.4419L18.4733 48.5747C18.5298 49.0038 18.7572 49.3919 19.1038 49.6511C19.4519 49.9118 19.8884 50.0256 20.3195 49.9681L31.9091 48.3796C32.3402 48.3233 32.7306 48.0961 32.9925 47.7491L36.166 43.5933C36.0335 43.763 35.869 43.905 35.6818 44.0113ZM16.0348 3.43895C16.0046 3.22353 16.0188 3.00421 16.0766 2.7945C16.1309 2.59075 16.2257 2.40001 16.3553 2.23366L13.1679 6.38948C12.9043 6.73218 12.79 7.16671 12.8509 7.59477L14.1468 17.0629L17.8184 16.5613L16.0348 3.43895Z" fill="url(#paint1_linear_296_59)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_296_59" x1="680.229" y1="1070.97" x2="3924.21" y2="3534.19" gradientUnits="userSpaceOnUse">
          <stop offset="0.03" stopColor="#FFE842" />
          <stop offset="1" stopColor="#FDC100" />
        </linearGradient>
        <linearGradient id="paint1_linear_296_59" x1="614.193" y1="1089.81" x2="3645.99" y2="4121.64" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C58821" />
          <stop offset="1" stopColor="#9B6C1B" />
        </linearGradient>
        <clipPath id="clip0_296_59">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default PsPlusIcon;