import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchLocationData } from './locationActions';

interface ApplicationState {
  selectedCurrency: string;
  selectedRegion: string;
  userIp: string | null;  // New field for storing user IP
  isLoading: boolean;
  locationStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isOnline: boolean;
}

const initialState: ApplicationState = {
  selectedCurrency: 'EUR',
  selectedRegion: 'EU',
  userIp: null,  // Initialize IP as null
  isLoading: false,
  locationStatus: 'idle',
  error: null,
  isOnline: navigator.onLine,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setSelectedCurrency(state, action: PayloadAction<string>) {
      state.selectedCurrency = action.payload;
    },
    setCurrencyAndRegion(state, action: PayloadAction<{ currency: string, region: string }>) {
      state.selectedCurrency = action.payload.currency;
      state.selectedRegion = action.payload.region;
    },
    setUserIp(state, action: PayloadAction<string>) {
      state.userIp = action.payload;
    },
    setOnlineStatus(state, action: PayloadAction<boolean>) {
      state.isOnline = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocationData.pending, (state) => {
        state.isLoading = true;
        state.locationStatus = 'loading';
        state.error = null;
      })
      .addCase(fetchLocationData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locationStatus = 'succeeded';
        state.selectedCurrency = action.payload.currency || 'EUR'; 
        state.selectedRegion = action.payload.region || 'EU';
        state.userIp = action.payload.ip || null;
      })
      .addCase(fetchLocationData.rejected, (state, action) => {
        state.isLoading = false;
        state.locationStatus = 'failed';
        state.error = action.error.message || 'Failed to fetch location data';
        state.selectedCurrency = 'EUR';
        state.selectedRegion = 'EU';
      });
  },
});

export const { setSelectedCurrency, setCurrencyAndRegion, setUserIp, setOnlineStatus } = applicationSlice.actions;

export default applicationSlice.reducer;
