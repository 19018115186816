import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, TextField, Button } from '@mui/material';
import axios from 'axios';
import useSnackbarAlert from 'utils/useSnackbarAlert';

interface EmailSubscribeProps {
  userId?: string;
}

const EmailSubscribe: React.FC<EmailSubscribeProps> = ({ userId }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const showAlert = useSnackbarAlert();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      showAlert(t('invalidEmail'), 'error');
      return;
    }

    setLoading(true);
    try {
      await axios.post('/api/mail/subscribe', { email, userId });
      setSuccess(true);
      setEmail('');
      showAlert(t('succesSubcribed'), 'success');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data === 'duplicate_subscription') {
        showAlert(t('duplicateSubscription'), 'error');
      } else {
        console.error('Subscription failed', error);
        showAlert(t('subscribeFailed'), 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Stack direction="row" spacing={1} useFlexGap>
      <TextField
        id="outlined-basic"
        hiddenLabel
        size="small"
        variant="outlined"
        fullWidth
        value={email}
        onChange={handleEmailChange}
        aria-label={t('enterEmail')}
        placeholder={t('yourEmail')}
        slotProps={{
          input: {
            autoComplete: 'off',
            'aria-label': t('enterEmail'),
          },
        }}
        disabled={loading || success}
      />

      <Button
        variant="contained"
        color="primary"
        sx={{ flexShrink: 0 }}
        onClick={handleSubscribe}
        disabled={loading || success}
      >
        {success ? t('subscribed') : t('subscribe')}
      </Button>
    </Stack>
  );
};

export default EmailSubscribe;
