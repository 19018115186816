import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FrenchSwissFlag: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 60 30" preserveAspectRatio="xMidYMid meet">
    <rect width="20" height="30" fill="#00267f"/>
    <rect width="20" height="30" x="20" fill="#fff"/>
    <rect width="20" height="30" x="40" fill="#f31830"/>
  </SvgIcon>
);
export default FrenchSwissFlag;
