import React from "react";
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store'; 

import styled from "styled-components";
import { List, ListItem, Link as MaterialLink, Typography } from '@mui/material';
import { DropdownSection } from "./Components";
import navbarConfig from '../../navbarConfig';

const CompanyDropdownEl = styled.div`
  width: 18.5rem;
`;

const CompanyDropdown = () => {
  const { t } = useTranslation();
  const isOnline = useSelector((state: RootState) => state.application.isOnline);

  const navConfig = navbarConfig(t, isOnline);
  const companyItem = navConfig.find(item => item.title === t('aboutUs'));

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    }
  };

  return (
    <CompanyDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <List sx={{ py: 0, pb: '24px' }}>
          {companyItem?.about?.map((item) => (
            <ListItem sx={{ px: 0 }} key={item.title}>
              <MaterialLink
                sx={{ display: 'flex' }}
                color="text.secondary"
                component={RouterLink}
                to={item.link}
                onClick={() => scrollToSection(item.sectionId)}
              >
                {item.title} &rsaquo;
              </MaterialLink>
            </ListItem>
          ))}
        </List>
      </DropdownSection>
      <DropdownSection>
        <Typography
          variant="h6"
          component="p"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.secondary"
        >
          {t('getInTouch')}
        </Typography>
        <List sx={{ mb: '24px' }}>
          {companyItem?.getInTouch?.map((item) => (
            <ListItem sx={{ px: 0 }} key={item.title}>
              <MaterialLink
                color="text.secondary"
                component={RouterLink}
                to={item.link}
              >
                {item.title} &rsaquo;
              </MaterialLink>
            </ListItem>
          ))}
        </List>
      </DropdownSection>
    </CompanyDropdownEl>
  )
}

export default CompanyDropdown;
