import { useNavigate } from 'react-router-dom';
import { Grid2 as Grid, Typography, Link, Box, Chip, Avatar, Card, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TagIcon from '@mui/icons-material/Tag';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface ProductAboutProps {
    ProductDescription: string;
    [key: string]: any;
    Ratings: { id: string; name: string }[];
    Developer: string;
    Publisher: string;
    Genres: { id: string; name: string }[];
    metacritic?: { score: number; link: string };
    ReleaseDate: string;
    Website: string;
}

export const ProductAbout = ({
    ProductDescription,
    Ratings,
    Developer,
    Publisher,
    ReleaseDate,
    Website,
    Genres,
    metacritic,
}: ProductAboutProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const formatWebsite = (url: string) => {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return `http://${url}`;
        }
        return url;
    };

    const formattedDescription = ProductDescription.replace(/\n/g, '<br />');

    const handleChipClick = (genreId: string) => {
        navigate(`/products?genre=${genreId}`);
    };

    return (
        <Grid container mb={4}>
            <Grid size={{ xs: 12 }} mb={3}>
                <Typography
                    variant="h3"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    {t('about')}&nbsp;
                    <Typography
                        component="span"
                        variant="h3"
                        sx={{
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}
                    >
                        {t('game')}&nbsp;
                    </Typography>
                </Typography>
            </Grid>
            <Grid container spacing={6}>
                <Grid size={{ xs: 12, sm: 7 }}>
                    <Typography color="text.secondary" dangerouslySetInnerHTML={{ __html: formattedDescription }} />

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 4 }}>
                        {Genres.map((genre) => (
                            <Chip
                                key={genre.id}
                                icon={<TagIcon fontSize='small' />}
                                label={genre.name}
                                onClick={() => handleChipClick(genre.id)}
                                clickable
                                sx={{ marginRight: 1, marginBottom: 1 }}
                            />
                        ))}
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 5 }}>
                    <Typography color="text.primary" sx={{ mb: 3 }}>
                        <span style={{ fontWeight: 'bold' }}>{t('released')}:</span> {ReleaseDate}<br />
                        <span style={{ fontWeight: 'bold' }}>{t('developer')}:</span> {Developer}<br />
                        <span style={{ fontWeight: 'bold' }}>{t('publisher')}:</span> {Publisher}<br />

                        {Website && (
                            <>
                                <span style={{ fontWeight: 'bold' }}>{t('website')}:</span>
                                <Link href={formatWebsite(Website)} target="_blank" rel="noopener noreferrer"> {Website}</Link><br />
                            </>
                        )}
                    </Typography>

                    {Ratings.map(rating => (
                        <img
                            src={`/img/pegi/${rating.id}.svg`}
                            alt={rating.name}
                            key={rating.id}
                            style={{ width: '70px', height: '70px', margin: '0 5px' }}
                        />
                    ))}

                    {metacritic && (
                        <Card sx={{ mt: 3 }}>
                            <CardHeader
                                avatar={
                                    <Avatar
                                        src="https://www.metacritic.com/images/icons/metacritic-icon.svg"
                                        alt="Metacritic Icon"
                                    />
                                }
                                title={<Typography variant='h5'>{t('Metacritic Score')}</Typography>}
                                subheader={
                                    <>
                                        <Link href={metacritic.link} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex' }}>
                                            <OpenInNewIcon fontSize='small' sx={{ mr: 1 }} />
                                            {t('Read reviews')}
                                        </Link>
                                    </>
                                }
                                action={
                                    <Typography variant="h4" sx={{ pr: 1 }}>
                                        {metacritic.score}
                                    </Typography>
                                }
                            />
                        </Card>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProductAbout;
