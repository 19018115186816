// src/helpers/apiHelper.ts

export const getTokenFromCookies = (): string | null => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === 'jwt_token') {
        return decodeURIComponent(value);
      }
    }
    return null;
  };
  
  // Helper function to create headers for Axios
  export const createAuthHeaders = (): Record<string, string> => {
    const token = getTokenFromCookies(); // Retrieve JWT token
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };
  
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
    return headers;
  };
  