import axios from 'axios';
import { setUserIp } from './applicationReducer';
import { AppDispatch } from '../store';

const IP_API_URL = process.env.IP_API_URL || 'https://api64.ipify.org?format=json';

const isValidIp = (ip: string): boolean => {
  const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const ipv6Regex = /^([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:)$/;
  return ipv4Regex.test(ip) || ipv6Regex.test(ip);
};

export const getUserLocation = async (dispatch: AppDispatch, userIp: string | null): Promise<{ currency: string; region: string; ip: string } | null> => {
  let ip = userIp;

  if (!ip) {
    try {
      const ipResponse = await axios.get(IP_API_URL);
      ip = ipResponse.data.ip;

      if (ip && isValidIp(ip)) {
        dispatch(setUserIp(ip));  // Save IP to Redux state
      } else {
        console.error('Failed to fetch user IP address: IP is null or invalid');
        return null;
      }
    } catch (error) {
      console.error('Failed to fetch user IP address:', error);
      return null;
    }
  } else {
    console.log('Using cached IP address from Redux state...');
  }

  try {
    if (ip) {
      const response = await axios.post('/api/location', { ip });
      return { ...response.data, ip };  // Return currency, region, and IP
    } else {
      console.error('IP is null');
      return null;
    }
  } catch (error) {
    console.error('Failed to fetch user location:', error);
    return null;
  }
};
