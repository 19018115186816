const asciiArt = `
██████╗ ██╗ ██████╗ ███████╗██╗    ██╗██╗███████╗███████╗
██╔══██╗██║██╔════╝ ██╔════╝██║    ██║██║██╔════╝██╔════╝
██████╔╝██║██║  ███╗███████╗██║ █╗ ██║██║███████╗███████╗
██╔══██╗██║██║   ██║╚════██║██║███╗██║██║╚════██║╚════██║
██████╔╝██║╚██████╔╝███████║╚███╔███╔╝██║███████║███████║
╚═════╝ ╚═╝ ╚═════╝ ╚══════╝ ╚══╝╚══╝ ╚═╝╚══════╝╚══════╝

Designed and Developed by: nikoovic
`;

function logAsciiArt() {
  console.log(asciiArt);
}

export default logAsciiArt;