import { Box, alpha } from '@mui/material';
import { getProductCover } from 'utils/getNames';

interface ProductCoverProps {
    productCoverImg: string;
    [key: string]: any;
}

export const ProductCover = ({ productCoverImg, ...rest }: ProductCoverProps) => (
    <Box {...rest} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box
            sx={(theme) => ({
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundClip: 'border-box',
                height: { xs: 200, sm: '55vh' },
                width: '100%',
                backgroundImage: `url("${getProductCover(productCoverImg)}")`,
                boxShadow:
                    theme.palette.mode === 'light'
                        ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                        : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
            })}
        />
    </Box>
);
