import React, { Component, ReactElement } from "react";
import { WithTranslation } from 'react-i18next';
import { Flipper } from "react-flip-toolkit";
import { Link as RouterLink } from 'react-router-dom';
import { Box, AppBar, Toolbar, Button, Container } from '@mui/material';
import { Menu as MenuIcon } from "@mui/icons-material";
import Navbar from "./Components/Navbar";
import NavbarItem from "./Components/Navbar/NavbarItem";
import DropdownContainer from "./Components/DropdownContainer";
import DrawerComponent from './Components/Drawer';
import SearchComponent from './Components/Search';
import Logo from "components/Logo";
import LoginButton from "components/Header/Components/LoginButton/LoginButton";
import CartButton from "components/Cart/CartButton";
import LogoMini from 'assets/avatar.svg';
import navbarConfig from './navbarConfig';

interface IState {
  activeIndices: number[];
  open: boolean;
  searchActive: boolean;
  $animatingOut?: boolean;
}

interface IProps extends WithTranslation {
  $duration: number;
  isOnline: boolean;
}

class AppHeader extends Component<IProps, IState> {

  toggleDrawer = (newOpen: boolean) => () => {
    this.setState({ open: newOpen });
  };

  state: IState = {
    activeIndices: [],
    open: false,
    searchActive: false,
  }

  toggleSearch = () => {
    this.setState(prevState => ({ searchActive: !prevState.searchActive }));
  };

  animatingOutTimeout?: NodeJS.Timeout;

  resetDropdownState = (i?: number) => {
    this.setState({
      activeIndices: typeof i === "number" ? [i] : [],
      $animatingOut: false
    })
    delete this.animatingOutTimeout
  }

  onMouseEnter = (i: number) => {
    if (this.animatingOutTimeout) {
      clearTimeout(this.animatingOutTimeout)
      this.resetDropdownState(i)
      return
    }
    if (this.state.activeIndices[this.state.activeIndices.length - 1] === i)
      return

    this.setState(prevState => ({
      activeIndices: prevState.activeIndices.concat(i),
      $animatingOut: false
    }))
  }

  onMouseLeave = () => {
    this.setState({
      $animatingOut: true
    })
    this.animatingOutTimeout = setTimeout(
      this.resetDropdownState,
      this.props.$duration
    )
  }

  render() {
    const { t, isOnline } = this.props;
    const config = navbarConfig(t, isOnline);

    const scrollToSection = (sectionId: string) => {
      const sectionElement = document.getElementById(sectionId);
      const offset = 128;
      if (sectionElement) {
        const targetScroll = sectionElement.offsetTop - offset;
        sectionElement.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
          top: targetScroll,
          behavior: 'smooth',
        });
        this.setState({ open: false });
      }
    };

    const { $duration } = this.props;
    let CurrentDropdown: ReactElement | null = null;
    let PrevDropdown: ReactElement | null = null;
    let direction: string | undefined;

    const currentIndex = this.state.activeIndices[
      this.state.activeIndices.length - 1
    ]
    const prevIndex =
      this.state.activeIndices.length > 1 &&
      this.state.activeIndices[this.state.activeIndices.length - 2]

    if (typeof currentIndex === "number")
      CurrentDropdown = React.createElement(config[currentIndex].dropdown, {
        displayPlatforms: config[currentIndex].platforms,
        groupTitle: config[currentIndex].groupTitle,
        groupIcon: config[currentIndex].groupIcon,
        giftCards: config[currentIndex].giftCards,
        title: config[currentIndex].title,
      });
    if (typeof prevIndex === "number") {
      PrevDropdown = React.createElement(config[prevIndex].dropdown, {
        displayPlatforms: config[prevIndex].platforms,
        groupTitle: config[prevIndex].groupTitle,
        groupIcon: config[prevIndex].groupIcon,
        giftCards: config[prevIndex].giftCards,
        title: config[prevIndex].title,
      });
      direction = currentIndex > prevIndex ? "right" : "left";
    }

    return (
      <AppBar position="fixed">
        <Container maxWidth="lg">
          <Toolbar variant="regular">
            <Box sx={{ display: 'flex', alignItems: 'center', ml: '-18px', px: 0, }} >
              {!this.state.searchActive ? (
                <Logo sx={{ mx: '2rem', display: 'flex' }} />
              ) : (
                <Box sx={{ ml: '32px', width: '40px', height: '30.53px', cursor: 'pointer', display: 'flex' }} component={RouterLink} to="/">
                  <img src={LogoMini} alt="Logo" />
                </Box>
              )}
            </Box>
            {!this.state.searchActive && (
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', ml: '-18px', px: 0, }} >
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Flipper
                    flipKey={currentIndex}
                    spring={$duration === 300 ? "noWobble" : { stiffness: 10, damping: 10 }}
                  >
                    <Navbar onMouseLeave={this.onMouseLeave}>
                      {config.map((n, index) => {
                        const key = n.title || `${n.title}-${index}`;

                        return (
                          <NavbarItem
                            key={key}
                            title={n.title}
                            titleHide={n.titleHide}
                            icon={n.icon || null}
                            index={index}
                            onMouseEnter={this.onMouseEnter}
                          >
                            {currentIndex === index && (
                              <DropdownContainer
                                direction={direction as "left" | "right" | undefined}
                                $animatingOut={this.state.$animatingOut}
                                $duration={$duration}
                              >
                                {CurrentDropdown}
                                {PrevDropdown}
                              </DropdownContainer>
                            )}
                          </NavbarItem>
                        )
                      })}
                    </Navbar>
                  </Flipper>
                </Box>
              </Box>
            )}

            <SearchComponent
              searchActive={this.state.searchActive}
              setSearchActive={this.toggleSearch}
            />
            <CartButton />

            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 0.5, alignItems: 'center', }} >
              <LoginButton />
            </Box>

            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button variant="text" aria-label="menu" onClick={this.toggleDrawer(true)} sx={{ minWidth: '30px', p: '4px', color: 'text.primary' }} >
                <MenuIcon />
              </Button>
              <DrawerComponent open={this.state.open} toggleDrawer={this.toggleDrawer} scrollToSection={scrollToSection} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default AppHeader;
