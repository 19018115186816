import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Checkbox, FormControlLabel, Box, Alert } from '@mui/material';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import ToggleColorMode from 'theme/ToggleColorMode';

const GdprDialog: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [necessaryAccepted, setNecessaryAccepted] = useState(false);
  const [allAccepted, setAllAccepted] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const warningRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isNecessaryAccepted = localStorage.getItem('gdprNecessaryAccepted') === 'true';
    const isAllAccepted = localStorage.getItem('gdprAllAccepted') === 'true';
    
    if (!isNecessaryAccepted && !isAllAccepted) {
      setOpen(true);
    }
  }, []);

  const scrollToWarning = () => {
    if (warningRef.current) {
      warningRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleAcceptNecessary = () => {
    if (!termsAccepted) {
      setShowWarning(true);
      scrollToWarning();
      return;
    }
    
    setNecessaryAccepted(true);
    localStorage.setItem('gdprNecessaryAccepted', 'true');
    setOpen(false);
  
    window.gtag('consent', 'update', {
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'ad_storage': 'denied',
      'analytics_storage': 'granted',
    });
  };
  
  const handleAcceptAll = () => {
    if (!termsAccepted) {
      setShowWarning(true);
      scrollToWarning();
      return;
    }
    
    setAllAccepted(true);
    localStorage.setItem('gdprAllAccepted', 'true');
    setOpen(false);
  
    window.gtag('consent', 'update', {
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
    setShowWarning(false);
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      PaperProps={{ sx: { p: 2, borderRadius: "21px", maxWidth: '665px' } }}
    >
      <DialogTitle variant='h4'>{t('welcome')}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>
            {t('selectLanguage')}
          </Typography>
          <LanguageSwitcher useButtons />
        </Box>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>
            {t('changeApplicationTheme')}
          </Typography>
          <ToggleColorMode useButtons />
        </Box>
        <Typography variant="h6" gutterBottom>
          {t('termsOfService')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('gdprFadpDescription')}
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={termsAccepted} onChange={handleCheckboxChange} />}
          label={t('acceptTerms')}
        />

        {showWarning && (
          <div ref={warningRef}>
            <Alert severity="warning" sx={{ mt: 2 }}>
              {t('Please agree to the terms and conditions to proceed.')}
            </Alert>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAcceptNecessary} color="primary" variant="outlined" disabled={necessaryAccepted || allAccepted}>
          {t('acceptNecessary')}
        </Button>
        <Button onClick={handleAcceptAll} color="primary" variant="contained" disabled={allAccepted}>
          {t('acceptAll')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GdprDialog;
