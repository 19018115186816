import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchProductDetailById, fetchProductScreenshots } from '../product/productActions';
import {  ProductDetailState } from '../types/ProductDetailTypes';

const initialState: ProductDetailState = {
  language: 'en',
  productDetails: {},
  isLoading: false,
  error: null,
};

const productDetailSlice = createSlice({
  name: 'productDetail',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductDetailById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProductDetailById.fulfilled, (state, action) => {
        state.isLoading = false;
        const productId = action.meta.arg.productId;
        if (productId) {
          state.productDetails[productId] = {
            ...state.productDetails[productId],
            ...action.payload,
          };
        }
      })
      .addCase(fetchProductDetailById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch product details';
      })
      .addCase(fetchProductScreenshots.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProductScreenshots.fulfilled, (state, action) => {
        state.isLoading = false;
        const productId = action.meta.arg.productId;
        if (productId) {
          state.productDetails[productId] = {
            ...state.productDetails[productId],
            screenshots: action.payload.images,
          };
        }
      })
      .addCase(fetchProductScreenshots.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch product screenshots';
      });
  },
});

export const { setLanguage } = productDetailSlice.actions;
export default productDetailSlice.reducer;
