import React from 'react';
import { Container, Typography } from '@mui/material';

export default function OfflinePage() {
  return (
    <Container sx={{ alignItems: 'center', pt: { xs: 14, sm: 20 }, pb: { xs: 8, sm: 12 } }}>
      <Typography variant="h2" component="h1" gutterBottom>
        You are currently offline.
      </Typography>
    </Container>
  );
}
