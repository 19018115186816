import React from "react";

import BlizzardIcon from 'components/icons/Platforms/BlizzardIcon';
import SteamIcon from 'components/icons/Platforms/SteamIcon';
import EpicIcon from 'components/icons/Platforms/EpicIcon';
import GogIcon from 'components/icons/Platforms/GogIcon';
import Ps4Icon from 'components/icons/Platforms/Ps4Icon';
import Ps5Icon from 'components/icons/Platforms/Ps5Icon';
import XboxXIcon from 'components/icons/Platforms/XboxXIcon';
import XboxIcon from 'components/icons/Platforms/XboxIcon';
import SwitchIcon from 'components/icons/Platforms/SwitchIcon';
import PsStoreIcon from 'components/icons/Platforms/PsStoreIcon';
import PsPlusIcon from 'components/icons/Platforms/PsPlusIcon';
import NintendoStoreIcon from 'components/icons/Platforms/NintendoShopIcon';
import NintendoOnlineIcon from 'components/icons/Platforms/NintendoOnlineIcon';
import EaIcon from 'components/icons/Platforms/EaIcon';
import MicrosoftIcon from 'components/icons/Platforms/MicrosoftIcon';
import RockstarIcon from 'components/icons/Platforms/RockstarIcon';
import UbisoftIcon from 'components/icons/Platforms/UbisoftIcon';

const platformIcons: { [key: string]: React.ElementType } = {
    '1': BlizzardIcon,
    '2': EaIcon,
    '3': EpicIcon,
    '4': GogIcon,
    '5': MicrosoftIcon,
    '6': RockstarIcon,
    '7': SteamIcon,
    '8': UbisoftIcon,
    '9': Ps4Icon,
    '10': Ps5Icon,
    '11': XboxIcon,
    '12': XboxXIcon,
    '13': SwitchIcon,
    '14': PsStoreIcon,
    '15': PsPlusIcon,
    '16': NintendoStoreIcon,
    '17': NintendoOnlineIcon,
};

export default platformIcons;
