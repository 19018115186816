import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Container } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import emptyCartImage from 'assets/EmptyCart.webp';

const EmptyCart: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      <img src={emptyCartImage} alt="Empty cart" />
      <Typography variant="h1" sx={{ mb: 3 }}>{t('emptyCartHeading')}</Typography>
      <Typography variant="h6">{t('emptyCartSubheading')}</Typography>
      <Button
        sx={{ mt: 5 }}
        startIcon={<ArrowBackRoundedIcon />}
        variant="contained"
        color="secondary"
        component={RouterLink}
        to="/products">
        {t('exploreCatalog')}
      </Button>
    </Container>
  );
};

export default EmptyCart;
