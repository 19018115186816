import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid2 as Grid } from "@mui/material";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';
import SiteTitle from "components/SiteTitle";
import ProductCardComponent from "components/ProductCard";
import { BestSellerGridLoading } from "./BestSellerGridLoading";
import { makeUrlSafe } from 'utils/getNames';

interface BestSellerGridProps {
  categoryTitle: string;
  category: string;
  productIds: Array<{ id: string, platform_id: string }>;
  regions: any[];
  loading: boolean; 
}

const BestSellerGrid: React.FC<BestSellerGridProps> = ({
  categoryTitle,
  category,
  productIds,
  regions,
  loading,
}) => {
  const { t } = useTranslation();
  const products = useSelector((state: RootState) => state.product.products);
  const navigate = useNavigate();

  const filteredProducts = useMemo(() => {
    const filtered = products.filter(product =>
      productIds.some(entry =>
        product.productId === entry.id && product._id === entry.platform_id
      )
    );
    return filtered;
  }, [products, productIds]);

  const handleTitleClick = () => {
    navigate(`/products?category=${makeUrlSafe(category)}`);
  };

  if (loading && !filteredProducts.length) {
    return (
      <Grid size={12}>
        <BestSellerGridLoading />
      </Grid>
    );
  }

  if (!filteredProducts.length) {
    return null;
  }

  return (
    <Grid container spacing={4} mb={10}>
      <Grid size={12}>
        <SiteTitle 
          firstTitle={categoryTitle} 
          secondTitle={t('games')}
          variant="h4" 
          color="primary" 
          onClick={handleTitleClick} 
          arrow
        />
      </Grid>
      {filteredProducts.map((product, index) => {
        const region = regions.find(r => r.shortname === product.region);
        const regionCurrency = product.type === '3' && region ? region.currency : '';

        return (
          <Grid
            size={{ xs: 12, sm: 6, md: 4 }}
            key={`${product._id}-${index}`}
          >
            <ProductCardComponent
              title={product.title}
              price={product.price}
              type={product.type}
              category={product.category}
              platform={product.platformId}
              regionId={product.region}
              productId={product.productId}
              platformImageUrl={product.platformId}
              region={product.region}
              {...(product.editionName && { editionName: product.editionName })}
              {...(regionCurrency && { regionCurrency })}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default BestSellerGrid;
