import React from 'react';
import { useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PcIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      style={{ width: '100%', height: 'auto' }}
    >
      <path d="M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2zM4 6h16v10H4z" fill={theme.palette.text.primary} />
    </SvgIcon>
  );
}

export default PcIcon;