import { createSlice } from '@reduxjs/toolkit';
import { fetchCreditDetailById } from '../product/productActions';
import { CreditDetailState } from '../types/ProductDetailTypes';

const initialState: CreditDetailState = {
  creditDetails: {},
  isLoading: false,
  error: null,
};

const creditDetailSlice = createSlice({
  name: 'creditDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreditDetailById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCreditDetailById.fulfilled, (state, action) => {
        state.isLoading = false;
        const productId = action.meta.arg;
        if (productId) {
          state.creditDetails[productId] = action.payload;
        }
      })
      .addCase(fetchCreditDetailById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch product details';
      })

  },
});

export const { } = creditDetailSlice.actions;
export default creditDetailSlice.reducer;
