import React from 'react';
import { Web as BrowserIcon, Key as KeylessIcon } from '@mui/icons-material';
import RockstarIcon from 'components/icons/Platforms/RockstarIcon'; 
import ActivationGuide from './ActivationGuide';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface RockstarInstructionsProps {
    keyCode: string;
}

const RockstarInstructions: React.FC<RockstarInstructionsProps> = ({ keyCode }) => {
    const { t } = useTranslation();

    const steps = {
        default: [
            {
                label: t('activationMethod'),
                description: t('chooseActivation'),
                options: [
                    { title: t('Browser'), icon: <BrowserIcon sx={{ width: '64px', height: '64px' }} />, method: 'browser' },
                    { title: t('Rockstar App'), icon: <RockstarIcon sx={{ width: '64px', height: '64px' }} />, method: 'rockstarApp' },
                    { title: t('keylessActivation'), icon: <KeylessIcon sx={{ width: '64px', height: '64px' }} />, method: 'keyless' }
                ]
            }
        ],
        browser: [
            { label: t('activationMethod'), description: '', imageUrl: '' },

            {
                label: t('activationLink'),
                description: t('rockstarStep1'),
                imageUrl: '/img/activation/activation-link.webp',
                actionButton: (
                    <Button
                        variant="contained"
                        color="secondary"
                        href={`https://socialclub.rockstargames.com/activate/${keyCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('goToRockstarActivation')}
                    </Button>
                ),
            },
            {
                label: t('login'),
                description: t('rockstarStep2'),
                imageUrl: '/img/activation/rockstar-login.webp',
            },
            {
                label: t('linkToAccount'),
                description: t('rockstarStep3'),
                imageUrl: '/img/activation/rockstar-redeem-code.webp',
            },
            {
                label: t('confirmActivation'),
                description: t('rockstarStep4'),
                imageUrl: '/img/activation/download.webp',
            }
        ],
        rockstarApp: [
            { label: t('activationMethod'), description: '', imageUrl: '' },

            {
                label: t('login'),
                description: t('rockstarStep2app'),
                imageUrl: '/img/activation/rockstar-app-login.webp'
            },
            {
                label: t('goToActivation'),
                description: t('rockstarStep3app'),
                imageUrl: '/img/activation/rockstar-app-redeem-code.webp'
            },
            {
                label: t('enterTheKey'),
                description: t('rockstarStep4app'),
                imageUrl: '/img/activation/rockstar-app-enter-redeem-code.webp'
            },
            {
                label: t('activateKey'),
                description: t('rockstarStep5app'),
                imageUrl: '/img/activation/rockstar-app-confirm-redeem-code.webp'
            },
            {
                label: t('confirmActivation'),
                description: t('rockstarStep4'),
                imageUrl: '/img/activation/download.webp',
            }
        ]
    };

    return <ActivationGuide steps={steps} />;
};

export default RockstarInstructions;
