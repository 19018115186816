import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { fetchProductById, fetchCreditDetailById } from 'redux/product/productActions';

import { Container } from '@mui/material';
import { ProductCover } from './Components/ProductCover';
import { CreditCart } from './Components/CreditCart';
import { CreditAbout } from './Components/CreditAbout';
import { HowToActivate } from './Components/HowToActivate';
import { ProductReviews } from './Components/ProductReviews';
import NoDataFound from 'components/NoDataFound';
import { ProductLoading } from './Components/ProductLoading';
import { getProductImgLg } from 'utils/getNames';
import SEO from 'utils/SEO';

export default function ProductPage() {
  const { productId, platformName, regionName, categoryId } = useParams<{ productId?: string; platformName?: string; regionName?: string, categoryId?: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);

  const product = useSelector((state: RootState) => state.product.products.find(p => p._id === productId));
  const creditDetail = useSelector((state: RootState) => productId ? state.creditDetail.creditDetails[productId] : undefined);
  const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
  const regions = useSelector((state: RootState) => state.productInfo.region.regions);
  const types = useSelector((state: RootState) => state.productInfo.type.types);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        if (productId) {
          await Promise.all([
            dispatch(fetchProductById(productId)).unwrap(),
            dispatch(fetchCreditDetailById(productId)).unwrap()
          ]);
        }
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [dispatch, productId]);

  if (!creditDetail) {
    return <ProductLoading />;
  }

  if (!product) {
    return <NoDataFound text="Product not found or details are missing." />;
  }

  const decodedPlatformName = platformName?.replace(/-/g, ' ').toLowerCase();
  const decodedRegionName = regionName?.replace(/-/g, ' ').toLowerCase();
  const matchedPlatform = platforms.find(p => p.name.toLowerCase() === decodedPlatformName);
  const matchedRegion = regions.find(r => r.name.toLowerCase() === decodedRegionName);

  const platformAvailability = product?.platforms;
  const uniquePlatformIds = [...new Set(platformAvailability.map(platform => platform.platformId))];
  const filteredPlatforms = platforms.filter(platform => uniquePlatformIds.includes(platform._id));

  const platformRegions = platformAvailability
    .filter(p => p.platformId === matchedPlatform?._id)
    .map(p => p.region);

  const uniqueRegionIds = [...new Set(platformRegions)];
  const regionsForPlatform = regions.filter(region => uniqueRegionIds.includes(region.shortname));
  const selectedPlatformCategory = product?.platforms.find(p => p.platformId === matchedPlatform?._id)?.category;
  const selectedPlatformRegion = product?.platforms.find(p => p.region === matchedRegion?.shortname)?.region;
  const selectedPlatform = product?.platforms.find(p => p.platformId === matchedPlatform?._id)?.platformId;

  const getTypeName = (typeId: string): string => {
    const type = types.find(t => t._id === typeId);
    return type ? type.name : 'Unknown Type';
  };

  if (!matchedPlatform || !matchedRegion) {
    return <NoDataFound text="Platform or region information not found." />;
  }

  const platformDetail = product.platforms.find(platform =>
    platform.platformId === matchedPlatform._id && platform.region === matchedRegion.shortname
  );

  if (!platformDetail) {
    return <NoDataFound text="Platform details not found." />;
  }

  const fetchEditionDetailsById = async (editionId: string): Promise<{ title: string, firstPlatform: any }> => {
    if (!editionId) {
      return { title: 'Unknown', firstPlatform: { category: 'Unknown', region: 'Unknown', platformId: 'Unknown' } };
    }
    try {
      const response = await dispatch(fetchProductById(editionId)).unwrap();
      if (!response || !response.platforms || response.platforms.length === 0) {
        return { title: 'Unknown', firstPlatform: { category: 'Unknown', region: 'Unknown', platformId: 'Unknown' } };
      }
      const firstPlatform = response.platforms[0];
      return {
        title: response.title || 'Unknown',
        firstPlatform: {
          category: firstPlatform.category || 'Unknown',
          region: firstPlatform.region || 'Unknown',
          platformId: firstPlatform.platformId || 'Unknown'
        }
      };
    } catch (error) {
      console.error("Failed to fetch edition details:", error);
      return { title: 'Unknown', firstPlatform: { category: 'Unknown', region: 'Unknown', platformId: 'Unknown' } };
    }
  };

  return (
    <>
      <SEO
        title={product.title}
        description={creditDetail?.description}
        keywords="Bigswiss, video games, digital codes, gift cards, game points, subscription cards, gaming, online marketplace"
        image={getProductImgLg(product._id)}
      />

      <ProductCover productCoverImg={product._id} />
      <Container sx={{ mt: { xs: '-130px', sm: '-340px' }, gap: 6, pt: { xs: 14, sm: 20 }, pb: { xs: 8, sm: 12 } }}>
        <CreditCart
          productId={product._id}
          ProductImage={product._id}
          ProductTitle={product.title}
          ProductType={getTypeName(product.type)}
          ProductTypeId={product.type}
          categoryId={categoryId || ''}
          platformsDetails={product?.platforms}
          ProductRegion={decodedRegionName || ''}
          ProductRegionId={matchedRegion.shortname || ''}
          platformName={matchedPlatform.name || ''}
          platformImageUrl={matchedPlatform.imageUrl || '/img/defaultPlatform.svg'}
          platforms={filteredPlatforms}
          ProductPrice={platformDetail.price}
          selectedPlatformCategory={selectedPlatformCategory || ''}
          selectedPlatformRegion={selectedPlatformRegion || ''}
          selectedPlatform={selectedPlatform || ''}
          regions={regionsForPlatform}
          platformGroupId={platformDetail._id}
          fetchEditionDetailsById={fetchEditionDetailsById}
        />
        <CreditAbout ProductType={getTypeName(product.type)} ProductDescription={creditDetail?.description || "Description not available"} />
        {/* <ProductRelated /> */}
        {/* <HowToActivate steps={creditDetail.howtoactivate} /> */}
        {/* <ProductSuggestions /> */}
        <ProductReviews reviews={creditDetail.reviews} />
      </Container>
    </>
  );
}