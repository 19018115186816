import { useTranslation } from 'react-i18next';

import { Container, Box, Skeleton, Grid2 as Grid, Typography, Card, Button } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import FavoriteIcon from '@mui/icons-material/Favorite';

export const ProductLoading = () => {
    const { t } = useTranslation(); 
    return(
    <>
        <Box display={'flex'}>
            <Skeleton
                variant="rectangular"
                sx={{
                    alignSelf: 'center',
                    height: { xs: 200, sm: '55vh' },
                    width: '100%',
                }} />
        </Box>
        <Container sx={{ mt: { xs: '-130px', sm: '-340px' }, gap: 6, pt: { xs: 14, sm: 20 }, pb: { xs: 8, sm: 12 } }}>
            <Grid container spacing={6} justifyContent="center">
                <Grid size={{ xs: 12, md: 6 }}>
                    <Skeleton
                        variant="rectangular"
                        sx={{
                            mb: { xs: 8, sm: 10 },
                            height: { xs: 200, sm: 340 },
                            width: '100%',
                            borderRadius: '21px'
                        }}
                    />
                </Grid>

                <Grid size={{ xs: 12, md: 6 }}>
                    <Card sx={{ display: 'flex', height: { xs: 200, sm: 340 }, overflow: 'visible', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', p: 4 }}>
                        <Typography component="span" variant="h5" sx={{ textTransform: 'capitalize' }}>
                            <Skeleton width={80} />
                        </Typography>
                        <Typography component="span" variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                            <Skeleton width={80} />
                        </Typography>

                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center', mt: 2 }}>
                            <Typography component="span" variant="h4" sx={{ my: 2, display: "flex" }}>
                                <Skeleton width={80} />
                            </Typography>
                            <Typography component="span" variant="h4" sx={{ my: 2, display: "flex" }}>
                                <Skeleton width={80} />
                            </Typography>
                            <Typography component="span" variant="h4" sx={{ my: 2, display: "flex" }}>
                                <Skeleton width={80} />
                            </Typography>
                            <Typography component="span" variant="h4" sx={{ my: 2, display: "flex" }}>
                                <Skeleton width={80} />
                            </Typography>

                        </Box>
                        <Typography component="span" variant="h4" sx={{ my: 1, display: "flex" }}>
                            <Skeleton width={80} /> <Box sx={{ fontSize: 'small', mt: 1 }}></Box>
                        </Typography>
                        <Box sx={{ position: 'absolute', bottom: '-20px', zIndex: '100', gap: 2, display: 'flex' }}>
                            <Button variant="outlined" color={'secondary'} size="large" >
                                <FavoriteIcon />
                            </Button>
                            <Button variant="outlined" color={'secondary'} size="large" sx={{ minWidth: '230px' }} startIcon={<ShoppingCart />}>
                            {t('addToCart')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>

            <Grid container mb={4}>
                <Grid size={{ xs: 12 }} mb={3}>
                    <Typography component="span" variant="h4" sx={{ my: 2, display: "flex" }}>
                        <Skeleton width={380} />
                    </Typography>
                </Grid>
                <Grid container spacing={6}>
                    <Grid size={{ xs: 12, sm: 8 }}>
                        <Typography component="span" color="text.secondary" >
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Typography component="span" color="text.primary" >
                            <Box sx={{ display: 'flex' }}> <span style={{ fontWeight: 'bold', marginRight: '12px' }}>Rating:</span> <Skeleton width={80} /><br /> </Box>
                            <Box sx={{ display: 'flex' }}>  <span style={{ fontWeight: 'bold', marginRight: '12px' }}>Developer:</span> <Skeleton width={80} /><br /></Box>
                            <Box sx={{ display: 'flex' }}>  <span style={{ fontWeight: 'bold', marginRight: '12px' }}>Publisher:</span> <Skeleton width={80} /><br /></Box>
                            <Box sx={{ display: 'flex' }}>   <span style={{ fontWeight: 'bold', marginRight: '12px' }}>Released:</span> <Skeleton width={80} /><br /></Box>
                            <Box sx={{ display: 'flex' }}>   <span style={{ fontWeight: 'bold', marginRight: '12px' }}>Genre:</span> <Skeleton width={80} /><br /></Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </>
    );
};
