import React from 'react';
import { Web as BrowserIcon, Key as KeylessIcon } from '@mui/icons-material';
import UbisoftIcon from 'components/icons/Platforms/UbisoftIcon';
import ActivationGuide from './ActivationGuide';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface UbisoftInstructionsProps {
    keyCode: string;
}

const UbisoftInstructions: React.FC<UbisoftInstructionsProps> = ({ keyCode }) => {
    const { t } = useTranslation();

    const steps = {
        default: [
            {
                label: t('activationMethod'),
                description: t('chooseActivation'),
                options: [
                    { title: t('Browser'), icon: <BrowserIcon sx={{ width: '64px', height: '64px' }} />, method: 'browser' },
                    { title: t('Ubisoft Connect App'), icon: <UbisoftIcon sx={{ width: '64px', height: '64px' }} />, method: 'ubisoftApp' },
                    { title: t('keylessActivation'), icon: <KeylessIcon sx={{ width: '64px', height: '64px' }} />, method: 'keyless' }
                ]
            }
        ],
        ubisoftApp: [
            { label: t('activationMethod'), description: '', imageUrl: '' },

            {
                label: t('login'),
                description: t('ubisoftStep2app'),
                imageUrl: '/img/activation/ubisoft-app-login.webp'
            },
            {
                label: t('goToActivation'),
                description: t('ubisoftStep3app'),
                imageUrl: '/img/activation/ubisoft-app-clickon-redeem-code.webp'
            },
            {
                label: t('enterTheKey'),
                description: t('ubisoftStep4app'),
                imageUrl: '/img/activation/ubisoft-app-redeem-code.webp'
            },
            {
                label: t('confirmActivation'),
                description: t('ubisoftStep5'),
                imageUrl: '/img/activation/ubisoft-app-confirm-redeem-code.webp',
            }
        ]
    };

    return <ActivationGuide steps={steps} />;
};

export default UbisoftInstructions;
