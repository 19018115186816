import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChromeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ width: '100%', height: 'auto' }} 
      viewBox="0 0 512 512"
    >
      <path 
        style={{ fill: '#82D889' }} 
        d="M256,386.648c-25.6,0-49.434-7.062-70.621-21.186c-21.186-14.124-37.076-31.779-47.669-53.848
        L31.779,128C9.71,167.724,0,210.979,0,256c0,64.441,21.186,120.055,62.676,167.724s93.572,75.917,155.366,85.628l74.152-128.883
        C285.131,383.117,271.89,386.648,256,386.648"
      />
      <path 
        style={{ fill: '#E86438' }} 
        d="M175.669,150.069c23.834-18.538,50.317-26.483,80.331-26.483h219.807
        c-22.952-38.841-53.848-67.09-92.69-89.159C344.276,11.476,301.903,0,256,0c-39.724,0-77.683,8.828-112.11,25.6
        S76.8,67.09,53.848,98.869L128,220.69C135.062,192.441,151.834,168.607,175.669,150.069"
      />
      <path 
        style={{ fill: '#FCE056' }} 
        d="M492.579,159.779H344.276c25.6,25.6,43.255,59.145,43.255,96.221
        c0,27.366-7.945,52.083-22.952,75.034L258.648,512c69.738-0.883,129.766-25.6,179.2-75.917S512,325.738,512,256
        C512,223.338,506.703,188.91,492.579,159.779"
      />
      <path 
        style={{ fill: '#25B9E1' }} 
        d="M256,158.897c52.966,0,97.103,44.138,97.103,97.103S308.966,353.103,256,353.103
        S158.897,308.966,158.897,256S203.034,158.897,256,158.897"
      />
    </SvgIcon>
  );
}

export default ChromeIcon;
