import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './redux/store'; // Assuming you have a RootState type for the entire state
import { fetchLocationData } from './redux/services/locationActions';
import { fetchCategories, fetchPlatforms, fetchRegions, fetchTypes, fetchSettings, fetchDevelopers, fetchPublishers, fetchGenres, fetchRatings } from './redux/actions/fetchDataActions';

import { SnackbarProvider } from 'notistack';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { createTheme, ThemeProvider as MuiThemeProvider, CssBaseline, Divider } from '@mui/material';
import { ThemeProvider, useThemeContext } from './theme/ThemeContext';
import ScrollToTopWrapper from './utils/ScrollToTopWrapper';

import AppHeader from './components/Header/AppHeaderWrapper';
import RoutesGroup from './RoutesGroup';
import Footer from './components/Footer/Footer';

import './utils/i18n';
import GdprDialog from './components/GdprDialog';
import getBSTheme from './theme/BigswissTheme';

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { actualMode } = useThemeContext();

  const [$duration] = useState(300);

  const locationStatus = useSelector((state: RootState) => state.application.locationStatus);

  useEffect(() => {
    if (locationStatus === 'idle') {
      dispatch(fetchLocationData());
    }
    dispatch(fetchPlatforms());
    dispatch(fetchRegions());
    dispatch(fetchCategories());
    dispatch(fetchTypes());
    dispatch(fetchDevelopers());
    dispatch(fetchPublishers());
    dispatch(fetchGenres());
    dispatch(fetchRatings());
    if (locationStatus === 'succeeded') {
      dispatch(fetchSettings());
    }
  }, [dispatch, locationStatus]);

  const LPtheme = useMemo(() => createTheme(getBSTheme(actualMode)), [actualMode]);

  return (
    <MuiThemeProvider theme={LPtheme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <ScrollToTopWrapper>
          <>
            <AppHeader $duration={$duration} />
            <RoutesGroup />
            <Divider />
            <Footer />
            <GdprDialog />
          </>
        </ScrollToTopWrapper>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

const AppWrapper: React.FC = () => (
  <ThemeProvider>
    <GoogleOAuthProvider clientId={process.env.VITE_APP_GOOGLE_CLIENT_ID || ''}>
      <App />
    </GoogleOAuthProvider>
  </ThemeProvider>
);

export default AppWrapper;
