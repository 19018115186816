import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const RockstarIcon = (props: SvgIconProps) => {

  return (
      <SvgIcon
      {...props}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.7669 2H42.0161C46.2885 2 49.783 5.4951 49.783 9.7669V39.5598C49.783 43.8316 46.2885 47.3264 42.0161 47.3264H7.7669C3.4951 47.3264 0 43.8316 0 39.5598V9.7669C0 5.4951 3.4951 2 7.7669 2Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.28324 2.6835H41.5004C45.6429 2.6835 49.0324 6.07298 49.0324 10.2158V39.1109C49.0324 43.2537 45.6429 46.6432 41.5004 46.6432H8.28324C4.14038 46.6432 0.750908 43.2537 0.750908 39.1109V10.2158C0.750908 6.07298 4.14038 2.6835 8.28324 2.6835Z" fill="#E7B144" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.1799 10.7579L14.6052 29.5209H19.9299L21.7694 22.9498C22.2565 22.7755 23.5076 22.366 25.4388 22.9498C25.9309 23.1931 26.3435 23.4689 26.6743 23.7771C27.1714 24.3252 27.3134 24.9804 27.1627 25.7235L26.7501 28.5277C26.7725 29.8648 27.0925 30.2209 28.0711 30.5604L28.4806 30.6443L30.5916 33.0707L27.8139 38.8623L33.8777 35.5763L38.087 39.0848L37.6393 33.0194L42.0313 29.8117L36.697 29.502L35.8153 23.7265L32.8263 29.3786L32.2183 29.3569C31.9816 29.3485 31.8912 29.3743 31.7045 29.2314C31.1754 28.8259 31.1316 28.2987 31.2658 27.4785L31.9599 21.9991C31.6911 21.2541 30.9927 20.7353 30.048 20.443H31.2111C31.8676 20.3274 32.026 20.2277 32.4768 19.8868C35.2232 16.863 35.2251 13.8374 31.853 11.2348C31.0337 10.8232 30.5556 10.775 29.9209 10.7586H19.1799V10.7579ZM23.1354 14.5995H27.429C30.0132 15.4203 29.4732 18.7141 27.429 19.1198H22.1357L23.1354 14.5995Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.4786 25.4545L36.2608 30.0413L40.55 30.2827L37.0313 32.833L37.4973 37.8647L33.973 34.83L29.0644 37.5227L31.3863 32.8364L28.8779 29.8682L33.1637 30.0211L35.4786 25.4545Z" fill="white" />

    </SvgIcon>
  );
}

export default RockstarIcon;