import { Container, Typography } from '@mui/material';
import { Link as MaterialLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export default function PrivacyPage() {
    return (
        <>
            <Container
                id="privacy"
                sx={{
                    mb: 8,
                    pt: { xs: 4, sm: 20 },
                    pb: { xs: 8, sm: 16 },
                    position: 'relative',
                    alignItems: 'center',
                    gap: { xs: 2, sm: 3 },
                }}
            >
                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Privacy Policy of Bigswiss GmbH
                </Typography>
                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Last Updated: 09 Sep 2024
                </Typography>

                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    Bigswiss GmbH ("we," "us," "our") is committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services, including our online shop, wholesale store, kiosk applications, and wholesale API store. By using our services, you consent to the collection and use of your information in accordance with this policy.
                </Typography>

                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Information We Collect
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    We collect personal information that you provide to us directly, such as your name, email address, postal address, payment details, and any other information you choose to provide when making a purchase or creating an account. Additionally, we may collect information related to your device, transaction history, and API usage, including IP addresses and cookies.
                </Typography>

                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Use of Information
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    We use your personal information for the following purposes:
                    <ul style={{ listStyle: 'circle', paddingLeft: '20px' }}>
                        <li>To process your transactions and deliver products to you.</li>
                        <li>To personalize and enhance your experience with our services.</li>
                        <li>To communicate with you regarding your account, purchases, or promotional offers.</li>
                        <li>To monitor and analyze trends and usage patterns in order to improve our services.</li>
                        <li>To detect, investigate, and prevent fraudulent or unlawful activities.</li>
                    </ul>
                </Typography>

                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Sharing of Information
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    We may share your information in the following instances:
                    <ul style={{ listStyle: 'circle', paddingLeft: '20px' }}>
                        <li>With vendors, consultants, and other service providers who perform tasks on our behalf.</li>
                        <li>With game distributors and third parties to prevent fraudulent activities, including sharing usernames and IP addresses for fraud detection and security purposes.</li>
                        <li>In compliance with any applicable laws, regulations, or legal processes.</li>
                        <li>To protect the rights, property, or safety of Bigswiss GmbH, its users, or others.</li>
                    </ul>
                </Typography>

                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    International Data Transfers
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    Your information may be transferred to and processed in countries outside of your country of residence, including jurisdictions that may not have data protection laws as stringent as those in your home country. We take appropriate safeguards to ensure your data is protected, including implementing Standard Contractual Clauses approved by the European Commission.
                </Typography>

                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Data Retention
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including any legal, accounting, or reporting requirements. Once the retention period expires, your data will be securely deleted.
                </Typography>

                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Cookies and Tracking Technologies
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    We may use cookies, web beacons, and similar technologies to collect information about your browsing activities on our website. This information is used to provide you with a more personalized experience and to monitor the effectiveness of our advertising efforts. You can control the use of cookies through your browser settings.
                </Typography>

                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Your Rights and Choices
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    You have the right to access, correct, delete, or restrict the use of your personal information. To exercise these rights, please contact us at <MaterialLink color="text.primary" component={RouterLink} to="mailto:info@bigswiss.ch">info@bigswiss.ch</MaterialLink>. We will respond to your request in accordance with applicable data protection laws.
                </Typography>

                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Security Measures
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    We take the security of your personal information seriously. All user passwords are encrypted using industry-standard encryption algorithms, ensuring that they remain secure and inaccessible to unauthorized individuals, including our staff. We utilize Stripe to securely process payment information, and Cloudflare is employed to protect our infrastructure and your data from potential security threats.
                    <br />
                    While we implement reasonable and appropriate measures to safeguard your personal information, no method of transmission over the internet or method of electronic storage is completely secure, and we cannot guarantee its absolute security.
                </Typography>


                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Changes to This Privacy Policy
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    We reserve the right to modify this Privacy Policy at any time. Any changes will be posted on this page with an updated "Last Updated" date. It is your responsibility to review this policy periodically for any updates.
                </Typography>

                <Typography component="h2" variant="h5" sx={{ textAlign: 'left', width: '100%' }} color="text.primary">
                    Contact Us
                </Typography>
                <Typography sx={{ textAlign: 'left', width: '100%' }} variant="body1" color="text.secondary">
                    If you have any questions about this Privacy Policy, please contact us at <MaterialLink color="text.primary" component={RouterLink} to="mailto:info@bigswiss.ch">info@bigswiss.ch</MaterialLink>.
                </Typography>
            </Container>
        </>
    );
}
