import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCsrfToken } from './fetchCsrfToken';
import axiosGetToken from './axiosGetToken';
import axiosDefault from 'axios';
import { RootState, AppDispatch } from '../store';
import { handleUserLocation } from '../services/locationActions';
import { clearAuthState } from './authSlice';

interface Credentials {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  address?: string;
  city?: string;
  zip?: string;
  country?: string;
  confirmPassword?: string;
}

interface GoogleLoginResponse {
  success: boolean;
  token: string;
  user: any;
  message?: string;
}

export const authMe = createAsyncThunk(
  'auth/me',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      // Fetch CSRF token
      await fetchCsrfToken();

      // Make request to /api/users/me endpoint to get user profile
      const response = await axiosDefault.get<{ user: any, currency: string }>('/api/google/me');

      if (response.data) {
        const { user, currency } = response.data;
        const state = getState() as RootState;

        // Fetch the current region and currency from the application state
        const currentCurrency = state.application.selectedCurrency;
        const currentRegion = state.application.selectedRegion;

        const country = user.country || 'EU';

        // Call a helper to handle the location
        handleUserLocation(dispatch as AppDispatch, { country, currency }, currentRegion, currentCurrency);

        return { user };
      }

      return rejectWithValue('User not authenticated');
    } catch (error) {
      return rejectWithValue('Failed to fetch user profile');
    }
  }
);

export const googleLogin = createAsyncThunk(
  'auth/googleLogin',
  async ({ redirectUrl }: { redirectUrl: string }, { getState, rejectWithValue }) => {
    try {
      // Fetch CSRF token to protect against CSRF attacks
      await fetchCsrfToken();

      const state = getState() as RootState;
      const selectedRegion = state.application.selectedRegion; // Fallback to a default region if not set

      const baseUrl = process.env.VITE_API_BASE_URL ? process.env.VITE_API_BASE_URL.replace(/\/$/, '') : '';
      const apiUrl = `${baseUrl}/api/google?redirectUrl=${redirectUrl}&region=${selectedRegion}`;

      window.location.href = apiUrl;
    } catch (error) {
      return rejectWithValue('Error during Google login');
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }: Credentials, { dispatch, getState, rejectWithValue }) => {
    try {
      await fetchCsrfToken();
      const response = await axiosGetToken.post<{ token: string, user: any, currency: string }>('/users/login', { email, password });

      // Get the current currency and region from the state
      const state = getState() as RootState;
      const currentCurrency = state.application.selectedCurrency;
      const currentRegion = state.application.selectedRegion;

      // Extract country and currency from the server response
      const { user, currency } = response.data;
      const country = user.country || 'EU';

      // Use the helper to handle any location changes
      handleUserLocation(dispatch as AppDispatch, { country, currency }, currentRegion, currentCurrency);

      return response.data;
    } catch (error: unknown) {
      if (axiosDefault.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data.error || error.response.data.errors?.[0]?.msg || 'Login failed due to server error';
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('An unexpected error occurred during login');
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosGetToken.get('/users/logout');
      dispatch(clearAuthState());
      return response.data;
    } catch (error: unknown) {
      if (axiosDefault.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message || 'Logout failed due to server error');
      }
      return rejectWithValue('An unexpected error occurred during logout');
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async ({ email, password, firstName, lastName, birthday, address, city, zip, country, confirmPassword }: Credentials, { dispatch, getState, rejectWithValue }) => {
    try {
      await fetchCsrfToken();
      const response = await axiosDefault.post<{ user: any, token: string, currency: string }>('/api/users/register', { email, password, firstName, lastName, birthday, address, city, zip, country, confirmPassword });

      // Get the current currency and region from the state
      const state = getState() as RootState;
      const currentCurrency = state.application.selectedCurrency;
      const currentRegion = state.application.selectedRegion;

      // Extract country and currency from the server response
      const { user, currency } = response.data;
      const userCountry = user.country || 'EU';

      // Use the helper to handle any location changes
      handleUserLocation(dispatch as AppDispatch, { country: userCountry, currency }, currentRegion, currentCurrency);

      return response.data;
    } catch (error: unknown) {
      if (axiosDefault.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data.error || error.response.data.errors?.[0]?.msg || 'Registration failed due to server error';
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('An unexpected error occurred during registration');
    }
  }
);