import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const tiers = [
  {
    title: 'basic',
    price: '0€',
    description: [
      'basicDesc1', 
      'basicDesc2', 
      'basicDesc3', 
    ],
    buttonText: 'getStarted',
    buttonVariant: 'outlined',
  },
  {
    title: 'pro',
    subheader: 'mostPopular',
    price: '249€',
    description: [
      'proDesc1', 
      'proDesc2', 
      'proDesc3', 
      'proDesc4', 
    ],
    buttonText: 'startNow',
    buttonVariant: 'contained',
  },
  {
    title: 'enterprise',
    price: '899€',
    description: [
      'enterpriseDesc1', 
      'enterpriseDesc2', 
      'enterpriseDesc3', 
      'enterpriseDesc4', 
      'enterpriseDesc5', 
    ],
    buttonText: 'contactUs',
    buttonVariant: 'outlined',
  },
];


export default function Pricing() {
  const { t } = useTranslation();


  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          {t('pricing')}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t('pricingDescription')}
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => {
          const translatedTitle = t(tier.title);
          const translatedSubheader = t(tier.subheader ?? '');
          const isPro = translatedTitle === t('pro');


          const emailBody = `Hello,

We are interested in your ${tier.title} API Subscription

This is our company info:

Company name:       
Registration No:    
TAX No:             
Website:            
Address:            
Zipcode:            
City:               
County:             
Contact Number:     


Store Location (Optional)
Address:            
Zipcode:            
City:               
County:             `;

          const mailtoLink = `mailto:wholesale@bigswiss.ch?subject=${encodeURIComponent(tier.title + ' API Subscription')}&body=${encodeURIComponent(emailBody)}`;


          return (
            <Grid
              key={tier.title}
              size={{ xs: 12, md: 4, sm: translatedTitle === t('enterprise') ? 12 : 6 }}
            >
              <Card
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  border: isPro ? '1px solid' : undefined,
                  borderColor: isPro ? 'primary.main' : undefined,
                  background: isPro ? 'linear-gradient(#033363, #021F3B)' : undefined,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      color: isPro ? 'grey.100' : '',
                    }}
                  >
                    <Typography component="h3" variant="h6">
                      {translatedTitle}
                    </Typography>
                    {isPro && (
                      <Chip
                      icon={<AutoAwesomeIcon />}
                      label={translatedSubheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      color: isPro ? 'grey.50' : undefined,
                    }}
                  >
                    <Typography component="h3" variant="h2">
                      {tier.price}
                    </Typography>
                    <Typography component="h3" variant="h6">
                      &nbsp;{t('perMonth')}
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      my: 2,
                      opacity: 0.2,
                      borderColor: 'grey.500',
                    }}
                  />
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{
                        py: 1,
                        display: 'flex',
                        gap: 1.5,
                        alignItems: 'center',
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          width: 20,
                          color: isPro ? 'primary.light' : 'primary.main',
                        }}
                      />
                      <Typography
                        component="p"
                        variant="subtitle2"
                        sx={{
                          color: isPro ? 'grey.200' : undefined,
                        }}
                      >
                        {t(line)}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                    component="a"
                    href={mailtoLink}
                    target="_blank"
                  >
                    {t(tier.buttonText)}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
