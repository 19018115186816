import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { Box, Grid2 as Grid, Card, alpha, Typography, Button, MenuItem, TextField, SelectChangeEvent } from '@mui/material';

import PlatformLogo from 'components/icons/PlatformLogos';
import ProductPriceTag from 'components/ProductPriceTag';
import FavoritesButton from 'components/FavoritesButton';
import AddToCartButton from 'components/Cart/AddToCartButton';
import { makeUrlSafe, getCategoryName, getPlatformName, getRegionName, getProductImgLg } from 'utils/getNames';

interface Platform {
    _id: string;
    category: string;
    platformId: string;
    region: string;
    price: number;
}

interface ProductCartProps {
    ProductPrice: number;
    productId: string;
    ProductType: string;
    platformGroupId: string;
    categoryId: string;
    selectedPlatform: string;
    ProductTitle: string;
    ProductImage: string;
    ProductRegion: string;
    platformName: string;
    editions: Record<string, string> | undefined;
    platformsDetails: Platform[];
    fetchEditionDetailsById: (editionId: string) => Promise<any>;
    [key: string]: any;
}

export const ProductCart = ({
    productId, ProductType, categoryId, ProductPrice, selectedPlatform,
    ProductImage, ProductRegion, platformGroupId, platformName, ProductTitle, platformsDetails, editions,
    fetchEditionDetailsById, ...rest
}: ProductCartProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const categories = useSelector((state: RootState) => state.productInfo.category.categories);

    const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);
    const regions = useSelector((state: RootState) => state.productInfo.region.regions);

    const handlePlatformChange = (platformId: string) => {
        const platform = platformsDetails.find(p => p.platformId === platformId);
        if (!platform) {
            console.error('Platform not found');
            return;
        }
        const platformName = getPlatformName(platformId, platforms);
        const regionName = getRegionName(platform.region, regions);

        const newPath = `/${makeUrlSafe(ProductType)}/${productId}/${makeUrlSafe(ProductTitle)}/${platform.category}/${makeUrlSafe(platformName)}/${makeUrlSafe(regionName)}`;
        navigate(newPath);
    };

    const handleEditionChange = async (event: SelectChangeEvent<string>) => {
        const editionId = event.target.value as string;
        const editionDetails = await fetchEditionDetailsById(editionId);
        const platformDetails = editionDetails.firstPlatform;

        const platformName = getPlatformName(platformDetails.platformId, platforms);
        const regionName = getRegionName(platformDetails.region, regions);
        const category = platformDetails.category;

        const newPath = `/${makeUrlSafe(ProductType)}/${editionId}/${makeUrlSafe(editionDetails.title)}/${category}/${makeUrlSafe(platformName)}/${makeUrlSafe(regionName)}`;
        navigate(newPath);
    };

    const hasEditions = editions && Object.keys(editions).length > 0;

    return (
        <Grid container spacing={6} sx={{ mb: { xs: 8, sm: 10 } }} justifyContent="center" {...rest}>
            <Grid size={{ xs: 12, md: 6 }}>
                <Box
                    id="image"
                    sx={(theme) => ({
                        alignSelf: 'center',
                        height: { xs: 200, sm: 340 },
                        width: '100%',
                        backgroundImage: `url(${getProductImgLg(ProductImage)})`,
                        backgroundSize: 'cover',
                        backgroundPositionY: 'center',
                        borderRadius: '21px',
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    })}
                />
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
                <Card sx={{ display: 'flex', height: { xs: 'fit-content', sm: 340 }, overflow: 'visible', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', p: 4 }}>
                    <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                        {ProductTitle}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ textTransform: 'capitalize', mb: 2 }}>
                        {getCategoryName(categoryId, categories)} - {platformName} - {ProductRegion}
                    </Typography>

                    {hasEditions && (
                        <Box sx={{ width: '100%', mb: 2 }}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                label="Select Edition"
                                value={productId}
                                onChange={(event) => handleEditionChange(event as SelectChangeEvent<string>)}
                                name="edition"
                                slotProps={{
                                    htmlInput: {
                                        'aria-label': 'Select edition'
                                    }
                                }}
                            >
                                {Object.entries(editions).map(([editionName, editionId]) => (
                                    <MenuItem key={editionId} value={editionId}>
                                        {editionName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    )}

                    <Typography variant='subtitle2' my={1}>
                        {t('availablePlatforms')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                        {platformsDetails.map((platform) => {
                            const isSelected = selectedPlatform === platform.platformId;
                            const platformLabel = platforms.find(p => p._id === platform.platformId)?.name || 'Unknown Platform';

                            return (
                                <Button
                                    key={platform.platformId}
                                    variant={isSelected ? 'contained' : 'outlined'}
                                    disabled={isSelected}
                                    onClick={() => handlePlatformChange(platform.platformId)}
                                    sx={{ display: 'flex', alignItems: 'center', textTransform: 'none' }}
                                    startIcon={<PlatformLogo platformId={platform.platformId} sx={{ width: '21px', height: '21px' }} />}
                                >
                                    {platformLabel}
                                </Button>
                            );
                        })}
                    </Box>

                    <ProductPriceTag productTagPrice={ProductPrice.toString()} variant='h4' currencyPadding="10px" />
                    <Box sx={{ position: 'absolute', bottom: '-20px', zIndex: '100', gap: 2, display: 'flex' }}>
                        <FavoritesButton productId={productId} platformGroupId={platformGroupId} />
                        <AddToCartButton productId={productId} platformGroupId={platformGroupId} quantity={1} price={ProductPrice} />
                    </Box>
                </Card>
            </Grid>
        </Grid>
    );
};
