import React from 'react';

import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { SvgIconProps } from '@mui/material/SvgIcon';

export const currencyMapping: Record<string, { symbol: React.ElementType<SvgIconProps>; name: string }> = {
  EUR: { symbol: EuroSymbolIcon, name: 'Euro' },
  USD: { symbol: AttachMoneyIcon, name: 'US Dollar' },
  CHF: { symbol: 'CHF', name: 'Swiss Franc' } as any,
  GBP: { symbol: CurrencyPoundIcon, name: 'British Pound' },
  BGN: { symbol: 'Лв', name: 'Bulgarian Lev' } as any,
  CZK: { symbol: 'Kč', name: 'Czech Koruna' } as any,
  DKK: { symbol: 'kr', name: 'Danish Krone' } as any,
  HUF: { symbol: 'Ft', name: 'Hungarian Forint' } as any,
  PLN: { symbol: 'zł', name: 'Polish Zloty' } as any,
  RON: { symbol: 'lei', name: 'Romanian Leu' } as any,
  SEK: { symbol: 'kr', name: 'Swedish Krona' } as any,
  NOK: { symbol: 'kr', name: 'Norwegian Krone' } as any,
  ISK: { symbol: 'kr', name: 'Icelandic Krona' } as any,
};
