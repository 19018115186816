import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const faqItems = [
    {
      panel: 'panel1',
      question: t('faq1Question'),
      answer: (
        <Typography
          variant="body2"
          gutterBottom
          sx={{ maxWidth: { sm: '100%', md: '70%' } }}
        >
          {t('faq1Answer')} <Link href="mailto:support@bigswiss.ch">support@bigswiss.ch</Link>
        </Typography>
      ),
    },
    {
      panel: 'panel2',
      question: t('faq2Question'),
      answer: (
        <Typography
          variant="body2"
          gutterBottom
          sx={{ maxWidth: { sm: '100%', md: '70%' } }}
        >
          {t('faq2Answer')}
        </Typography>
      ),
    },
    {
      panel: 'panel3',
      question: t('faq3Question'),
      answer: (
        <Typography
          variant="body2"
          gutterBottom
          sx={{ maxWidth: { sm: '100%', md: '70%' } }}
        >
          {t('faq3Answer')}
        </Typography>
      ),
    },
    {
      panel: 'panel4',
      question: t('faq4Question'),
      answer: (
        <Typography
          variant="body2"
          gutterBottom
          sx={{ maxWidth: { sm: '100%', md: '70%' } }}
        >
          {t('faq4Answer')}
        </Typography>
      ),
    },
  ];

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        {t('faqTitle')}
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faqItems.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === item.panel}
            onChange={handleChange(item.panel)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${item.panel}d-content`}
              id={`${item.panel}d-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{item.answer}</AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
