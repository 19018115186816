import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Drawer, Box, Divider, Button, List, ListItemText, Collapse, ListItemIcon, ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import navbarConfig from '../../navbarConfig';
import { getPlatformName } from 'utils/getNames';
import PlatformLogo from "../../../icons/PlatformLogos";
import { UserMenuItems } from 'components/Header/Components/LoginButton/UserMenu';

interface DrawerComponentProps {
  open: boolean;
  toggleDrawer: (open: boolean) => () => void;
  scrollToSection: (sectionId: string) => void;
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({
  open,
  toggleDrawer,
  scrollToSection,
}) => {
  const [openGroup, setOpenGroup] = useState<string | null>(null);
  const user = useSelector((state: RootState) => state.auth.user);
  const platforms = useSelector((state: RootState) => state.productInfo.platform.platforms);

  const handleGroupClick = (group: string) => {
    setOpenGroup(prevOpenGroup => (prevOpenGroup === group ? null : group));
  };

  const handleClose = () => {
    toggleDrawer(false)();
  };

  const { t } = useTranslation();
  const isOnline = useSelector((state: RootState) => state.application.isOnline);
  const navConfig = navbarConfig(t, isOnline);
  const companyItem = navConfig.find(item => item.title === t('aboutUs'));

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <Box
        sx={{
          minWidth: '80dvw',
          p: 2,
          backgroundColor: 'background.paper',
          flexGrow: 1,
        }}
      >
        <List>
          {navConfig.filter(item => item.title !== t('aboutUs')).map((item) => (
            <Box key={item.title}>
              <ListItemButton onClick={() => handleGroupClick(item.title)}>
                {item.icon && (
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                )}
                <ListItemText primary={item.title} />
                {openGroup === item.title ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openGroup === item.title} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.platforms.map((platform) => (
                    <ListItemButton
                      key={platform}
                      component={RouterLink}
                      to={item.title === t('giftCards') ? `/products?type=3&platform=${platform}` : `/products?type=1&platform=${platform}`}
                      sx={{ pl: 4 }}
                      onClick={() => {
                        scrollToSection(`platform-${platform}`);
                        toggleDrawer(false)();
                      }}
                    >
                      <ListItemIcon>
                        <PlatformLogo platformId={String(platform) || '/path/to/default/image.svg'} sx={{ width: 24, height: 24, mr: 1 }} />
                      </ListItemIcon>
                      <ListItemText primary={getPlatformName(String(platform), platforms)} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </Box>
          ))}
          {companyItem && (
            <>
              <Box key="aboutUs">
                <ListItemButton onClick={() => handleGroupClick('aboutUs')}>
                  <ListItemText primary={t('aboutUs')} />
                  {openGroup === 'aboutUs' ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openGroup === 'aboutUs'} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {companyItem.about && companyItem.about.map((item) => (
                      <ListItemButton
                        key={item.title}
                        component={RouterLink}
                        to={item.link}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          scrollToSection(item.sectionId);
                          toggleDrawer(false)();
                        }}
                      >
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Box>
              <Box key="getInTouch">
                <ListItemButton onClick={() => handleGroupClick('getInTouch')}>
                  <ListItemText primary={t('getInTouch')} />
                  {openGroup === 'getInTouch' ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openGroup === 'getInTouch'} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {companyItem.getInTouch && companyItem.getInTouch.map((item) => (
                      <ListItemButton
                        key={item.title}
                        component={RouterLink}
                        to={item.link}
                        sx={{ pl: 4 }}
                        onClick={toggleDrawer(false)}
                      >
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Box>
            </>
          )}
        </List>
        {user && (
          <>
            <Divider />
            <UserMenuItems handleClose={handleClose} /> {/* Use UserMenuItems directly */}
          </>
        )}
        {!user && (
          <>
            <Divider />
            <Button
              color="primary"
              variant="contained"
              component="a"
              href="/login"
              sx={{ width: '100%', my: 3 }}
              onClick={toggleDrawer(false)}
            >
              Login
            </Button>

            <Button
              color="primary"
              variant="outlined"
              component="a"
              href="/register"
              sx={{ width: '100%' }}
              onClick={toggleDrawer(false)}
            >
              Register
            </Button>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
