export const getPlatformName = (platformId: string, platforms: any[]): string => {
    const platform = platforms.find(p => p._id === platformId);
    return platform ? platform.name : 'Unknown Platform';
};

export const getCurrencyName = (regionId: string, regions: any[]): string => {
    const region = regions.find(r => r.shortname === regionId);
    return region ? region.currency : 'Unknown Region';
};

export const getCurrencySymbol = (regionId: string, regions: any[]): string => {
    const region = regions.find(r => r.shortname === regionId);
    return region ? region.symbol : 'Unknown Region';
};

export const getRegionName = (regionId: string, regions: any[]): string => {
    const region = regions.find(r => r.shortname === regionId);
    return region ? region.name : 'Unknown Region';
};

export const getCategoryName = (categoryId: string, categories: any[]): string => {
    const category = categories.find(c => c._id === categoryId);
    return category ? category.name : 'Unknown Category';
};

export const getTypeName = (typeId: string, types: any[]): string => {
    const type = types.find(t => t._id === typeId);
    return type ? type.name : 'Unknown Type';
};

export const makeUrlSafe = (string: string | undefined) => {
    if (!string) return '';

    const lowerCaseString = string.toLowerCase();

    if (lowerCaseString.includes("gog.com")) {
        return lowerCaseString.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-.]/g, '').replace(/-com/g, '.com');
    }
    if (lowerCaseString.includes("battle.net")) {
        return lowerCaseString.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-.]/g, '').replace(/-net/g, '.net');
    }
    return lowerCaseString.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
};


export const getProductImgSm = (productId: string): string => {
    const baseUrl = 'https://bucket.bigswiss.ch';
    return `${baseUrl}/product/sm/${productId}.webp`;
};

export const getProductImgLg = (productId: string): string => {
    const baseUrl = 'https://bucket.bigswiss.ch';
    return `${baseUrl}/product/lg/${productId}.webp`;
};

export const getProductCover = (productId: string): string => {
    const baseUrl = 'https://bucket.bigswiss.ch';
    return `${baseUrl}/product/cover/${productId}.webp`;
};

export const getProductScreenshotUrl = (productId: string, fileName: string): string => {
    const baseUrl = 'https://bucket.bigswiss.ch';
    return `${baseUrl}/product/screenshots/${productId}/${fileName}`;
};

export const getUserAvatarUrl = (userId: string): string => {
    const baseUrl = 'https://bucket.bigswiss.ch';
    return `${baseUrl}/avatars/${userId}.webp`;
};
