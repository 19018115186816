import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPaymentIntent, fetchOrderStatus } from './paymentThunks';

interface PaymentState {
  paymentMethod: string | null;
  orderStatus: string | null; 
  clientSecret?: string | null;
  paymentId?: string | null;
  error: string | null;
}

const initialState: PaymentState = {
  paymentMethod: null,
  orderStatus: null, 
  clientSecret: null,
  paymentId: null,
  error: null
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload;
    },
    setOrderStatus: (state, action: PayloadAction<string>) => {
      state.orderStatus = action.payload;
    },
    setClientSecret: (state, action: PayloadAction<string | null>) => {
      state.clientSecret = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    clearPaymentData: (state) => {
      state.clientSecret = null;
      state.orderStatus = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPaymentIntent.fulfilled, (state, action) => {
        state.clientSecret = action.payload.clientSecret;
        state.paymentId = action.payload.paymentId;
      })
      .addCase(createPaymentIntent.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(fetchOrderStatus.fulfilled, (state, action) => {
        state.orderStatus = action.payload.status;
      })
      .addCase(fetchOrderStatus.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  }
});

export const { setPaymentMethod, setOrderStatus, setClientSecret, setError, clearPaymentData } = paymentSlice.actions;

export default paymentSlice.reducer;
