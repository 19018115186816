import React from 'react';
import { Web as BrowserIcon, Key as KeylessIcon } from '@mui/icons-material';
import MicrosoftStoreIcon from 'components/icons/Platforms/MicrosoftIcon';
import ActivationGuide from './ActivationGuide';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MicrosoftStoreInstructions: React.FC = () => {
    const { t } = useTranslation();

    const steps = {
        default: [
            {
                label: t('activationMethod'),
                description: t('chooseActivation'),
                options: [
                    { title: t('Browser'), icon: <BrowserIcon sx={{ width: '64px', height: '64px' }} />, method: 'browser' },
                    { title: t('Windows Store'), icon: <MicrosoftStoreIcon sx={{ width: '64px', height: '64px' }} />, method: 'msStoreApp' },
                    { title: t('keylessActivation'), icon: <KeylessIcon sx={{ width: '64px', height: '64px' }} />, method: 'keyless' },
                ]
            }
        ],
        browser: [
            { label: t('activationMethod'), description: '', imageUrl: '' },
            {
                label: t('activationLink'),
                description: t('microsoftStep1'),
                imageUrl: '/img/activation/activation-link.webp',
                actionButton: (
                    <Button
                        variant="contained"
                        color="secondary"
                        href={`https://account.microsoft.com/billing/redeem`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('goToMicrosoftActivation')}
                    </Button>
                ),
            },
            {
                label: t('login'),
                description: t('microsoftStep2'),
                imageUrl: '/img/activation/microsoft-login.webp',
            },
            {
                label: t('enterTheKey'),
                description: t('microsoftStep3'),
                imageUrl: '/img/activation/microsoft-clickon-redeem-code.webp',
            },
            {
                label: t('confirmActivation'),
                description: t('microsoftStep4'),
                imageUrl: '/img/activation/download.webp',
            }
        ],
        msStoreApp: [
            { label: t('activationMethod'), description: '', imageUrl: '' },
            { label: t('Open Store'), description: t('microsoftStep2app'), imageUrl: '/img/activation/microsoft-app-open-app.webp' },
            { label: t('goToActivation'), description: t('microsoftStep3app'), imageUrl: '/img/activation/microsoft-app-clickon-redeem-code.webp' },
            { label: t('enterTheKey'), description: t('microsoftStep3'), imageUrl: '/img/activation/microsoft-app-redeem-code.webp' },
            {
                label: t('confirmActivation'),
                description: t('microsoftStep4'),
                imageUrl: '/img/activation/download.webp',
            }
        ]
    };

    return <ActivationGuide steps={steps} />;
};

export default MicrosoftStoreInstructions;
