import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PsStoreIcon = (props: SvgIconProps) => {

  return (
      <SvgIcon
      {...props}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
    >
      <g clipPath="url(#clip0_295_9)">
        <path d="M22.9086 1.76466C23.2433 1.73424 23.578 1.79509 23.8822 1.91679C21.7221 4.86803 21.3265 10.4358 21.2657 12.5352H22.7565C22.8478 9.79691 23.365 5.05058 25.0688 2.79912C27.0465 4.86803 28.3243 9.40139 28.7198 12.5352H30.2107C29.8152 9.15798 28.4156 4.2291 26.1641 1.79509C26.5292 1.61254 26.8943 1.52126 27.2899 1.52126C28.0505 1.55168 28.7807 1.91679 29.2675 2.52529C31.4581 4.92888 32.4317 10.4663 32.6447 12.5352H34.1355C33.953 10.2229 32.9794 4.98973 30.88 2.09934C29.8152 0.69978 28.6286 0 27.3203 0C26.4988 0 25.6773 0.273827 25.0384 0.791055C24.369 0.365102 23.578 0.212976 22.8174 0.273827C18.8621 0.669354 17.7972 6.87609 17.6451 12.5352H19.1359C19.2576 5.90249 20.6267 2.00806 22.9086 1.76466Z" fill="#F48250" />
        <path d="M43.9324 12.4439C43.902 12.2614 43.7499 12.1092 43.5369 12.1092L39.5816 11.5312H39.4599C37.9387 11.5312 32.3405 11.5312 23.6388 11.5312C14.9372 11.5312 9.30858 11.6529 7.78732 11.6833C7.57435 11.6833 7.36137 11.8658 7.36137 12.0788C7.27009 13.6001 6.93542 19.533 6.44861 28.5997C5.93139 38.214 5.62713 43.4776 5.56628 44.8163C5.56628 45.0293 5.71841 45.2118 5.93139 45.2118L21.6916 47.6154L37.2389 49.9886C37.3606 50.019 37.4823 49.9886 37.604 49.8973L45.332 44.938C45.545 44.8163 45.6363 44.5729 45.6363 44.3295L43.9324 12.4439Z" fill="url(#paint0_linear_295_9)" />
        <path d="M24.1561 22.2712C23.6388 22.1191 23.3041 22.6059 23.3041 23.3057V38.7313L19.1359 37.423V19.0157C21.0831 19.4113 22.9999 19.9589 24.8558 20.5979C28.3852 21.8149 29.6022 23.3057 29.6022 26.6829C29.6022 30.0601 27.5637 31.2467 24.9775 29.9992V23.8229C24.9471 23.1231 24.8254 22.4538 24.1561 22.2712ZM33.2228 31.9465C31.3668 31.3075 29.3892 31.0641 27.442 31.2771C26.3771 31.3988 25.3426 31.6118 24.3386 31.9465L24.0952 32.0377V34.4717L28.5677 32.8896C29.3588 32.6158 30.2411 32.555 31.0626 32.7679C31.6711 32.9809 31.5494 33.346 30.8192 33.6198L24.1256 35.993V38.3966L33.2228 35.1411C33.8617 34.8977 34.4702 34.5326 34.957 34.0458C35.4134 33.4981 35.17 32.555 33.2228 31.9465ZM10.0692 33.346C9.15643 34.0154 9.49111 35.2019 11.4992 35.78C13.5681 36.4798 15.7587 36.6624 17.9189 36.2973L18.3448 36.2364V34.0762L16.2759 34.8064C15.4849 35.0802 14.6025 35.1411 13.7811 34.9281C13.1726 34.7151 13.2943 34.35 14.0549 34.0762L18.3448 32.555V30.1209L12.3815 32.2507C11.56 32.5245 10.7994 32.8896 10.0692 33.346ZM32.1275 38.7313H32.3709V36.9666H32.9185V36.7536H31.5189V36.9666H32.1275V38.7313ZM33.6183 36.7536H33.2228V38.7313H33.4662V36.9362L34.0442 38.7313H34.3485L34.9266 36.9362V38.7313H35.17V36.7536H34.744L34.1964 38.5183L33.6183 36.7536Z" fill="white" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_295_9" x1="10.5407" y1="12.801" x2="40.7979" y2="49.4686" gradientUnits="userSpaceOnUse">
          <stop stopColor="#01BBEF" />
          <stop offset="1" stopColor="#0074BC" />
        </linearGradient>
        <clipPath id="clip0_295_9">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default PsStoreIcon;