import { createSlice } from '@reduxjs/toolkit';
import { GenreState } from '../types/dataTypes';
import { fetchGenres } from '../actions/fetchDataActions';

const initialState: GenreState = {
  loading: false,
  genres: [],
  error: null,
};

const genreSlice = createSlice({
  name: 'genre',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGenres.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGenres.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.genres = payload;
      })
      .addCase(fetchGenres.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || 'Failed to fetch genres';
      });
  },
});

export default genreSlice.reducer;
