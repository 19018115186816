import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid2 as Grid, Typography, Tabs, Tab, Box } from '@mui/material';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import AppleIcon from '@mui/icons-material/Apple';
import LinuxIcon from '@mui/icons-material/Computer';

import SiteTitle from 'components/SiteTitle';

interface RequirementsProps {
    OS?: string;
    Processor?: string;
    Memory?: string;
    Graphics?: string;
    DirectX?: string;
    Storage?: string;
    AdditionalNotes?: string;
    VRSupport?: string;
    SoundCard?: string;
    _id?: string;
}

interface ProductRequirementsProps {
    minimumRequirements: RequirementsProps;
    recommendedRequirements: RequirementsProps;
    minimumRequirementsMac?: RequirementsProps;
    recommendedRequirementsMac?: RequirementsProps;
    minimumRequirementsLinux?: RequirementsProps;
    recommendedRequirementsLinux?: RequirementsProps;
}

const filterEmptyFields = (requirements: RequirementsProps) => {
    return Object.entries(requirements).filter(([key, value]) => key !== '_id' && value);
}

const RequirementsSection = ({ title, requirements }: { title: string; requirements: RequirementsProps }) => {
    const { t } = useTranslation();
    const filteredRequirements = filterEmptyFields(requirements);
    if (filteredRequirements.length === 0) return null;

    return (
        <Box>
            <Typography variant="h6" gutterBottom>{t(title)}</Typography>
            {filteredRequirements.map(([key, value]) => (
                <Typography key={key}>
                    <span style={{ fontWeight: 'bold' }}>{t(key)}:</span> {value}
                </Typography>
            ))}
        </Box>
    );
};

export const ProductRequirements = ({
    minimumRequirements,
    recommendedRequirements,
    minimumRequirementsMac,
    recommendedRequirementsMac,
    minimumRequirementsLinux,
    recommendedRequirementsLinux,
}: ProductRequirementsProps) => {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        const platform = navigator.platform.toLowerCase();
        const hasMacRequirements = minimumRequirementsMac && recommendedRequirementsMac && filterEmptyFields(minimumRequirementsMac).length > 0;
        const hasLinuxRequirements = minimumRequirementsLinux && recommendedRequirementsLinux && filterEmptyFields(minimumRequirementsLinux).length > 0;

        if (platform.includes('mac') && hasMacRequirements) {
            setTabIndex(1);
        } else if (platform.includes('linux') && hasLinuxRequirements) {
            setTabIndex(2);
        } else {
            setTabIndex(0);
        }
    }, [minimumRequirementsMac, recommendedRequirementsMac, minimumRequirementsLinux, recommendedRequirementsLinux]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const hasMacRequirements = minimumRequirementsMac && recommendedRequirementsMac && filterEmptyFields(minimumRequirementsMac).length > 0;
    const hasLinuxRequirements = minimumRequirementsLinux && recommendedRequirementsLinux && filterEmptyFields(minimumRequirementsLinux).length > 0;

    return (
        <Grid container spacing={6} sx={{ mb: 5 }}>
            <Grid size={{ xs: 12 }}>
                <SiteTitle firstTitle={t('game')} secondTitle={t('requirements')} variant="h4" color="primary" />
            </Grid>
            <Grid size={{ xs: 12 }}>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="requirements tabs">
                    <Tab label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MicrosoftIcon sx={{ mr: 1 }} />
                            Windows
                        </Box>
                    } />
                    {hasMacRequirements && (
                        <Tab label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <AppleIcon sx={{ mr: 1 }} />
                                Mac
                            </Box>
                        } />
                    )}
                    {hasLinuxRequirements && (
                        <Tab label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <LinuxIcon sx={{ mr: 1 }} />
                                Linux
                            </Box>
                        } />
                    )}
                </Tabs>
            </Grid>
            <Grid size={{ xs: 12 }}>
                {tabIndex === 0 && (
                    <Grid container spacing={6}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <RequirementsSection title="minimumRequirements" requirements={minimumRequirements} />
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <RequirementsSection title="recommendedRequirements" requirements={recommendedRequirements} />
                        </Grid>
                    </Grid>
                )}
                {tabIndex === 1 && hasMacRequirements && (
                    <Grid container spacing={6}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <RequirementsSection title="minimumRequirements" requirements={minimumRequirementsMac} />
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <RequirementsSection title="recommendedRequirements" requirements={recommendedRequirementsMac} />
                        </Grid>
                    </Grid>
                )}
                {tabIndex === 2 && hasLinuxRequirements && (
                    <Grid container spacing={6}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <RequirementsSection title="minimumRequirements" requirements={minimumRequirementsLinux} />
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <RequirementsSection title="recommendedRequirements" requirements={recommendedRequirementsLinux} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
