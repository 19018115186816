import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { alpha, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import LogoCollection from './Components/LogoCollection';
import Pricing from './Components/Pricing';
import FAQ from './Components/FAQ';

export default function AboutPosPage() {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        alignItems: 'center',
        pt: { xs: 14, sm: 20 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      <Box
        id="image"
        sx={(theme) => ({
          mb: { xs: 8, sm: 10 },
          alignSelf: 'center',
          height: { xs: 200, sm: 300 },
          width: '100%',
          backgroundImage: 'url("/img/poscover.webp")',
          backgroundSize: 'cover',
          backgroundPositionY: 'bottom',
          borderRadius: '10px',
          outline: '1px solid',
          ...theme.applyStyles('light', {
            outlineColor: alpha('#BFCCD9', 0.5),
            boxShadow: `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`,
          }),
          ...theme.applyStyles('dark', {
            outlineColor: alpha('#9CCCFC', 0.1),
            boxShadow: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          }),
        })}
      />


      <Box sx={{ padding: 3 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          {t('aboutOurPosTerminals')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('welcomeMessage')}
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          {t('whyChooseOurPosTerminals')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('posTerminalFeatures')}
        </Typography>
        <ul>
          <li>{t('feature1')}</li>
          <li>{t('feature2')}</li>
          <li>{t('feature3')}</li>
          <li>{t('feature4')}</li>
          <li>{t('feature5')}</li>
        </ul>
        <Typography variant="body1" paragraph>
          {t('posTerminalDescription')}
        </Typography>

        <Divider sx={{ marginY: 4 }} />

        <LogoCollection />

        <Pricing />

        <FAQ />
      </Box>
    </Container>
  );
}
