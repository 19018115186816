import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const XboxXIcon = (props: SvgIconProps) => {

  return (
      <SvgIcon
      {...props}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
    >
      <path d="M23.0568 49.8994C19.2821 49.5379 15.4607 48.1832 12.1781 46.0425C9.42739 44.2496 8.80662 43.5113 8.80662 42.04C8.80662 39.0842 12.0585 33.9062 17.6226 28.0049C20.7816 24.6523 25.1828 20.7249 25.6583 20.8308C26.5843 21.0374 33.9822 28.2496 36.7523 31.6454C41.1318 37.0129 43.1445 41.4093 42.1216 43.369C41.3443 44.8586 36.5194 47.7706 32.9754 48.889C30.0538 49.8111 26.217 50.2016 23.0568 49.8994ZM5.09116 38.9669C2.80516 35.4618 1.6502 32.0114 1.09322 27.0207C0.908703 25.3729 0.973627 24.4309 1.51067 21.0476C2.1787 16.8356 4.58031 11.9577 7.46771 8.95803C8.69671 7.68304 8.80605 7.64889 10.305 8.15547C12.1217 8.7702 14.0637 10.1112 17.0764 12.8342L18.8362 14.4245L17.8737 15.6028C13.4156 21.0727 8.71038 28.8308 6.93921 33.629C5.97674 36.2359 5.58948 38.8542 6.00522 39.9442C6.28428 40.6807 6.028 40.4064 5.08831 38.9686L5.09116 38.9669ZM45.2146 39.5629C45.4407 38.4609 45.1548 36.438 44.4857 34.3969C43.0363 29.9771 38.1903 21.7551 33.7408 16.1646L32.3398 14.4046L33.8558 13.0141C35.8343 11.1984 37.2079 10.1112 38.6909 9.18741C39.8601 8.45885 41.5316 7.81396 42.2503 7.81396C42.6929 7.81396 44.2527 9.43615 45.5113 11.2006C47.4613 13.9328 48.8954 17.2511 49.622 20.7004C50.0919 22.9317 50.1312 27.7015 49.6978 29.927C49.339 31.753 48.5872 34.1197 47.8583 35.7254C47.3059 36.9281 45.9447 39.2646 45.3468 40.025C45.0392 40.4161 45.0392 40.4155 45.2101 39.572L45.2146 39.5629ZM23.4612 6.97952C21.4075 5.9379 18.2399 4.81944 16.4904 4.51777C15.8776 4.41247 14.8314 4.35271 14.1668 4.38686C12.7226 4.45971 12.7869 4.38458 15.1025 3.29117C17.0275 2.38217 18.6335 1.8477 20.8147 1.39007C23.2664 0.874951 27.8766 0.869259 30.2896 1.37755C32.8951 1.92682 35.9641 3.06804 37.6931 4.13243L38.208 4.44776L37.0291 4.38857C34.685 4.27017 31.2691 5.21674 27.6015 6.99944C26.4955 7.5379 25.5342 7.96707 25.4636 7.95568C25.3935 7.94202 24.492 7.50318 23.4589 6.97952H23.4612Z" fill="#107C10" />
    </SvgIcon>
  );
}

export default XboxXIcon;