// Import your platform-specific instruction components
import { Typography } from '@mui/material';

import SteamInstructions from './Steam';
import GOGInstructions from './GOG';
import MicrosoftStoreInstructions from './MicrosoftStore';
import EpicInstructions from './Epic';
import RockstarInstructions from './Rockstar';
import SwitchInstructions from './Switch';
import EAPlayInstructions from './EAPlay';
import PlayStationInstructions from './PlayStation';
import XboxInstructions from './Xbox';
import BlizzardInstructions from './Blizzard';
import UbisoftInstructions from './Ubisoft';

interface RenderInstructionsProps {
    platformDetails: { platformId: string; region: string } | null;
    actualKey: string;
}

// Function to render the correct instruction component based on platformId
export const renderInstructions: React.FC<RenderInstructionsProps> = ({ platformDetails, actualKey }) => {
    switch (platformDetails?.platformId) {
        case '1':
            return <BlizzardInstructions keyCode={actualKey} />;
        case '7':
            return <SteamInstructions keyCode={actualKey} />;
        case '4':
            return <GOGInstructions keyCode={actualKey} />;
        case '5':
            return <MicrosoftStoreInstructions />;
        case '3':
            return <EpicInstructions keyCode={actualKey} />;
        case '6':
            return <RockstarInstructions keyCode={actualKey} />;
        case '13':
            return <SwitchInstructions keyCode={actualKey} />;
        case '2':
            return <EAPlayInstructions />;
        case '8':
            return <UbisoftInstructions keyCode={actualKey} />;
        case '9':
        case '10':
            return <PlayStationInstructions keyCode={actualKey} />;
        case '11':
        case '12':
            return <XboxInstructions keyCode={actualKey} />;
        default:
            return <Typography variant="body2">No specific instructions available.</Typography>;
    }
};