import { Computer as ComputerIcon, CardGiftcard as CardGiftcardIcon } from "@mui/icons-material";
import PsJoystickIcon from "components/icons/Platforms/PsJoystickIcon";
import XboxJoystickIcon from "components/icons/Platforms/XboxJoystickIcon";
import NintendoJoystickIcon from "components/icons/Platforms/NintendoJoystickIcon";
import PsIcon from "../icons/Platforms/PsIcon";
import XboxXIcon from "../icons/Platforms/XboxXIcon";
import ProductsDropdown from "components/Header/Components/DropdownContents/ProductsDropdown";
import CompanyDropdown from "components/Header/Components/DropdownContents/CompanyDropdown";

const navbarConfig = (t: (key: string) => string, isOnline: boolean) => {
    const config = [
        {
            title: "PC",
            titleHide: true,
            groupTitle: t('platforms'),
            groupIcon: <ComputerIcon sx={{ color: 'text.secondary' }} />,
            icon: <ComputerIcon />,
            dropdown: ProductsDropdown,
            platforms: [7, 1, 2, 3, 4, 5, 6, 8],
            giftCards: [
                // { title: "Blizzard Balance Card", platformId: 1, type: 3, imgId: '1' },
                { title: "EA Cash Card", platformId: 2, type: 3, imgId: '2' },
                // { title: "Steam Gift Card", platformId: 7, type: 3, imgId: '7' },
            ]
        },
        {
            title: "Xbox",
            titleHide: true,
            groupTitle: t('games'),
            groupIcon: <XboxJoystickIcon sx={{ color: 'text.secondary' }} />,
            icon: <XboxXIcon sx={{ width: '20px', height: '24px' }} />,
            dropdown: ProductsDropdown,
            platforms: [12],
            giftCards: [
                { title: "XBOX Gift Card", platformId: 12, type: 3, imgId: '11' },
                { title: "XBOX Game Pass", platformId: 12, type: 4, imgId: '11' }
            ]
        },
        {
            title: "PlayStation",
            titleHide: true,
            groupTitle: t('games'),
            groupIcon: <PsJoystickIcon sx={{ color: 'text.secondary' }} />,
            icon: <PsIcon sx={{ width: '24px', height: '24px' }} />,
            dropdown: ProductsDropdown,
            platforms: [10],
            giftCards: [
                { title: "Networt Card", platformId: 10, type: 3, imgId: '14' },
                { title: "PlayStation Plus", platformId: 10, type: 4, imgId: '15' },
            ]
        },
        {
            title: "Nintendo",
            titleHide: true,
            groupTitle: t('games'),
            groupIcon: <NintendoJoystickIcon sx={{ color: 'text.secondary' }} />,
            icon: <NintendoJoystickIcon />,
            dropdown: ProductsDropdown,
            platforms: [13],
            giftCards: [
                { title: "Nintendo Eshop Card", platformId: 13, type: 3, imgId: '16' },
                { title: "Nintendo Online", platformId: 13, type: 4, imgId: '17' }
            ]
        },
        {
            title: t('giftCards'),
            titleHide: true,
            groupTitle: t('platforms'),
            icon: <CardGiftcardIcon />,
            dropdown: ProductsDropdown,
            platforms: [ 2, 3, 4, 5, 6, 8, 10, 12, 13]
        },
        {
            title: t('aboutUs'),
            titleHide: false,
            groupTitle: t('company'),
            icon: null,
            dropdown: CompanyDropdown,
            platforms: [],
            about: [
                { title: t('aboutUs'), link: '/about#products', sectionId: 'products' },
                { title: t('highlights'), link: '/about#highlights', sectionId: 'highlights' },
                //  { title: t('testimonials'), link: '/about#testimonials', sectionId: 'testimonials' },
                { title: t('pricing'), link: '/about#pricing', sectionId: 'pricing' },
                { title: t('faqs'), link: '/about#faqs', sectionId: 'faq' }
            ],
            getInTouch: [
                { title: t('careers'), link: '/careers' },
                { title: t('contactUs'), link: '/contactus' }
            ]
        }
    ];

    // Filter out specific items if offline
    if (!isOnline) {
        return config.filter(item => item.title === t('aboutUs'));
    }

    return config;
};

export default navbarConfig;
